//componente per l'immagine profilo della dashboard
import { useEffect, useState } from "react"
import { getImage } from "./Engine/Engine"
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { UpSection } from "./UpSection";
export function ProfileImage(props){

const [image,setImage] = useState(null)
const [upSection,setUpSection] = useState(false)

    useEffect(()=>{
        getImage(props.db,props.auth,setImage) //funzione che prende immagine
    },[props.auth,props.db])

    return (
        <div>
            {upSection===true?<UpSection setImage={setImage} setTrigger={setUpSection} db={props.db} auth={props.auth} />:null}
             {image===null?<div
        style={{
          width: "4vw",
          height: "4vw",
          borderRadius: "50%",
          backgroundColor: "#0496ff",
          marginLeft: "auto",
          marginRight: "auto",
          position: "relative",
        }}
      >
        <div onClick={()=>setUpSection(true)} style={{
            cursor:'pointer',
            position: "absolute",
           borderBottomRightRadius:50,
           borderBottomLeftRadius:50,
            left: 0,
            right: 0,
            color: "white",
            bottom: 0,
            textAlign: "center",
            marginTop: 0,
            marginBottom: 0,
            fontFamily: "Rubik",
            height:'2vw',
            backgroundColor:'rgba(0,0,0,0.6)',
            width:'4vw',zIndex:100
          }}>
            <PhotoCameraIcon style={{marginTop:5,marginBottom:'auto',marginLeft:'auto',marginRight:'auto',width:'fit-content'}}/>
        </div>
        <h4
          style={{
            position: "absolute",
            top: "0.7vw",
            left: 0,
            right: 0,
            fontSize: "2vw",
            color: "white",
            bottom: 0,
            textAlign: "center",
            marginTop: 0,
            marginBottom: 0,
            fontFamily: "Rubik",
            zIndex:99
          }}
        >
          {props.ragsoc[0]}
        </h4>
      </div>:<div
        style={{
          width: "4vw",
          height: "4vw",
          borderRadius: "50%",
          backgroundColor: "#0496ff",
          marginTop: "2vh",
          marginLeft: "auto",
          marginRight: "auto",
          position: "relative",
        }}
      >
         <div onClick={()=>setUpSection(true)} style={{
            cursor:'pointer',
            position: "absolute",
           borderBottomRightRadius:50,
           borderBottomLeftRadius:50,
            left: 0,
            right: 0,
            color: "white",
            bottom: 0,
            textAlign: "center",
            marginTop: 0,
            marginBottom: 0,
            fontFamily: "Rubik",
            height:'2vw',
            backgroundColor:'rgba(0,0,0,0.6)',
            width:'4vw',zIndex:100
          }}>
            <PhotoCameraIcon style={{marginTop:5,marginBottom:'auto',marginLeft:'auto',marginRight:'auto',width:'fit-content'}}/>
        </div>
        <img src={image} alt='profile_image' style={{width:'4vw',height:'4vw',borderRadius: "50%",}}></img></div>}
        </div>
       
    )
}