import React, {useEffect,useState} from 'react'
import NavBar from '../NavBar/NavBar';
import Notifiche from '../Notifiche/Notifiche';
import firebase from "firebase";
import { getUtente } from '../EngineClienti/engineClienti';
import { getSedi } from '../EngineClienti/engineClienti';
import { Tooltip } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import PlaceIcon from '@mui/icons-material/Place';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
const MappeCliente = ({history}) => {

    const db = firebase.firestore()
    const auth = firebase.auth()

    const [user,setUser] = useState(null)
    const [sedi,setSedi] = useState([])

    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
            getUtente(db,auth,setUser)
          }
        })
      },[auth])

      
  useEffect(()=>{
    if(user!==null){
      getSedi(db,user.Email,setSedi)
  }
},[user])

  return (
    <div className="background" style={{width:'100vw',height:'100vh'}}>
    <NavBar/>
    {user!==null&&user!==null?<Notifiche user={user}/>:null}

    <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
    <div className="container-dash">
      <h4 className="page-title" style={{marginTop:'1.2rem'}}>MAPPE</h4>
     
    </div>
        <div style={{width:'75vw',height:'84vh',overflowY:'auto',paddingTop:'1vh'}}>
            <div style={{marginTop:'1vh',alignItems:'center',display: "grid", gridTemplateColumns: "repeat(5, 1fr)", gridGap: 1}}>
              {sedi.map((sede,x)=>(
                <div key={x} onClick={()=>history.push(`/mappecliente/${user.Email}/${sede.Id}/piani`)} className='box-shadow' style={{width:'10vw',height:'10vw',marginTop:'1vh',borderRadius:10,padding:5,cursor:'pointer',backgroundColor:'white'}}>
                  <Tooltip title={sede.Comune}>
                  <div style={{display:'flex',alignItems:'center'}}><BusinessIcon style={{width:'1vw',height:'1vw',color:"#192bc2"}}/>
                  <h4 className='normal-text' style={{color:'#192bc2',maxWidth:'8.6vw',whiteSpace: 'nowrap',overflow: 'hidden',
                  textOverflow: 'ellipsis',fontWeight:'bold'}}>{sede.Nome?sede.Nome:sede.Comune}</h4>
                  </div>
                  </Tooltip>
                  <div style={{display:'flex',alignItems:'center'}}><PlaceIcon style={{width:'1vw',height:'1vw',color:"#677483"}}/>
                  <h4 className='sub-text' style={{whiteSpace: 'nowrap',overflow: 'hidden',
                  textOverflow: 'ellipsis',maxWidth:'8.6vw'}}>{sede.Indirizzo}</h4>
                  </div>
                  <div style={{display:'flex',alignItems:'center'}}><LocalPhoneIcon style={{width:'1vw',height:'1vw',color:"#677483"}}/>
                  <h4 className='sub-text' style={{whiteSpace: 'nowrap',overflow: 'hidden',
                  textOverflow: 'ellipsis',maxWidth:'8.6vw'}}>{sede.Numero}</h4>
                  </div>
                  <div style={{display:'flex',alignItems:'center'}}>
                  <h4 className='sub-text' style={{whiteSpace: 'nowrap',overflow: 'hidden',
                  textOverflow: 'ellipsis',maxWidth:'8.6vw'}}>Piani: {sede.Numero_Piani?sede.Numero_Piani:0}</h4>
                  </div>

                </div>
              ))}
              </div>

        </div>
      </div>
  </div>
  )
}

export default MappeCliente