//sezione account
import React,{useState} from "react";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { IconButton,Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import firebase from "firebase/app";
import AddIcon from '@mui/icons-material/Add';
const Allegati = (props) => {
  const auth = firebase.auth();
  const db = firebase.firestore();
  const storage = firebase.storage();
  var storageRef = storage.ref();

  const [allegati,setAllegati] = useState(props.intervento.Allegati)
  const [uploadPercentage,setUploadPercentage] = useState(0) //percentuale caricamento


  function handleFile(e){
    var files = e.target.files;
    var newAll = [];
    for(let i=0;i<files.length;i++){
        newAll.push(files[i])
    }
   aggiungiAllegati(files)
} //chiama aggiungi allegati

function aggiungiAllegati(files){
    db.collection("Attività").doc(props.intervento.Id).get().then((doc)=>{
            var allegatiAggiunti;
            if(allegati===undefined)
               allegatiAggiunti = []
            else
                allegatiAggiunti = [...allegati];
            
    
            for(let i=0;i<files.length;i++){
                    const nameTmp = files[i].name;
                const fileRef = storageRef.child(
                    props.user.Email+
                    "/"+
                    props.intervento.Id+
                    "/"+
                    nameTmp
                )
                const uploadTask = storageRef.child(
                  props.user.Email+
                  "/"+
                  props.intervento.Id+
                  "/"+
                  nameTmp
                ).put(files[i])

                uploadTask.on('state_changed',
                (snapshot)=>{
                    var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setUploadPercentage(progress)
                },
                (error)=>{console.log(error)},
                ()=>{

                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL)=>{

                        allegatiAggiunti.push({Allegato:downloadURL,AggiuntoDa:props.user.Email,Nome:nameTmp,StorageRef: fileRef.fullPath})
                      
                        if(i === files.length - 1){ // ho aggiunto tutti gli allegati, quindi posso caricare le ref sull'intervento
                          console.log(allegatiAggiunti)
                            db.collection("Attività").doc(props.intervento.Id)
                            .update({
                                Allegati:allegatiAggiunti
                            })
                            .then(()=>{
                              setUploadPercentage(0)
                                setAllegati(allegatiAggiunti)
                                alert("Allegati aggiunti correttamente")
                        })
                        }
                    })
                  })
                    
                }
    })
} //aggiunge gli allegati

  function deleteAllegato(all){
    if(window.confirm("Sei sicuro di voler eliminare l'allegato: "+all.Nome+"?")){
      var arr = [...allegati]
      var arr2 = props.intervento
      var index = arr.findIndex((e)=>e.Allegato===all.Allegato)
      arr.splice(index,1)
      arr2.Allegati = arr
      setAllegati(arr)
      props.setIntervento(arr2)

      db.collection('Attività').doc(props.intervento.Id).update({
        Allegati: arr
      }).then(()=>{
        if(all.StorageRef){
          var deleteRef = storageRef.child(all.StorageRef);
          deleteRef.delete()

        }
      })


      

    }
  }

    return <div style={{paddingLeft:'1rem',height:'80vh',overflowY:'auto',width:'71vw'}}>
      {props.intervento!==null?<div>
        <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
        <FilePresentIcon style={{color:'black'}}/>

      <h4 className="header-normal" style={{marginLeft:'5px'}}>Allegati {uploadPercentage!==0?uploadPercentage+'%':null}</h4>
      <Button component="label" style={{marginLeft:"5px",backgroundColor:'transparent', color:'black',
                 width:'fit-content', cursor:'pointer'}}>
                <AddIcon/>
                <input hidden multiple type="file" onChange={handleFile}/>

            </Button>
      </div>
      {allegati?allegati.length>0?allegati.map((all,n)=>(
        <div key={n} style={{marginTop:'1rem',borderBottom:'1px solid lightgrey'}}>
          <h4 className="normal-text2" onClick={()=>window.open(all.Allegato, "_blank")}  style={{maxWidth:'30rem',whiteSpace: 'nowrap',color:'#192bc2',fontWeight:'bold',fontSize:'0.9rem',
overflow: 'hidden',
textOverflow: 'ellipsis',cursor:'pointer'}}>{all.Nome}</h4>
      <button className="transparent-button" style={{width:'fit-content'}} onClick={()=>deleteAllegato(all)}>
        <DeleteIcon style={{color:'#FF0060'}} />
      </button>
          </div>
      )):<h4 className="medium-text" style={{marginTop:'1rem'}}>Nessun allegato presente</h4>:<h4 style={{marginTop:'1rem'}} className="medium-text">Nessun allegato presente</h4>}

      <div style={{alignItems:'center',marginTop:'5vh',marginLeft:'auto',width:'fit-content'}}>
        </div>
    </div>:null}
    </div>
}

export default Allegati;