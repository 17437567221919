//form di login
import React, { useCallback, useState } from "react";
import '../Style/login.css'
import app from "../../base";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
export function FormLogin(props) {
  const [passwordShown, setPasswordShown] = useState(false); //variabile per visualizzare la pw o no

  const handleLogin = useCallback(
    //funzione per gestire il login
    async (event) => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await app
          .auth()
          .signInWithEmailAndPassword(email.value, password.value)
          .then(() => {
            props.history.push("/home"); //se loggato manda alla homepage
          }); //funzione di login firebase
      } catch (error) {
        alert("Credenziali errate!");
        console.log(error);
      }
    },
    [props.history]
  ); //funzione di login

  return (
    <form onSubmit={handleLogin}>
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width: "fit-content",
        }}
      >
        <label
          style={{
            fontSize: "1vw",
            color: "black",
            fontWeight: "normal",
            fontFamily: "Montserrat",
            marginBottom: 0,
          }}
        >
          Email
        </label>
        <br />

        <input
           className="loginInput"
          type="email"
          name="email"
        />
      </div>
      <br />
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width: "fit-content",
        }}
      >
        <label
          style={{
            fontSize: "1vw",
            color: "black",
            fontWeight: "normal",
            fontFamily: "Montserrat",
            marginBottom: 0,
          }}
        >
          Password
        </label>
        <br />

        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            width: "fit-content",
            position:'relative'

          }}
        >
          <input
           className="loginInput"
            name="password"
            style={{borderRadius:5}}
            type={passwordShown===true ? "text" : "password"}
          />
 <button type="button" onClick={()=>setPasswordShown(!passwordShown)} style={{position:'absolute',right:0,width:'fit-content',backgroundColor:'transparent',border:'none',height:'100%'}}>
          {passwordShown===true?<VisibilityOffIcon style={{color:'black',marginTop:5,marginBottom:'auto'}}/>:<VisibilityIcon style={{color:'black',marginTop:5,marginBottom:'auto'}}/>}
        </button>
        </div>
        {/* icona per mostrare o no la pw*/}
       
      </div>
      <div style={{ height: "15px" }}></div>

      <div
        style={{
          marginTop: "3vh",
          marginLeft: "auto",
          marginRight: "auto",
          width: "fit-content",
        }}
      >
        <button
          type="submit"
          style={{
            backgroundColor: "#0496ff",
            color: "white",
            fontWeight: "bold",
            fontFamily: "Montserrat",
            fontSize: "0.9vw",
            border: "1px solid #118ab2",
            borderRadius: 5,
            width: "30vw",
            cursor: "pointer",
          }}
        >
          ACCEDI
        </button>
      </div>
    </form>
  );
}
