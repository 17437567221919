//sezione account
import React, {useState} from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BuildIcon from '@mui/icons-material/Build';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import firebase from "firebase";
import FactCheckIcon from '@mui/icons-material/FactCheck';
const Operazioni = (props) => {

  const db = firebase.firestore()
  const auth = firebase.auth()

  const Pianifica = () => {

    const [date,setDate] = useState(null)
    const [hour,setHour] = useState(null)

    const handleSubmit = () => { //funzione di pianificazione
      if(date!==null&&hour!==null){
        const dateSplitted = date.split("-");
  const day = dateSplitted[2];
  const month = dateSplitted[1];
  const year= dateSplitted[0];
  console.log(year, month,day)

         // aggiungo la notifica al cliente
         var date2 = new Date();
         var days2 = date2.getDate();
         if(days2<10){
           days2 = '0' + days2;
         }
             var month2 = ('0'+(date2.getMonth()+1)).slice(-2);
             var minutes2 =  ('0'+(date2.getMinutes())).slice(-2);
               var dataFormattata = days2+"-"+month2+"-"+date2.getFullYear()+" "+(date2.getHours())+":"+minutes2;
        db.collection("Users").doc(props.intervento.Cliente).collection("Notifiche").doc().set({
      Nome_Azienda:props.user.Nome_Azienda,
      Nome_Cognome:props.user.Nome+" "+props.user.Cognome,
      Email:props.user.Email,
      Messaggio:"ha pianificato un intervento",
      Data:dataFormattata,
      Oggetto: props.intervento.Titolo,
      InterventoID: props.intervento.Id
    })
    db.collection("Attività") //aggiorna attività
    .doc(props.intervento.Id)
    .update({
      Data: date,
      Stato: "Pianificata",
      Hour: hour,
    });
      
      db.collection("Users") //inserisce sul calendario dell'utente loggato l'attività
        .doc(props.user.Email)
        .collection("Annotazioni")
        .doc(props.intervento.Id)
        .set({
          Day: day,
          Description: props.intervento.Descrizione,
          Month: month,
          Subject:
            "Intervento a:"+props.intervento.Cliente +
            ' ('+props.intervento.SedeName +') '+
            "- " +
            props.intervento.Titolo,
          Year: year,
          endHours: "00",
          endMinutes: "00",
          startHours: "00",
          startMinutes: "00",
          Cliente: props.intervento.Cliente,
          Fornitore: props.user.Email,
          InterventoID: props.intervento.Id

        });
      db.collection("Users") //inserisce sul calendario del cliente l'attività
        .doc(props.intervento.Cliente)
        .collection("Annotazioni") 
        .doc(props.intervento.Id)
        .set({
          Day: day,
          Description: props.intervento.Descrizione,
          Month: month,
          Subject:
            "Intervento a:"+props.intervento.Cliente +
            ' ('+props.intervento.SedeName +') '+
            "- " +
            props.intervento.Titolo,
          Year: year,
          endHours: "00",
          endMinutes: "00",
          startHours: "00",
          startMinutes: "00",
          Cliente: props.intervento.Cliente,
          Fornitore: props.user.Email,
          InterventoID: props.intervento.Id

        });
          var int = props.intervento
          int.Data = date
          int.Hour = hour
          int.Stato = 'Pianificata'
          const listaS = JSON.parse(sessionStorage.getItem('interventi'));
          var arrz = listaS
          var index = arrz.findIndex((e)=>e.Id===props.intervento.Id)
          arrz[index].Stato = 'Pianificata'
          arrz[index].Data = date
          arrz[index].Hour = hour
          props.setIntervento(int)
          sessionStorage.setItem('interventi', JSON.stringify(arrz));

          
          alert("L'intervento è stato pianificato")
          
  }else{
    alert("Devi impostare una data e un orario d'intervento prima di poter pianificare l'intervento.")
  }

};


    return (
      <div>
 <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
        <EditCalendarIcon style={{color:'black'}}/>

      <h4 className="header-normal" style={{marginLeft:'5px'}}>Pianifica</h4>
      </div>
      <div style={{marginTop:'1rem'}}>
        <h4 className="normal-text2" style={{marginLeft:0}}>Imposta data di intervento:</h4>
          <input
          type="date"
            format="dd/MM/yyyy hh:mm a"
            id="StartTime"
            data-name="StartTime"
            onChange={(e)=>setDate(e.target.value)}
            className="input-style" 
            style={{marginTop:'0.5rem'}}
          ></input></div>
            <div style={{marginTop:'1rem'}}>
        <h4 className="normal-text2" style={{marginLeft:0}}>Imposta orario per l'intervento:</h4>
        <input type="time" id="orario" name="orario" onChange={(e)=>setHour(e.target.value)}
 required className="input-style" ></input>
        </div>
                  <div style={{marginTop:'1rem'}}>
          <button className="main-button" style={{width:'fit-content'}} onClick={handleSubmit}  value="Pianifica intervento">PIANIFICA INTERVENTO</button>
       </div>        
       </div>
    )
  }
  const Evadi = () => {

    const [selectedOptionEsito, setOptionEsito] = useState(""); //esito
  const [reportz, setReport] = useState(""); //report
  const [titolo, setTitolo] = useState(""); //titolo
  const [descrizione, setDesc] = useState(""); //descrizione



  const handleSubmit = () => {
    if (reportz!=='') {
      var date2 = new Date();
      var days2 = date2.getDate();
      if (days2 < 10) {
        days2 = "0" + days2;
      }
      var month2 = ("0" + (date2.getMonth() + 1)).slice(-2);
      var minutes2 = ("0" + date2.getMinutes()).slice(-2);
      var dataFormattata =
        days2 +
        "-" +
        month2 +
        "-" +
        date2.getFullYear() +
        " " +
        date2.getHours() +
        ":" +
        minutes2;
      db.collection("Users")
        .doc(props.intervento.Cliente)
        .collection("Notifiche")
        .doc()
        .set({
          Nome_Azienda: props.user.Nome_Azienda,
          Nome_Cognome: props.user.Nome + " " + props.user.Cognome,
          Email: props.user.Email,
          Messaggio: "ha evaso un intervento con esito: "+selectedOptionEsito,
          Data: dataFormattata,
          Oggetto: props.intervento.Titolo,
        });

      if (selectedOptionEsito === "Positivo") {
        //se l'esito è positivo
        db.collection("Attività") //setta come conclusa l'attività al cliente
          .doc(props.intervento.Id)
          .update({
            Stato: "Conclusa",
            Esito: "Positivo",
            Report: reportz,
          });
          var arr = props.intervento
          arr.Stato = "Conclusa";
        arr.Esito = "Positivo";
        arr.Report = reportz;
        props.setIntervento(arr);
        const listaS = JSON.parse(sessionStorage.getItem('interventi'));
        var arrz = listaS
        var index = arrz.findIndex((e)=>e.Id===props.intervento.Id)
        arrz[index].Stato = 'Concluso'
        arrz[index].Esito = 'Positivo'
        arrz[index].Report = reportz

        sessionStorage.setItem('interventi', JSON.stringify(arrz));

        props.setSection(0)
        alert("Intervento evaso con successo.");

      } else {
        if(titolo!==''&&descrizione!==''){
        const titoloRe = titolo.toString();
        const descrizioneRe = descrizione.toString();
        db.collection('Users').doc(props.intervento.Cliente).get().then((us)=>{
   
        
        const ref = db.collection("Attività")
          .doc(props.intervento.Id)
          .collection("Attività")
          .doc()
          
        const id = ref.id;

        db.collection("Attività") //aggiorna sul cliente le mie attività
          .doc(props.intervento.Id)
          .update({
            Stato: "Conclusa",
            Esito: "Negativo",
            Report: reportz,
            SottoAttività: true,
          });
          ref.set({
            Titolo: titoloRe,
            Descrizione: descrizione,
            Nome: us.data().Nome,
            Cognome: us.data().Cognome,
            Data: '',
            Categoria: props.intervento.Categoria,
            Macrocategoria: props.intervento.Macrocategoria!==''?props.intervento.Macrocategoria:"",
            Oggetto: props.intervento.Oggetto!==''?props.intervento.Oggetto:props.intervento.Macrocategoria!==''?props.intervento.Macrocategoria:props.intervento.Categoria,
            Stato: "Pianificata",
            Cliente: props.intervento.Cliente,
            ID: id,
            Operatore: props.user.Nome_Azienda,
            Sede: props.intervento.Sede,
            Nome_Azienda: us.data().Nome_Azienda,
            Indirizzo: props.intervento.Indirizzo,
            Op_Email: props.user.Email,
            
          }).then(()=>{
            db.collection("Users")
            .doc(props.intervento.Cliente)
            .collection("Notifiche")
            .doc()
            .set({
              Nome_Azienda: props.user.Nome_Azienda,
              Nome_Cognome: props.user.Nome + " " + props.user.Cognome,
              Email: props.user.Email,
              Messaggio: "ha creato un intervento di riparazione",
              Data: dataFormattata,
              Oggetto: titoloRe,
            });
            
  
            var arr = props.intervento
            arr.Stato = "Conclusa";
          arr.Esito = "Negativo";
          arr.Report = reportz;
          const listaS = JSON.parse(sessionStorage.getItem('interventi'));
          var arrz = listaS
          if(arrz){
            const index = arrz.findIndex((e)=>e.Id===props.intervento.Id)
            arrz[index].Stato = 'Concluso'
            arrz[index].Esito = 'Negativo'
            arrz[index].Report = reportz
            arrz[index].SottoAttività = true
            arrz[index].Sott_Attivi = true
            sessionStorage.setItem('interventi', JSON.stringify(arrz));

  
          }
         

          props.setIntervento(arr);
          props.setSection(0)
          alert(
            "Intervento evaso con successo, è stata creata un'ulteriore intervento di riparazione"
          );
          })
        })
      }else{
        alert("Inserire i parametri per l'intervento correttivo.")
      }
    }
    }else{
      alert("Compila le osservazioni dell'intervento per evadere.")
    }
  }; //scrittura database cliente

    return (
      <div>
      <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
             <FactCheckIcon style={{color:'black'}}/>
     
           <h4 className="header-normal" style={{marginLeft:'5px'}}>Evadi</h4>
           </div>
           <div
            
          >
              <br />
              <div
              >
                <label
                className="normal-text"
                >
                  ESITO:
                </label>
              </div>
              <div style={{ display: "flex" }}>
                <label
                 className="normal-text2"
                >
                  <input
                    value="Positivo"
                    type="radio"
                    onChange={() => setOptionEsito("Positivo")}
                    checked={selectedOptionEsito === "Positivo"}
                  ></input>
                  Positivo
                </label>
                <label
                   className="normal-text2"
                   style={{marginLeft:'1rem'}}
                >
                  <input
                    type="radio"
                    value="Negativo"
                    onChange={() => setOptionEsito("Negativo")}
                    checked={selectedOptionEsito === "Negativo"}
                  ></input>
                  Negativo
                </label>
              </div>
              {selectedOptionEsito !== "" ? (
                <div>
                  {" "}
                  <div
                   
                  >
                    <label
                     className="normal-text"
                    >
                      OSSERVAZIONI*
                    </label>
                  </div>
                  <div
                   
                  >
                    <input
                      type="text"
                      name="reportP"
                     className="input-style2"
                      placeholder="Inserisci..."
                      style={{width:'20rem'}}
                      onChange={(e) => setReport(e.target.value)}
                    ></input>
                  </div>
                </div>
              ) : null}
              <br />
              {selectedOptionEsito === "Negativo" ? (
                <div>
                  <div
                  >
                    <h4
                     className="title-section"
                    >
                     AGGIUNTA INTERVENTO CORRETTIVO:
                    </h4>
                  </div>
                  <div
                   style={{marginTop:'1rem'}}
                  >
                    <label
                     className="normal-text"
                    >
                      AZIONE CORRETTIVA*
                    </label>
                  </div>
                  <div
                  
                  >
                    <input
                      type="text"
                      name="titolo"
                      onChange={(e) => setTitolo(e.target.value)}
                      className="input-style"
                      placeholder="Inserisci..."
                    ></input>
                  </div>
                  <br />
                  <div
                  
                  >
                    <label
                    className="normal-text"
                    >
                      DESCRIZIONE AZIONE CORRETTIVA*
                    </label>
                  </div>
                  <div
                   
                  >
                    <input
                      type="text"
                      name="descrizione"
                      onChange={(e) => setDesc(e.target.value)}
                    className="input-style2"
                    style={{width:'20rem'}}
                      placeholder="Inserisci..."
                    ></input>
                  </div>                 
                  <br />
                  <div
                    style={{
                      marginTop:'1rem'
                    }}
                  >
                    <button
                      onClick={handleSubmit}
                      className='main-button'
                      style={{width:'fit-content'}}
                      value="Pianifica intervento"
                    >
                      EVADI INTERVENTO
                    </button>
                  </div>
                  <br />
                  <br />
                </div>
              ) : selectedOptionEsito === "Positivo" ? (
                <div
                  style={{
                    marginTop:'1rem'
                  }}
                >
                  <button
                    onClick={handleSubmit}
                    className='main-button'
                      style={{width:'fit-content'}}
                    value="Pianifica intervento"
                  >
                    EVADI INTERVENTO
                  </button>
                </div>
              ) : null}
          </div>
                   
            </div>
    )
  }

    return <div style={{paddingLeft:'1rem',height:'80vh',overflowY:'auto',width:'71vw'}}>
      {props.intervento.Stato==='In attesa'?<Pianifica />:<Evadi/>}
      <div style={{alignItems:'center',marginTop:'5vh',marginLeft:'auto',width:'fit-content'}}>
        </div>
    </div>
}

export default Operazioni;