//sezione account
import React, {useState} from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BuildIcon from '@mui/icons-material/Build';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import firebase from "firebase";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { IconButton } from "@mui/material";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import SecurityIcon from '@mui/icons-material/Security';
import CancelIcon from '@material-ui/icons/Cancel';

const Operazioni = (props) => {

  const db = firebase.firestore()
  const auth = firebase.auth()

  const Evadi = () => {

    const [selectedOptionEsito, setOptionEsito] = useState(""); //esito
  const [reportz, setReport] = useState(""); //report
  const [titolo, setTitolo] = useState(""); //titolo
  const [descrizione, setDesc] = useState(""); //descrizione

  const handleSubmit=(event)=>{
    event.preventDefault();
    console.log(props.scadenza)
    const titoloRe = titolo.toString();
    const descrizioneRe = descrizione.toString();
  
    var elementi = elementiSelezionati.length>0 ? elementiSelezionati : props.scadenza.Elementi;

    if(props.scadenza.Elementi){
      console.log('Inizio elementi');
      var batch = db.batch();
    
      // Creiamo un array di promesse
      const promises = props.scadenza.Elementi.map((elemento) => {
        // Restituiamo la catena di promesse per ogni elemento
        return db.doc(elemento).get().then((docz) => {
          return db.collection('Elementi').doc(docz.data().ref).get().then((doczz) => {
            // Aggiorniamo il batch con i dati necessari
            batch.update(db.collection('Elementi').doc(doczz.id), {
              EsitoUltimaScadenza: elementiSelezionati.indexOf(elemento) >= 0 ? "Negativo" : "Positivo",
              UltimaScadenza: props.scadenza.Data,
              ProssimaScadenza: null
            });
          });
        });
      });
    
      // Attendiamo che tutte le promesse siano risolte
      Promise.all(promises).then(() => {
        // Ora possiamo fare il commit
        batch.commit().then(() => {
          console.log('Elementi aggiornati');
        });
      }).catch((error) => {
        console.error("Errore durante l'aggiornamento degli elementi: ", error);
      });
    }
    
    if(selectedOptionEsito==="Positivo"){
        db.collection("Manutenzioni").doc(props.scadenza.Id).update({
            Stato: "Conclusa",
            Esito: "Positivo",
            Report:reportz,
        }).then(()=>{

          


          if(props.scadenza.DocId&&props.scadenza.DocId!==''){
            db.collection('Documenti').doc(props.scadenza.DocId).update({
              Verified: true,
            })
          }




          var arr = props.scadenza
          arr.Stato = 'Conclusa'
          arr.Esito = 'Positivo'
          arr.Report = reportz
          const listaS = JSON.parse(sessionStorage.getItem('scadenze'));
          var arrz = listaS
          var index = arrz.findIndex((e)=>e.Id===props.scadenza.Id)
          arrz[index].Stato = 'Concluso'
          arrz[index].Esito = 'Negativo'
          arrz[index].Report = reportz
          sessionStorage.setItem('scadenze', JSON.stringify(arrz));
            props.setScadenza(arr)
            props.setSection(0);

            alert("Manutenzione evasa con successo!")
        })
    }else if(selectedOptionEsito==="Negativo"){
      var date2 = new Date();
      var days2 = date2.getDate();
      if(days2<10){
        days2 = '0' + days2;
      }
          var month2 = ('0'+(date2.getMonth()+1)).slice(-2);
          var minutes2 =  ('0'+(date2.getMinutes())).slice(-2);
            var dataFormattata = days2+"-"+month2+"-"+date2.getFullYear()+" "+(date2.getHours())+":"+minutes2;
        db.collection('Users').doc(props.user.Email).get().then((me)=>{
          db.collection("Users").doc(props.scadenza.Cliente).collection("Notifiche").doc().set({
            Nome_Azienda:me.data().Nome_Azienda,
            Nome_Cognome:me.data().Nome+" "+me.data().Cognome,
            Email:me.data().Email,
            Messaggio:"ha aggiunto una manutenzione di riparazione",
            Data:dataFormattata,
            Oggetto:props.scadenza.Titolo
          })
        })
        var elementiInfo = [];

        var elementiUpdated = [];
        
        if(props.scadenza.Type!=='doc'){
          props.scadenza.Elementi.forEach((sing)=>{
            elementiUpdated.push({
              Elemento:sing,
              Esito:elementiSelezionati.indexOf(sing)>=0 ? "Negativo":"Positivo" // se è presente l'elemento vuol dire che NON ha passato la manutenzione
            })
            
          })
        }
        
        if(props.scadenza.Type!=='doc'){
          props.scadenza.Elementi.forEach((elemento)=>{
            db.doc(elemento).get().then((docz)=>{
              db.collection('Elementi').doc(docz.data().ref).get().then((doc)=>{
                var dati = doc.data();
                dati.path = elemento
                elementiInfo.push(dati)

                if(elementiInfo.length===props.scadenza.Elementi.length){
                    // Eseguo scrittura
                    var batch = db.batch();

                    // Aggiorno la manutenzione e la chiudo
                    batch.update(db.collection("Manutenzioni").doc(props.scadenza.Id),{
                        Stato: "Conclusa",
                        Esito: "Negativo",
                        Report:reportz, 
                        SottoAttività: true, 
                        Elementi:elementiUpdated
                    })
                    var newManutenzioneDoc = db.collection("Manutenzioni").doc();
                    
                    // Creo la nuova manutenzione
                    batch.set(newManutenzioneDoc,{

                      Nome_AziendaOff: props.scadenza.Nome_AziendaOff,
                      Nome_AziendaCli: props.scadenza.Nome_AziendaCli,                  
                        Titolo: titoloRe,
                        Descrizione: descrizioneRe,
                        Stato: "Pianificata",
                        Nome: props.scadenza.Nome,
                        Cognome: props.scadenza.Cognome,
                        Oggetto: props.scadenza.Oggetto,
                        Cliente: props.scadenza.Cliente,
                        //Cliente: auth.currentUser.email,
                        ID: props.scadenza.Id,
                        Sede: props.scadenza.Sede,
                        Indirizzo: props.scadenza.Indirizzo,
                        Elementi:elementi,
                        Operatore: props.scadenza.Operatore,
                      
                    })
        
                    // Per ognuno degli elementi aggiungo il ref della nuova manutenzione
                    elementiInfo.forEach((item)=>{
                        var numeroManutenzioni = 0;
                        if(item.NumeroManutenzioni!==undefined){numeroManutenzioni = item.NumeroManutenzioni} 
                        numeroManutenzioni++;
                        batch.update(db.doc(item.path),{NumeroManutenzioni:numeroManutenzioni})
                
                        batch.set(db.doc(item.path).collection("Interventi").doc(newManutenzioneDoc.id),{
                          ref: newManutenzioneDoc.id,
                          name:item.Nome,
                          SottoAttività: true, 

                        })
                      }) 
        
                   
                    // Aggiungo la nuova manutenzione all'offritore e al cliente
                    batch.set(db.collection("Users").doc(props.scadenza.Cliente).collection("Sedi").doc(props.scadenza.Sede).collection("Manutenzioni").doc(props.scadenza.Id).collection("Manutenzioni").doc(newManutenzioneDoc.id),{
                        ref:newManutenzioneDoc.id
                    })
        
                    batch.set(db.collection("Users").doc(props.user.Email).collection("Clienti").doc(props.scadenza.Cliente).collection("Manutenzioni").doc(props.scadenza.Id).collection("Manutenzioni").doc(newManutenzioneDoc.id),{
                        ref:newManutenzioneDoc.id
                    })
        
                    // Aggiungo le annotazioni del calendario ad entrambi
                    var annotazioneCalendario = new Object();

                  batch.commit().then(()=>{
                    var date2 = new Date();
                    var days2 = date2.getDate();
                    if(days2<10){
                      days2 = '0' + days2;
                    }
                        var month2 = ('0'+(date2.getMonth()+1)).slice(-2);
                        var minutes2 =  ('0'+(date2.getMinutes())).slice(-2);
                        var dataFormattata = days2+"-"+month2+"-"+date2.getFullYear()+" "+(date2.getHours())+":"+minutes2;
                      db.collection('Users').doc(props.user.Email).get().then((me)=>{
                        db.collection("Users").doc(props.scadenza.Cliente).collection("Notifiche").doc().set({
                          Nome_Azienda:me.data().Nome_Azienda,
                          Nome_Cognome:me.data().Nome+" "+me.data().Cognome,
                          Email:me.data().Email,
                          Messaggio:"ha evaso una manutenzione",
                          Data:dataFormattata,
                          Oggetto:props.scadenza.Titolo
                        }).then(() => {

                                  // Aggiorna correttamente l'oggetto scadenza con tutti i cambiamenti
                const updatedScadenza = { ...props.scadenza, Stato: 'Conclusa', Esito: 'Negativo', Report: reportz, SottoAttività: true, Elementi: elementiUpdated };
                        
                props.setScadenza(updatedScadenza);

                // Aggiorna anche sessionStorage
                const listaS = JSON.parse(sessionStorage.getItem('scadenze'));
                var arrz = listaS;
                var index = arrz.findIndex((e) => e.Id === props.scadenza.Id);
                arrz[index] = updatedScadenza;

                sessionStorage.setItem('scadenze', JSON.stringify(arrz));
                          props.setSection(0)
                        alert("Manutenzione evasa correttamente!")
        
        
                        });
                      })
                   
                    })
                }
              })
      
            })
        })
        }else{
                    // Eseguo scrittura
                    var batch = db.batch();

                    // Aggiorno la manutenzione e la chiudo
                    batch.update(db.collection("Manutenzioni").doc(props.scadenza.Id),{
                        Stato: "Conclusa",
                        Esito: "Negativo",
                        Report:reportz, 
                        SottoAttività: true, 
                    })
                    var newManutenzioneDoc = db.collection("Manutenzioni").doc();
                    console.log("1:",newManutenzioneDoc);
                    console.log("2",db.collection("Manutenzioni").doc(newManutenzioneDoc.id))
                    console.log("3:",props.scadenza)
                    // Creo la nuova manutenzione
                    batch.set(newManutenzioneDoc,{

                      Nome_AziendaOff: props.scadenza.Nome_AziendaOff,
                      Nome_AziendaCli: props.scadenza.Nome_AziendaCli,                  
                        Titolo: titoloRe,
                        Descrizione: descrizioneRe,
                        Stato: "Pianificata",
                        Nome: props.scadenza.Nome,
                        Cognome: props.scadenza.Cognome,
                        Oggetto: props.scadenza.Oggetto,
                        Cliente: props.scadenza.Cliente,
                        //Cliente: auth.currentUser.email,
                        ID: props.scadenza.Id,
                        Sede: props.scadenza.Sede,
                        Indirizzo: props.scadenza.Indirizzo,
                        Operatore: props.scadenza.Operatore,
                        Type:'doc',
                        Categoria: props.scadenza.Categoria,
                        Macrocategoria: "",
                      	DocId: props.scadenza.DocId
                    })
        
                    // Per ognuno degli elementi aggiungo il ref della nuova manutenzione
                    
                    // Aggiungo la nuova manutenzione all'offritore e al cliente
                    batch.set(db.collection("Users").doc(props.scadenza.Cliente).collection("Sedi").doc(props.scadenza.Sede).collection("Manutenzioni").doc(props.scadenza.Id).collection("Manutenzioni").doc(newManutenzioneDoc.id),{
                        ref:newManutenzioneDoc.id
                    })
        
                    batch.set(db.collection("Users").doc(props.user.Email).collection("Clienti").doc(props.scadenza.Cliente).collection("Manutenzioni").doc(props.scadenza.Id).collection("Manutenzioni").doc(newManutenzioneDoc.id),{
                        ref:newManutenzioneDoc.id
                    })
        
                    // Aggiungo le annotazioni del calendario ad entrambi
                    var annotazioneCalendario = new Object();

                  batch.commit().then(()=>{
                    var date2 = new Date();
                    var days2 = date2.getDate();
                    if(days2<10){
                      days2 = '0' + days2;
                    }
                        var month2 = ('0'+(date2.getMonth()+1)).slice(-2);
                        var minutes2 =  ('0'+(date2.getMinutes())).slice(-2);
                        var dataFormattata = days2+"-"+month2+"-"+date2.getFullYear()+" "+(date2.getHours())+":"+minutes2;
                      db.collection('Users').doc(props.user.Email).get().then((me)=>{
                        db.collection("Users").doc(props.scadenza.Cliente).collection("Notifiche").doc().set({
                          Nome_Azienda:me.data().Nome_Azienda,
                          Nome_Cognome:me.data().Nome+" "+me.data().Cognome,
                          Email:me.data().Email,
                          Messaggio:"ha evaso una manutenzione",
                          Data:dataFormattata,
                          Oggetto:props.scadenza.Titolo
                        }).then(() => {
                          var arr = props.scadenza
                          arr.Stato = 'Conclusa'
                          arr.Esito = 'Negativo'
                        arr.Report = reportz
                        arr.SottoAttività= true
                        const listaS = JSON.parse(sessionStorage.getItem('scadenze'));
                        var arrz = listaS
                        var index = arrz.findIndex((e)=>e.Id===props.scadenza.Id)
                        arrz[index].Elementi = elementiUpdated
                        arrz[index].Stato = 'Concluso'
                        arrz[index].Esito = 'Negativo'
                        arrz[index].Report = reportz
                        arrz[index].SottoAttività = true
                        arrz[index].Sott_Attivi = true

                        props.setScadenza(arr)

                        sessionStorage.setItem('scadenze', JSON.stringify(arrz));
                          props.setSection(0)
                        alert("Manutenzione evasa correttamente!")
        
        
                        });
                      })
                   
                    })
                }
          
     
        }
       

    } //evade manutenzione 

  const [showSelezionaElementi,setShowSelezionaElementi] = useState(false)
  const [elementiSelezionati,setElementiSelezionati] = useState([]) //elementi selezionati

  const SelezionaElementi =()=>{

    const [eleSearch,setEleSearch] = useState('')

    function handleClickElemento(event){
      var checked = event.target.checked
      console.log(event.target.checked)
      
      if(checked===true){
        
          setElementiSelezionati((prev)=>[...prev,event.target.value])

      }else{
        if(elementiSelezionati.length===1){
          setElementiSelezionati([])
        }else{
        var index = props.scadenza.Elementi.indexOf(event.target.value)
        setElementiSelezionati(elementiSelezionati.filter((i,itemIndex)=>index!==itemIndex))
        }
      }
    }

    const GetEle = (props) =>{
    const [name,setName] = useState(null)
    const [name2,setName2] = useState(null)

      var elementoString = props.elemento.split('/')
      db.collection('Elementi').doc(elementoString[elementoString.length-1]).get().then((el)=>{
        setName(el.data().Nome)
        setName2('('+el.data().Categoria+' - '+el.data().Macrocategoria+') ')
      })
 
      function returnElement(){
        console.log(name,name2)
        if(name!==null&&name2!==null){
          if(eleSearch===''||(eleSearch!==''&&(name.toLowerCase().includes(eleSearch)||name2.toLowerCase().includes(eleSearch)))){
            return true
          }
        }
      }

      
      return (returnElement()===true?<div style={{display:'flex',alignItems:'center',marginTop:'0.5vh'}}>
        <input checked={elementiSelezionati.indexOf(props.elemento)!==-1}  value={props.elemento} onClick={(e)=>{handleClickElemento(e)}} type="checkbox" style={{verticalAlign:"middle"}}/>
        {name!==null&&name2!==null?
        <h4 style={{verticalAlign:"middle",fontSize:15,color:'black',margin:0,fontWeight:'normal'}}><b>{name}</b> {name2}</h4>:null}
        </div>:null)
    }

   




    return (
      <div className="pop-opac">
    <div style={{borderRadius:10,display:"inline-flex",flexDirection:"column",position:"fixed",marginLeft:"auto",marginRight:"auto",left:'10vw',right:0,top:0,bottom:"15vh",marginTop:"auto",marginBotton:"auto",zIndex:10,width:"60vw",height:"70vh",backgroundColor:"white"}}>
                <div style={{}}>
                <button
              className="cancelIconButton"
              style={{marginLeft:"0.5vw",marginRight:"auto",padding:0,marginTop:'1vh',marginBottom:0}}
              onClick={() => setShowSelezionaElementi(false)}
            >
              <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
            </button>
      <h4 className="title-section" style={{marginLeft:'1.5vw'}}>Seleziona gli elementi che NON hanno superato la manutenzione</h4>
      </div>
      <div style={{marginTop:'1.5vh',display:'flex',flexDirection:'row',alignItems:'center', marginLeft:'1.5vw'}}>
      <h4 className="normal-cool" style={{margin:0}}>Cerca elemento: </h4> <input className="input-style" onChange={(e)=>setEleSearch(e.target.value.toLowerCase())} style={{marginLeft:'1vw'}} placeholder="Cerca elemento..."/>
      </div>
      <div style={{marginTop:'1.5vh',overflowY:"auto",disply:"inline-flex",flexDirection:"column",width:"50vw",maxHeight:"50vh",marginLeft:"1.5vw",marginRight:"auto"}}>
        {props.scadenza.Elementi.map((elemento,index)=>(
         <GetEle elemento={elemento} key={index} index={index}/>
        ))}
      </div>
      <div style={{height:"10%",alignItems:"center",marginTop:'2vh',marginLeft:'1.5vw'}}>
      <button className="main-button" onClick={()=>setShowSelezionaElementi(false)} variant="contained">Conferma</button>
      </div>
      </div></div>)
  } //componente per selezionare gli elementi

    return (
      <div>
      <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
             <FactCheckIcon style={{color:'black'}}/>
     
           <h4 className="header-normal" style={{marginLeft:'5px'}}>Evadi</h4>
           </div>
           <div>
              <br />
              <div>
                <label
                className="normal-text"
                >
                  ESITO COMPLESSIVO:
                </label>
              </div>
              <div style={{ display: "flex" }}>
                <label
                 className="normal-text2"
                >
                  <input
                    value="Positivo"
                    type="radio"
                    onChange={() => setOptionEsito("Positivo")}
                    checked={selectedOptionEsito === "Positivo"}
                  ></input>
                  Positivo
                </label>
                <label
                   className="normal-text2"
                   style={{marginLeft:'1rem'}}
                >
                  <input
                    type="radio"
                    value="Negativo"
                    onChange={() => setOptionEsito("Negativo")}
                    checked={selectedOptionEsito === "Negativo"}
                  ></input>
                  Negativo
                </label>
              </div>
              {selectedOptionEsito !== "" ? (
                <div style={{marginTop:'1vh'}}>
                  {" "}
                  <div>
                    <label
                     className="normal-text"
                    >
                      OSSERVAZIONI:
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      name="reportP"
                     className="input-style2"
                      placeholder="Inserisci..."
                      style={{width:'20rem'}}
                      onChange={(e) => setReport(e.target.value)}
                    ></input>
                  </div>
                </div>
              ) : null}
              <br />
              {selectedOptionEsito === "Negativo" ? (
                <div>
                  <div
                  >
                    <h4
                     className="title-section"
                    >
                      AGGIUNTA INTERVENTO CORRETTIVO:
                    </h4>
                  </div>
                  {props.scadenza.Type!=='doc'?<div style={{display:'flex',width:"fit-content",paddingBottom:"5px",alignItems:'center'}} >
          
                  <h4 className="text-buttons" onClick={()=>setShowSelezionaElementi(true)} >Seleziona gli elementi che non hanno superato la manutenzione</h4>   
                  <IconButton onClick={()=>setShowSelezionaElementi(true)} style={{width:'fit-content',backgroundColor:'#192bc2',marginLeft:'1vw'}} aria-label="Osserva elementi">
                    <MenuOpenIcon style={{color:"white"}}/></IconButton>
                  <SecurityIcon style={{color:'#5C6672',marginLeft:'0.5vw',width:'1vw'}}></SecurityIcon>
                  <h4 style={{fontSize:'1vw',color:'#5C6672', fontWeight:'bold', fontFamily:'Montserrat',marginTop:'auto',marginBottom:'auto'}}>{elementiSelezionati.length}</h4>
                  </div>:null}
                  <div style={{marginTop:'1rem'}}>
                    <label
                     className="normal-text"
                    >
                      AZIONE CORRETTIVA:
                    </label>
                  </div>
                  <div
                  
                  >
                    <input
                      type="text"
                      name="titolo"
                      onChange={(e) => setTitolo(e.target.value)}
                      className="input-style"
                      placeholder="Inserisci..."
                    ></input>
                  </div>
                  <br />
                  <div
                  
                  >
                    <label
                    className="normal-text"
                    >
                      DESCRIZIONE AZIONE CORRETTIVA:
                    </label>
                  </div>
                  <div
                   
                  >
                    <input
                      type="text"
                      name="descrizione"
                      onChange={(e) => setDesc(e.target.value)}
                    className="input-style2"
                    style={{width:'20rem'}}
                      placeholder="Inserisci..."
                    ></input>
                  </div>                 
                  <br />
                  
                  
                  {showSelezionaElementi===true&&<SelezionaElementi/>}   
                  <div
                    style={{
                      marginTop:'1rem'
                    }}
                  >
                    <button
                      onClick={handleSubmit}
                      className='main-button'
                      style={{width:'fit-content'}}
                      value="Pianifica intervento"
                    >
                      EVADI
                    </button>
                  </div>
                  <br />
                  <br />
                </div>
              ) : selectedOptionEsito === "Positivo" ? (
                <div
                  style={{
                    marginTop:'1rem'
                  }}
                >
                  <button
                    onClick={handleSubmit}
                    className='main-button'
                      style={{width:'fit-content'}}
                    value="Pianifica intervento"
                  >
                    EVADI
                  </button>
                </div>
              ) : null}
          </div>
                   
            </div>
    )
  }

    return <div style={{paddingLeft:'1rem',height:'80vh',overflowY:'auto',width:'71vw'}}>
      <Evadi/>
      <div style={{alignItems:'center',marginTop:'5vh',marginLeft:'auto',width:'fit-content'}}>
        </div>
    </div>
}

export default Operazioni;