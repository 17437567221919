import React,{useEffect,useState} from 'react'
import firebase from "firebase";
import NavBar2 from '../../NavBar/NavBar2';
import CancelIcon from '@material-ui/icons/Cancel';

const AggiungiPiano = (props) => {

    const db = firebase.firestore()
    const auth = firebase.auth()

    const [pianoNome,setPianoNome] = useState('')

    const handleOutsideClick = (event) => {
        if (event.target.className === "pop-opac") {
          props.setTrigger(false);
        }
      };

      function addPiano(){
        var date2 = new Date();
        var days2 = date2.getDate();
        if(days2<10){
            days2 = '0' + days2;
          }
              var month2 = ('0'+(date2.getMonth()+1)).slice(-2);
              var minutes2 =  ('0'+(date2.getMinutes())).slice(-2);
                var dataFormattata = days2+"-"+month2+"-"+date2.getFullYear()+" "+(date2.getHours())+":"+minutes2;

        var ref = db.collection('Users').doc(props.user).collection('Sedi').doc(props.sede).collection('Piani').doc()
        ref.set({
            Index:props.piani.length,
            Nome: pianoNome,
            UltimaModifica: dataFormattata,
            Mappe:0,
        }).then(()=>{
          db.collection('Users').doc(props.user).collection('Sedi').doc(props.sede).update({
            Numero_Piani: firebase.firestore.FieldValue.increment(1)
          }).then(()=>{
            props.setPiani((prev)=>{
              return [
                  ...prev,
                  {
                      Index:props.piani.length,
                      Nome: pianoNome,
                      UltimaModifica: dataFormattata,
                      Mappe:0,
                      Id: ref.id
                  }
              ]
             }) 
             alert('Piano aggiunto con successo.')
             props.setTrigger(false)
          })
          
        })
      }

  return (
    <div className="pop-opac" onClick={handleOutsideClick}>
    <div className="popup" style={{top:'15vh',backgroundColor:"white", minHeight:'40vh', height:'fit-content', oveflowY: "auto", width:'50vw', left:'25vw'}} >
    <div style={{textAlign:'left', width: 'fit-content'}}>
            <button
              className="cancelIconButton"
              
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
            </button>
            </div>
            <div style={{marginLeft:'auto',marginRight:'auto',display:'flex',flexDirection:'column',alignItems:'center'}}>
            <h4 className='page-title' style={{margin:0}}>Aggiungi Piano</h4>
            <h4 className='sub-text' style={{margin:0}}>{props.sede}</h4>
            </div>
            <div style={{marginLeft:'auto',marginRight:'auto',display:'flex',flexDirection:'column',alignItems:'center',marginTop:'2vh'}}>
            <h4 className='normal-text' style={{margin:0}}>Nome Piano: </h4>
            <input className='input-style' style={{marginTop:'1vh'}} onChange={(e)=>setPianoNome(e.target.value)}></input>
            <button className='main-button' style={{marginTop:'1vh'}} onClick={addPiano}>Aggiungi</button>
            </div>
    </div>   
    </div>
  )
}

export default AggiungiPiano