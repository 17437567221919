//caricamento immagine profilo
import CancelIcon from "@material-ui/icons/Cancel";
import { useState } from "react";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import ContentCutIcon from '@mui/icons-material/ContentCut';
import SaveIcon from '@mui/icons-material/Save';
import firebase from "firebase/app";

export function UpSection(props){
    const [percentage,setPercentage] = useState(null) //percentuale caricamento
    const [section,setSection] = useState(0) //sezione 
    const [image,setImage]  = useState() //immagine caricata
    const [imagez,setImagez] = useState() //immagine da mostrare
    const [crop, setCrop] = useState() //dimensioni ritaglio
      const [newImage,setNewImage] = useState(null) //immagine ritagliata
      const storage = firebase.storage();
      var storageRef = storage.ref();
      const db = firebase.firestore();
      const auth = firebase.auth();

    const upload = async (e) => {
        var files =  e.target.files;
    
            console.log(e.target.files)
 
        
                  const file = files[0];
                  const ext = file.name.split('.').pop()
                  if(ext!=='jpg'&&ext!=='png'){
                    alert('Tipo di immagine caricato non supportato')
                  }else{
                    setImage(URL.createObjectURL(file))
                    setSection(1)
                  }
    } //caricamento immagine

    const uploadFile = async () => {
        //funzione per uploadare file su storage
              
            try{

              var imageBlob;
              var arr = newImage.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            imageBlob = new Blob([u8arr], {type:mime})
              const uploadTask = storageRef.child(
                auth.currentUser.email +
                  "/ProfileImage/" + 
                  'profile_image'
              ).put(imageBlob); //percorso del file su storage
              uploadTask.on('state_changed', 
              (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setPercentage(progress)
                
              }, 
              (error) => {
                // Handle unsuccessful uploads
              }, 
              // eslint-disable-next-line no-loop-func
              () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                  db.collection('Users').doc(auth.currentUser.email).update({
                    Photo: downloadURL
                  }).then(()=>{
                    alert('Immagine di profilo caricata')
                    props.setImage(newImage)
                  })
             
                
                 
                });
              })
            }catch(e){
              alert('Error: '+e)
            }
                 
                } //funzione che carica immagine su db

                const getCroppedImg = async () => {
                  try{
                    console.log(image)
                  const canvas = document.createElement("canvas");
                  const scaleX = imagez.naturalWidth / imagez.width;
                  const scaleY = imagez.naturalHeight / imagez.height;
                  canvas.width =crop.width;
                  canvas.height = crop.height;
                  const ctx = canvas.getContext("2d");
                  ctx.drawImage(
                      imagez,
                      crop.x * scaleX,
                      crop.y * scaleY,
                      crop.width * scaleX,
                      crop.height * scaleY,
                      0,
                      0,
                      crop.width,
                      crop.height
                  );
              
                  const base64Image = canvas.toDataURL();
                  setNewImage(base64Image);
                  console.log(base64Image);
                  }catch (e) {
                  console.log("crop the image",e);
              }
              }; //ritagli immagine

    return(
    <div style={{position:'fixed',left:0,right:0,top:0,bottom:0,backgroundColor:'rgba(0,0,0,0.7)',zIndex:2000}}>
        <div style={{position:'absolute',left:'25vw',width:'50vw',height:'40vw',backgroundColor:'white',borderRadius:20,top:'5vh'}}>
        <div style={{ textAlign: "left", width: "fit-content" }}>
            <button
              className="cancelIconButton"
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon
                style={{ width: "15px", height: "15px" }}
                className="cancelIcon"
              />
            </button>
          </div>
<h4 style={{
              fontSize: "2vw",
              color: "#0496ff",
              fontFamily: "Montserrat",
              letterSpacing: "-0.1vw",
              fontWeight: "bold",
              width: "fit-content",
              marginRight: "auto",
              marginLeft: "auto",
              marginTop: 0,
              marginBottom: 0,
            }}>CARICA FOTO PROFILO</h4>
         {section===0? <div style={{position:'relative'}}>

        <input type="file" multiple onChange={(e)=>upload(e)} style={{zIndex:1000,width:'50vw',height:'35vw', position:'absolute', backgroundColor:'blue',opacity:0 }}/>
            <div style={{width:'fit-content',height:'10vw',marginTop:'10vh',marginBottom:'auto',marginLeft:'auto',marginRight:'auto',alignItems:'center'}}>
                <div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content'}}><FileUploadIcon style={{marginLeft:'auto',marginRight:'auto',color:'#0496ff',width:'7vw',height:'7vw'}} /></div>
            
            <h4 style={{
              fontSize: "1vw",
              color: "#5C6672",
              fontFamily: "Montserrat",
              fontWeight: "normal",
              width: "fit-content",
              marginRight: "auto",
              marginLeft: "auto",
              marginTop: 0,
              marginBottom: 0,
              zIndex:99,
            }}>Clicca o trascina qui l'immagine che desideri caricare </h4>
            </div>
        </div>:<div style={{width:'50vw',height:'30vw'}}>
          <h4 style={{
              fontSize: "1.2vw",
              color: "#5C6672",
              fontFamily: "Montserrat",
              fontWeight: "bold",
              width: "fit-content",
              marginRight: "auto",
              marginLeft: "auto",
              marginTop: '1vh',
              marginBottom: 0,
            }}>Ritaglia foto</h4>
            <div style={{width:"fit-content",marginLeft:'auto',marginRight:'auto',marginTop:'2vh'}}>
            {newImage===null?<ReactCrop  src={image} crop={crop}  onChange={(c, percentCrop) =>setCrop(c)}>
          <img src={image} onLoad={(e)=>setImagez(e.target)} alt="uploaded_image"  style={{objectFit:'cover',width:'20vw',height:'20vw'}}/>
    </ReactCrop>:<div  style={{width:'10vw',height:'10vw',border:'2px solid #0496ff',borderRadius:'50%'}}>
      <img src={newImage} alt='imagezz'  style={{width:'100%',height:'100%',objectFit:'cover',borderRadius:'50%'}}/>
      </div>}
    </div>
    {newImage===null?<button onClick={()=>{crop?getCroppedImg():alert("Selezionare l'area")}}
                       
                       style={{
                         boxShadow:
                           "rgba(0, 0, 0, 0.35) 1.95px 1.95px 1.6px",
                         backgroundColor: "#25a18e",
                         color: "white",
                         fontWeight: "bold",
                         fontFamily: "Montserrat",
                         fontSize: "0.75vw",
                         border: "2px solid #0496ff",
                         borderRadius: 5,
                         width: "8vw",
                         cursor: "pointer",
                         height: "4vh",
                         display: "flex",
                         marginTop: "2vh",
                         marginLeft:'auto',
                         marginRight:'auto',
                         backgroundImage:
                           "linear-gradient(to bottom,#0496ff , #00bbf9)",
                       }}
                     >
                       <h4 style={{marginTop:'auto',marginBottom:'auto',marginLeft:'auto',}}>RITAGLIA</h4>
                       <ContentCutIcon color="white" style={{marginTop:'auto',marginBottom:'auto',marginRight:'auto',width:'1vw',height:'1vw'}}/>
                     </button>:<button onClick={uploadFile}
                       
                       style={{
                         boxShadow:
                           "rgba(0, 0, 0, 0.35) 1.95px 1.95px 1.6px",
                         backgroundColor: "#25a18e",
                         color: "white",
                         fontWeight: "bold",
                         fontFamily: "Montserrat",
                         fontSize: "0.75vw",
                         border: "2px solid #0496ff",
                         borderRadius: 5,
                         width: "8vw",
                         cursor: "pointer",
                         height: "4vh",
                         display: "flex",
                         marginTop: "2vh",
                         marginLeft:'auto',
                         marginRight:'auto',
                         backgroundImage:
                           "linear-gradient(to bottom,#0496ff , #00bbf9)",
                       }}
                     >
                       <h4 style={{marginTop:'auto',marginBottom:'auto',marginLeft:'auto',}}>SALVA</h4>
                       <SaveIcon color="white" style={{marginTop:'auto',marginBottom:'auto',marginRight:'auto',width:'1vw',height:'1vw'}}/>
                     </button>}
                     {percentage!==null?<div><h4 style={{
              fontSize: "1vw",
              color: "black",
              fontFamily: "Montserrat",
              fontWeight: "normal",
              width: "fit-content",
              marginRight: "auto",
              marginLeft: "auto",
              marginTop: '1vh',
              marginBottom: 0,
            }}>Caricamento: {percentage}%</h4>
            <h4 style={{
              fontSize: "0.7vw",
              color: "black",
              fontFamily: "Montserrat",
              fontWeight: "normal",
              width: "fit-content",
              marginRight: "auto",
              marginLeft: "auto",
              marginTop: '0.5vh',
              marginBottom: 0,
            }}>Attendere il messaggio di conferma prima di chiudere la pagina.</h4>
            </div>:null}
          </div>}

        </div>
    </div>
    )
}