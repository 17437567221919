import React, { useState, useEffect } from "react";
import PersonIcon from '@mui/icons-material/Person';
import { Tooltip } from "@mui/material";
import { useHistory } from 'react-router-dom';

function Agenda(props){

    const navigate = useHistory();

    const goToAboutOff = (id) => {
        navigate.push(`/interventimanutentore/intervento/${id}`);
      };
      const goToAboutCli = (id) => {
        navigate.push(`/interventi/intervento/${id}`);
      };

    const notificRender = (item,key) =>{


        function getA(string){
            var v = string.split('"')
            var x = v[5].replace('"', '')
            return x
        }

        return (
            <div key={key} style={{backgroundColor:'white',cursor:'pointer',width:'11rem',marginLeft:13,marginTop:13,height:'12rem',borderRadius:5,border:'1px solid #14213d',boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'}}>
             <h4 style={{margin:0,marginLeft:5,marginTop:5,fontSize:'0.6rem',fontFamily:'Rubik' }}>{item.data.Day}/{item.data.Month}/{item.data.Year}</h4>
            <div style={{display:'flex',alignItems:'center'}}>
            <PersonIcon style={{color:item.User==='Assente'?'#5C6672':'#192bc2',fontSize:'1rem'}}></PersonIcon>
            
            <h4 style={{margin:0,fontSize:'0.8rem',color:item.User==='Assente'?'#5C6672':'#192bc2',fontFamily:'Rubik'}}>{item.User}</h4>
            </div>
            <div style={{marginTop:5,marginLeft:5,width:'10.5rem',height:'2rem',overflowY:'hidden'}}>
            <h4 style={{margin:0,fontSize:'0.9rem',fontFamily:'Rubik' }}>{item.data.Subject}</h4>

            </div>
            <div style={{marginTop:5,width:'11rem',borderTop:'1px solid #192bc2'}}>
                {item.data.Description?item.data.Description.includes('<a style')?
                <div style={{marginLeft:5}}>
                    <h4 style={{margin:0,fontSize:'0.7rem',fontFamily:'Rubik',marginTop:5 }}>Premere sul bottone sottostante per visualizzare il documento.</h4>
                    <br/>
                    <a href={getA(item.data.Description)} target="_blank" rel="noreferrer" 
                 className="main-button">Visualizza documento</a>
                </div>
               
                :<h4 style={{margin:0,fontSize:'0.7rem',fontFamily:'Rubik',marginLeft:5,marginTop:5 }}>{item.data.Description}</h4>:
                <h4 style={{margin:0,fontSize:'0.7rem',fontFamily:'Rubik',marginLeft:5,marginTop:5 }}>Nessuna descrizione inserita.</h4>}
                {item.data.InterventoID?<button onClick={()=>props.type==='Lavoratore'?goToAboutOff(item.data.InterventoID):goToAboutCli(item.data.InterventoID)} className="main-button" style={{marginTop:'10px',marginLeft:'5px'}}>Visualizza</button>:null}
                

            
            </div>
            </div>
        )
    }

    return <div style={{width:'100%',height:'25rem',marginTop:'1rem',borderRadius:10,display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignContent: "flex-start",overflowY:'auto'}}>
        {props.annotazioni.length>0?props.annotazioni.map((ann,key)=>(
            notificRender(ann,key)
        )):<h4>Nessuna annotazione inserita...</h4>
       }
    </div>
}

export default Agenda
