import React, { useEffect,useState } from 'react'
import NavBar from '../NavBar/NavBar'
import firebase from "firebase";
import { useHistory } from "react-router-dom";
import { getUtente,prendiSedi,checkWriteS,checkDeleteS } from '../EngineClienti/engineClienti';
import {  IconButton, Tooltip } from '@mui/material';
import LoopIcon from '@material-ui/icons/Loop';
import Checks from '../Popups/Checks';
import EditIcon from '@mui/icons-material/Edit';
import Notifiche from '../Notifiche/Notifiche';
import CancelIcon from '@material-ui/icons/Cancel';
import Mancanze from '../Popups/Mancanze';

const Sedi = () => {

  const [user,setUser] = useState(null) //info utente 
  const [sedi,setSedi] = useState([]) //lista sedi

  const db = firebase.firestore()
  const auth = firebase.auth()
  const history = useHistory()

    ////filtri

    const [statoF,setStatoF] = useState('default')
    const [findInput,setFindInput] = useState('')
    const listaSe = JSON.parse(sessionStorage.getItem('sediS'));

    ///popup stato
    const [triggerStato,setTriggerStato] = useState(false)
    const [sedeStato,setSedeStato] = useState(null)

    //popup mancanze

    const [triggerMancanze,setTriggerMancanze] = useState(false)
    const [sedeMancanze,setSedeMancanze] = useState(null)
 
  
    useEffect(() => {
      if(user!==null){
      if(user.Sedi_Create===sedi.length){

        sessionStorage.setItem('sediS', JSON.stringify(sedi));
  
      }
    }
       
      
    }, [sedi]);
  useEffect(()=>{
    auth.onAuthStateChanged(function (user) {
      if (user) {
        getUtente(db,auth,setUser)
      }
    })
  },[auth])

  useEffect(()=>{
    if(user!==null){
      prendiSedi(db,user.Email,user.Sedi_Create,setSedi)
  }
},[user])

const Buttons = () =>{

  return(
    <div style={{display:'flex',alignItems:'center',gap:'1.5rem'}}>
      <div>
      <h4 onClick={()=>history.push('/sedi/aggiungisede')}  className='text-buttons' >
              AGGIUNGI SEDE
            </h4>
      </div>
    
    </div>
  )
}
function reGet (){
  sessionStorage.clear()
  prendiSedi(db,user.Email,user.Sedi_Create,setSedi)

}

function triggerPopupStato(sede){
  setSedeStato(sede)
  setTriggerStato(true)
}
function triggerPopupMancanze(sede){
  setSedeMancanze(sede)
  setTriggerMancanze(true)
}

function editTag(n){
  setSedeIndex(n)
  setTriggerTag(true)
}
function editNumero(n){
  setSedeIndex(n)
  setTriggerNumero(true)
}
function editIndirizzo(n){
  setSedeIndex(n)
  setTriggerIndirizzo(true)
}
const [sedeIndex,setSedeIndex] = useState(null)
const [triggerTag,setTriggerTag] = useState(false)

const PopTag = (props) =>{

  const [tagName,setTagName] = useState('')

  function editTag(){

    db.collection('Users').doc(user.CurrentEmail).collection('Sedi').doc(sedi[sedeIndex].Id).update({
      Tag: tagName
    }).then(()=>{
      var arr = [...sedi]
      arr[sedeIndex].Tag = tagName
      setSedi(arr)
      sessionStorage.setItem('sediS', JSON.stringify(arr));
      props.setTrigger(false)
    })
  }

  return (
    <div className='pop-opac'>
      <div className='popup' style={{height:'20vh',marginTop:'25vh',width:'60vw',left:'15vw'}}>
      <div style={{textAlign:'left', width: 'fit-content'}}>
            <button
              className="cancelIconButton"
              
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
            </button>
            </div>
            <div style={{width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>
            <h4 className="page-title" style={{marginTop:'10px',marginLeft:'1rem'}}>MODIFICA TAG {sedi[sedeIndex]?sedi[sedeIndex].Comune:null}</h4>
            <div style={{marginTop:'10px',display:'flex',alignItems:'center',width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>
            <input className='input-style' style={{marginLeft:'1rem'}} onChange={(e)=>setTagName(e.target.value)} placeholder='Inserisci tag...'></input>
            <button className='main-button' onClick={()=>editTag()} style={{marginLeft:'1rem'}}>Salva</button>
            </div>
            </div>
      </div>
    </div>
  )

}

const [triggerNumero,setTriggerNumero] = useState(false)

const PopNumero = (props) =>{

  const [tagName,setTagName] = useState('')

  function editNumero(){

    db.collection('Users').doc(user.CurrentEmail).collection('Sedi').doc(sedi[sedeIndex].Id).update({
      Numero: tagName
    }).then(()=>{
      var arr = [...sedi]
      arr[sedeIndex].Numero = tagName
      setSedi(arr)
      sessionStorage.setItem('sediS', JSON.stringify(arr));
      props.setTrigger(false)
    })
  }

  return (
    <div className='pop-opac'>
      <div className='popup' style={{height:'20vh',marginTop:'25vh',width:'60vw',left:'15vw'}}>
      <div style={{textAlign:'left', width: 'fit-content'}}>
            <button
              className="cancelIconButton"
              
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
            </button>
            </div>
            <div style={{width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>
            <h4 className="page-title" style={{marginTop:'10px',marginLeft:'1rem'}}>MODIFICA NUMERO {sedi[sedeIndex]?sedi[sedeIndex].Comune:null}</h4>
            <div style={{marginTop:'10px',display:'flex',alignItems:'center',width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>
            <input className='input-style' style={{marginLeft:'1rem'}} type='number' onChange={(e)=>setTagName(e.target.value)} placeholder='Inserisci numero...'></input>
            <button className='main-button' onClick={()=>editNumero()} style={{marginLeft:'1rem'}}>Salva</button>
            </div>
            </div>
      </div>
    </div>
  )

}

const [triggerIndirizzo,setTriggerIndirizzo] = useState(false)

const PopIndirizzo = (props) =>{

  const [tagName,setTagName] = useState('')

  function editIndirizzo(){

    db.collection('Users').doc(user.CurrentEmail).collection('Sedi').doc(sedi[sedeIndex].Id).update({
      Indirizzo: tagName
    }).then(()=>{
      var arr = [...sedi]
      arr[sedeIndex].Indirizzo = tagName
      setSedi(arr)
      sessionStorage.setItem('sediS', JSON.stringify(arr));
      props.setTrigger(false)
    })
  }

  return (
    <div className='pop-opac'>
      <div className='popup' style={{height:'20vh',marginTop:'25vh',width:'60vw',left:'15vw',}}>
      <div style={{textAlign:'left', width: 'fit-content'}}>
            <button
              className="cancelIconButton"
              
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
            </button>
            </div>
            <div style={{width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>
            <h4 className="page-title" style={{marginTop:'10px',marginLeft:'1rem'}}>MODIFICA INDIRIZZO {sedi[sedeIndex]?sedi[sedeIndex].Comune:null}</h4>
            <div style={{marginTop:'10px',display:'flex',alignItems:'center',width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>
            <input className='input-style' style={{marginLeft:'1rem'}} type='text' onChange={(e)=>setTagName(e.target.value)} placeholder='Inserisci indirizzo...'></input>
            <button className='main-button' onClick={()=>editIndirizzo()} style={{marginLeft:'1rem'}}>Salva</button>
            </div>
            </div>
      </div>
    </div>
  )

}

function renderSede(sede){
  var tag = sede.Tag?sede.Tag: ''

  if((statoF===''||statoF==='default'||sede.Status===parseInt(statoF,10))&&(findInput===''||sede.Comune.toLowerCase().includes(findInput.toLowerCase())||
  sede.Numero.toLowerCase().includes(findInput.toLowerCase())||tag.toLowerCase().includes(findInput.toLowerCase()))){
    return true
  }else{
    return false
  }
}

   //sorting
   const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

   const onSort = (columnKey) => {
    let direction = 'ascending';
    if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortedSede = React.useMemo(() => {
    let sortableItems = [...sedi];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (typeof a[sortConfig.key] === 'string') {
          return a[sortConfig.key].localeCompare(b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
        } else if (sortConfig.key === 'date') {
          return new Date(a[sortConfig.key]) - new Date(b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
        }else if (sortConfig.key === 'Ele') {
          return (a[sortConfig.key] - b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
        }else if (sortConfig.key === 'Doc') {
          return (a[sortConfig.key] - b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
        } else {
          return 0;
        }
      });
    }
    return sortableItems;
  }, [sedi, sortConfig]);


  return (
    <div className="background" style={{width:'100vw',height:'100vh'}}>
        <NavBar/>
        {triggerTag===true?<PopTag index={sedeIndex}  trigger={triggerTag} setTrigger={setTriggerTag}/>:null}
        {triggerNumero===true?<PopNumero index={sedeIndex}  trigger={triggerNumero} setTrigger={setTriggerNumero}/>:null}
        {triggerIndirizzo===true?<PopIndirizzo index={sedeIndex}  trigger={triggerIndirizzo} setTrigger={setTriggerIndirizzo}/>:null}

        {user!==null&&user!==null?<Notifiche user={user}/>:null}
        {
          triggerStato===true?<Checks trigger={triggerStato}
          email = {user.CurrentEmail}
          setTrigger={setTriggerStato}
          sede={sedeStato}
          />:null
        }
        {
          triggerMancanze===true?<Mancanze trigger={triggerMancanze}
          email = {user.CurrentEmail}
          setTrigger={setTriggerMancanze}
          sede={sedeMancanze}
          />:null
        }
        <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
        <div className="container-dash">
        <h4 className="page-title" style={{marginTop:'1.2rem'}}>SEDI</h4>
       
      </div>
     {user!==null?checkWriteS(user.Permissions)===true? <Buttons/>:null:null}
      <div className='box-shadow' style={{marginTop:'0.5rem',borderRadius:5,width:'75vw',backgroundColor:'white',height:'3rem',display:'flex',alignItems:'center'}}>
          <div style={{width:'7vw',height:'3rem',borderRight:'1px solid lightgrey',}}>
          <h4 className="normal-text" style={{marginLeft:'auto',marginRight:'auto',marginTop:'0.3rem',fontSize:'1rem',width:'fit-content'}}>
           {sedi.length}
          </h4>
          <h4 className="sub-text" style={{marginLeft:'auto',marginRight:'auto',fontSize:'0.8rem',width:'fit-content'}}>
          {'Sedi totali'}
          </h4>
          </div>
          
          <select
                style={{marginTop:'auto',marginBottom:'auto',marginLeft:'1vw'}}
                defaultValue={"default"}
                value={statoF}
                className='input-style'
                onChange={(e) => {
                  setStatoF(e.target.value);
                 
                }}
              >
                <option value={"default"} disabled>
                  Filtra per stato...
                </option>
                <option value={""}>Tutte</option>
                <option value={0}>In regola</option>
                <option value={1}>Parzialmente in regola</option>
                <option value={2}>Non in regola</option>

              </select>
             {/* */}
      
              <div  style={{marginLeft:'auto'}}>
                <input onChange={(e)=>
                  setFindInput(e.target.value.toLowerCase())}
                  className="input-style2" style={{width:'30rem',height:'1rem',fontWeight:'normal',fontFamily:'Poppins'}} type='text' placeholder={"Cerca sede per: comune, tag o numero..."}></input>
                </div>            
          <div style={{marginLeft:10,marginRight:5}}>
          <Tooltip style={{width:'fit-content'}} title="Aggiorna lista">
                 <IconButton onClick={()=>reGet()} style={{backgroundColor:'#192bc2',width:'fit-content',height:'fit-content'}}  
                // onClick={()=>getEle(sedi,email)}
                 >
                 <LoopIcon style={{verticalAlign: 'middle', color: 'white',fontSize:'1rem'}}/>
                 </IconButton>
                 </Tooltip>
          </div>
          </div>
      <div style={{marginTop:10,borderRadius:5,width:'75vw',backgroundColor:'#14213d',height:'3rem',marginRight:'auto',display:'flex',alignItems:'center',boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'}}>
          
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',alignItems:'center',justifyContent:'center',}} onClick={() => onSort('Comune')}>
            <h4 className="header-table" >Luogo {sortConfig.key === 'Comune' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem'}}>
          <h4 className="header-table" >Comune</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem'}}>
          <h4 className="header-table" >Indirizzo</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem'}}>
          <h4 className="header-table" >Numero</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem'}}>
          <h4 className="header-table" >Tag</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem'}} onClick={() => onSort('Doc')}>
          <h4 className="header-table" >Documenti {sortConfig.key === 'Doc' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem'}} onClick={() => onSort('Ele')}>
          <h4 className="header-table" >Elementi {sortConfig.key === 'Ele' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{width:'10vw',height:'3rem'}}>
          <h4 className="header-table" >Stato</h4>

          </div>
          </div>
          <div style={{overflowY:'auto',height:'74vh'}}>
          {sortedSede.length>0?sortedSede.map((ele,n)=>(
           renderSede(ele)===true?
           <div key={n} className='table-row'>
            <div  style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
              <div>
              <Tooltip title={ele.Nome?ele.Nome!==''?ele.Nome:ele.Comune:ele.Comune}>

<h4 className="medium-text" style={{whiteSpace: 'nowrap',color:'#192bc2',fontWeight:'bold',fontSize:'0.9rem',
overflow: 'hidden',
textOverflow: 'ellipsis',maxWidth:'9vw'}}>
                {ele.Nome?ele.Nome!==''?ele.Nome:ele.Comune:ele.Comune}
              </h4>
              </Tooltip>
                </div>
              
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
            <div style={{display:'flex',alignItems:'center'}}>
              <Tooltip title={ele.Comune}>
              <h4 className="medium-text" style={{maxWidth:'7.3vw'}} >
              {ele.Comune!==''?ele.Comune:'Non inserito'}
              </h4>
              </Tooltip>
  
              </div> 
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
            <div style={{display:'flex',alignItems:'center'}}>
              <Tooltip title={ele.Indirizzo}>
              <h4 className="medium-text" style={{maxWidth:'6vw'}} >
              {ele.Indirizzo!==''?ele.Indirizzo:'Non inserito'}
              </h4>
              </Tooltip>
             {user!==null?checkWriteS(user.Permissions)===true? <Tooltip title='Modifica indirizzo'>
              <IconButton style={{width:'fit-content',marginLeft:'5px'}} onClick={()=>editIndirizzo(n)}>
              <EditIcon style={{width:'1vw'}}/>
              </IconButton>
              </Tooltip>:null:null}
              </div> 
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
            <div style={{display:'flex',alignItems:'center'}}>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
              {ele.Numero?ele.Numero!==''?ele.Numero:'Non inserito':'Non inserito'}
              </h4>
              {user!==null?checkWriteS(user.Permissions)===true?<Tooltip title='Modifica numero'>
              <IconButton style={{width:'fit-content',marginLeft:'10px'}} onClick={()=>editNumero(n)}>
              <EditIcon style={{width:'1vw'}}/>
              </IconButton>
              </Tooltip>:null:null}
              </div>     
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div style={{display:'flex',alignItems:'center'}}>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Tag?ele.Tag!==''?ele.Tag:'Non inserito':'Non inserito'}
              </h4>
              {user!==null?checkWriteS(user.Permissions)===true?<Tooltip title='Modifica tag'>
              <IconButton style={{width:'fit-content',marginLeft:'10px'}} onClick={()=>editTag(n)}>
              <EditIcon style={{width:'1vw'}}/>
              </IconButton>
              </Tooltip>:null:null}
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Doc}
              </h4>
           
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Ele}
              </h4>
           
              </div>
            </div>
            <div style={{width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
              <h4 className="medium-text" style={{fontWeight:'bold',maxWidth:'9.3vw',color:ele.Status===0?'#1B9C85':ele.Status===1?'#F7D060':'#FF0060'}} >
                {ele.Status===0?'In regola':ele.Status===1?'Parzialmente in regola':'Non in regola'}
              </h4>
           
              </div>
            </div>
            <div style={{width:'7vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div style={{marginLeft:'auto',marginRight:'20px',width:'fit-content'}}>
            {user!==null?checkWriteS(user.Permissions)===true?<h4 onClick={()=>triggerPopupStato(ele)} style={{borderBottom:'1px solid #192bc2',color:'#192bc2',cursor:'pointer',fontSize:'0.8rem',}} >
                Imposta stato
              </h4>:null:null}
              {ele.Status!==0?<div style={{width:'fit-content',marginLeft:'auto'}}>
              <h4 onClick={()=>triggerPopupMancanze(ele)} style={{borderBottom:'1px solid #192bc2',color:'#192bc2',cursor:'pointer',fontSize:'0.8rem',}} >
                Mancanze
              </h4>
                </div>:null}
           
              </div>
            </div>
  
            </div>:null
           
          )): <h4 className='normal-text' style={{marginTop:'1rem'}}>Nessuna sede inserita.</h4>}
        </div>

          </div>
    </div>
  )
}

export default Sedi