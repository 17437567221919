//sezione account
import React, { useState, useEffect } from "react";
import firebase from "firebase";
import { formatData } from "../../EngineClienti/engineClienti";
import AssignmentLateRoundedIcon from '@mui/icons-material/AssignmentLateRounded';
import GenerazionePdf from "../../GenerazionePdf";
import DownloadIcon from '@mui/icons-material/Download';
const Manutenzioni = (props) => {

    const db = firebase.firestore(); //database firebase

    const [manutenzioni,setManutenzioni] = useState([])

    useEffect(()=>{
      setManutenzioni(props.manutenzioni)
    },[props.manutenzioni])

    function handleManutenzionePdf(manutenzioneReal) {
      var manutenzione = manutenzioneReal;
      db.collection("Users")
        .doc(manutenzione.Operatore)
        .get()
        .then((operatoreDoc) => {
          var operatoreData = operatoreDoc.data();
          console.log(operatoreData);
          var operatore = {
            Nome_Azienda: operatoreData.Nome_Azienda,
            Nome: operatoreData.Nome,
            Cognome: operatoreData.Cognome,
            Indirizzo: operatoreData.Indirizzo,
            Sede: operatoreData.Comune,
            Telefono: operatoreData.Numero,
          };
  
          var listaElementi = [];
  
          var myManutenzione = manutenzione;
  
          db.collection("Users")
            .doc(myManutenzione.Cliente)
            .get()
            .then((clienteData) => {
              console.log(clienteData.data())
              myManutenzione.Numero_telefono_cli = clienteData.data().Numero;
              manutenzione.Sede_cli = manutenzione.Sede;
              manutenzione.Nome_AziendaCli = clienteData.data().Nome_Azienda;
              manutenzione.Numero_telefono_cli = clienteData.data().Numero;
              manutenzione.Elementi.forEach((elePath, index) => {
                console.log("miacula:", elePath);
                if (manutenzione.Elementi[0].Elemento !== undefined) {
                  // (se l'elemento ha esito:true o positivo la lista degli elementi è un oggetto, altrimenti è un array di stringhe)
                  db.doc(elePath.Elemento)
                    .get()
                    .then((eleRef) => {
                      db.collection("Elementi").doc(eleRef.data().ref).get().then((eleData)=>{
  
          
  
                        console.log("rooo:",eleData)
            
                    listaElementi.push({Nome:eleData.data().Nome,
                      Esito:manutenzione.Esito})
                    if(index==manutenzione.Elementi.length-1){    GenerazionePdf.generaPdfManutenzione(manutenzione,operatore,listaElementi)
                    }
                  })
                 
                    });
                } else {
                  db.doc(elePath)
                    .get()
                    .then((eleRef) => {
                      db.collection("Elementi").doc(eleRef.data().ref).get().then((eleData)=>{
  
          
  
                        //onsole.log("rooo:",eleData)
            
                    listaElementi.push({Nome:eleData.data().Nome,
                      Esito:manutenzione.Esito})
                    if(index==manutenzione.Elementi.length-1){    GenerazionePdf.generaPdfManutenzione(manutenzione,operatore,listaElementi)
                    }
                  })
                    /*  listaElementi.push({
                        Nome: eleData.data().Nome,
                        Esito: manutenzione.Esito,
                      });
                      if (index === manutenzione.Elementi.length - 1) {
                        GenerazionePdf.generaPdfManutenzione(
                          manutenzione,
                          operatore,
                          listaElementi
                        );
                      }*/
                    });
                }
              });
            });
        });
    } //funzione che genera la manutenzione pdf



    return <div style={{paddingLeft:'1rem',height:'80vh',overflowY:'auto',width:'71vw'}}>
      <div>
      {/*informazioni di contatto*/}
        <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
        <AssignmentLateRoundedIcon style={{color:'black'}}/>

      <h4 className="header-normal" style={{marginLeft:'5px'}}>Manutenzioni elemento</h4>
      </div>
      {manutenzioni.length>0?<div style={{marginTop:'1rem'}}>
        {manutenzioni.map((int,n)=>(
          <div key={n} style={{marginTop:'1rem'}}>
            <h4 className="header-normal" style={{color:'#192bc2'}}>{int.Titolo}</h4>
            <div style={{display:'flex',alignItems:'center'}}>
            <h4 className="sub-text">Stato: </h4>
            <h4 className="sub-text" style={{marginLeft:'5px', color:'black'}}>{int.Stato}</h4>
            </div>
            <div style={{display:'flex',alignItems:'center'}}>
            <h4 className="sub-text">Esito: </h4>
            <h4 className="sub-text" style={{marginLeft:'5px', color:'black'}}>{int.Esito?int.Esito:'-'}</h4>
            </div>
            <div style={{display:'flex',alignItems:'center'}}>
            <h4 className="sub-text">Data di intervento: </h4>
            <h4 className="sub-text" style={{marginLeft:'5px', color:'black'}}>{int.Data?formatData(int.Data):'-'}</h4>
            </div>
            <div onClick={()=>handleManutenzionePdf(int)} style={{display:'flex',alignItems:'center',cursor:'pointer',width:'fit-content',marginTop:'10px'}}>
            <h4  className="medium-text" style={{marginLeft:0}}>Scarica PDF</h4>
            <DownloadIcon style={{color:'black',fontSize:'1rem'}}/>
            </div>
          </div>
        ))}
      </div>:<h4 style={{marginTop:'1rem'}} className="medium-text">Nessuna manutenzione aggiunta...</h4>}

      <div style={{alignItems:'center',marginTop:'5vh',marginLeft:'auto',width:'fit-content'}}>
        </div>
    </div>
    </div>
}

export default Manutenzioni;