//sezione account
import React, { useState, useEffect } from "react";
import firebase from "firebase";
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import ComputerIcon from '@mui/icons-material/Computer';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { InfoPlan } from "../../Dashboard/Popups/InfoPlan";
import Loading from "../../loading";
import { ProfileImage } from "../../ProfileImage/ProfileImage";
import CancelIcon from '@material-ui/icons/Cancel';
import { checkWriteS,checkDeleteS,getUtente } from "../../EngineClienti/engineClienti";
const AccountS = ({history}) => {

    const db = firebase.firestore(); //database firebase
    const auth = firebase.auth(); //autentication firebase

    const [nome, setNome] = useState(""); //var nome account
    const [cognome, setCognome] = useState(""); //var cognome account
    const [telefono,setTelefono] = useState('') //var per telefono
    const [email,setEmail] = useState('') //var per telefono
    const [azienda,setAzienda] = useState('') //var per ragione soc
    

    const [editN,setEditN] = useState(false) //trigger edit nome
    const [editC,setEditC] = useState(false) //trigger edit cognome
    const [editT,setEditT] = useState(false) //trigger edit telefono
    const [editA,setEditA] = useState(false) //trigger edit nome azienda

    const [newNome,setNewNome] = useState('') //var nuovo nome
    const [newCognome,setNewCognome] = useState('') //var nuovo cognome
    const [newTelefono,setNewTelefono] = useState('') //var nuovo telefono
    const [newAzienda,setNewAzienda] = useState('') //var per nuova azienda

    const [user,setUser] = useState() //var per info utente

    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
            if (user) {
        db.collection("UsersId")
        .doc(auth.currentUser.uid)
        .get()
        .then((docz) => {
          db.collection("Users")
        .doc(docz.data().Email)
        .get().then((doc)=>{
          getUtente(db,auth,setUser)

          if(doc.data().Tipo==='SottoAccount'){
            db.collection('UsersId').doc(doc.data().MasterAccount).get().then((sottuss)=>{
                db.collection('Users').doc(sottuss.data().Email).get().then((sottus)=>{
                    console.log(sottus.data())
                setNome(doc.data().Nome);
                setCognome(doc.data().Cognome);
                setTelefono(doc.data().Numero);
                setAzienda(sottus.data().Nome_Azienda)
                setEmail(doc.data().CurrentEmail)  
              })
            })
        }else{
            setNome(doc.data().Nome);
            setCognome(doc.data().Cognome);
            setTelefono(doc.data().Numero);
            setAzienda(doc.data().Nome_Azienda)
            setEmail(doc.data().CurrentEmail)
        }
         
        })
         
        
        });
    }
})
    },[auth]) //prende dati utenti

    function changePw(){
      if(window.confirm("Sei sicuro di voler cambiare password?")){
        auth.sendPasswordResetEmail(auth.currentUser.email).then(()=>{
          alert("Una mail con il link per cambiare la password è stata inviata all'indirizzo email correlato a questo account. Se non la visualizzi prova a guardare nella sezione Spam del tuo servizio email. ")
        })
      }
    } //cambia pw

    const ChangeEmail = (props) => {

      const [newEmail,setNewEmail] = useState('')

      async function saveNewEmail(){
        console.log(user.Password)
        const credential = firebase.auth.EmailAuthProvider.credential(
          email,
          user.Password
        );
        try{
        if(newEmail!==''){
          
          if(window.confirm(`Sei sicuro di voler cambiare la mail con: ${newEmail} ?`)){
            await auth.currentUser.reauthenticateWithCredential(credential);

            await auth.currentUser.updateEmail(newEmail);
            // Update the email
            await auth.currentUser.updateEmail(newEmail).then(()=>{
              db.collection('UsersId').doc(auth.currentUser.uid).get().then((docz)=>{
                db.collection('UsersId').doc(auth.currentUser.uid).update({
                  CurrentEmail: newEmail
                }).then(()=>{
                  db.collection('Users').doc(docz.data().Email).update({
                    CurrentEmail: newEmail
                  }).then(()=>{
                    setEmail(newEmail)
                    alert('Email cambiata con successo!')
                  })
                })
              })
              
            });

          }
        }else{
          alert('Inserisci una email per procedere.')
        }
      }catch(e){
        alert('Errore: ',e)
      }
        
      }

      return (
        <div className="pop-opac">
        <div className="popup" style={{backgroundColor:"white", minHeight:'40vh', height:'fit-content', oveflowY: "auto", width:'50vw', left:'25vw',top:'20vh'}} >
        <div style={{textAlign:'left', width: 'fit-content'}}>
            <button
              className="cancelIconButton"
              
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
            </button>
            </div>
            <h4 className='page-title' style={{marginTop:'5vh',marginLeft:'auto',marginRight:'auto'}}>CAMBIO EMAIL</h4>
        <div style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',marginTop:'2vh'}}>
         <input className="input-style2" placeholder="Inserisci nuova email..." style={{padding:'5px',width:'15vw'}} onChange={(e)=>setNewEmail(e.target.value)}/> <br/>
         <div  style={{marginLeft:'auto',marginRight:'auto',width:'fit-content',marginTop:'2vh'}}>
         <button className="main-button" onClick={saveNewEmail}>
          Salva
         </button>
         </div>
        </div>

        </div>
        </div>
      )
    }

    

    function salvaNome(){
      db.collection('Users').doc(user.Email).update({
        Nome: newNome
      }).then(()=>{
        setNome(newNome)
        alert('Nome cambiato con successo')
      })
    } //salva nuovo nome
    function salvaCognome(){
      db.collection('Users').doc(user.Email).update({
        Cognome: newCognome
      }).then(()=>{
        setCognome(newCognome)
        alert('Cognome cambiato con successo')
      })
    } //salva nuovo cognome
    function salvaTelefono(){
      db.collection('Users').doc(user.Email).update({
        Numero: newTelefono
      }).then(()=>{
        setCognome(newTelefono)
        alert('Telefono cambiato con successo')
      })
    } //salva nuovo telefono
    function salvaAzienda(){
      db.collection('Users').doc(user.Email).update({
        Nome_Azienda: newAzienda
      }).then(()=>{
        setAzienda(newAzienda)
        alert('Ragione sociale cambiata con successo')
      })
    } //salva nuovo telefono

    const [triggerPlan,setTriggerPlan] = useState(false) //trigger popup piani
    const [triggerEmail,setTriggerEmail] = useState(false)

    return <div style={{paddingLeft:'1rem',height:'88vh',overflowY:'auto',width:'71vw'}}>
      {triggerEmail===true?<ChangeEmail trigger={triggerEmail} setTrigger={setTriggerEmail}/>:null}
      <h4 className="title-section" style={{marginTop:'0.2rem'}}>Account</h4>
      {triggerPlan===true?<InfoPlan info={user} tipo={user.Tipo} plan={user.Plan} trigger={triggerPlan} setTrigger={setTriggerPlan}/>:null}
      {user?<div>
      {/*informazioni di contatto*/}
      <div>
        <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
          <PersonIcon style={{color:'black'}}/>
      <h4 className="header-normal" style={{marginLeft:'5px'}}>Informazioni di contatto</h4>
      </div>
      {/*nome*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        {editN===false?<h4 className="normal-text">{nome}</h4>:<input type='text' className='input-style' placeholder="Inserisci..." onChange={(e)=>setNewNome(e.target.value)}></input>}
    <h4 className="sub-text">Nome</h4>
        </div>
        <div style={{marginLeft:'auto',width:'fit-content'}}>
          {editN===false?<IconButton style={{width:'fit-content'}} onClick={()=>setEditN(true)}>
            <EditIcon></EditIcon>
          </IconButton>: <div style={{display:'flex',alignItems:'center',marginLeft:5}}>
        <IconButton style={{width:'fit-content',backgroundColor:'lightgreen'}}>
        <CheckIcon onClick={()=>{setEditN(false);newNome!==''?salvaNome():alert('Inserisci il nuovo nome')}} style={{color:'green'}}/>
        </IconButton>
        <IconButton onClick={()=>{setEditN(false);setNewNome('')}} style={{marginLeft:5,backgroundColor:'lightpink',width:'fit-content'}}>
        <CloseIcon style={{color:'red'}}/>
        </IconButton>
        </div>}
        </div>
      </div>
      {/*cognome*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        {editC===false?<h4 className="normal-text">{cognome}</h4>:<input type='text' className='input-style' placeholder="Inserisci..." onChange={(e)=>setNewCognome(e.target.value)}></input>}
    <h4 className="sub-text">Cognome</h4>
        </div>
        <div style={{marginLeft:'auto',width:'fit-content'}}>
        {editC===false?<IconButton style={{width:'fit-content'}} onClick={()=>setEditC(true)}>
            <EditIcon></EditIcon>
          </IconButton>: <div style={{display:'flex',alignItems:'center',marginLeft:5}}>
        <IconButton style={{width:'fit-content',backgroundColor:'lightgreen'}}>
        <CheckIcon onClick={()=>{setEditC(false);newCognome!==''?salvaCognome():alert('Inserisci il nuovo cognome')}} style={{color:'green'}}/>
        </IconButton>
        <IconButton onClick={()=>{setEditC(false);setNewCognome('')}} style={{marginLeft:5,backgroundColor:'lightpink',width:'fit-content'}}>
        <CloseIcon style={{color:'red'}}/>
        </IconButton>
        </div>}
        </div>
      </div>
      {/*telefono*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        {editT===false?<h4 className="normal-text">{telefono}</h4>:<input type='text' className='input-style' placeholder="Inserisci..." onChange={(e)=>setNewTelefono(e.target.value)}></input>}
    <h4 className="sub-text">Telefono</h4>
        </div>
        <div style={{marginLeft:'auto',width:'fit-content'}}>
        {editT===false?<IconButton style={{width:'fit-content'}} onClick={()=>setEditT(true)}>
            <EditIcon></EditIcon>
          </IconButton>: <div style={{display:'flex',alignItems:'center',marginLeft:5}}>
        <IconButton style={{width:'fit-content',backgroundColor:'lightgreen'}}>
        <CheckIcon onClick={()=>{setEditT(false);newTelefono!==''?salvaTelefono():alert('Inserisci il nuovo telefono')}} style={{color:'green'}}/>
        </IconButton>
        <IconButton onClick={()=>{setEditT(false);setNewTelefono('')}} style={{marginLeft:5,backgroundColor:'lightpink',width:'fit-content'}}>
        <CloseIcon style={{color:'red'}}/>
        </IconButton>
        </div>}
        </div>
      </div>
      {/*email*/}
      <div className="flex-row" style={{width:'45vw',borderBottom:'none'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{email}</h4>
    <h4 className="sub-text">Email</h4>
        </div>
       
      </div>
      <div style={{display:'flex'}}>
      <button onClick={()=>changePw()}  style={{marginBottom:'1vh',width:'fit-content'}} className="blue-button">Cambia password</button><br/>
      <button onClick={()=>setTriggerEmail(true)}  style={{marginBottom:'1vh',width:'fit-content',marginLeft:'1vw'}} className="blue-button">Cambia Email</button><br/>
      </div>
      </div>
      {/*informazioni account mysic*/}
      <div style={{marginTop:'1rem'}}>
        <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
          <ComputerIcon style={{color:'black'}}/>
      <h4 className="header-normal" style={{marginLeft:'5px'}}>Informazioni Account Mysic</h4>
      </div>
      {/*nome azienda*/}
      <div style={{width:'45vw'}}>
        {console.log(user)}
        {user!==null?<div style={{marginTop:'1rem',width:'fit-content',maxWidth:'40vw'}}>
        <ProfileImage db={db} ragsoc={user.Nome_Azienda} auth={auth}/>
    <h4 className="sub-text">Foto Profilo</h4>
        </div>:null}
       
      </div>
      {/*nome azienda*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        {editA===false?<h4 className="normal-text">{azienda}</h4>:<input type='text' className='input-style' placeholder="Inserisci..." onChange={(e)=>setNewAzienda(e.target.value)}></input>}
    <h4 className="sub-text">Ragione Sociale</h4>
        </div>
        {console.log(user)}
        {user.TipoS===null?<div style={{marginLeft:'auto',width:'fit-content'}}>
        {editA===false?<IconButton style={{width:'fit-content'}} onClick={()=>setEditA(true)}>
            <EditIcon></EditIcon>
          </IconButton>: <div style={{display:'flex',alignItems:'center',marginLeft:5}}>
        <IconButton style={{width:'fit-content',backgroundColor:'lightgreen'}}>
        <CheckIcon onClick={()=>{setEditA(false);newAzienda!==''?salvaAzienda():alert('Inserisci la nuova ragione sociale')}} style={{color:'green'}}/>
        </IconButton>
        <IconButton onClick={()=>{setEditA(false);setNewAzienda('')}} style={{marginLeft:5,backgroundColor:'lightpink',width:'fit-content'}}>
        <CloseIcon style={{color:'red'}}/>
        </IconButton>
        </div>}
        </div>:null}
      </div>
      {/*tipo account*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{user.Tipo==='Cliente'?'Cliente':'Manutentore'}</h4>
    <h4 className="sub-text">Tipo Account</h4>
        </div>
        
      </div>
      {/*piano selezionato*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{user.Plan===0?'Base':user.Plan===1?'PRO':"PRO XL"}</h4>
    <h4 className="sub-text">Piano Selezionato</h4>
        </div>
        <div style={{marginLeft:'auto',width:'fit-content'}}>
          <IconButton style={{width:'fit-content'}} onClick={()=>setTriggerPlan(true)}>
            <InfoIcon></InfoIcon>
          </IconButton>
        </div>
      </div>
      {/*spazio occupato*/}
      {user.Tipo==='Cliente'?<div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{parseFloat(user.Current_MB).toFixed(2)}MB / {user.Max_MB}MB</h4>
    <h4 className="sub-text">Spazio Occupato</h4>
        </div>
        
      </div>:null}
       {/*clienti aggiunti*/}
       {user.Tipo==='Lavoratore'?<div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{user.Current_Cli} / {user.Max_Cli}</h4>
    <h4 className="sub-text">Clienti aggiunti</h4>
        </div>
        
      </div>:null}
      {/*elementi inseriti*/}
      {user.Tipo==='Cliente'?<div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{user.Current_Ele} / {user.Max_Ele}</h4>
    <h4 className="sub-text">Elementi creati</h4>
        </div>
       
      </div>:null}
      {/*sottoaccount creati*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{user.Current_Sott} / {user.Max_Sott}</h4>
    <h4 className="sub-text">Sottoaccount creati</h4>
        </div>
        
      </div>
      </div>
      </div>:<Loading/>}
      <div style={{alignItems:'center',marginTop:'5vh',marginLeft:'auto',width:'fit-content'}}>
        </div>
    </div>
}

export default AccountS;