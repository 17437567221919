//sezione account
import React, { useState, useEffect } from "react";
import firebase from "firebase";
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Tooltip } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { formatData } from "../../EngineClienti/engineClienti";
import PlaceIcon from '@mui/icons-material/Place';
import EngineeringIcon from '@mui/icons-material/Engineering';
import BuildIcon from '@mui/icons-material/Build';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import CancelIcon from "@material-ui/icons/Cancel";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { getIcon,checkDeleteS,checkWriteS,isShared } from "../../EngineClienti/engineClienti";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HelpIcon from '@mui/icons-material/Help';

const Dettagli = (props) => {

    const db = firebase.firestore(); //database firebase

    const [elemento,setElemento] = useState(props.elemento)
    

    const [editN,setEditN] = useState(false) //trigger edit nome
    const [editD,setEditD] = useState(false) //trigger edit desc
    const [editI,setEditI] = useState(false) //trigger edit data inst
    const [editM,setEditM] = useState(false) //trigger edit marca
    const [editC,setEditC] = useState(false) //trigger edit codice univoco


    const [newNome,setNewNome] = useState('') //var nuovo nome
    const [newDescrizione,setNewDescrizione] = useState('') //var nuova desc
    const [newInst,setNewInst] = useState('') //var nuova d inst
    const [newMarca,setNewMarca] = useState('') //var per nuova marca
    const [newCode,setNewCode] = useState('') //var per nuova marca

    const [triggerChange,setTriggerChange] = useState(false)
    const [image,setImage] = useState()

    const [mapName,setMapName] = useState(null)

    const history = useHistory()

    function salvaNome(){
        db.collection('Elementi').doc(props.elemento.Id).update({
            Nome: newNome
        })
        var e = elemento
        e.Nome=newNome
        setElemento(e)

        const listaE = JSON.parse(sessionStorage.getItem('elementi'));
        var arr = [...listaE]
        var index = listaE.findIndex((e)=>e.Id===elemento.Id)
        if(index!==-1){
            arr[index].Nome = newNome
        }
        sessionStorage.setItem('elementi', JSON.stringify(arr));


    }
    function salvaDescrizione(){
        db.collection('Elementi').doc(props.elemento.Id).update({
            Descrizione: newDescrizione
        })
        var e = elemento
        e.Descrizione=newDescrizione
        setElemento(e)

        const listaE = JSON.parse(sessionStorage.getItem('elementi'));
        var arr = [...listaE]
        var index = listaE.findIndex((e)=>e.Id===elemento.Id)
        if(index!==-1){
            arr[index].Descrizione = newDescrizione
        }
        sessionStorage.setItem('elementi', JSON.stringify(arr));


    }
    function salvaInst(){
        db.collection('Elementi').doc(props.elemento.Id).update({
            Data_Inst: newInst
        })
        var e = elemento
        e.Data_Inst=newInst
        setElemento(e)

        const listaE = JSON.parse(sessionStorage.getItem('elementi'));
        var arr = [...listaE]
        var index = listaE.findIndex((e)=>e.Id===elemento.Id)
        if(index!==-1){
            arr[index].Data_Inst = newInst
        }
        sessionStorage.setItem('elementi', JSON.stringify(arr));


    }
    function salvaMarca(){
        db.collection('Elementi').doc(props.elemento.Id).update({
            Marca: newMarca
        })
        var e = elemento
        e.Marca=newMarca
        setElemento(e)

        const listaE = JSON.parse(sessionStorage.getItem('elementi'));
        var arr = [...listaE]
        var index = listaE.findIndex((e)=>e.Id===elemento.Id)
        if(index!==-1){
            arr[index].Marca = newMarca
        }
        sessionStorage.setItem('elementi', JSON.stringify(arr));


    }
    function salvaCodice(){
        db.collection('Elementi').doc(props.elemento.Id).update({
            Code: newCode
        })
        var e = elemento
        e.Code=newCode
        setElemento(e)

        const listaE = JSON.parse(sessionStorage.getItem('elementi'));
        var arr = [...listaE]
        var index = listaE.findIndex((e)=>e.Id===elemento.Id)
        if(index!==-1){
            arr[index].Code = newCode
        }
        sessionStorage.setItem('elementi', JSON.stringify(arr));


    }

    const ChangePhoto = (props) =>{

      return (
        props.trigger===true?<div className="pop-opac">
          <div className="popup" style={{top:'10vh'}}>
          <div style={{ textAlign: "left", }}>
              <button
                className="cancelIconButton"
                onClick={() => props.setTrigger(false)}
              >
                <CancelIcon
                  style={{ width: "15px", height: "15px" }}
                  className="cancelIcon"
                />
              </button>
            </div>
            <h4 className='page-title' style={{marginTop:10,marginLeft:'2rem',fontSize:'2rem'}}>Carica foto elemento {percentage!==null?percentage+'%':null}</h4>
            <div style={{width:'25vw',height:'25vw',marginLeft:'auto',marginRight:'auto',border:'1px solid lightgrey',borderRadius:5,marginTop:'1rem',position:'relative'}}>
              <input type='file' onChange={(e)=>upload(e)} style={{position:'absolute',width:'25vw',height:'25vw',opacity:0,zIndex:100}}>
              </input>
              <div style={{marginTop:'10vh',marginBottom:'auto',marginLeft:'auto',marginRight:'auto',width:'fit-content',height:'fit-content',}}>
              <FileUploadIcon style={{width:'4vw',height:'4vw',color:'#5C6672',marginLeft:'1vw',marginRight:'auto'}}/>
              <h4 style={{marginTop:10,fontSize:'1rem',color:'#5C6672'}}>Trascina o clicca</h4>
  
              </div>
            </div>
          </div>
        </div>:null
      )
    }
    
    const upload = async (e) => {
      var files =  e.target.files;
  
          console.log(e.target.files)
  
      
                const file = files[0];
                const ext = file.name.split('.').pop()
                if(ext!=='jpg'&&ext!=='png'){
                  alert('Tipo di immagine caricato non supportato')
                }else{
                  setImage(file)
                  uploadFile(file)
                }
  }
  const storage = firebase.storage();
      var storageRef = storage.ref();
      const [percentage,setPercentage] = useState(null)
const uploadFile = async (img) => {
  //funzione per uploadare file su storage
        
      try{
        alert('Caricamento in corso, premere Ok e attendere il caricamento.')
       
    
        const uploadTask = storageRef.child(
          props.user.Email +
            "/Elementi/" + 
            elemento
        ).put(img); //percorso del file su storage
        uploadTask.on('state_changed', 
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setPercentage(progress)
          
        }, 
        (error) => {
          // Handle unsuccessful uploads
        }, 
        // eslint-disable-next-line no-loop-func
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            db.collection('Elementi').doc(elemento.Id).update({
              Photo: downloadURL
            }).then(()=>{
              setPercentage(null)
              alert('Immagine elemento inserita, aggiornare la pagina per visualizzare le modifiche')
            }) 
          });
        })
      }catch(e){
        alert('Error: '+e)
      }
           
          }

          useEffect(()=>{
            getMappaName()
          },[elemento])

         const getMappaName = () => {
            if(elemento.Mappa&&elemento.Mappa!==''){
              db.collection('Mappe').doc(elemento.Mappa).get().then((map)=>{
                if(map.exists){
                  const name = map.data().Nome
                 setMapName(name)
                }else{
                  setMapName(null)
                }
              })
            }else{
              setMapName(null)
            }
          }

          function goToMap(){
            if(props.user.Tipo==='Cliente'){
              history.push(`/mappecliente/${elemento.Cliente}/${elemento.Sede}/piani/${elemento.Piano}/${elemento.Mappa}/visualizemappa`)
            }else{
              history.push(`/mappemanutentore/${elemento.Cliente}/${elemento.Sede}/piani/${elemento.Piano}/${elemento.Mappa}/visualizemappa`)
            }
          }

    return <div style={{paddingLeft:'1rem',height:'80vh',overflowY:'auto',width:'71vw'}}>
      <div>
      {/*informazioni di contatto*/}
      <div>
        <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
        <BuildIcon style={{color:'black'}}/>

      <h4 className="header-normal" style={{marginLeft:'5px'}}>Informazioni elemento</h4>
      </div>
          {console.log(elemento)}
      {/*foto profilo */}
      {triggerChange===true?<ChangePhoto trigger={triggerChange} setTrigger={setTriggerChange}/>:null}
            <div style={{ display: "flex", alignItems: "center",marginTop:'1rem' }}>
              <div style={{position:'relative',width:'3vw',height:'3vw'}}>
              <button onClick={()=>setTriggerChange(true)} style={{cursor:'pointer',position:'absolute',backgroundColor:'rgba(0,0,0,0.5)',border:'none',width:'3vw',height:'3vw'}}>
              <PhotoCameraIcon style={{color:'white',marginLeft:0,marginRight:'auto',width:'fit-content'}}/>
              </button>
              {elemento?<img src={elemento.Photo!==null&&elemento.Photo!==undefined?elemento.Photo:getIcon(elemento)} alt='ele_img' style={{width: "3vw",
            height: "3vw",}}></img>:null}
              </div>
              </div>
      {/*nome*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        {editN===false?<h4 className="normal-text">{elemento.Nome}</h4>:<input type='text' className='input-style' placeholder="Inserisci..." onChange={(e)=>setNewNome(e.target.value)}></input>}
    <h4 className="sub-text">ID TESTUALE</h4>
        </div>
        <div style={{marginLeft:'auto',width:'fit-content'}}>
          {editN===false?checkWriteS(props.user.Permissions)===true?props.user.Tipo==='Lavoratore'?<IconButton style={{width:'fit-content'}} onClick={()=>setEditN(true)}>
            <EditIcon></EditIcon>
          </IconButton>:isShared(elemento)===false?<IconButton style={{width:'fit-content'}} onClick={()=>setEditN(true)}>
            <EditIcon></EditIcon>
          </IconButton>:null:null: <div style={{display:'flex',alignItems:'center',marginLeft:5}}>
        <IconButton style={{width:'fit-content',backgroundColor:'lightgreen'}}>
        <CheckIcon onClick={()=>{setEditN(false);newNome!==''?salvaNome():alert('Inserisci il nuovo nome')}} style={{color:'green'}}/>
        </IconButton>
        <IconButton onClick={()=>{setEditN(false);setNewNome('')}} style={{marginLeft:5,backgroundColor:'lightpink',width:'fit-content'}}>
        <CloseIcon style={{color:'red'}}/>
        </IconButton>
        </div>}
        </div>
      </div>
      {/*categoria*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{elemento.Categoria}</h4>
    <h4 className="sub-text">Categoria</h4>
        </div>
        
      </div>
      {/*sottocategoria*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{elemento.Macrocategoria}</h4>
    <h4 className="sub-text">Sottocategoria</h4>
        </div>
      </div>
      {/*sede*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{elemento.Sede}</h4>
    <h4 className="sub-text">Sede</h4>
        </div>
      </div>
      {/*descrizione*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        {editD===false?<h4 className="normal-text">{elemento.Descrizione!==''?elemento.Descrizione:'-'}</h4>:<input type='text' className='input-style' placeholder="Inserisci..." onChange={(e)=>setNewDescrizione(e.target.value)}></input>}
    <h4 className="sub-text">Descrizione</h4>
        </div>
        <div style={{marginLeft:'auto',width:'fit-content'}}>
        {editD===false?checkWriteS(props.user.Permissions)===true?props.user.Tipo==='Lavoratore'?<IconButton style={{width:'fit-content'}} onClick={()=>setEditD(true)}>
            <EditIcon></EditIcon>
          </IconButton>:isShared(elemento)===false?<IconButton style={{width:'fit-content'}} onClick={()=>setEditD(true)}>
            <EditIcon></EditIcon>
          </IconButton>:null:null: <div style={{display:'flex',alignItems:'center',marginLeft:5}}>
        <IconButton style={{width:'fit-content',backgroundColor:'lightgreen'}}>
        <CheckIcon onClick={()=>{setEditD(false);newDescrizione!==''?salvaDescrizione():alert('Inserisci la descrizione')}} style={{color:'green'}}/>
        </IconButton>
        <IconButton onClick={()=>{setEditD(false);setNewDescrizione('')}} style={{marginLeft:5,backgroundColor:'lightpink',width:'fit-content'}}>
        <CloseIcon style={{color:'red'}}/>
        </IconButton>
        </div>}
        </div>
      </div>
      {/*data di installazione*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        {editI===false?<h4 className="normal-text">{elemento.Data_Inst!==''?formatData(elemento.Data_Inst):'-'}</h4>:<input type='date' className='input-style' placeholder="Inserisci..." onChange={(e)=>setNewInst(e.target.value)}></input>}
    <h4 className="sub-text">Data di installazione</h4>
        </div>
        <div style={{marginLeft:'auto',width:'fit-content'}}>
        {editI===false?checkWriteS(props.user.Permissions)===true?props.user.Tipo==='Lavoratore'?<IconButton style={{width:'fit-content'}} onClick={()=>setEditI(true)}>
            <EditIcon></EditIcon>
          </IconButton>:isShared(elemento)===false?<IconButton style={{width:'fit-content'}} onClick={()=>setEditI(true)}>
            <EditIcon></EditIcon>
          </IconButton>:null:null:<div style={{display:'flex',alignItems:'center',marginLeft:5}}>
        <IconButton style={{width:'fit-content',backgroundColor:'lightgreen'}}>
        <CheckIcon onClick={()=>{setEditI(false);newInst!==''?salvaInst():alert('Inserisci la data di installazione')}} style={{color:'green'}}/>
        </IconButton>
        <IconButton onClick={()=>{setEditI(false);setNewInst('')}} style={{marginLeft:5,backgroundColor:'lightpink',width:'fit-content'}}>
        <CloseIcon style={{color:'red'}}/>
        </IconButton>
        </div>}
        </div>
      </div>
      {/*Marca*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        {editM===false?<h4 className="normal-text">{elemento.Marca!==''?elemento.Marca:'-'}</h4>:<input type='text' className='input-style' placeholder="Inserisci..." onChange={(e)=>setNewMarca(e.target.value)}></input>}
    <h4 className="sub-text">Marca</h4>
        </div>
        <div style={{marginLeft:'auto',width:'fit-content'}}>
        {editM===false?checkWriteS(props.user.Permissions)===true?props.user.Tipo==='Lavoratore'?<IconButton style={{width:'fit-content'}} onClick={()=>setEditM(true)}>
            <EditIcon></EditIcon>
          </IconButton>:isShared(elemento)===false?<IconButton style={{width:'fit-content'}} onClick={()=>setEditM(true)}>
            <EditIcon></EditIcon>
          </IconButton>:null:null: <div style={{display:'flex',alignItems:'center',marginLeft:5}}>
        <IconButton style={{width:'fit-content',backgroundColor:'lightgreen'}}>
        <CheckIcon onClick={()=>{setEditM(false);newMarca!==''?salvaMarca():alert('Inserisci la marca')}} style={{color:'green'}}/>
        </IconButton>
        <IconButton onClick={()=>{setEditM(false);setNewMarca('')}} style={{marginLeft:5,backgroundColor:'lightpink',width:'fit-content'}}>
        <CloseIcon style={{color:'red'}}/>
        </IconButton>
        </div>}
        </div>
      </div>
      {/*Codice univoco*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        {editC===false?<h4 className="normal-text">{elemento.Code!==''?elemento.Code:'-'}</h4>:<input type='text' className='input-style' placeholder="Inserisci..." onChange={(e)=>setNewCode(e.target.value)}></input>}
    <h4 className="sub-text">Matricola</h4>
        </div>
        <div style={{marginLeft:'auto',width:'fit-content'}}>
        {editC===false?checkWriteS(props.user.Permissions)===true?props.user.Tipo==='Lavoratore'?<IconButton style={{width:'fit-content'}} onClick={()=>setEditC(true)}>
            <EditIcon></EditIcon>
          </IconButton>:isShared(elemento)===false?<IconButton style={{width:'fit-content'}} onClick={()=>setEditC(true)}>
            <EditIcon></EditIcon>
          </IconButton>:null:null: <div style={{display:'flex',alignItems:'center',marginLeft:5}}>
        <IconButton style={{width:'fit-content',backgroundColor:'lightgreen'}}>
        <CheckIcon onClick={()=>{setEditC(false);newCode!==''?salvaCodice():alert('Inserisci il nuovo telefono')}} style={{color:'green'}}/>
        </IconButton>
        <IconButton onClick={()=>{setEditC(false);setNewCode('')}} style={{marginLeft:5,backgroundColor:'lightpink',width:'fit-content'}}>
        <CloseIcon style={{color:'red'}}/>
        </IconButton>
        </div>}
        </div>
      </div>
      </div>
      {/*informazioni scadenze*/}
      <div style={{marginTop:'2rem'}}>
        <div style={{display:'flex',alignItems:'center',}}>
          <AccessTimeIcon style={{color:'black'}}/>
      <h4 className="header-normal" style={{marginLeft:'5px'}}>Stato Elemento</h4>
      </div>
      <div style={{width:'45vw'}}>
            
                  {/*ultima scadenza effettuata*/}

      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{elemento.UltimaScadenza?formatData(elemento.UltimaScadenza):'-'}</h4>

    <h4 className="sub-text">Ultima verifica effettuata</h4>
        </div>
      </div>
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text" style={{color:elemento.EsitoUltimaScadenza?elemento.EsitoUltimaScadenza==='Positivo'?'green':'red':'black'}}>{elemento.EsitoUltimaScadenza?elemento.EsitoUltimaScadenza:'-'}</h4>
    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>            
    <h4 className="sub-text">Esito ultima verifica effettuata</h4>
   { /*<Tooltip title="L'esito potrebbe dipendere da eventuali scadenze di riparazione."><HelpIcon style={{color:'#677483',fontSize:'1vw',marginLeft:'10px'}}/></Tooltip>*/}
    </div>
        </div>
      </div>
      {/*prossima verifica*/}
      <div style={{marginTop:'1rem',width:'fit-content',maxWidth:'40vw'}}>

      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{height:'4rem',width:'fit-content',maxWidth:'40vw',}}>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          <h4 className="normal-text">{elemento.ProssimaScadenza?elemento.ProssimaScadenza===null?'-':formatData(elemento.ProssimaScadenza):'-'}</h4>
          </div>
        
    <h4 className="sub-text">Prossima verifica</h4>
        </div>
      </div>
        {/*<ProfileImage db={db} ragsoc={user.Nome_Azienda} auth={auth}/>*/}
        </div>
       
      </div>

      </div>
      {/*informazioni mappa*/}
      <div style={{marginTop:'2rem'}}>
        <div style={{display:'flex',alignItems:'center',}}>
          <PlaceIcon style={{color:'black'}}/>
      <h4 className="header-normal" style={{marginLeft:'5px'}}>Informazioni Mappa</h4>
      </div>
      <div style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',width:'fit-content',maxWidth:'40vw'}}>
            {/*mappa*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{height:'4rem',width:'fit-content',maxWidth:'40vw',}}>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          <h4 className="normal-text">{elemento.Mappa?mapName===null?elemento.Mappa:mapName:'-'}</h4>
        {elemento.Mappa?<button onClick={()=>goToMap()} style={{width:'fit-content',cursor:'pointer',border:'none',backgroundColor:'transparent',height:'fit-content',marginLeft:'10px'}}>
          <VisibilityIcon style={{color:'#192bc2'}}/>
        </button>:null}
          </div>
        
    <h4 className="sub-text">Mappa</h4>
        </div>
      </div>
                  {/*coordinate*/}

      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'5.5rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">LAT: {elemento.Posizione?elemento.Posizione.Lat?elemento.Posizione.Lat:'-':'-'}</h4>
        <h4 className="normal-text">LNG: {elemento.Posizione?elemento.Posizione.Lng?elemento.Posizione.Lng:'-':'-'}</h4>

    <h4 className="sub-text">Coordinate</h4>
        </div>
      </div>
        {/*<ProfileImage db={db} ragsoc={user.Nome_Azienda} auth={auth}/>*/}
        </div>
       
      </div>

      </div>
      {/*informazioni manutentore*/}
      <div style={{marginTop:'2rem'}}>
        <div style={{display:'flex',alignItems:'center'}}>
          <EngineeringIcon style={{color:'black'}}/>
      <h4 className="header-normal" style={{marginLeft:'5px'}}>Informazioni Manutentore</h4>
      </div>
      <div style={{width:'45vw'}}>
        <div style={{width:'fit-content',maxWidth:'40vw'}}>
            {/*mappa*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{elemento.Shared?elemento.Shared:'-'}</h4>
    <h4 className="sub-text">Condiviso con</h4>
        </div>
      </div>
                
        {/*<ProfileImage db={db} ragsoc={user.Nome_Azienda} auth={auth}/>*/}
        </div>
       
      </div>

      </div>
      </div>
      <div style={{alignItems:'center',marginTop:'5vh',marginLeft:'auto',width:'fit-content'}}>
        </div>
    </div>
}

export default Dettagli;