import React, { useState, useEffect } from "react";
import "../../Popups/Popup.css";
import firebase from "firebase/app";
import CancelIcon from "@material-ui/icons/Cancel";
import AddActivityE from "../../Popups/AddActivityE";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CheckIcon from "@mui/icons-material/Check";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import GenerazionePdf from "../../GenerazionePdf";
import TextField from '@mui/material/TextField';

const Richiesta = (props) => {
  const auth = firebase.auth();
  const db = firebase.firestore();
  const [messaggio,setMessaggio] = useState('')



  function sendReq(){
    db.collection("Users").doc(props.info.Email).collection('Offritori').doc(props.selectedUser.Email).get().then((control)=>{
        if(control.exists){
            alert('Attenzione: hai già aggiunto o mandato una richiesta a questo offritore.')
        }else{
            db.collection("Users").doc(props.selectedUser.Email).collection("Richieste").doc(props.info.Email).set({
                Nome_Azienda: props.info.Email,
                email: auth.currentUser.email,
                Message: messaggio
                  }).then(()=>{
                    db.collection("Users") 
                    .doc(props.info.Email)
                    .collection("Offritori")
                    .doc(props.selectedUser.Email)
                    .set({
                      Email: props.selectedUser.Email,
                      Stato: 'pending'
                    }).then(()=>{
                        alert('Richiesta inviata con successo, attendi una risposta dal manutentore.')
                        props.setTrigger(false)
                    })
                  })
        }
    })
      }

  return props.trigger ? (
    <div className="pop-opac" style={{ width: "100vw" }}>

        <div
          className="popup"
          style={{
            backgroundColor: "white",
            top: "20vh",
            zIndex: "90",
            left: "25vw",
            width: "50vw",
            borderRadius: 10,
            height: "60vh",
          }}
        >


          <div style={{ textAlign: "left", width: "50vw" }}>
            <button
              className="cancelIconButton"
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon
                style={{ width: "15px", height: "15px" }}
                className="cancelIcon"
              />
            </button>
          </div>
          <div style={{width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>
          <h4 style={{fontSize:'2vw',color:'#0496ff',marginBottom:0,marginTop:'1vh'}}>AGGIUNGI MANUTENTORE</h4>
       <h4 style={{fontSize:'1vw',color:'#5C6672',fontWeight:'normal',marginTop:0}}>Invia una richiesta per aggiungere un nuovo manutentore</h4>
       <div style={{marginTop:10}}>
            <div style={{}}>
            <h4 style={{fontSize:'0.9vw',color:'#5C6672',fontWeight:'bold',marginTop:0,marginBottom:0,width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>Manutentore selezionato: </h4>
          <h4 style={{fontSize:'0.9vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>{props.selectedUser.Nome_Azienda} ({props.selectedUser.Email})</h4>   
         
            </div>
            <div style={{marginTop:15,width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>
            <h4 style={{fontSize:'0.9vw',color:'#5C6672',fontWeight:'bold',marginTop:0,marginBottom:0,width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>Messaggio: </h4>
          <TextField
          sx={{width:'15vw',marginLeft:'auto',marginRight:'auto',marginTop:1,fontFamily:'Rubik',fontWeight:'normal'}}
          id="filled-multiline-static"
          label="Inserisci"
          multiline
          rows={4}
          onChange={(e)=>setMessaggio(e.target.value)}
          variant="filled"
        />
            </div>
            <div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content',marginTop:'2vh'}}>
                <button onClick={sendReq} style={{cursor:'pointer',backgroundColor:'#0496ff',width:'fit-content',border:'none'}}>
                    <h4 style={{marginTop:0,marginBottom:0,fontFamily:'Rubik',fontWeight:'bold'}}>Invia richiesta</h4></button>
            </div>
            </div>
          </div>
         
        </div>

    </div>
  ) : (
    ""
  );
};

export default Richiesta;
