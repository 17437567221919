import React, { useState, useCallback } from "react";
import "../../Popups/Popup.css";
import firebase from "firebase/app";
import { withRouter } from "react-router-dom";
import CancelIcon from "@material-ui/icons/Cancel";
import emailjs from "emailjs-com"; //libreria per mandare le mail "emailjs.com"

function SottoAccount(props) {
  //popup aggiunta sottoaccount
  const auth = firebase.auth();
  const authnew = firebase.auth();
  const db = firebase.firestore();
  const [pass, setPass] = useState(null);
  const [emailBackup, setEmail] = useState(null);

  const handleSubmit = (async (event) => {
    event.preventDefault();
    const { email, password, nome, cognome, numero, confirm_password } =
      event.target.elements;
    try {
      
      if (
        password.value !== "" &&
        email.value !== "" &&
        nome.value !== "" &&
        cognome.value !== "" &&
        numero.value !== ""
      ) {
        if (
          password.value === confirm_password.value &&
          password.value.length >= 6
        ) {
          alert('Creazione sottoaccount in corso, premere "OK" e attendere.')
          var nomeE;
          db.collection("Users")
            .doc(auth.currentUser.email)
            .get()
            .then((docData) => {
              if(docData.data().Current_Sott<docData.data().Max_Sott){
                var fornitore = auth.currentUser.email;
                nomeE = docData.data().Nome;
                var typeMain = docData.data().Tipo;
                if (docData.data().Fornitore !== undefined) {
                  fornitore = docData.data().Fornitore;
                }
                db.collection('Users').doc(auth.currentUser.email).update({
                  Current_Sott: parseInt(docData.data().Current_Sott,10) + 1 
                })
  
                db.collection("Users")
                  .doc(email.value)
                  .set(
                    // Creo la cartella dell'utente del sotto account
                    {
                      Id: auth.currentUser.uid,
                      Nome: nome.value,
                      Cognome: cognome.value,
                      Email: email.value,
                      Password: password.value,
                      Numero: numero.value,
                      Tipo: "SottoAccount",
                      MasterAccount: auth.currentUser.email,
                      Nome_Azienda: docData.data().Nome_Azienda,
                      TipoSott: typeMain,
                      Fornitore: fornitore,
                      Ruolo: "Lettore",
                    }
                  )
                  .then((docRef) => {
                    setEmail(auth.currentUser.email);
                    setPass(password.value);
                  })
                  .catch((error) => {});
                  db.collection("Users")
                  .doc(auth.currentUser.email)
                  .collection("SottoAccounts")
                  .doc(email.value)
                  .set({
                    // Aggiungo nella cartella sottoAccount dell'account master i dati del sottoAccount
                    Nome: nome.value,
                    Cognome: cognome.value,
                    Email: email.value,
                    Password: password.value,
                    Numero: numero.value,
                    Ruolo: "Lettore",
                  })
                  .then(() => {
                                
                    authnew
                      .createUserWithEmailAndPassword(email.value, password.value) // Creo l'account firebase per il sottoaccount
                      .then((user) => {
                      
                        user.user.sendEmailVerification()
                          .then((response) => {
                            alert(
                              "Registrazione completata! Vi sarà mandata una mail con le vostre credenziali."
                            );
                          });
                       
                        authnew.signOut();
                        props.history.push("/login");
                      });
                  })
                  .catch((error) => {});
              }else{
                alert('Il tuo piano Mysic non ti permette di aggiungere ulteriori sottoaccount, fai un upgrade del tuo piano per poterne aggiungere degli ulteriori.')              }
             
            });

       
        } else {
          alert("Inserire una password con + di 6 caratteri!");
        }
      } else {
        alert("Attenzione campi mancanti!");
      }
    } catch (error) {
      alert("Errore: " + error.message);
    }
  });
  return props.trigger ? (
    <div className="pop-opac">
      <div
        className="popup"
        style={{
          backgroundColor: "white",
          width: "50vw",
          left: "25vw",
          height: "70vh",
        }}
      >
        <div style={{ textAlign: "left", width: "fit-content" }}>
          <button
            className="cancelIconButton"
            onClick={() => props.setTrigger(false)}
          >
            <CancelIcon
              style={{ width: "15px", height: "15px" }}
              className="cancelIcon"
            />
          </button>
        </div>
        <h4
          style={{
            fontSize: "2vw",
            color: "#0496ff",
            fontWeight: "bold",
            fontFamily: "Montserrat",
            letterSpacing: "-0.1vw",
            marginBottom: 0,
            marginTop: 0,
            width: "fit-content",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          AGGIUNGI SOTTOACCOUNT
        </h4>
        <h4
          style={{
            fontSize: "1vw",
            color: "#5C6672",
            fontWeight: "normal",
            fontFamily: "Montserrat",
            marginBottom: 0,
            marginTop: 0,
            marginLeft: "auto",
            marginRight: "auto",

            width: "fit-content",
          }}
        >
          Al termine verrà effettuato il logout dall'account corrente.
        </h4>

        <br />

        <form onSubmit={handleSubmit}>
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "fit-content",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div>
              <h4
                style={{
                  fontSize: "1.3vw",
                  color: "black",
                  fontWeight: "normal",
                  fontFamily: "Montserrat",
                  letterSpacing: "-0.1vw",
                  marginBottom: 0,
                  marginTop: "0.5vw",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "fit-content",
                }}
              >
                Nome
              </h4>
              <input
                type="text"
                style={{
                  fontWeight: "bold",
                  width: "14vw",
                  fontFamily: "Montserrat",
                  borderRadius: 5,
                  backgroundColor: "white",
                  border: "1px solid #5C6672",
                  fontSize: "0.8vw",
                  color: "black",
                  paddingBottom: "3px",
                  textAlign: "center",
                  height: "4vh",
                  marginTop: "1vh",
                }}
                name="nome"
                className="textInput"
                placeholder="Nome"
              />
            </div>
            <div style={{ marginLeft: "2vw" }}>
              <h4
                style={{
                  fontSize: "1.3vw",
                  color: "black",
                  fontWeight: "normal",
                  fontFamily: "Montserrat",
                  letterSpacing: "-0.1vw",
                  marginBottom: 0,
                  marginTop: "0.5vw",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "fit-content",
                }}
              >
                Cognome
              </h4>
              <input
                type="text"
                style={{
                  fontWeight: "bold",
                  width: "14vw",
                  fontFamily: "Montserrat",
                  borderRadius: 5,
                  backgroundColor: "white",
                  border: "1px solid #5C6672",
                  fontSize: "0.8vw",
                  color: "black",
                  paddingBottom: "3px",
                  textAlign: "center",
                  height: "4vh",
                  marginTop: "1vh",
                }}
                name="cognome"
                className="textInput"
                placeholder="Cognome"
              />
            </div>
          </div>
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "fit-content",
              alignItems: "center",
              display: "flex",
              marginTop: "2vh",
            }}
          >
            <div>
              <h4
                style={{
                  fontSize: "1.3vw",
                  color: "black",
                  fontWeight: "normal",
                  fontFamily: "Montserrat",
                  letterSpacing: "-0.1vw",
                  marginBottom: 0,
                  marginTop: "0.5vw",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "fit-content",
                }}
              >
                Email
              </h4>
              <input
                type="email"
                style={{
                  fontWeight: "bold",
                  width: "14vw",
                  fontFamily: "Montserrat",
                  borderRadius: 5,
                  backgroundColor: "white",
                  border: "1px solid #5C6672",
                  fontSize: "0.8vw",
                  color: "black",
                  paddingBottom: "3px",
                  textAlign: "center",
                  height: "4vh",
                  marginTop: "1vh",
                }}
                name="email"
                className="textInput"
                placeholder="Email"
              ></input>
            </div>
            <div style={{ marginLeft: "2vw" }}>
              <h4
                style={{
                  fontSize: "1.3vw",
                  color: "black",
                  fontWeight: "normal",
                  fontFamily: "Montserrat",
                  letterSpacing: "-0.1vw",
                  marginBottom: 0,
                  marginTop: "0.5vw",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "fit-content",
                }}
              >
                Numero di telefono
              </h4>
              <input
                type="number"
                name="numero"
                className="textInput"
                style={{
                  fontWeight: "bold",
                  width: "14vw",
                  fontFamily: "Montserrat",
                  borderRadius: 5,
                  backgroundColor: "white",
                  border: "1px solid #5C6672",
                  fontSize: "0.8vw",
                  color: "black",
                  paddingBottom: "3px",
                  textAlign: "center",
                  height: "4vh",
                  marginTop: "1vh",
                }}
                placeholder="Inserisci il numero di telefono"
              ></input>
            </div>
          </div>

          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "fit-content",
              alignItems: "center",
              display: "flex",
              marginTop: "2vh",
            }}
          >
            <div>
              <h4
                style={{
                  fontSize: "1.3vw",
                  color: "black",
                  fontWeight: "normal",
                  fontFamily: "Montserrat",
                  letterSpacing: "-0.1vw",
                  marginBottom: 0,
                  marginTop: "0.5vw",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "fit-content",
                }}
              >
                Password
              </h4>
              <input
                type="password"
                style={{
                  fontWeight: "bold",
                  width: "14vw",
                  fontFamily: "Montserrat",
                  borderRadius: 5,
                  backgroundColor: "white",
                  border: "1px solid #5C6672",
                  fontSize: "0.8vw",
                  color: "black",
                  paddingBottom: "3px",
                  textAlign: "center",
                  height: "4vh",
                  marginTop: "1vh",
                }}
                name="password"
                className="textInput"
                placeholder="password"
              ></input>
            </div>
            <div style={{ marginLeft: "2vw" }}>
              <h4
                style={{
                  fontSize: "1.3vw",
                  color: "black",
                  fontWeight: "normal",
                  fontFamily: "Montserrat",
                  letterSpacing: "-0.1vw",
                  marginBottom: 0,
                  marginTop: "0.5vw",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "fit-content",
                }}
              >
                Conferma Password
              </h4>
              <input
                type="Password"
                className="textInput"
                style={{
                  fontWeight: "bold",
                  width: "14vw",
                  fontFamily: "Montserrat",
                  borderRadius: 5,
                  backgroundColor: "white",
                  border: "1px solid #5C6672",
                  fontSize: "0.8vw",
                  color: "black",
                  paddingBottom: "3px",
                  textAlign: "center",
                  height: "4vh",
                  marginTop: "1vh",
                }}
                name="confirm_password"
                placeholder="Conferma password"
              ></input>
            </div>
          </div>
          <div
            style={{
              width: "fit-content",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <button
              style={{
                backgroundColor: "#0496ff",
                color: "white",
                fontFamily: "Montserrat",
                fontSize: "1vw",
                fontWeight: "bold",
                border: "1px solid #118ab2",
                borderRadius: 5,
                width: "15vw",
                cursor: "pointer",
                marginTop: "5vh",
              }}
              type="submit"
            >
              AGGIUNGI
            </button>
          </div>
        </form>
      </div>
    </div>
  ) : (
    ""
  );
}

export default withRouter(SottoAccount);
