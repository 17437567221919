//sezione account
import React, { useState, useEffect } from "react";
import firebase from "firebase";
import { formatData, getElemento, returnStato } from "../../EngineClienti/engineClienti";
import BuildIcon from '@mui/icons-material/Build';
import GenerazionePdf from "../../GenerazionePdf";
import { IconButton,Tooltip } from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const Dettagli = (props) => {

    const db = firebase.firestore(); //database firebase

    const [intervento,setIntervento] = useState(null)
    const [elemento,setElemento] = useState(null)
    const [stato,setStato] = useState('')
    const [indirizzo,setIndirizzo] = useState(null)
    
  const storage = firebase.storage();
      var storageRef = storage.ref();


      useEffect(()=>{
        if(props.intervento!==null){
          setIntervento(props.intervento)
        }
      },[props.intervento])


      useEffect(()=>{
        if(intervento!==null&&props.user!==null){
          getElemento(db,props.user.Email,intervento.Oggetto,setElemento)
          setStato(returnStato(props.intervento.Stato,props.intervento.Data))
          db.collection('Users').doc(intervento.Cliente).collection('Sedi').doc(intervento.Sede).get().then((sede)=>{
            setIndirizzo(sede.data().Indirizzo)
          })
        }
      },[intervento])

      function getSubActs(interventoId) {
        return new Promise((resolve, reject) => {
            const acts = [];
            db.collection("Attività")
                .doc(interventoId)
                .collection('Attività')
                .onSnapshot((querySnapshot) => {
                    querySnapshot.forEach((act) => {
                        acts.push({
                            key: act.id,
                            ...act.data(),
                        });
                    });
                    resolve(acts);  // Risolve la promessa con i sottointerventi
                }, reject);  // Gestisce l'errore
        });
    }

      function handleTitoloClick(elemento) {
        props.setLoading(true)
        console.log(elemento)
        db.collection("Users")
          .doc(elemento.Op_Email)
          .get()
          .then((operatoreDoc) => {
            var operatoreData = operatoreDoc.data();
            console.log(operatoreDoc);
            var operatore = {
              Nome_Azienda: operatoreData.Nome_Azienda,
              Nome: operatoreData.Nome,
              Cognome: operatoreData.Cognome,
              Indirizzo: operatoreData.Indirizzo,
              Sede: operatoreData.Comune,
              Telefono: operatoreData.Numero,
              Email: operatoreData.Email,
             
            };
            db.collection("Elementi").doc(elemento.Oggetto).get().then((elementoDoc) => {
              const elementiData = elementoDoc.exists ? [{
                  Nome: elementoDoc.data().Nome,
                  Esito: elemento.Esito,
              }] : [{
                  Nome: elemento.Oggetto,
                  Esito: elemento.Esito,
              }];

              // Recupera i sottointerventi
              getSubActs(elemento.Id).then((sottointerventi) => {
                  // Genera il PDF includendo i sottointerventi
                  console.log(sottointerventi)
                  GenerazionePdf.generaPdfIntervento(elemento, operatore, elementiData, sottointerventi,props.setLoading);
              });
          });
            
          });
      } //scarica il pdf riassuntivo dell intervento

    return <div style={{paddingLeft:'1rem',height:'80vh',overflowY:'auto',width:'71vw'}}>
      {/*informazioni intervento*/}
      {intervento!==null?<div>
        <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
        <BuildIcon style={{color:'black'}}/>

      <h4 className="header-normal" style={{marginLeft:'5px'}}>Informazioni intervento</h4>
        <Tooltip title='Scarica PDF riepilogativo'>
        <IconButton style={{width:'fit-content',height:'fit-content',marginLeft:'auto',marginRight:'2rem'}} onClick={()=>handleTitoloClick(intervento)}>
        <PictureAsPdfIcon style={{ color: "#FF0060" }}/>
        </IconButton>
        </Tooltip>
  

      </div>
      {/*titolo*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{intervento.Titolo}</h4>
    <h4 className="sub-text">Titolo</h4>
        </div>
      </div>
      {/*descrizione*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{intervento.Descrizione}</h4>
    <h4 className="sub-text">Descrizione</h4>
        </div>
      </div>
            {/*sede*/}
      <div className="flex-row" style={{width:'45vw'}}>
      <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
      <h4 className="normal-text">{intervento.SedeName?intervento.SedeName!==''?intervento.SedeName:intervento.Sede:intervento.Sede}</h4>
      <h4 className="sub-text">Sede</h4>
      </div>
      </div>
                  {/*indirizzo*/}
                  <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{indirizzo!==null?indirizzo:'Non inserito'}</h4>
    <h4 className="sub-text">Indirizzo</h4>
        </div>
      </div>
      {/*categoria*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{intervento.Categoria}</h4>
    <h4 className="sub-text">Categoria</h4>
        </div>
      </div>
            {/*Sottocategoria*/}
            <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{intervento.Macrocategoria?intervento.Macrocategoria:'Non inserito'}</h4>
    <h4 className="sub-text">Sottocategoria</h4>
        </div>
      </div>
                  {/*elemento*/}
                  <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{elemento!==null?elemento.Nome:'Non inserito'}</h4>
    <h4 className="sub-text">Elemento</h4>
        </div>
      </div>
                        {/*data*/}
                        <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{formatData(intervento.Data)} {intervento.Hour?' - '+intervento.Hour:null}</h4>
    <h4 className="sub-text">Data d'intervento</h4>
        </div>
      </div>
                              {/*stato*/}
         <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{stato}</h4>
    <h4 className="sub-text">Stato</h4>
        </div>
      </div>
                                    {/*esito*/}
                                    <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{intervento.Esito?intervento.Esito:"Esito disponibile una volta evaso l'intervento"}</h4>
    <h4 className="sub-text">Esito</h4>
        </div>
      </div>
      {/*report*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{intervento.Report?intervento.Report:"Report disponibile una volta evaso l'intervento"}</h4>
    <h4 className="sub-text">Report</h4>
        </div>
      </div>
      </div>:null}
      <div style={{alignItems:'center',marginTop:'5vh',marginLeft:'auto',width:'fit-content'}}>
        </div>
    </div>
}

export default Dettagli;