import React , { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import firebase from "firebase/app";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getUtente, getCategoria } from "../../EngineClienti/engineClienti";
import Loading from '../../loading';
import Dettagli from '../Categoria/Dettagli';
import Sedi from '../Categoria/Sedi';
import Sottocategorie from '../Categoria/Sottocategorie';
import NavBar from '../../NavBar/NavBar';
import NavBar2 from '../../NavBar/NavBar2'
const Categoria = ({history}) => {
    const auth = firebase.auth();
    const db = firebase.firestore();
    const {id} = useParams()

    const [user,setUser] = useState(null)
    const [categoria,setCategoria] = useState(null) //dati categoria
    const [section,setSection] = useState(0)


    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
            getUtente(db,auth,setUser)
          }
        })
      },[auth])

      useEffect(()=>{
        if(user!==null){
        
         getCategoria(db,user.Email,setCategoria,id)      
      }
    
      },[user,id])


  

  return (
    <div className="background" style={{width:'100vw',height:'100vh',overflow:'hidden'}}>
      {user?user.Tipo==='Lavoratore'?<NavBar2/>:<NavBar/>:null}
         <div
        style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}
      >

<div  style={{  width: "30vw",display:'flex',marginTop:'1.2rem' }}>
          <button style={{marginBottom:'auto',marginTop:'1rem'}} onClick={() => history.goBack()}
            className="cancelIconButton"
            
          >
            <ArrowBackIcon
             className="back-button"
            />
          </button>
          <div>
          <h4 className="page-title" style={{margin:0}}>{categoria?categoria.Nome:null}</h4>
         
          </div>

        </div>
        {categoria!==null?
        <div className="box-shadow" style={{display:'flex',width:'80vw',backgroundColor:'white',marginTop:'1.7rem',borderRadius:'15px',marginLeft:'3rem'}}>
        <div style={{alignItems:'center',height:'80vh',backgroundColor:'white',borderBottomLeftRadius:'15px',borderTopLeftRadius:'15px',width:'10rem',border:'1px solid #7d8da1'}}>
        <button onClick={()=>setSection(0)} style={{borderTopLeftRadius:'15px'}} className={section===0?"section-style-on":'section-style-off'}>Dettagli</button><br/>
        <button onClick={()=>setSection(1)} className={section===1?"section-style-on":'section-style-off'}>Sedi</button><br/>
        <button  onClick={()=>setSection(2)} className={section===2?"section-style-on":'section-style-off'}>Sottocategorie</button>
        </div>
       <div>
        {section===0?<Dettagli user={user} categoria={categoria} setCategoria={setCategoria}/>:null}
        {section===1?<Sedi user={user} categoria={categoria}/>:null}
        {section===2?<Sottocategorie user={user} setCategoria={setCategoria} categoria={categoria}/>:null}

        </div>
        </div>:<Loading/>}
       
      </div>
    </div>
  )
}

export default Categoria