import lampadaemergenzaIcon from "../images/lampadaemergenza.png";
import estintoreIcon from "../images/estintore.png";
import altroIcon from "../images/altro.png";
import quadroIcon from "../images/quadro-ele.png";
import tIcon from "../images/t.png";
import doorIcon from "../images/door.png";
import dispIcon from "../images/disp.png";
import pressIcon from "../images/pressurizzazione.png";
import attIcon from "../images/attman.png";
import rivIcon from "../images/riv.png";
import aspIcon from "../images/vac.png";
import idrIcon from "../images/idr.png";
import alarmIcon from "../images/alarm.png";
import bottIcon from "../images/bott.png";
import e from "cors";


////////////GENERALE

export function prendiSedi(db,email,n_sedi,setSedi){
    var arr = []
    var cx = 0
    var sx = 0
    var dx = 0

    setSedi([])
    db.collection('Users').doc(email).collection('Sedi').orderBy('Comune','asc').get().then((sedi)=>{
      sedi.docs.forEach((sed)=>{
        var n = 0
        var sede = sed.id
        db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Dipendenze').get().then((cats)=>{
          if(cats.docs.length>0){
            cx = 0
          cats.docs.forEach((c,i)=>{
            cx++
            db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Dipendenze').doc(c.id).collection('Sezioni').get().then((sez)=>{
              if(sez.docs.length>0){
                sx = 0
              sez.docs.forEach((s)=>{
                sx++
                db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Dipendenze').doc(c.id).collection('Sezioni').doc(s.id).collection('Dipendenze').get().then((dips)=>{
                  if(dips.docs.length>0){
                    dx = 0
                  dips.docs.forEach((d)=>{
                    dx++
                    if(d.data().Checked===false){
                        console.log(d.data())
                      if(d.data().Essenziale===true){
                        n = 2
                        //non presenti essenziali
                      }else{
                        if(n===2){
                          
                        }else{
                          n=1
                       
                        } //non presenti non essenziali
                      }
                    }
                    console.log(sede,dx,dips.docs.length,dx===dips.docs.length)
                    if(dx===dips.docs.length){
                      var index = arr.findIndex((e)=>e.Id===sede)
                      console.log(sede,index)
                      if(index===-1){
                        console.log(sede,n)
                        arr.push({
                          ...sed.data(),
                          Id: sede,
                          Status: n,
                          ChangeTag: false
                        })
                      }else{
                        arr[index] = { Id: sede, Status: n, ChangeTag: false, ...sed.data()}
                      }
                      
                    if(n_sedi===arr.length){
                        setSedi(arr)
                        return 
                    }
                    }
                  })
                }else{
                    var index = arr.findIndex((e)=>e.Id===sede)
                    if(index===-1){
                      arr.push({
                        ...sed.data(),
                        Id: sede,
                        Status: n,
                        ChangeTag: false
                      })
                    }
                    if(n_sedi===arr.length){
                      setSedi(arr)
                      return
                    }
                  }
                })
              })
            }else{
                var index = arr.findIndex((e)=>e.Id===sede)
                    if(index===-1){
                      arr.push({
                        ...sed.data(),
                        Id: sede,
                        Status: n,
                        ChangeTag: false
                      })
                    }
                if(n_sedi===arr.length){
                  setSedi(arr)
                  return 
                }
              }
            })
         
          })
        }else{
            var index = arr.findIndex((e)=>e.Id===sede)
                    if(index===-1){
                      arr.push({
                        ...sed.data(),
                        Id: sede,
                        Status: n,
                        ChangeTag: false
                      })
                    }
            if(n_sedi===arr.length){
              setSedi(arr)
              return
            }
          }
          })
      })
    })
  } //prende le sedi 

export function getElementiOnly(db,email,setElementi){
    setElementi([])
    db.collection('Users').doc(email).collection('Sedi').get().then((sedi)=>{
        sedi.forEach((sed)=>{
            db.collection('Users').doc(email).collection('Sedi').doc(sed.id).collection('Categorie').get().then((cats)=>{
                cats.forEach((cat)=>{
                    db.collection('Users').doc(email).collection('Sedi').doc(sed.id).collection('Categorie').doc(cat.id)
                    .collection('Sottocategorie').get().then((sotts)=>{
                        sotts.forEach((sott)=>{
                            db.collection('Users').doc(email).collection('Sedi').doc(sed.id).collection('Categorie').doc(cat.id)
                            .collection('Sottocategorie').doc(sott.id).collection('Elementi').get().then((elems)=>{
                                elems.forEach((ele)=>{
                                    db.collection('Elementi').doc(ele.data().ref).get().then((elez)=>{
                                        setElementi((prev)=>{
                                            return [
                                             ...prev,
                                             {
                                                 Id: elez.id,
                                                 ...elez.data()
                                             }
                                            ]
                                             
                                         })
                                    })
                                })
                            })
                        })
                    })
                })
            })
        })
    })
}
export function getDocumentiGenerale(db,email,setDocumenti){
    setDocumenti([])
    db.collection('Documenti').where('Autore','==',email).get().then((docs)=>{
        docs.forEach((doc)=>{
            setDocumenti((prev)=>{
                return [
                    ...prev,
                    {
                        Id: doc.id,
                        ...doc.data()
                    }
                ]
            })
        })
    })
}

export function formatData(data){
    if(data!==undefined){
      var string = data.split('-')
      var newstring = string[2]+'-'+string[1]+'-'+string[0]
    
      return newstring
    }else{
      return data
    }
  
  } //funzione per formattare la data

  export function returnStato(stato,data){ //funzione che ritorna lo stato dell'intervento/manutenzione
    var string = data.split('-')
    var current = new Date()
    var month = null
   
    if(current.getMonth()<=11){
       month = current.getMonth()+1
    }else{
       month=1
    }   
    if(stato==='Conclusa'){
       return stato
    }else{
       if(parseInt(string[0],10)<current.getFullYear()){ //anno più basso di quello di scadenza
           return 'Scaduto'
       }else if(parseInt(string[2],10)<current.getDate()&&parseInt(string[1],10)===month){ //stesso mese giorno più basso
           return 'Scaduto'
       }else if(parseInt(string[1],10)<month&&parseInt(string[0],10)===current.getFullYear()){ //mese più basso stesso anno
           return 'Scaduto'
       }else{
           return stato
       }
    }
   }

export function getSediCategorie(db,email,setSedi,setCategorie){

    setSedi([])
    setCategorie([])

    db.collection('Users').doc(email).collection('Sedi').get().then((sedi)=>{
        sedi.forEach((sed)=>{
            setSedi((prev)=>{
                return [
                    ...prev,
                    {
                        Id: sed.id,
                        ...sed.data()
                    }
                ]
            })
            db.collection('Users').doc(email).collection('Sedi').doc(sed.id).collection('Categorie').get().then((categorie)=>{
                categorie.forEach((cat)=>{
                    setCategorie((prev)=>{
                        return [
                            ...prev,
                            {
                                Id: cat.id,
                                ...cat.data()
                            }
                        ]
                    })
                })
            })
        })
    })
}   

export function getUtente(db,auth,setUser){

    db.collection('UsersId').doc(auth.currentUser.uid).get().then((docz)=>{
        db.collection('Users').doc(docz.data().Email).get().then((us)=>{
            if(!us.data().CurrentEmail){
                db.collection('Users').doc(docz.data().Email).update({
                    CurrentEmail: docz.data().Email 
                })
            }
            if(us.data().Tipo==='SottoAccount'){
                db.collection('UsersId').doc(us.data().MasterAccount).get().then((sottuss)=>{
                    db.collection('Users').doc(sottuss.data().Email).get().then((sottus)=>{
                    setUser({Id: sottuss.id, CurrentEmail: docz.data().CurrentEmail,...sottus.data(), Nome:us.data().Nome,Cognome:us.data().Cognome,Permissions:us.data().Permissions,TipoS: us.data().Tipo })
                    })
                })
            }else{
                setUser({Id: us.id, CurrentEmail: docz.data().CurrentEmail,...us.data(), TipoS: null})
            }
        })
    })

    
}

export function getSede(db,email,sede,setSede){
    db.collection('Users').doc(email).collection('Sedi').doc(sede).get().then((sed)=>{
        console.log(sed.data())
        setSede({Id:sed.id,...sed.data()})
    })
}

export function getCategorie(db,email,sede,setCategorie){
    setCategorie([])
    db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Categorie').get().then((categorie)=>{
        categorie.forEach((cat)=>{
            setCategorie((prev)=>{
                return [
                    ...prev,
                    {
                        Id: cat.id,
                        ...cat.data()
                    }
                ]
            })
        })
    })
} //prende categorie e cancella lista inizialmente
export function getCategorie2(db,email,sede,setCategorie){
    db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Categorie').get().then((categorie)=>{
        categorie.forEach((cat)=>{
            setCategorie((prev)=>{
                return [
                    ...prev,
                    {
                        Id: cat.id,
                        ...cat.data()
                    }
                ]
            })
        })
    })
} //prende categorie e NON cancella lista inizialmente
export function getSottocategorie(db,email,sede,categoria,setSottocategorie){
    setSottocategorie([])
    db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Categorie').doc(categoria).collection('Sottocategorie').get().then((sottocategorie)=>{
        sottocategorie.forEach((sott)=>{
            setSottocategorie((prev)=>{
                return [
                    ...prev,
                    {
                        Id: sott.id,
                        ...sott.data()
                    }
                ]
            })
        })
    })
}
export function getElementiG(db,email,sede,categoria,sottocategoria,setElementi){
    setElementi([])
    db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Categorie').doc(categoria).collection('Sottocategorie').doc(sottocategoria)
    .collection('Elementi').get().then((elementi)=>{
        elementi.forEach((elez)=>{
            db.collection('Elementi').doc(elez.id).get().then((ele)=>{
                setElementi((prev)=>{
                    return [
                        ...prev,
                        {
                            Id: ele.id,
                            ...ele.data()
                        }
                    ]
                })
            })
            
        })
    })
}

export function getManutentori(db,email,setManutentori){
    setManutentori([])
    db.collection('Users').doc(email).collection('Offritori').where('Stato','==','accepted').get().then((manutentori)=>{
        manutentori.forEach((man)=>{
            db.collection('Users').doc(man.data().Email).get().then((manz)=>{
                setManutentori((prev)=>{
                    return [
                        ...prev,
                        manz.data()
                    ]
                })
            })
            
        })
    })  
}

export function getSedeName(s_id,sedi){
    const sediz = [...sedi]
    const index = sediz.findIndex((e)=>e.Id===s_id)
    return sediz[index].Nome?sediz[index].Nome!==''?sediz[index].Nome:sediz[index].Comune:sediz[index].Comune
}

//controllo se l'elemento è condiviso
export function isShared(ele){
    if(ele.Shared){
        if(ele.Shared!==''){
            return true
        }else{
            return false
        }
    }else{
        return false
    }
}

//////////////////////CATEGORIE//////////////////

export function prendiCategorie(db,email,setSedi,setCategorie){
    setCategorie([])
    setSedi([])
    
    db.collection('Users').doc(email).collection('Sedi').get().then((sedi)=>{
        sedi.forEach((sede)=>{
         setSedi((prev)=>{
            return [
                ...prev,
                {
                    Id:sede.id,
                    ...sede.data()
                }
            ]
         })   
         db.collection('Users').doc(email).collection('Sedi').doc(sede.id).collection('Categorie').get().then((categorie)=>{
            categorie.forEach((categoria)=>{
               setCategorie((prev)=>{
                return [
                    ...prev,
                    {
                        Id: categoria.id,
                        Sede: sede.id,
                        SedeNome:sede.data().Comune,
                        ...categoria.data()
                    }
                ]
               })
            })
         })
        })
    })
}

export function getCategoria(db,email,setCategoria,categoriaz){
    var sediz = []
    var i = 0
    var agg = []

    db.collection('Users').doc(email).collection('SottocategorieAggiuntive').get().then((sottagg)=>{
        sottagg.forEach((sott)=>{
            if(sott.data().Categoria===categoriaz){
                agg.push({Id: sott.id, Nome:sott.data().Nome})
            }
        })
    })

    db.collection('Users').doc(email).collection('Sedi').get().then((sedi)=>{
        sedi.forEach((sede)=>{
            i++
                db.collection('Users').doc(email).collection('Sedi').doc(sede.id).collection('Categorie').doc(categoriaz).get().then((cat)=>{
                    
                    if(cat.exists){ //se presente significa che nella sede c'è la categoria
                        sediz.push({Nome: sede.data().Comune, Id: sede.id, Doc: cat.data().Doc, Ele: cat.data().Ele})
                        
                        console.log(i,sedi.docs.length,cat.data())
                      if(sedi.docs.length===i){
                        setCategoria({Id: cat.id, Nome: cat.data().Nome, Sedi: sediz, Aggiuntive: agg})
                      }
                    }
                })
        })
    })
}

/////////////////////ELEMENTI/////////////////////////////

export function getElementi(db,email,setSedi,setCategorie,setSottoCategorie,setElementi){
    setSedi([])
    setCategorie([])
    setSottoCategorie([])
    setElementi([])

    var categorie = []
    var sottocategorie = []
    var manutentori = []
    //////////SEDI/////////////////
    db.collection('Users').doc(email).collection('Sedi').get().then((sedi)=>{
        sedi.forEach(sed => {
            setSedi((prev)=>{ 
                return [
                    ...prev,{
                        Id: sed.id,
                        ...sed.data()

                    }
                ]
            }) //aggiunge sedi

            //////////CATEGORIE///////////////////
            db.collection('Users').doc(email).collection('Sedi').doc(sed.id).collection('Categorie').get().then((cats)=>{
                cats.forEach((cat)=>{
                        categorie.push(cat.id)
                        setCategorie((prev)=>{
                            return [
                                ...prev,
                                {
                                    Id:cat.id,
                                    ...cat.data()
                                }
                            ]
                        })
                    

                    //////////SOTTOCATEGORIE//////////////////
                    db.collection('Users').doc(email).collection('Sedi').doc(sed.id).collection('Categorie').doc(cat.id).collection('Sottocategorie').get().then((sottz)=>{
                        sottz.forEach((sott)=>{
                                sottocategorie.push(sott.id)
                                setSottoCategorie((prev)=>{
                                    return [
                                        ...prev,
                                        {
                                            Id: sott.id,
                                            ...sott.data(),
                                            Categoria: cat.id
                                        }
                                    ]
                                })
                             //////////ELEMENTI///////////////
                             db.collection('Users').doc(email).collection('Sedi').doc(sed.id).collection('Categorie').doc(cat.id).collection('Sottocategorie')
                             .doc(sott.id).collection('Elementi').get().then((elem)=>{
                                elem.forEach((ele)=>{
                                    db.collection('Elementi').doc(ele.data().ref).get().then((elez)=>{
                                        if(elez.exists){
                                            if(!elez.data().Cliente){
                                                db.collection('Elementi').doc(ele.data().ref).update({
                                                    Cliente: email
                                                })
                                            }
                                            if(elez.data().Shared!==''&&elez.data().Shared!==undefined){
                                                var find = null;
                                                var manutentore = '';
                                                find = manutentori.findIndex((e)=>e.Email===elez.data().Shared)
                                                if(find!==null&&find===-1){
                                                    console.log(elez.data().Shared)
                                                    db.collection('Users').doc(elez.data().Shared).get().then((us)=>{
                                                        var data = {Email:elez.data().Shared, Nome_Azienda: us.data().Nome_Azienda}
                                                        manutentori.push(data)
    
                                                    }).then(()=>{
                                                        console.log(manutentori,find,elez.data().Shared)
                                                        find = manutentori.findIndex((e)=>e.Email===elez.data().Shared)
                                                        manutentore = manutentori[find].Nome_Azienda
                                                        console.log(manutentore)
                                                        setElementi((prev)=>{
                                                            return [
                                                             ...prev,
                                                             {
                                                                 Id: elez.id,
                                                                 ...elez.data(),
                                                                 SharedN:manutentore,
                                                                 Checked: false,
                                                             }
                                                            ]
                                                             
                                                         })
                                                    })
                                                    
                                                }
                                            }else{
                                                setElementi((prev)=>{
                                                    return [
                                                     ...prev,
                                                     {
                                                         Id: elez.id,
                                                         ...elez.data(),
                                                         SharedN:'',
                                                         Checked: false,
                                                     }
                                                    ]
                                                     
                                                 })
                                            }
                                            
                                        }
                                        
                                    })
                                })
                             })

                        })
                    })

                   
                })
            })
        });
    })

}

export function getElemento(db,email,id,setElemento){
    db.collection('Elementi').doc(id).get().then((ele)=>{
        setElemento({Id:ele.id,...ele.data()})
    })
}

export function getElementiSede(db,email,sede,setElementi,currEle){
    db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Categorie').get().then((categorie)=>{
        categorie.forEach((cat)=>{
            db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Categorie').doc(cat.id).collection('Sottocategorie').get().then((sottocategorie)=>{
                sottocategorie.forEach((sott)=>{
                    db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Categorie').doc(cat.id).collection('Sottocategorie')
                    .doc(sott.id).collection('Elementi').get().then((elementi)=>{
                        elementi.forEach((ele)=>{
                            db.collection('Elementi').doc(ele.data().ref).get().then((elez)=>{
                                if(elez.exists){
                                    if((!elez.data().MyEleFatherName||elez.data().MyEleFatherName===null)&&elez.id!==currEle){
                                        setElementi((prev)=>{
                                            return [
                                                ...prev,
                                                {
                                                    Id: elez.id,
                                                    ...elez.data(),
                                                    MyEleX: elez.data().MyEleX?elez.data().MyEleX:0,
                                                    MyEleY: elez.data().MyEleY?elez.data().MyEleY:0,
                                                    MyEleImg: elez.data().MyEleImg?elez.data().MyEleImg:0
                                                }
                                            ]
                                        })
                                    }
                                  
                                }
                                
                            })
                        })
                    })
                })
            })
        })
    })
}
export function getSelectedElementi(db,elemento,setElementiPosizionati){
    db.collection('Elementi').doc(elemento).get().then((ele)=>{
        if(ele.data().MyEleChilds){
            if(ele.data().MyEleChilds.length>0){
                ele.data().MyEleChilds.forEach((child)=>{
                    db.collection('Elementi').doc(child.Id).get().then((elez)=>{
                        if(elez.exists){
                            setElementiPosizionati((prev)=>{
                                return [
                                    ...prev,
                                    {
                                        Id: elez.id,
                                        ...elez.data(),
                                        MyEleX: elez.data().MyEleX?elez.data().MyEleX:0,
                                        MyEleY: elez.data().MyEleY?elez.data().MyEleY:0
                                    }
                                ]
                               })
                        }
                          
                    })
                })
            }
        }
    })
}

export function getIcon(data) {
    switch (data.Macrocategoria) {
      case "Lampada emergenza":
        return lampadaemergenzaIcon;
      case "Quadro elettrico":
        return quadroIcon;
      case "Interruttore differenziale":
        return tIcon;
      case "Estintore CO2":
        return estintoreIcon;
      case "Estintore polvere":
        return estintoreIcon;
      case "Estintore schiuma":
        return estintoreIcon;
      case "Estintore acqua":
        return estintoreIcon;
      case "Porta-Portone REI":
        return doorIcon;
      case "Dispositivo antipanico":
        return dispIcon;
      case "Gruppo di pressurizzazione":
        return pressIcon;
      case "Attacco Mandata VVF":
        return attIcon;
      case "Rivelatore Antincendio":
        return rivIcon;
      case "Aspiratore":
        return aspIcon;
      case "Idrante a muro":
        return idrIcon;
      case "Idrante a colonna":
        return idrIcon;
      case "Segnalazione ottico-acustica":
        return alarmIcon;
      case "Pulsante":
        return bottIcon;
  
      default:
        return altroIcon;
    }
  } //funzione per settare l'icona da visualizzare

//////////////////DOCUMENTI//////////////////////////////

export function getDocumenti(db,email,setSedi,setCategorie,setCartelle,setDocumenti){
    setSedi([])
    setCategorie([])
    setCartelle([])
    setDocumenti([])

    var categorie = []
    var sottocategorie = []
    var manutentori = []
    //////////SEDI/////////////////
    db.collection('Users').doc(email).collection('Sedi').get().then((sedi)=>{
        sedi.forEach(sed => {
            setSedi((prev)=>{ 
                return [
                    ...prev,{
                        Id: sed.id,
                        ...sed.data()

                    }
                ]
            }) //aggiunge sedi

            //////////CATEGORIE///////////////////
            db.collection('Users').doc(email).collection('Sedi').doc(sed.id).collection('Categorie').get().then((cats)=>{
                cats.forEach((cat)=>{
                        setCategorie((prev)=>{
                            return [
                                ...prev,
                                {
                                    Id:cat.id,
                                    ...cat.data()
                                }
                            ]
                        })

                    //////////SOTTOCATEGORIE//////////////////
                    db.collection('Users').doc(email).collection('Sedi').doc(sed.id).collection('Categorie').doc(cat.id).collection('Cartelle').get().then((sottz)=>{
                        sottz.forEach((sott)=>{
                                setCartelle((prev)=>{
                                    return [
                                        ...prev,
                                        {
                                            Id: sott.id,
                                            ...sott.data(),
                                            Categoria: cat.id,
                                        Sede: sed.id
                                        }
                                    ]
                                })
                             //////////DOCUMENTI///////////////
                             db.collection('Users').doc(email).collection('Sedi').doc(sed.id).collection('Categorie').doc(cat.id).collection('Cartelle')
                             .doc(sott.id).collection('Documenti').get().then((elem)=>{
                                elem.forEach((ele)=>{
                                    db.collection('Documenti').doc(ele.data().ref).get().then((elez)=>{
                                        if(elez.exists){
                                            if(!ele.data().Cartella){
                                                db.collection('Documenti').doc(ele.data().ref).update({
                                                    Cartella: sott.id,
                                                    Autore: email,
                                                    CaricatoDa: email
                                                })
                                            }
                                           
                                            if(elez.data().Shared!==''){
                                                var find = null;
                                                var manutentore = '';
                                                find = manutentori.findIndex((e)=>e.Email===elez.data().Shared)
                                                if(find!==null&&find===-1){
                                             
                                                    db.collection('Users').doc(elez.data().Shared).get().then((us)=>{
                                                        var data = {Email:elez.data().Shared, Nome_Azienda: us.data().Nome_Azienda}
                                                        manutentori.push(data)
    
                                                    }).then(()=>{
                                                        console.log(manutentori,find,elez.data().Shared)
                                                        find = manutentori.findIndex((e)=>e.Email===elez.data().Shared)
                                                        manutentore = manutentori[find].Nome_Azienda
                                                        console.log(manutentore)
                                                        setDocumenti((prev)=>{
                                                            return [
                                                             ...prev,
                                                             {
                                                                 Id: elez.id,
                                                                 Cartella: sott.id,
                                                                 SharedN: manutentore, 
                                                                 ...elez.data()
                                                             }
                                                            ]
                                                             
                                                         })
                                                    })
                                                    
                                                }
                                            }else{
                                                setDocumenti((prev)=>{
                                                    return [
                                                     ...prev,
                                                     {
                                                         Id: elez.id,
                                                         Cartella: sott.id,
                                                         SharedN: '', 
                                                         ...elez.data()
                                                     }
                                                    ]
                                                     
                                                 })
                                            }
                                        }
                                       
                                    })
                                    
                                })
                             })

                        })
                    })

                   
                })
            })
        });
    })
}
export function getSediCategorieCartelle(db,email,setSedi,setCategorie,setCartelle){

    setSedi([])
    setCategorie([])
    setCartelle([])

    db.collection('Users').doc(email).collection('Sedi').get().then((sedi)=>{
        sedi.forEach((sed)=>{
            setSedi((prev)=>{
                return [
                    ...prev,
                    {
                        Id: sed.id,
                        ...sed.data()
                    }
                ]
            })
            db.collection('Users').doc(email).collection('Sedi').doc(sed.id).collection('Categorie').get().then((categorie)=>{
                categorie.forEach((cat)=>{
                    setCategorie((prev)=>{
                        return [
                            ...prev,
                            {
                                Id: cat.id,
                                ...cat.data()
                            }
                        ]
                    })
                    db.collection('Users').doc(email).collection('Sedi').doc(sed.id).collection('Categorie').doc(cat.id).collection('Cartelle').get().then((cartelle)=>{
                        cartelle.forEach((cart)=>{
                            setCartelle((prev)=>{
                                return [
                                    ...prev,
                                    {
                                        Id: cart.id,
                                        ...cart.data(),
                                        Categoria: cat.id,
                                        Sede: sed.id
                                    }
                                ]
                            })
                        })
                    })
                })
            })
        })
    })
}  
export function getDocumento(db,email,id,setDocumento){
    db.collection('Documenti').doc(id).get().then((doc)=>{
        setDocumento(doc.data())
    })
}

///////////////INTERVENTI///////////////////////////////

export function getInterventi(db,email,setSedi,setFornitori,setInterventi){
    setSedi([])
    setInterventi([])
    setFornitori([])

    var fornitori = []
  

    db.collection('Users').doc(email).collection('Sedi').get().then((sedi)=>{
        sedi.forEach(sed => {
            setSedi((prev)=>{ 
                return [
                    ...prev,{
                        Id: sed.id,
                        ...sed.data()

                    }
                ]
            }) //aggiunge sedi


                             //////////INTERVENTI E MANUTENTORI///////////////
                             db.collection('Users').doc(email).collection('Sedi').doc(sed.id).collection('Attività').get().then((elem)=>{
                                elem.forEach((ele)=>{
                                    db.collection('Attività').doc(ele.data().ref).get().then((int)=>{
                                        if(int.exists){
                                            if(int.data().SottoAttività===true){
                                                db
                                                .collection("Attività")
                                                .doc(int.id)
                                                .collection("Attività").get().then((sottoManutenzioni)=>{
                                                    var c = 1
                                                    var arrz = []
                                                    var areNotClosed = []
                                                    if(sottoManutenzioni.docs.length>0){
                                                        sottoManutenzioni.forEach((sott)=>{
                                                            arrz.push(sott.data())
            
                                                            if(c===sottoManutenzioni.docs.length){
                                                                   areNotClosed = arrz.find((e)=>e.Stato!=='Conclusa')
            
            
                                                                if(areNotClosed){
                                                                    setInterventi((prev)=>{
                                                                        return [
                                                                            ...prev,
                                                                            {
                                                                                Id: int.id,
                                                                                ...int.data(),
                                                                                Stato: returnStato(int.data().Stato,int.data().Data),
                                                                                Archiviato: int.data().Archiviato?int.data().Archiviato:false,
                                                                                Sott_Attivi: true
                                                                            }
                                                                        ]
                                                                    })
                                                                }else{
                                                                    setInterventi((prev)=>{
                                                                        return [
                                                                            ...prev,
                                                                            {
                                                                                Id: int.id,
                                                                                ...int.data(),
                                                                                Stato: returnStato(int.data().Stato,int.data().Data),
                                                                                Archiviato: int.data().Archiviato?int.data().Archiviato:false,
                                                                                Sott_Attivi: false
                                                                            }
                                                                        ]
                                                                    })
                                                                }
                
                                                            }
                                                        
                                                            c++
                                                          
                                                        })
                                                    }
                                             
                                                })
                                            }else{
                                            setInterventi((prev)=>{
                                                return [
                                                    ...prev,
                                                    {
                                                        Id: int.id,
                                                        ...int.data(),
                                                        Stato: returnStato(int.data().Stato,int.data().Data),
                                                        Archiviato: int.data().Archiviato?int.data().Archiviato:false,
                                                        Sott_Attivi: false
            
                                                    }
                                                ]
                                            })
                                        
                                    }
                                }
                                        
                                        if(fornitori.indexOf(int.data().Operatore)===-1){
                                            setFornitori((prev)=>{
                                                return [
                                                    ...prev,
                                                    int.data().Operatore
                                                ]
                                            })
                                            fornitori.push(int.data().Operatore)
                                        }
                                    })
                                    
                                })
                             })

                        })
                    })
}

export function getIntervento(db,intervento,setIntervento){
    db.collection('Attività').doc(intervento).get().then((int)=>{
        setIntervento({Id:int.id,...int.data()})
    })
}

//////////////SCADENZE////////////////////////

export async function  getScadenze(db,email,setSedi,setFornitori,setScadenze){
    setSedi([])
    setScadenze([])
    setFornitori([])

    var fornitori = []

    db.collection('Users').doc(email).collection('Sedi').get().then((sedi)=>{
        sedi.forEach(sed => {
            setSedi((prev)=>{ 
                return [
                    ...prev,{
                        Id: sed.id,
                        ...sed.data()

                    }
                ]
            }) //aggiunge sedi


                             //////////INTERVENTI E MANUTENTORI///////////////
                             db.collection('Users').doc(email).collection('Sedi').doc(sed.id).collection('Manutenzioni').get().then((elem)=>{
                                elem.forEach((ele)=>{
                                    db.collection('Manutenzioni').doc(ele.data().ref).get().then((elez)=>{
                                        if(elez.data().SottoAttività===true){
                                            var control = false
                                            db.collection("Users")
                                            .doc(elez.data().Cliente)
                                            .collection("Sedi")
                                            .doc(elez.data().Sede)
                                            .collection("Manutenzioni")
                                            .doc(elez.id)
                                            .collection("Manutenzioni").get().then((sottoManutenzioni)=>{
                                                console.log(sottoManutenzioni.docs[0].data(),sottoManutenzioni.docs.length)
                                    
                                                if(sottoManutenzioni.docs.length>0){
                                                    sottoManutenzioni.forEach((sott)=>{
                                                      
                                                      db.collection("Manutenzioni").doc(sott.data().ref).get().then((sottoManutenzione)=>{
                                                        if(sottoManutenzione.data().Stato!=="Conclusa"){
                                                            control = true
                                                            setScadenze((prev)=>{
                                                                return [
                                                                 ...prev,
                                                                 {
                                                                     Id: elez.id,
                                                                     ...elez.data(),
                                                                     Stato: returnStato(elez.data().Stato,elez.data().Data),
                                                                     Archiviato: elez.data().Archiviato?elez.data().Archiviato:false,
                                                                     Sott_Attivi: true
                     
                                                                 }
                                                                ]
                                                                 
                                                             })
                                                          }
                                                          if(sottoManutenzione.data().Stato==="Conclusa"&&control===false){
                                                            setScadenze((prev)=>{
                                                                return [
                                                                 ...prev,
                                                                 {
                                                                     Id: elez.id,
                                                                     ...elez.data(),
                                                                     Stato: returnStato(elez.data().Stato,elez.data().Data),
                                                                     Archiviato: elez.data().Archiviato?elez.data().Archiviato:false,
                                                                     Sott_Attivi: false
                     
                                                                 }
                                                                ]
                                                                 
                                                             })
                                                          }else if(sottoManutenzione.data().Stato==="Conclusa"&&control===true){
                                                            setScadenze((prev)=>{
                                                                return [
                                                                 ...prev,
                                                                 {
                                                                     Id: elez.id,
                                                                     ...elez.data(),
                                                                     Stato: returnStato(elez.data().Stato,elez.data().Data),
                                                                     Archiviato: elez.data().Archiviato?elez.data().Archiviato:false,
                                                                     Sott_Attivi: true
                     
                                                                 }
                                                                ]
                                                                 
                                                             })
                                                          }     
                                                      })
                                                      
                                                    })
                                                }
                                         
                                            })
                                        }else{
                                            setScadenze((prev)=>{
                                                return [
                                                 ...prev,
                                                 {
                                                     Id: elez.id,
                                                     ...elez.data(),
                                                     Stato: returnStato(elez.data().Stato,elez.data().Data),
                                                     Archiviato: elez.data().Archiviato?elez.data().Archiviato:false,
                                                     Sott_Attivi: false
     
                                                 }
                                                ]
                                                 
                                             })
                                        }

                                    
                                        if(fornitori.indexOf(elez.data().Nome_AziendaOff)===-1){
                                            setFornitori((prev)=>{
                                                return [
                                                    ...prev,
                                                    elez.data().Nome_AziendaOff
                                                ]
                                            })
                                            fornitori.push(elez.data().Nome_AziendaOff)
                                        }
                                    })
                                    
                                })
                             })

                        })
                    })
}

export function getScadenza(db,scadenza,setScadenza){
    db.collection('Manutenzioni').doc(scadenza).get().then((int)=>{
        setScadenza({Id:int.id,...int.data()})
    })
}

//////////MAPPE////////////////
export function getSedi(db,email,setSedi){
    db.collection('Users').doc(email).collection('Sedi').get().then((sedi)=>{
        sedi.forEach((sede)=>{
            setSedi((prev)=>{
                return [
                    ...prev,
                    {Id: sede.id,
                    ...sede.data()
                    }
                ]
            })
        })
    })
}
export function getElementiMappa(db,email,setElementi,setCategorie,setSottocategorie,setElementiPosizionati,mappa_id,sede_id){
    var cat = []
    var sott = []
    setElementi([])
    setCategorie([])
    setSottocategorie([])

      //////////CATEGORIE///////////////////
      db.collection('Users').doc(email).collection('Sedi').doc(sede_id).collection('Categorie').get().then((cats)=>{
        cats.forEach((catz)=>{
              //aggiunta categoria
              var indexx = cat.findIndex((e)=>e.Nome===catz.data().Nome)
              if(indexx===-1){
                  cat.push( {
                      Nome: catz.data().Nome
                  })
                  setCategorie((prev)=>{
                      return [
                          ...prev,
                          {
                              Nome: catz.data().Nome,
                              Id: catz.id
                              
                          }
                          
                      ]
                  })
              }

            //////////SOTTOCATEGORIE//////////////////
            db.collection('Users').doc(email).collection('Sedi').doc(sede_id).collection('Categorie').doc(catz.id).collection('Sottocategorie').get().then((sotts)=>{
                sotts.forEach((sottz)=>{
                    console.log(sottz.data())
                      //aggiunta sottocategoria
                      var indexxx = sott.findIndex((e)=>e.Nome===sottz.data().Nome)
                      if(indexxx===-1){
                          sott.push( {
                              Nome: sottz.data().Nome,
                              Categoria: catz.data().Nome
                          })
                          setSottocategorie((prev)=>{
                              return [
                                  ...prev,
                                  {
                                      Nome: sottz.data().Nome,
                                      Categoria: catz.data().Nome,
                                      Open: false,
                                      Visualize: true
                                  }
                              ]
                          })
                      }

                     //////////ELEMENTI///////////////
                     db.collection('Users').doc(email).collection('Sedi').doc(sede_id).collection('Categorie').doc(catz.id).collection('Sottocategorie')
                     .doc(sottz.id).collection('Elementi').get().then((elem)=>{
                        elem.forEach((ele)=>{
                            db.collection('Elementi').doc(ele.data().ref).get().then((ele)=>{
                                if(ele.exists){
                                    if(ele.data().Sede===sede_id){
                                        if(ele.data().Posizione&&ele.data().Mappa===mappa_id){
                                            setElementiPosizionati((prev)=>{
                                                return [
                                                    ...prev,
                                                    {
                                                        Id: ele.id,
                                                        ...ele.data()
                                                    }
                                                ]
                                               
                                            })
                                        }else if(!ele.data().Mappa&&!ele.data().Posizione){
                                            setElementi((prev)=>{
                                                return [
                                                    ...prev,
                                                    {
                                                        Id: ele.id,
                                                        ...ele.data()
                                                    }
                                                ]
                                            })
                                        }
                                        
                                      
                                            
                                
                                      
                                    }
                                }
                               
                            })
                        })
                     })

                })
            })

           
        })
    })

        db.collection('Users').doc(email).collection('Sedi').doc(sede_id).collection('Elementi').get().then((elementi)=>{
            elementi.forEach((elez)=>{
                    db.collection('Elementi').doc(elez.data().ref).get().then((ele)=>{
                            //aggiunta elementi
                        
                          
                            
                        })
                        
                    })
                })  
           
      
    }


////////IMPOSTAZIONI///////////
export function getSottoaccounts(db,email,setSottoaccount){
    setSottoaccount([])
    db.collection('Users').doc(email).collection('Sottoaccount').get().then((sotts)=>{
        sotts.forEach((sottz)=>{
            db.collection('Users').doc(sottz.data().Email).get().then((sott)=>{
                setSottoaccount((prev)=>{
                    return [
                        ...prev,
                        sott.data()
                    ]
                })
            })
        })
    })
}

/////////SOTTOACCOUNT

export function checkDeleteS(type){
    if(type===2||type===null||type===undefined){
        return true

    }else{
        return false
    }

}
export function checkWriteS(type){
    if(type===1||type===2||type===null||type===undefined){
        return true

    }else{
        return false
    }

}


