//popup aggiungi categoria
import React, { useState, useEffect } from "react";
import "../../Popups/Popup.css";
import firebase from "firebase/app";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getUtente,getSediCategorie,getSedeName } from "../../EngineClienti/engineClienti";
import NavBar from "../../NavBar/NavBar";
import NavBar2 from "../../NavBar/NavBar2";


const AggiungiCategoria = ({history}) => {
  const auth = firebase.auth();
  const db = firebase.firestore();
  const [selectedOption, setOption] = useState("Antincendio"); //categoria selezionata
  const [user,setUser] = useState(null)
  const [custom, setCustom] = useState(); //nome categoria altro



  const [categorie, setCategorie] = useState([]); //lista categorie
  const [sedi,setSedi] = useState([])

  const [sedeF,setSedeF] = useState('')

  useEffect(()=>{
    auth.onAuthStateChanged(function (user) {
      if (user) {
        getUtente(db,auth,setUser)
      }
    })
  },[auth])

  useEffect(()=>{
    if(user!==null){
     getSediCategorie(db,user.Email,setSedi,setCategorie)      
  }

  },[user])

  useEffect(()=>{
    if(sedi.length>0){
      setSedeF(sedi[0].Id)
    }

  },[sedi])


  function returnCategorie(sede){
    var arr = categorie.filter((e)=>e.Sede===sede)
    return arr
  }

  function addCatz() {
    
    var control = false;
    var name;
    if (selectedOption === "Altro") {
      name = custom.trim();
    } else {
      name = selectedOption;
    }
    if(window.confirm("Vuoi aggiungere la categoria "+name+" alla sede "+getSedeName(sedeF,sedi)+" ?")){
    categorie.forEach((cat) => {
      if (cat.Id.trim() === name&&cat.Sede===sedeF) {
        control = true;
      }
    });

    const sedeName = getSedeName(sedeF,sedi)

    if (control === false) {
     db.collection("Users")
        .doc(user.Email)
        .collection("Sedi")
        .doc(sedeF)
        .collection("Categorie")
        .doc(name)
        .set({
          //scrive il nuovo valore sull'acc principale
          Nome: name,
          Sede: sedeF,
          SedeName: sedeName,
          Ele: 0,
          Doc: 0,
        })
        .then(() => {
                  //aggiunge alla session storage la categoria appena aggiunta 

          var arr = []
        var c1 = JSON.parse(sessionStorage.getItem('categorie'));
        if(c1!==null){
          if(c1.length>0){
            arr = c1
          }
        }
                if(arr.findIndex((e)=>e.Id===name)===-1){
          arr.push({Nome: name,Sede: sedeF,
            SedeName: sedeName,
            Ele: 0,
            Doc: 0, Id: name})
            sessionStorage.setItem('categorie', JSON.stringify(arr));
            console.log('bella')
        }

        //aggiunge categoria alle categorie della pagina
        var arr1 = [...categorie]
        arr1.push({Nome: name,Sede: sedeF,
          SedeName: sedeName,
          Ele: 0,
          Doc: 0, Id: name})
          setCategorie(arr1)
          alert("Categoria aggiunta con successo");
        }); 

       
    } else {
      alert("Esiste già una categoria con questo nome.");
    }
  }
  } //funzione che aggiunge la categoria

  return  (
    <div  className="background" style={{width:'100vw',height:'100vh',overflow:'hidden'}}>
              {user!==null?user.Tipo==='Lavoratore'?<NavBar2/>:<NavBar/>:null}

      <div
        style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}
      >
        <div  style={{  width: "30vw",display:'flex',marginTop:'1.2rem' }}>
          <button style={{marginBottom:'auto',marginTop:'1rem'}} onClick={() => history.goBack()}
            className="cancelIconButton"
            
          >
            <ArrowBackIcon
             className="back-button"
            />
          </button>
          <div>
          <h4 className="page-title" style={{margin:0}}>AGGIUNGI CATEGORIA</h4>
          <h4
          className="sub-text"
        >
          Aggiungi le tue categorie per gestirne gli elementi e documenti
        </h4>
          </div>

        </div>
        <div style={{display:'flex',marginLeft:'3.5rem',marginTop:'1rem'}}>
            <div style={{width:'20rem',height:'82vh',overflowY:'auto',borderRight:'1px solid #677483'}}>
                <h4 className="normal-text">Categorie aggiunte:</h4>
            {sedi.length>0?sedi.map((s,n)=>(
                <div key={n} style={{marginTop:'1rem'}}>
                    <h4 className="sub-text-b">{s.Nome?s.Nome!==''?s.Nome:s.Comune:s.Comune}</h4>
                    {returnCategorie(s.Id).length>0?returnCategorie(s.Id).map((el,i)=>(
                        <div key={i} style={{}}>
                            <h4 className="medium-text">• {el.Nome}</h4>
                        </div>
                    )):<h4 className="medium-text">Nessuna categoria aggiunta</h4>}
                </div>
            )):<h4 className="medium-text">Nessuna sede aggiunta</h4>}
            </div>

            <div style={{marginLeft:'1rem'}}>
            <h4 className="normal-text">Aggiungi categorie: </h4>

        <h4 className="sub-text-b" style={{marginTop:'1rem'}}>Seleziona la sede a cui aggiungere la categoria:</h4>
        <select className="input-style"
        defaultValue={"default"}
        value={sedeF} onChange={(e) => {
            setSedeF(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona sede...
                </option>
                {sedi && sedi.length > 0 && sedi.map((sede,n)=>{
                                  return <option key={n} value={sede.Id}>{sede.Nome?sede.Nome!==''?sede.Nome:sede.Comune:sede.Comune}</option>
                              })}
        </select>
        <h4 className="sub-text-b" style={{marginTop:'1rem'}}>Seleziona categoria da aggiungere:</h4>
        <div style={{display:'flex',alignItems:'center'}}>
        <select className="input-style"
        defaultValue={"default"}
        value={selectedOption}
        onChange={(e) => {
                      setOption(e.target.value)
                    }}>
            <option value={"default"} disabled>
                  Seleziona categoria...
                </option>
                <option value={'Antincendio'}>
                    Antincendio
                </option>
                <option value={'Elettrico'}>
                    Elettrico
                </option>
                <option value={'Altro'}>
                   Altro
                </option>
        </select>
        {selectedOption==='Altro'?<input className="input-style" style={{marginLeft:'1rem',height:'1.6rem',marginTop:'10px',marginBottom:'auto'}} onChange={(e)=>setCustom(e.target.value)} placeholder="Inserisci nome categoria..."></input>:null}
        </div>
        <button onClick={()=>addCatz()} className="main-button" style={{marginTop:'2rem'}}>Aggiungi</button>
        </div>
            </div>
      
 
      </div>
    </div>
  );
};

export default AggiungiCategoria;
