//sezione account
import React, { useState, useEffect } from "react";
import firebase from "firebase";
import { getIcon,checkDeleteS,checkWriteS } from "../../EngineClienti/engineClienti";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@material-ui/icons/Cancel';

const Sottocategorie = (props) => {

    const db = firebase.firestore(); //database firebase

    const [categoria,setCategoria] = useState(props.categoria)
    

    const [trigger,setTrigger] = useState(false)

    

    const arrAnti = ["Estintore polvere", "Estintore CO2", "Estintore schiuma", "Estintore acqua", "Porta-Portone REI", "Dispositivo antipanico", "Gruppo di pressurizzazione", "Attacco Mandata VVF", "Rivelatore Antincendio", "Aspiratore", "Idrante a muro", "Idrante a colonna", "Segnalazione ottico-acustica", "Pulsante"]
    const arrEle = ["Lampada emergenza", "Quadro elettrico", "Interruttore differenziale"]

    const AddSottocategoria = (props) =>{

      const [custom,setCustom] = useState('')

      function addSottoCatz() {
    
        var control = false;
        var name;
    
        if(custom!==''){
       
        if(window.confirm("Vuoi aggiungere la sottocategoria "+custom+"?")){
        categoria.Aggiuntive.forEach((cat) => { //controlla che la sottocategoria non esita già
          if (cat.Nome.trim().toLowerCase() === custom.trim().toLowerCase()&&cat.Categoria===categoria.Id) {
            control = true;
          }
        });
    
        const indexA = arrAnti.findIndex((e)=>e.trim().toLowerCase()===custom.trim().toLowerCase()) //controlla se si inseriscono sottocategorie già esistenti
        const indexE = arrEle.findIndex((e)=>e.trim().toLowerCase()===custom.trim().toLowerCase())
    
    
        if(indexA!==-1||indexE!==-1){ //se già esistente return
          alert('Sottocategoria già inserita!')
          return
        }    
          
        if (control === false) {
          categoria.Sedi.forEach((sede)=>{
            db.collection('Users').doc(props.user.Email).collection('Sedi')
            .doc(sede.Id).collection('Categorie').doc(categoria.Id)
            .collection('Sottocategorie').doc(custom.trim()).set({ //aggiunge sottocat
              Nome: custom.trim(),
              Sede: sede.Id,
              Valore:0
            })
          })

          var arr = [...categoria.Aggiuntive]
          arr.push({Id: custom.trim(), Nome: custom.trim()})
          
          var categoriaz = categoria
          categoriaz.Aggiuntive = arr

          props.setCategoria(categoriaz)

          db.collection('Users').doc(props.user.Email).collection('SottocategorieAggiuntive').doc(custom.trim()).set({
            Nome: custom.trim(),
            Categoria: props.categoria.Id,
          })
              
    
              alert("Sottocategoria aggiunta con successo");
            }
        } else {
          alert("Esiste già una sottocategoria con questo nome.");
        }
      }else{
      alert('Inserire tutti i campi richiesti!')
    }
      } //funzione che aggiunge la sottocategoria

      return (
        <div className="pop-opac">
        <div className="popup" style={{backgroundColor:"white", minHeight:'50vh', height:'fit-content', oveflowY: "auto", width:'60vw', left:'25vw',top:'15vh'}} >
        <div style={{textAlign:'left', width: 'fit-content'}}>
            <button
              className="cancelIconButton"
              
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
            </button>
            </div>
            <div style={{width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>
            <h4 className="page-title" style={{margin:0}}>AGGIUNGI SOTTOCATEGORIA</h4>

            </div>
            <div ><h4 className="sub-text-b" style={{marginTop:'1rem',width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>Inserisci il nome della sottocategoria da aggiungere:</h4>
            <div style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',}}>
        <input className="input-style2" style={{height:'1.6rem',marginTop:'10px',marginBottom:'auto',width:'20vw',marginLeft:'auto',marginRight:'auto'}} onChange={(e)=>setCustom(e.target.value)} placeholder="Inserisci nome sottocategoria..."></input>
       </div>
        </div>
        <div style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',}}>
        <button onClick={()=>addSottoCatz()} className="main-button" style={{marginTop:'2rem'}}>Aggiungi</button>
        </div>
        </div>
        </div>
      )
    }

    return <div style={{paddingLeft:'1rem',height:'80vh',overflowY:'auto',width:'71vw'}}>
      {trigger===true?<AddSottocategoria categoria={props.categoria} setCategoria={props.setCategoria} user={props.user} trigger={trigger} setTrigger={setTrigger}/>:null}
      <div>
      {/*informazioni di contatto*/}
      <div>
        <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
        <AccountTreeIcon style={{color:'black'}}/>

      <h4 className="header-normal" style={{marginLeft:'5px'}}>Sottocategorie</h4>

      {checkWriteS(props.user?props.user.Permissions:null)===true?<button className="btnD" onClick={()=>setTrigger(true)} style={{cursor:'pointer'}}><AddIcon style={{color:'black'}}/></button>:null}
      </div>
      
      </div>
      
      <div style={{width:'50vw',height:'69vh',overflowY:'auto',marginTop:'1vh'}}>
        {categoria.Id==='Antincendio'?arrAnti.map((ele,i)=>(
          <div key={i} style={{marginTop:'10px'}}>
            <h4 className="normal-cool" style={{margin:0}}>• {ele}</h4>
          </div>
        )):categoria.Id==='Elettrico'?arrEle.map((ele,i)=>(
          <div key={i}>
          <h4 className="normal-cool" style={{margin:0}}>• {ele}</h4>
        </div>
        )):null}
              <h4 className="header-normal" style={{marginTop:'1vh'}}>Sottocategorie aggiunte</h4>

      {categoria.Aggiuntive.length>0?categoria.Aggiuntive.map((ele,i)=>(
        <div key={i} >
          <h4 className="normal-cool" style={{margin:0}}>• {ele.Nome}</h4>

        </div>
      )):<h4 className="normal-cool">Nessuna sottocategoria aggiunta</h4>}
        </div>
      </div>
      <div style={{alignItems:'center',marginTop:'5vh',marginLeft:'auto',width:'fit-content'}}>
        </div>
    </div>
}

export default Sottocategorie;