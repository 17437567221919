//parte destra della schermata di login con form
import logo from "../../images/mysiclogo.png";
import { FormLogin } from "./form"; //form di login
import { RegisterLink } from "./registerLink"; //link che porta alla registrazione

export const RightScreen = (props) =>{
    return(
        <div style={{height:'100vh', width:'45vw', backgroundColor:'white'}}>
<img        alt='Logo Mysic'
            src={logo}
            style={{zIndex:0, height: "15vh", marginLeft:'7.5vw',position:'absolute',top:'5vh'  }}
          />
           <div style={{zIndex:99,width:'30vw',position:'absolute',right:'7.5vw',top:'10vh'}}>
      <h4  style={{ fontSize:'2vw',color:'black',fontWeight:'bold', fontFamily:'Montserrat',letterSpacing:'-0.1vw', marginBottom:0,marginTop:'15vh',width:'fit-content', marginRight:'auto'}}>Ciao, bentornato!</h4>
      <h4  style={{ fontSize:'1vw',color:'#5C6672',fontWeight:'bold', fontFamily:'Montserrat',letterSpacing:'-0.08vw', marginTop:0,marginBottom:0,width:'fit-content', marginRight:'auto'}}>Inserisci le informazioni con cui ti sei registrato.</h4><br/>

      <div  style={{width:'30vw'}}>
          <FormLogin history={props.history}></FormLogin>
             {/* form di login*/}
          
            <h4 style={{marginLeft:'auto', marginRight:'auto',width:'fit-content', fontFamily:'Montserrat', color:'#5C6672', fontWeight:300}}>oppure</h4>
           <RegisterLink></RegisterLink>
            </div>

        </div>
      </div>
    )
}