//selezione tipo man o cli
import '../Style/signup.css'
import manut from '../../images/manut.jpg'
import cliente from '../../images/cliente.jpg'

export function ChooseType(props){
    return(
        <div
        style={{
          marginTop: "3vh",
          width: "60vw",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div
          style={{
            width: "fit-content",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div style={{display:'flex',alignItems:'center'}}>
          <h4
            className='titleReg'
            style={{marginRight:0}}
          >
            Sono un
          </h4>
          <h4
            className='titleReg'
            style={{marginLeft:'0.5vw',color:props.optionAz===''?'black':'#0496ff'}}
          >
           {props.optionAz===''?'...':props.optionAz==='Lavoratore'?'Manutentore':'Cliente'}
          </h4>
          </div>
          <div style={{display:'flex',marginTop:'2vh'}}>
            <div onClick={()=>props.setOptionAz('Lavoratore')} style={{boxShadow: props.optionAz==='Lavoratore'?'rgba(4, 150, 255, 0.8) 0px 7px 29px':null,cursor:'pointer',
            width:'20vw',height:'40vh',borderRadius:20,position:'relative'}}>
              <div style={{backgroundColor:'rgba(0,0,0,0.75)',position:'absolute',left:0,right:0,top:0,bottom:0,width:'20vw',height:'40vh',borderRadius:20,zIndex:100}}>
              <h4 style={{transition:'1s ease-in-out',height:'fit-content',marginBottom:0,marginTop:props.optionAz==='Lavoratore'?'20vh':'25vh',marginLeft:'1vw',color:"white",fontFamily:'Montserrat',fontSize:'1.7vw',fontWeight:'bold'}}>Manutentore</h4>
              <h4 style={{opacity:props.optionAz==='Lavoratore'?1:0,transition:'1s ease-in-out',marginTop:'1vh',marginLeft:'1vw',color:"white",fontFamily:'Montserrat',fontSize:'1vw',fontWeight:'normal'}}>Gestisci la sicurezza, le scadenze e gli interventi
               dei tuoi clienti.</h4>
              </div>
            <img src={manut} alt='manut' style={{zIndex:90,objectFit:'cover',position:'absolute',left:0,right:0,top:0,bottom:0,width:'20vw',height:'40vh',borderRadius:20}}></img>
            </div>
            <div  onClick={()=>props.setOptionAz('Cliente')} style={{boxShadow: props.optionAz==='Cliente'?'rgba(4, 150, 255, 0.8) 0px 7px 29px':null,cursor:'pointer',width:'20vw',height:'40vh',marginLeft:'3vw',borderRadius:20,position:'relative'}}>
            <div style={{backgroundColor:'rgba(0,0,0,0.75)',position:'absolute',left:0,right:0,top:0,bottom:0,width:'20vw',height:'40vh',borderRadius:20,zIndex:100}}>
              <h4 style={{transition:'1s ease-in-out',height:'fit-content',marginBottom:0,marginTop:props.optionAz==='Cliente'?'20vh':'25vh',marginLeft:'1vw',color:"white",fontFamily:'Montserrat',fontSize:'1.7vw',fontWeight:'bold'}}>Cliente</h4>
              <h4 style={{opacity:props.optionAz==='Cliente'?1:0,transition:'1s ease-in-out',marginTop:'1vh',marginLeft:'1vw',color:"white",fontFamily:'Montserrat',fontSize:'1vw',fontWeight:'normal'}}>Aggiungi i tuoi manutentori e tieni sotto controllo
              la tua sicurezza.</h4>
              </div>
            <img src={cliente} alt='cliente' style={{zIndex:90,objectFit:'cover',position:'absolute',left:0,right:0,top:0,bottom:0,width:'20vw',height:'40vh',borderRadius:20}}></img>
            </div>
          </div>
          {props.optionAz!==''?<div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content'}}>
          <button
            className="nextButton"
            style={{marginTop:'4vh'}}
              onClick={() => props.setSection(1)}
            >
              Avanti
            </button>
          </div>:null}
        
        </div>
      </div>
    )
}