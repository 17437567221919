import React from 'react';
import logo from './images/mysiclogo.png'
import loading from './images/loading.json'
import Lottie from 'lottie-react'

const containerStyle ={
    position: 'absolute',
    left:'40vw',
    right:'40vw',
    top:'30vh',
}


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
  
  };

const spinTransition ={
    loop: Infinity,
    duration: 1,
    ease: "linear",
}

export default function CircleLoader() {
    return (
        <div style={containerStyle}>
           <Lottie options={defaultOptions} height={'auto'} width={'25vw'}/>

        </div>
    )
}