import React from 'react'

const Sezione4 = (props) => {
  return (
    <div style={{width:'80vw',height:'80vh',marginLeft:'auto',marginRight:'auto',marginTop:'5vh',display:'flex'}}>
        <div style={{width:'35vw',height:'80vh',borderRight:'1px solid lightgrey'}}>
          
           <div style={{marginTop:'2vh',marginBottom:'1.5vh'}}>
              <div style={{display:'flex',height:'4vh',alignItems:'center'}}>
              <div style={{width:'3px',border:'1px solid #14213d',height:'10px',backgroundColor:'#14213d'}}></div>
             <p className='normal-cool' style={{marginLeft:'5px'}}><b>Istruzioni:</b>  Posizionamento della planimetria completato.</p>

              </div>
              <div style={{alignItems:'left'}}>
              <p className='normal-cool' style={{margin:0}}>Ora puoi inserire i tuoi elementi all'interno della mappa.</p>

              <button className='text-buttons' onClick={()=>props.history.push(`/mappemanutentore/${props.user_id}/${props.sede_id}/piani/${props.piano_id}/${props.mapRef}/editmappa`)}  style={{borderRadius:0,height:'fit-content',display:'flex',paddingLeft:0,paddingRight:0,marginTop:'2vh'}}>Inserisci elementi</button>
              <h4 className='sub-text' style={{fontSize:'0.7vw',marginTop:'1vh',marginBottom:'1vh'}}>oppure</h4>
              <button className='text-buttons2' onClick={()=>props.history.goBack()} style={{borderRadius:0,height:'fit-content',display:'flex',alignItems:'center',color:'black',borderBottom:'1px solid black',paddingLeft:0,paddingRight:0}}>Torna ai piani della sede</button>

              </div>
           
        </div>
       </div>
       </div>
  )
}

export default Sezione4