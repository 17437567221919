import React, { useState } from 'react'
import CancelIcon from '@material-ui/icons/Cancel';
import { IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import firebase from "firebase/app";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import DeleteIcon from '@mui/icons-material/Delete';
export const Versionamento = (props) => {

    const [documento,setDocumento] = useState(props.documento)
    const [percentage,setPercentage] = useState(null)
    const auth = firebase.auth();
    const db = firebase.firestore();
    const storage = firebase.storage();
    var storageRef = storage.ref();
    


    function upload(e){

        uploadFile(e)
        
         } //funzione che triggera uploadFile quando si carica un documento
         
         async function calculate(files){
           var totalMB = parseFloat(0)
               var check = false
               var checked = 0
           for(let i=0;i<files.length;i++){
   
             const file = files[i];
             const sizeInMB = (file.size / (1024*1024)).toFixed(2);
             totalMB = parseFloat(totalMB) + parseFloat(sizeInMB)
             checked = checked + 1 
             console.log(checked, totalMB,files.length)
             if(checked===files.length){
               return totalMB           
             }
   
          
           }
         } //funziona che calcola il peso dei file caricati
         const uploadFile = async (e) => {
           //funzione per uploadare file su storage
               var files =  e.target.files;
       
                const controlz =  await calculate(files)
                 db.collection("Users")
               .doc(props.documento.Autore).get().then((docz)=>{
   
                 const user = docz.data()
   
                 if(parseFloat(docz.data().Current_MB)+controlz<=docz.data().Max_MB){
                   alert('Caricamento nuova versione documento. Premere OK e attendere il completamento')
   
                   var documenti =  [...props.documenti]
                   var arr = []
   
                   if(documenti){
                     arr=[...documenti]
                   }
   
                     const docRef = db.collection("Documenti").doc().id
           
                     const file = files[0];
                     const ext = file.name.split('.').pop()
                     var sizeInMB = (file.size / (1024*1024)).toFixed(2);
       
                     const fileRef = storageRef.child(
                       user.Email +
                         "/" +
                         props.documento.Sede +
                         "/" +
                         props.documento.Cartella +
                         "/" +
                         docRef+'.'+ext
                     )
                     const uploadTask = storageRef.child(
                       user.Email +
                       "/" +
                       props.documento.Sede +
                       "/" +
                       props.documento.Cartella +
                       "/" +
                       docRef+'.'+ext
                     ).put(file); //percorso del file su storage
                     uploadTask.on('state_changed', 
                     (snapshot) => {
                       // Observe state change events such as progress, pause, and resume
                       // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                       var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                       setPercentage(progress)
                       
                     }, 
                     (error) => {
                       // Handle unsuccessful uploads
                     }, 
                     // eslint-disable-next-line no-loop-func
                     () => {
                       // Handle successful uploads on complete
                       // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                       uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {

                        let versioni = []
                        const today = new Date();
                        const formattedDate = today.toLocaleDateString('it-IT', {
                          day: 'numeric',
                          month: 'long',
                          year: 'numeric',
                        });
                        if(documento.Versioni!==null){//se esistente riempie arr versioni con le sue versioni
                            versioni= documento.Versioni
                        }else{ //se non esistente mette il documento corrente
                            versioni.push({Id: documento.Id, NomeFile: documento.NomeFile, URL: documento.URL,Scadenza: documento.Scadenza, Verified: documento.Verified?documento.Verified:false, 
                                Active:false,StorageRef: documento.StorageRef, Size:documento.Size})
                        } 

                        versioni.push({Id: docRef, NomeFile: files[0].name,URL:downloadURL,Scadenza: 'No', Verified: false,Active:true, StorageRef: fileRef.fullPath,Size: sizeInMB,Caricamento: formattedDate })

                        console.log(documento.Id,files[0].name,fileRef.fullPath,downloadURL,ext,sizeInMB,versioni)

                     db.collection("Documenti")
                   .doc(documento.Id).update({
                     //setta il file nella sua posizione nel db
                     //URL: url,
                     Scadenza: "No",
                     NomeFile:files[0].name,
                     StorageRef: fileRef.fullPath,
                     URL:downloadURL,
                     Size: sizeInMB,
                     Versioni: versioni,
                     ActiveVersion: docRef
   
                     //StorageRef: fileRef.fullPath
                   }).then(()=>{
                  if(arr.length>0){
                    const index =  arr.findIndex((e)=>e.Id===documento.Id)
                    console.log(index,arr[index])
                    arr[index].Scadenza= "No"
                    arr[index].NomeFile=files[0].name
                    arr[index].StorageRef= fileRef.fullPath
                    arr[index].URL = downloadURL
                    arr[index].Size = sizeInMB
                    arr[index].Versioni = versioni
                    arr[index].ActiveVersion = docRef

                  }

                     
                       db.collection("Users")
                     .doc(user.Email)
                     .collection("Sedi")
                     .doc(documento.Sede)
                     .collection("Categorie")
                     .doc(documento.Categoria).update({
                       Doc: firebase.firestore.FieldValue.increment(files.length)
                     }) //aggiorna n doc su categoria cli
   
                       db.collection("Users").doc(user.Email).collection("Sedi").doc(documento.Sede).update({
                         Doc: firebase.firestore.FieldValue.increment(files.length)
                       }) //aggiorna n doc su sede cli
   
                       db.collection("Users")
                       .doc(user.Email)
                       .collection("Sedi")
                       .doc(documento.Sede)
                       .collection("Categorie")
                       .doc(documento.Categoria)
                     .collection("Cartelle")
                     .doc(documento.Cartella).update({
                       Doc: firebase.firestore.FieldValue.increment(files.length)
                     }) //aggiorna n doc su cartella cli
   
                     db.collection('Users').doc(props.user.Email).collection('Clienti').doc(user.Email).update({
                       Doc: firebase.firestore.FieldValue.increment(files.length)
                     }) //aggiorna n doc su cliente man
   
                     sessionStorage.setItem('documenti', JSON.stringify(arr));
                     props.setDocumenti(arr)
                     var documentoz = documento
                     documentoz.Versioni = versioni
                     documentoz.ActiveVersion= docRef
                     setDocumento(documentoz)
                     
                     alert('Caricamento completato!')
                     setPercentage(null)
                     
                     
                   })
                        
                       });
                     })
                 }else{
                   alert('Hai terminato lo spazio fornito dal tuo piano Mysic, fai un upgrade del tuo piano per poter aggiungere ulteriori documenti.')
                 }
               })
                  
               
               
      
         }; //carica documento
         function downloadFile(url) {
          //funzione per aprire il documento
          window.open(url, "_blank");
        } //funzione che apre i file
        function setActive(doc){
          db.collection('Documenti').doc(documento.Id).update({
            Scadenza: doc.Scadenza,
            NomeFile:doc.NomeFile,
            StorageRef: doc.StorageRef,
            URL:doc.URL,
            Size: doc.Size,
            ActiveVersion: doc.Id
          }).then(()=>{
            var docz = documento
            docz.ActiveVersion= doc.Id
            setDocumento(docz)
            var documenti =  [...props.documenti]
            var arr = []

            if(documenti){
              arr=[...documenti]
            }

            if(arr.length>0){
              const index =  arr.findIndex((e)=>e.Id===documento.Id)
              console.log(index,arr[index])
              arr[index].Scadenza= "No"
              arr[index].NomeFile=doc.NomeFile
              arr[index].StorageRef= doc.StorageRef
              arr[index].URL = doc.URL
              arr[index].Size = doc.Size
              arr[index].ActiveVersion = doc.Id
            }

            sessionStorage.setItem('documenti', JSON.stringify(arr));
            props.setDocumenti(arr)
            alert('Documento attivo aggiornato.')
          })
        }
        function deleteDoc(doc){
          if(window.confirm("Vuoi eliminare il documento "+doc.NomeFile+"?")){

          db.collection('Documenti').doc(documento.Id).get().then((docz)=>{

            var versioni = [...docz.data().Versioni]

            var arr = versioni
            
            const index = versioni.findIndex((e)=>e.Id===doc.Id)
            
            arr.splice(index,1)

            db.collection('Documenti').doc(documento.Id).update({
              Versioni: arr
            }).then(()=>{
              var deleteRef;
              if(doc.StorageRef === undefined){
                deleteRef = storageRef.child(
                    documento.Autore + "/" + documento.Sede + "/" + documento.Cartella + "/" + doc.Id+'.'+documento.Extension
              );
              }
              else{
                deleteRef = storageRef.child(doc.StorageRef);
              }
               // Aggiorna lo stato di documento dopo l'eliminazione
            setDocumento(prevDocumento => ({
              ...prevDocumento,
              Versioni: arr // Aggiorna la lista delle versioni
            }));

            var documenti =  [...props.documenti]
            var arrz = []

            if(documenti){
              arrz=[...documenti]
            }
            if(arrz.length>0){
              const index =  arrz.findIndex((e)=>e.Id===documento.Id)
              console.log(index,arrz[index])
              arrz[index].Versioni= arr
            }

            sessionStorage.setItem('documenti', JSON.stringify(arrz));
            props.setDocumenti(arrz)

              //elimina su storage il doc
              deleteRef.delete().then(()=>(
                alert('Documento eliminato con successo.')
              ))
            })
          })
        }
        }

  return (
    props.documento?<div className="pop-opac">
         <div className="popup" style={{backgroundColor:"white", minHeight:'80vh', height:'fit-content', oveflowY: "auto", width:'66vw', left:'20vw'}} >
         <div style={{textAlign:'left', width: 'fit-content'}}>
            <button
              className="cancelIconButton"
              
              onClick={() => props.setTriggerPop(false)}
            >
              <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
            </button>
            </div>
            <div style={{}}>

<h4 className="page-title" style={{margin:0,width:'fit-content',marginLeft:'2vw',marginRight:'auto'}}>VERSIONAMENTO DOCUMENTI {percentage!==null?percentage+'%':null}</h4>
<h4 className="sub-text" style={{margin:0,width:'fit-content',marginLeft:'2vw',marginRight:'auto'}}>Inserisci le nuove versioni dei documenti che necessitano di essere rinnovati.</h4><br/>

</div>
<div style={{marginLeft:'2vw'}}>
    {console.log(documento)}
    <div style={{}}>
    <h4 className='bold-cool' style={{margin:0,color:'grey'}}>Versioni documento: </h4>
   
    
    </div>
    <div style={{height:'50vh',overflowY:'auto'}}>
    {documento.Versioni!==null?<div>
    {documento.Versioni.map((doc,i)=>(
      <div key={i} style={{borderBottom:'1px solid lightgrey'}}>
     <h4 className='bold-cool' style={{margin:0,color:documento.ActiveVersion===doc.Id?'#192bc2':'black'}}>{doc.NomeFile}</h4>
     <h4 className='bold-cool' style={{margin:0,color:'grey',fontSize:'0.7vw'}}>Data di caricamento: {doc.Caricamento?doc.Caricamento:'File Originale'}</h4>   
     <h4 className='normal-cool' style={{margin:0,color:'grey',fontSize:'0.7vw'}}>Dimensioni: {doc.Size?doc.Size:'-'} MB</h4>   
      <div style={{marginTop:'1vh',display:'flex',flexDirection:'row',alignItems:'center'}}>
        <Tooltip title='Visualizza documento'  onClick={()=>downloadFile(doc.URL)}>
      <IconButton style={{width:'fit-content'}}>
      <VisibilityIcon style={{color:'#192bc2'}}/>
      </IconButton>
      </Tooltip>
    {documento.ActiveVersion!==doc.Id?<Tooltip title={'Rendi documento attivo'}>
      <IconButton style={{width:'fit-content'}} onClick={()=>setActive(doc)}>
     <ToggleOnIcon style={{color:'green'}}/>
      </IconButton>
      </Tooltip>:null}
      {documento.ActiveVersion!==doc.Id?<Tooltip title='Elimina documento'>
      <IconButton style={{width:'fit-content'}} onClick={()=>deleteDoc(doc)}>
      <DeleteIcon style={{color:'red'}}/>
      </IconButton>
      </Tooltip>:null}
      </div>
     </div>
    ))}
    </div>:<div style={{marginTop:'1vh'}}>
     <h4 className='bold-cool' style={{margin:0,color:'#192bc2'}}>{documento.NomeFile}</h4>
     <h4 className='bold-cool' style={{margin:0,color:'grey',fontSize:'0.7vw'}}>File Originale</h4>   
     <h4 className='normal-cool' style={{margin:0,color:'grey',fontSize:'0.7vw'}}>Dimensioni: {documento.Size?documento.Size:'-'} MB</h4>   
     <div style={{marginTop:'1vh',display:'flex',flexDirection:'row',alignItems:'center'}}>
        <Tooltip title='Visualizza documento'  onClick={()=>downloadFile(documento.URL)}>
      <IconButton style={{width:'fit-content'}}>
      <VisibilityIcon style={{color:'#192bc2'}}/>
      </IconButton>
      </Tooltip>
      </div>
    </div>}
   
    </div>
    <div style={{marginTop:'1rem'}}><h4  className="sub-text-b">Aggiungi nuova versione:</h4>
       
            <input  onChange={(e)=>upload(e)} style={{marginTop:'1rem'}} type='file'/>

       </div>
</div>
         </div>
    </div>:null
  )
}
