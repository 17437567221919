//componente per le scadenze a breve
export function ScadBrev(props) {

  const date = new Date()
  //render della notifica
  const day = date.getDate()
  const month = date.getMonth()

  function getDiff(scad_day){
    var diff = 0
    if(scad_day<day){ //prossimo mese
      if(month===11||month===4||month===6||month===9){
        diff = (30 + parseInt(scad_day,10)) - parseInt(day,10)
        console.log(diff, parseInt(scad_day,10),parseInt(day,10))
        return diff

      }else if(month===2){
        diff= (28+ parseInt(scad_day,10)) - parseInt(day,10)
        return diff
  
      }else{
  
        diff= (31+ parseInt(scad_day,10)) - parseInt(day,10)
        console.log(diff, 31+parseInt(scad_day,10),parseInt(day,10))
  
        return diff
      }
    }else{
      diff = scad_day - day
      return diff
    }


  }

  return (
    <div
      key={props.index}
      className="box-shadow"
      style={{
        position: "relative",
        minHeight: "9vh",
        backgroundColor: "white",
        padding: 10,
        borderBottom:'1px solid lightgrey',
        marginBottom:10,
        paddingLeft:'5px',
        width:window.innerWidth>1440?'22rem':'15rem',
        borderRadius:'10px'
      }}
    >
      <div >
      <div
        style={{
          display: "flex",
          alignItems: "start",
          width: "26vw",

        }}
      >
        {" "}
       
      </div>
       <div style={{display:'flex'}}>
        <h4
          style={{
            marginTop: 'auto',
            marginBottom: 'auto',
            fontFamily: "Poppins",
            fontSize: "0.7rem",
            fontWeight: "bold",
            color:'#192bc2'
          }}
        >
          {props.not.Day}/{props.not.Month}/{props.not.Year} 

        </h4>
        <h4
          style={{
            marginTop: 'auto',
            marginBottom: 'auto',
            fontFamily: "Poppins",
            fontSize: "0.6rem",
            fontWeight: "normal",
            marginLeft:'auto',
            color:'#5C6672'
          }}
        >
          {getDiff(props.not.Day)===1?'Domani':getDiff(props.not.Day)===0?"Oggi":" Mancano "+getDiff(props.not.Day)+" giorni"}
         
        </h4>
        </div>  
     
        <div style={{ alignItems: "center", marginTop: "1vh" }}>
        <h4
          style={{
            marginTop: 0,
            marginBottom: 0,
            fontFamily: "Poppins",
            fontSize: "0.8rem",
            fontWeight: "bold",
            color: "black",
          }}
        >
          {props.not.Subject}
        </h4>
        {props.not.Description?props.not.Description[0]==='<'&&props.not.Description[1]==='a'?<div style={{marginTop:5}}
          dangerouslySetInnerHTML={{__html: props.not.Description}}
          ></div>:<h4  style={{
            marginTop: 0,
            marginBottom: 0,
            fontFamily: "Poppins",
            fontSize: "0.7rem",
            fontWeight: "normal",
      
          }}>{props.not.Description}</h4>:<h4  style={{
            marginTop: 0,
            marginBottom: 0,
            fontFamily: "Poppins",
            fontSize: "0.7vw",
            fontWeight: "normal",
      
          }}>Descrizione assente</h4>}
      </div>     
      </div>
      
     
    </div>
  );
}
