import React,{useState} from 'react'
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const Sezione2 = (props) => {

    ////PUNTO 1
    const [width1, setWidth1] = useState(40); //x1
    const [height1, setHeight1] = useState(0); //y2
    const [s1, setS1] = useState(10); //valore barra x1
    const [s3, setS3] = useState(1); //valore barra y1


    ////PUNTO 2
    const [width2, setWidth2] = useState(500); //x2
    const [s2, setS2] = useState(90); //valore barra x2
    const [s4, setS4] = useState(1); //valore barra y2
    const [height2, setHeight2] = useState(0); //y2

    const [heightImage, setImageHeight] = useState(0); //altezza immagine
    const [widthImage, setImageWidth] = useState(0); //larghezza immagine


      const handleWidth1 = (event, newValue) => {
        var c = (newValue * widthImage) / 100;
        setWidth1(c);
        setS1(newValue);
      }; //cambia x punto 1
      const handleWidth2 = (event, newValue) => {
        var c = (newValue * widthImage) / 100;
        setWidth2(c);
        setS2(newValue);
      }; //cambia x punto 2
      const handleHeight1 = (event, newValue) => {
        var c = (newValue * heightImage) / 100;
        setHeight1(c);
        setS3(newValue);
      }; //cambia y punto 1
      const handleHeight2 = (event, newValue) => {
        var c = (newValue * heightImage) / 100;
    
        setHeight2(c);
        setS4(newValue);
      }; //cambia y punto 2
  
      function onImgLoad({ target: img }) {
        setImageHeight(img.offsetHeight);
        setImageWidth(img.offsetWidth);
      }

      function confirm(){
        console.log(height1,height2)
        if(width1>=width2){
          alert('Errore: X del PUNTO 1 maggiore del PUNTO 2.')
        }else if(height1<=height2){
          alert('Errore: Y del PUNTO 1 maggiore del PUNTO 2.')
        }else{
          props.setWidth1(width1)
          props.setWidth2(width2)
          props.setHeight1(height1)
          props.setHeight2(height2)
          props.setHeightImage(heightImage)
          props.setWidthImage(widthImage)
          props.setSection(2)

        }
      }

  return (
    <div style={{width:'80vw',height:'80vh',marginLeft:'auto',marginRight:'auto',marginTop:'5vh',display:'flex'}}>
        <div style={{width:'35vw',height:'80vh',borderRight:'1px solid lightgrey'}}>
        <div style={{width:'30vw'}}>
        <h4 className='sub-text'>Posiziona il <b style={{color:'#192bc2'}}>PUNTO 1</b> in un punto qualunque del lato sinistro della planimetria, <b>più in basso del </b><b style={{color:'red'}}>PUNTO 2</b>.</h4>

            {/*SLIDER PUNTO 1*/}
            <div className='box-shadow'  style={{border:'1px solid #192bc2',padding:5,borderRadius:10,marginTop:'1vh'}}>
                <h4 className='normal-text' style={{color: '#192bc2',fontWeight:'bold'}}>PUNTO 1</h4>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1, padding: 2,width:'75%' }}
              alignItems="center"
            >
              <label className="subtitle2">X1</label>

              <RemoveIcon style={{ marginLeft: "auto" }} />
              <Slider
                min={0}
                max={100}
                step={1}
                aria-label="Volume"
                defaultValue={1}
                style={{ width: "12vw" }}
                value={s1}
                onChange={handleWidth1}
              />
              <AddIcon />
            </Stack>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1, padding: 2,width:'75%' }}
              alignItems="center"
            >
              <label className="subtitle2">Y1</label>

              <RemoveIcon style={{ marginLeft: "auto" }} />
              <Slider
                min={0}
                max={100}
                step={1}
                aria-label="Volume"
                defaultValue={1}
                style={{ width: "12vw" }}
                value={s3}
                onChange={handleHeight1}
              />
              <AddIcon />
            </Stack>
            </div>

            <h4 className='sub-text' style={{marginTop:'4vh'}}>Posiziona il <b style={{color:'red'}}>PUNTO 2</b> in un punto qualunque del lato destro della planimetria <b>più in alto del </b><b style={{color:'#192bc2'}}>PUNTO 1</b>.</h4>

{/*SLIDER PUNTO 2*/}
<div className='box-shadow'  style={{border:'1px solid red',padding:5,borderRadius:10,marginTop:'1vh'}}>
    <h4 className='normal-text' style={{color: 'red',fontWeight:'bold'}}>PUNTO 2</h4>
    <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1,  padding: 2,width:'75%'  }}
              alignItems="center"
            >
              <label className="subtitle2">X2</label>

              <RemoveIcon style={{ marginLeft: "auto" }} />
              <Slider
                min={0}
                max={100}
                step={1}
                aria-label="Volume"
                defaultValue={1}
                style={{ width: "12vw" }}
                value={s2}
                onChange={handleWidth2}
              />
              <AddIcon />
            </Stack>
            
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1,padding: 2,width:'75%'  }}
              alignItems="center"
            >
              <label className="subtitle2">Y2</label>

              <RemoveIcon style={{ marginLeft: "auto" }} />
              <Slider
                min={0}
                max={100}
                step={1}
                aria-label="Volume"
                defaultValue={1}
                style={{ width: "12vw" }}
                value={s4}
                onChange={handleHeight2}
              />
              <AddIcon />
            </Stack>
</div>
<div style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',marginTop:'4vh'}}>
<button className='main-button'  onClick={()=>confirm()}>CONFERMA</button>
</div>
    </div>

        </div>
        <div>
        <div style={{ width: "35rem", position: "relative",marginLeft:'8vw',overflowY:'auto' }}>
        <div
                style={{
                  position: "absolute",
                  backgroundColor: "blue",
                  top: height1 + "px",
                  left: width1 + "px",
                  width: "1rem",
                  height: "1rem",
                  borderRadius: 50,
                  padding:2,
                  display:'flex',
                  flexDirection:'column',
                  alignItems:'center',
                  justifyContent:'center'
                }}
              ><h4 style={{margin:0,color:'white',width:'fit-content',height:'fit-content',fontSize:'15px'}}>1</h4></div>
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "red",
                  top: height2 + "px",
                  left: width2 + "px",
                  width: "1rem",
                  height: "1rem",
                  borderRadius: 50,
                  padding:2,
                  display:'flex',
                  flexDirection:'column',
                  alignItems:'center',
                  justifyContent:'center'
                }}
              ><h4 style={{margin:0,color:'white',width:'fit-content',height:'fit-content',fontSize:'15px'}}>2</h4></div>
        <img src={props.planimetria} alt='planimetria' onLoad={onImgLoad} style={{ maxWidth: "100%"}}></img>
        </div>
        </div>
    </div>
  )
}

export default Sezione2