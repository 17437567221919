import React, { useEffect, useState,useRef,useCallback } from 'react'
import { useParams } from 'react-router-dom'
import NavBar2 from '../../NavBar/NavBar2'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import firebase from "firebase";
import { getElementiCliente, getMappa } from '../../EngineOffritore/engineOffritore';
import { getElementiMappa, getUtente,checkDeleteS,checkWriteS } from '../../EngineClienti/engineClienti';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { GoogleMap, useLoadScript, Marker, OverlayView } from "@react-google-maps/api";
import locationIcon from '../../images/location.png';

import Menu from './EditMappa/Menu';
import { getIcon } from '../../EngineClienti/engineClienti';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import NavBar from '../../NavBar/NavBar';
const EditMappa = ({history}) => {

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBy4500tb8vImwBqAyfom60eOXjoW1fQfI&libraries=places",
    
      }); //chiave api per la mappa

    const {user_id, sede_id,piano_id,mappa_id} = useParams()
    const mapRef = useRef()
    const mapInstanceRef = useRef();
    const refContainer = useRef()
    const db = firebase.firestore()
    const auth = firebase.auth()

    const [mappa,setMappa] = useState(null)
    const [center,setCenter] = useState(null)
    const [boundss,setBoundss] = useState(null)
    const [opacity,setOpacity] = useState(100)
    const [mode,setMode] = useState(0) //modalità mappa
    const [user,setUser] = useState(null)
    const [elementi,setElementi] = useState([]) //lista elementi non posizionati
    const [elementiPosizionati,setElementiPosizionati] = useState([]) //lista elementi posizionati
    const [sottocategorie,setSottocategorie] = useState([]) //lista sottocategorie
    const [categorie,setCategorie] = useState([]) //lista categorie
 
    const [selElement,setSelElement] = useState(null) //elemento selezionato per posizionamento
    const [zoom, setZoom] = useState(20); // stato per gestire lo zoom
    const [showMarkers,setShowMarkers] = useState(true) //se mostrare i markers durante lo zoom o no
    const [editMode,setEditMode] = useState(0) //modalità modifica -> 0 inserimento e drag elementi posizionati, 1 eliminazione (click su elemento)

    //cambio nome mappa
    const [editName,setEditName] = useState(false)
    const [newName,setNewName] = useState('')

    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
            if (user) {
                getUtente(db,auth,setUser)
                getMappa(db,mappa_id,setMappa)
            }
        })
    },[auth])

    useEffect(()=>{
     
        if(mappa!==null&&isLoaded){
          setMapRef()
        }
    },[mappa,isLoaded])

   
    function setMapRef(){
      const bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(mappa.FirstBound.Lat, mappa.FirstBound.Lng),
        new window.google.maps.LatLng(mappa.SecondBound.Lat, mappa.SecondBound.Lng)
      );
        setCenter(
          bounds.getCenter()
          );
          setBoundss(
            bounds
          );
    }

    useEffect(()=>{

            if (user!==null) {
              console.log(user.Email)
              if(user.Tipo==='Lavoratore'){
                getElementiCliente(db,user.Email,user_id,setElementi,setCategorie,setSottocategorie,setElementiPosizionati,mappa_id,sede_id)

                const currentUrl = window.location.href;
      
                if(currentUrl.includes('visualizemappa')){
                  setMode(0)
                }else{
                  setMode(1)
                }

              }else{
                getElementiMappa(db,user.Email,setElementi,setCategorie,setSottocategorie,setElementiPosizionati,mappa_id,sede_id)
              }
            }
    },[user])

    useEffect(()=>{
      if(elementi.length>0){
        setSelElement(elementi[0])
        
      }
    },[elementi])

    const handleZoomChanged = useCallback(() => {
      if (mapInstanceRef.current) {
        const newZoom = mapInstanceRef.current.getZoom();
        setZoom(newZoom);
          setShowMarkers(newZoom>18)
        
      }
    }, []);

    const onLoad = useCallback((map) => {
      mapInstanceRef.current = map;
    }, []);

    function handleSpostamentoMarker(e,i){
        let newArr = [...elementiPosizionati];
        newArr[i].Posizione = {Lat: e.latLng.lat(), Lng: e.latLng.lng()};
         setElementiPosizionati(newArr)
      
          db.collection("Elementi").doc(newArr[i].Id).update({
              Posizione: {Lat: e.latLng.lat(), Lng: e.latLng.lng()},
            })
            db.collection('Mappe').doc(mappa_id).update({
              UltimaModifica:new Date().toLocaleDateString("en-GB"),
  
            })

    }
    function deleteMarker(e,i){
      var newArr = [...elementiPosizionati]
      var arr2 = [...elementi]
      
      db.collection('Elementi').doc(newArr[i].Id).update({
        Piano:'',
        Mappa:'',
        Posizione: null
      }).then(()=>{
        console.log(newArr[i])
        arr2.push(newArr[i])
        console.log(arr2)
        setElementi(arr2)
        newArr.splice(i,1)
        setElementiPosizionati(newArr)
       
        db.collection('Mappe').doc(mappa_id).update({
          Elementi: newArr,
          UltimaModifica:new Date().toLocaleDateString("en-GB"),
        })
      })

        } //elimina marker

    function renderMarkerSott(m){
      var arr = [...sottocategorie]
      console.log(arr,m)
      var index = arr.findIndex((e)=>e.Nome===m.Macrocategoria)
      if(index!==-1){
        if(arr[index].Visualize===true){
          return true
        }
      }
     
    }

    const Map = React.memo(() => (

         !isLoaded||mappa===null ? (
          <h4>caricamento</h4>
        ) : (
          <div
            style={{
              position: "relative",
              zIndex: 5,
              maxWidth: "100%",
              marginLeft: "-2.5rem",
            }}
          >
            <GoogleMap
              onClick={editMode===0?handleClick:null}
              zoom={zoom}
              center={center}
              onZoomChanged={handleZoomChanged}
              ref={mapRef}
              onLoad={onLoad}
              style={{ width: "100%" }}
              mapContainerStyle={{ width: "100%",height:'85vh',border:'1px solid black' }}
              mapContainerClassName="map-container"
              options={{ minZoom: 10, maxZoom: 25 }}
            >
              {boundss && (
                <OverlayView
                  mapPaneName="overlayLayer"
                  ref={refContainer}
                  id="supercontainer"
                  position={center}
                  bounds={boundss}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        left: "50%",
                        top: "50%",
                      }}
                    >
                        <img
                          src={mappa.Planimetria}
                          alt='plan'
                          style={{
                            width: "100%",
                            opacity: opacity+'%',
                            transformOrigin:
                              mappa.TranslateSx + "% " + mappa.TranslateTop + "%",
                            transform:
                              "translate(-" +
                              mappa.TranslateSx +
                              "%, -" +
                              mappa.TranslateTop +
                              "%) rotate(" +
                              mappa.Rotation +
                              "deg) scale(" +
                              mappa.Zoom +
                              ")",
                          }}
                        />
                  
                    </div>
                  </div>
                </OverlayView>
              )}
              {showMarkers === true ?
            elementiPosizionati.map((marker,m) => (
                      renderMarkerSott(marker)===true?<Marker key={m} 
                      onClick={(e)=>mode===0?history.push(`/elementi/elemento/${marker.Id}`):editMode===1?deleteMarker(e,m):history.push(`/elementi/elemento/${marker.Id}`)} 
                      draggable={mode===1&&editMode===0} onDragEnd={(e)=>editMode===0?handleSpostamentoMarker(e,m):null}
                       position={new window.google.maps.LatLng(marker.Posizione.Lat,marker.Posizione.Lng)} label={{text:marker.Nome, 
                        color:marker.EsitoUltimaScadenza?marker.EsitoUltimaScadenza==='Positivo'?'black':'red':'black',
                        fontWeight:'bold'}} title={marker.Nome} 
                      icon={{url:getIcon(marker),scaledSize: new window.google.maps.Size(20,20),labelOrigin: new window.google.maps.Point(13,30)}}/>:null
                    )):<Marker draggable={false}   icon={{url:locationIcon,scaledSize: new window.google.maps.Size(100,100)
                    }} bounds={boundss} position={center}/>}
              
            </GoogleMap>
          </div>
        )
    ))
      const  handleClick= useCallback((e) => {
        if (mode === 1) {
          if(elementi.length>0){

          
          var currentSott = selElement.Macrocategoria //sottocat corrente

          //aggiunge elemento posizionato
          const newPosition = {
            ...selElement,
            Posizione: { Lat: e.latLng.lat(), Lng: e.latLng.lng() }
          };
          var arr = [...elementiPosizionati]
          arr.push(newPosition)
          //setCenter({ lat: e.latLng.lat(), lng: e.latLng.lng() })
          setElementiPosizionati(prev => [...prev, newPosition]);
          db.collection('Elementi').doc(selElement.Id).update({
            Posizione:{ Lat: e.latLng.lat(), Lng: e.latLng.lng() },
            Piano: piano_id,
            Mappa: mappa_id,

          })
          db.collection('Mappe').doc(mappa_id).update({
            Elementi: arr,
            UltimaModifica:new Date().toLocaleDateString("en-GB"),

          })

          //rimuovi elemento posizionato da elementi disponibili
          const updatedElementi = elementi.filter(el => !(el.Nome === selElement.Nome && el.Macrocategoria === selElement.Macrocategoria));
          setElementi(updatedElementi);
          
          //seleziona nuovo elemento
          if (updatedElementi.length > 0) {
            const nextElement = updatedElementi.find(el => el.Macrocategoria === currentSott) || updatedElementi[0];
            setSelElement(nextElement);
          }
         
        }
        }
        

      },[mode,elementi,selElement]) //gestisce i click sulla mappa

      function editMapName(){
        if(newName!==''){
          db.collection('Mappe').doc(mappa_id).update({
            Nome: newName,
            UltimaModifica:new Date().toLocaleDateString("en-GB"),

          }).then(()=>{
            setEditName(false)
            var m = mappa
            m.Nome=newName
            setMappa(m)
            setNewName('')
            
          })
        }else{
          alert('Inserire un nome valido.')
        }
        
      }

  return (
    <div className="background" style={{width:'100vw',height:'100vh',overflow:'hidden'}}>
    {user!==null?user.Tipo==='Lavoratore'?<NavBar2/>:<NavBar/>:null}
    <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
    <div  style={{  width: "60vw",display:'flex',marginTop:'1.2rem' }}>
          <button style={{marginBottom:'auto',marginTop:'1rem'}} onClick={() => history.goBack()}
            className="cancelIconButton"
            
          >
            <ArrowBackIcon
             className="back-button"
            />
          </button>
          <div>
            {editName===false?<div style={{display:'flex',alignItems:'center'}}>
            <h4 className="page-title" style={{margin:0}}>{mappa!==null?mappa.Nome:null}</h4>
           {checkWriteS(user?user.Permissions:null)===true? <IconButton onClick={()=>setEditName(true)} style={{width:'fit-content',marginLeft:'1vw'}}><EditIcon style={{color:'#14213d'}}/></IconButton>:null}
            </div>:<div style={{display:'flex',alignItems:'center'}}>
            <input className='input-style' placeholder='Inserisci nome mappa...' type='text' onChange={(e)=>setNewName(e.target.value)}></input>
            <IconButton onClick={editMapName} style={{width:'fit-content',marginLeft:'1vw'}}><CheckIcon style={{color:'green'}}/></IconButton>
            <IconButton onClick={()=>{setEditName(false);setNewName('')}} style={{width:'fit-content',marginLeft:'0.5vw'}}><CloseIcon style={{color:'red'}}/></IconButton>

            </div>}
          <h4
          className="sub-text-b"
        >
          Modifica mappa
        </h4>
          </div>

        </div>
        {/*DIV CHE CONTIENE MAPPA E MENÙ*/}
        <div style={{position:'relative',width:'100%',height:'85vh',marginTop:'1.2rem',display:'flex'}}>
        {/*MAPPA */}
        <div style={{width:'60%',height:'100%',position:'relative'}}>
       {mode===1?selElement? <div style={{position:'absolute',zIndex:100,left:'50%',transform: 'translate(-50%)',backgroundColor:'white',padding:5,top:'10px',border:'1px solid lightgrey'}}>
        {editMode===0?<h4 style={{margin:0}} className='normal-cool'>Elemento da posizionare: <b style={{color:'#192bc2'}}>{selElement.Nome}</b></h4>:
        <h4 style={{margin:0}} className='normal-cool'>Clicca sull'elemento che desideri rimuovere dalla mappa.</h4>}
        </div>:null:null}
        <Map opacity={opacity}/>
       
        </div>
        {/*MENU */}
        <Menu opacity={opacity} mappa={mappa} setOpacity={setOpacity} user={user} mode={mode} setZoom={setZoom} setCenter={setCenter} elementiPosizionati={elementiPosizionati} setSelElement={setSelElement} editMode={editMode} setEditMode={setEditMode} selElement={selElement} setMode={setMode} categorie={categorie} sottocategorie={sottocategorie} setSottocategorie={setSottocategorie} elementi={elementi}/>
        </div>

        </div>
    </div>
  )
}

export default EditMappa