//popup condivisione con offritore
import CancelIcon from '@material-ui/icons/Cancel';
import React, { useState, useCallback, useEffect } from "react";
import firebase from "firebase/app";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Share';

const Condividi = (props) => {

    const [all,setAll] = useState([]) //tutti gli elementi
    const [selectedAll,setSelectedAll] = useState(false) // se selezionati tutti
    const [selectedOff,setSelectedOff] = useState(null) //offritore selezionato
    const db = firebase.firestore();
    const auth = firebase.auth();

    useEffect(()=>{
        props.docs.forEach((d)=>{
            console.log('bella',d)
            setAll((prev)=>{
                return [
                    ...prev,
                    {
                        checked: false,
                        data: d
                    }
                ]
            })
        })
    },[props.trigger]) //prende gli elementi

    function choose(){
        if(selectedAll===false){
            setSelectedAll(true)
            selectAll()
        }else{
            setSelectedAll(false)

            deselectAll()
        }
    } //seleziona tutti/deseleziona tutti

    function selectAll(){
        var arr = [...all]
        for (let i = 0; i<arr.length; i++){
            console.log('ciauuu',arr[i].checked)
            arr[i].checked= true
            if(i===arr.length-1){
                setAll(arr)
            }
        }
    } //seleziona tutti
    function deselectAll(){
        var arr = [...all]
        for (let i = 0; i<arr.length; i++){
            arr[i].checked= false
            if(i===arr.length-1){
                setAll(arr)
            }
        }
    } //deseleziona tutti
    function toogleSelect(index){
        var arr = [...all]
        arr[index].checked===true?arr[index].checked=false:arr[index].checked=true
        setAll(arr)
    } //selezione quello su cui clicchi

    function share(){
        var arr = [...all]
        var arr2 = [...props.docs]
        console.log(arr2)
        var i = 0
        var check = arr.filter((e)=>e.checked===true)
        console.log(check)
        if(check.length>0){
            check.forEach((a)=>{

                if(props.page==='doc'){
                    db.collection('Documenti').doc(a.data.Id).update({
                        Shared: selectedOff
                    }).then(()=>{

                        db.collection('Users').doc(selectedOff).collection('Clienti').doc(props.email).collection('Documenti').doc(a.data.Id).set({
                            ref: a.data.Id
                        }) //aggiunge ref a man
                     
                        console.log(a.data)
                        const indexO = arr2.findIndex((e)=>e.Id===a.data.Id)
                        console.log(indexO)
                        arr2[indexO].Shared=selectedOff
                        if(i===check.length-1){
                            db.collection('Users').doc(selectedOff).update({
                                Current_Doc: firebase.firestore.FieldValue.increment(check.length)
                            }).then(()=>{
                                db.collection('Users').doc(selectedOff).collection('Clienti').doc(props.email).update({
                                    Doc: firebase.firestore.FieldValue.increment(check.length)
                                })
                                props.setDocs(arr2)
                                deselectAll()
                                alert('Condivisione completata,aggiornare per visualizzare i risultati')
                            })
                            

                        }
                        i++

                    })
                }else{
                    db.collection('Elementi').doc(a.data.Id).update({
                        Shared: selectedOff,
                        Cliente: props.email
                    }).then(()=>{
                        db.collection('Users').doc(selectedOff).collection('Clienti').doc(props.email).collection('Elementi').doc(a.data.Id).set({
                            ref: a.data.Id
                        }) //aggiunge ref a man

                        const indexO = arr2.findIndex((e)=>e.Id===a.data.Id)
                        arr2[indexO].Shared=selectedOff
                        if(i===check.length-1){
                            db.collection('Users').doc(selectedOff).update({
                                Current_Ele: firebase.firestore.FieldValue.increment(check.length)
                            }).then(()=>{
                                db.collection('Users').doc(selectedOff).collection('Clienti').doc(props.email).update({
                                    Ele: firebase.firestore.FieldValue.increment(check.length)
                                })
                                props.setDocs(arr2)
                                deselectAll()
                                alert('Condivisione completata,aggiornare per visualizzare i risultati')
                            })
                        }
                        i++

                    })   
                }
            })
        }else{
            alert('Nessun elemento selezionato')
        }
    } //condivide

    const [triggerOff,setTriggerOff] = useState(false) //trigger scelta off
    
    const SelectOff = (props) =>{

        const [offritori,setOffritori] = useState([])

        useEffect(()=>{
            console.log(props.email)
            db.collection('Users').doc(props.email).collection('Offritori').get().then((offs)=>{
                offs.forEach((o)=>{
                    db.collection('Users').doc(o.data().Email).get().then((off)=>{
                        setOffritori((prev)=>{
                            return [
                                ...prev,
                                off.data()
                            ]
                        })
                    })
                    console.log(o.data())
                  
                })
            })
        },[auth])

        return (
            triggerOff===true?<div className='pop-opac'>
                <div className='popup' style={{height:'35vh',top:'10vh'}}>
                <div style={{textAlign:'left', width: 'fit-content'}}>
            <button
              className="cancelIconButton"
              
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
            </button>
            </div>
            <div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content'}}>

                <h4 style={{marginLeft:'auto',marginRight:'auto'}}>Seleziona offritore</h4>
                </div>
                <div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content'}}>

                <select value={selectedOff} className='input-filter' onChange={(e)=>setSelectedOff(e.target.value)}>
                <option value="" hidden selected>
                      Seleziona...
                    </option>
                    {offritori.map((o,index)=>(
                         <option key={index} value={o.Email}>
                            {o.Nome_Azienda}
                         </option>
                    ))}
               
                </select>
                </div>
                <br/>
                    <div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content'}}>
                    <button  onClick={()=>selectedOff!==null?share():alert('Seleziona un manutentore per procedere.')} className='blue-button'>Condividi</button>

                    </div>
                </div>
            </div>:null
        )
    } //componente scelta off

return (
    <div className="pop-opac">
        <div className="popup" style={{height:'85vh',width:'60vw',left:'20vw'}}>
            {triggerOff===true?<SelectOff email={props.email} trigger={triggerOff} setTrigger={setTriggerOff}/>:null}
        <div style={{textAlign:'left', width: 'fit-content'}}>
            <button
              className="cancelIconButton"
              
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
            </button>
            </div>
            <h4 className='page-title' style={{marginTop:10,marginLeft:20}}>CONDIVISIONE MULTIPLA {props.page==='ele'?'ELEMENTI':'DOCUMENTI'} </h4>
            <div style={{display:'flex',alignItems:'center',marginLeft:'auto',marginRight:'auto',width:'fit-content'}}>
            <div style={{borderRadius:5,width:'50vw',height:'3rem',backgroundColor:'#001d3d',display:'flex'}}>
            <div  style={{width:'2vw',borderRight:'1px solid white',height:'3rem',alignItems:'center',justifyContent:'center',}}>
            <input onChange={()=>choose()} checked={selectedAll} type='checkbox' style={{marginBottom:'auto',marginLeft:'0.6rem',marginTop:'1rem'}}></input>

            </div>
            <div  style={{width:'12vw',borderRight:'1px solid white',height:'3rem',alignItems:'center',justifyContent:'center',}}>
            <h4 className="header-table" >{props.page==='doc'?'Documento':'Elemento'}</h4>
            </div>
            <div  style={{width:'12vw',borderRight:'1px solid white',height:'3rem',alignItems:'center',justifyContent:'center',}}>
            <h4 className="header-table" >Categoria</h4>
            </div>
            <div  style={{width:'12vw',borderRight:'1px solid white',height:'3rem',alignItems:'center',justifyContent:'center',}}>
            <h4 className="header-table" >{props.page==='doc'?'Cartella':'Sottocategoria'}</h4>
            </div>
            <div  style={{width:'12vw',height:'3rem',alignItems:'center',justifyContent:'center',}}>
            <h4 className="header-table" >Condiviso con</h4>
            </div>
            </div>
            <div style={{width:'7vw',height:'3rem',display:'flex',alignItems:'center'}}>
            <Tooltip title='Condividi'>
            <IconButton onClick={()=>all.filter((e)=>e.checked===true).length>0?setTriggerOff(true):alert('Nessun elemento selezionato')} style={{width:'fit-content',marginLeft:'auto'}}>
            <ShareIcon style={{color:'#0496ff'}}/>
            </IconButton>
            </Tooltip>
            {/*<Tooltip title='Elimina'>
            <IconButton style={{width:'fit-content',marginRight:'auto'}}>
            <DeleteIcon style={{color:'red'}}/>
            </IconButton>
            </Tooltip>*/}
            </div>
            </div>
            
            <div style={{width:'57vw', height:'65vh',marginLeft:'auto',marginRight:'auto',overflowY:'auto',marginTop:5}}>
            {props.docs.length>0?props.docs.map((doc,index)=>(
            <div key={index} style={{display:'flex', borderBottom:'1px solid lightgrey',height:'3rem'}}>
                <div style={{width:'2vw',alignItems:'center',justifyContent:'center'}}>
                <input onChange={()=>toogleSelect(index)} checked={all.length>0?all[index].checked:null} type='checkbox' style={{marginBottom:'auto',marginTop:'1rem',marginLeft:'0.5rem'}}></input>
                </div>
                <div style={{width:'12vw',display:'flex',alignItems:'center'}}>
                <h4 className="body-table" style={{ marginBottom:0,fontSize:'1vw',marginLeft:'0.5vw'}}>
                {props.page==='ele'?doc.Nome:doc.NomeFile}
                </h4>
                </div>
                <div style={{width:'12vw',display:'flex',alignItems:'center'}}>                
                <h4 className="body-table2" style={{maxWidth:'9.3vw'}}>
                 {doc.Categoria}
                </h4>
                </div>
                <div style={{width:'12vw',display:'flex',alignItems:'center'}}>                
                <h4 className="body-table2" style={{maxWidth:'9.3vw'}}>
                 {props.page==='doc'?doc.Cartella:doc.Macrocategoria}
                </h4>
                </div>
                <div style={{width:'12vw',display:'flex',alignItems:'center'}}>                
                <h4 className="body-table2" style={{maxWidth:'9.3vw'}}>
                 {doc.SharedN}
                </h4>
                </div>
            </div>
            )):<h4>Nessun {props.page==='ele'?'elemento':'documento'} presente.</h4>}
            </div>
        </div>
    </div>
)
    
}

export default Condividi