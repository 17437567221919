import React from 'react'
import FileUploadIcon from '@mui/icons-material/FileUpload';
const Sezione1 = (props) => {


  return (
    <div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content',marginTop:'8vh'}}>
        {props.planimetria===null?<div className='box-shadow' style={{width:'30vw',height:'40vh',border:'1px solid #192bc2',borderRadius:10,position:'relative'}}>
        <input type='file' onChange={(e)=>{props.setPlanimetria(URL.createObjectURL(e.target.files[0]));props.setFile(e.target.files[0])}} style={{width:'100%',height:'100%',opacity:0,position:'absolute',zIndex:100,backgroundColor:'blue',cursor:'pointer'}}/>

            <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',marginTop:'8vh',marginBottom:'auto',height:'fit-content',zIndex:99}}>
                <FileUploadIcon style={{color:'#192bc2',fontSize:'5vw'}}/>
            <h4 className='text-buttons'>Trascina o clicca qui per caricare la planimetria del piano</h4>


            </div>
            
        </div>:<div style={{width:'fit-content'}}>
        <img src={props.planimetria} 
 alt='planimetria' style={{maxWidth:'20vw',maxHeight:'70vh'}}></img>
        <br/>
        <div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content',marginTop:'2vh'}}>
        <button className='main-button' onClick={()=>props.setSection(1)}>Conferma</button>

        </div>
            
        </div>}
    </div>
  )
}

export default Sezione1