//connette componenti agli url
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./components/Dashboard/Home";
import LoginPage from "./components/LoginPage/LoginPage";
import { AuthProvider } from "./components/Auth";
import SignUp from "./components/RegisterPage/SignUp";
import Error from "./components/Error";
import PreLoginPage from "./components/PreLogin/PreLoginPage";
import SearchOff from "./components/Search/SearchOff";
import Impostazioni from "./components/Impostazioni/Impostazioni";
import ProvaMappe from "./components/ProvaMappe/ProvaMappe";
import Elementi from "./components/Elementi/Elementi";
import Documenti from "./components/Documenti/Documenti";
import Interventi from "./components/Interventi/Interventi";
import Scadenze from "./components/Scadenze/Scadenze";
import AggiungiCategoria from "./components/Elementi/Popups/AggiungiCategoria";
import AggiungiElemento from "./components/Elementi/Popups/AggiungiElemento";
import Elemento from "./components/Elementi/Popups/Elemento";
import AggiungiDocumento from "./components/Documenti/Popups/AggiungiDocumento";
import AggiungiScadenza from "./components/Documenti/Popups/AggiungiScadenza";
import ModificaScadenza from "./components/Documenti/Popups/ModificaScadenza";
import RichiediIntervento from "./components/Interventi/Popups/RichiediIntervento";
import DettagliIntervento from "./components/Interventi/Popups/DettagliIntervento";
import DettagliScadenza from "./components/Scadenze/Popups/DettagliScadenza";
import Sedi from "./components/Sedi/Sedi";
import AggiungiSede from "./components/Sedi/Popups/AggiungiSede";
import Categorie from "./components/Categorie/Categorie";
import Manutentori from "./components/Manutentori/Manutentori";
import MappeCliente from "./components/MappeCliente/MappeCliente";
import PianiMappeCli from "./components/MappeCliente/Popups/PianiMappeCli";
import Categoria from "./components/Categorie/Popups/Categoria";

////Manutentore
import ElementiOffritore from "./components/ElementiOffritore/ElementiOffritore";
import AggiungiCategoriaOff from "./components/ElementiOffritore/Popups/AggiungiCategoriaOff";
import AggiungiElementoOff from "./components/ElementiOffritore/Popups/AggiungiElementoOff";
import DocumentiOffritore from "./components/DocumentiOffritore/DocumentiOffritore";
import AggiungiScadenzaOffritore from "./components/DocumentiOffritore/Popups/AggiungiScadenzaOffritore";
import ModificaScadenzaOffritore from "./components/DocumentiOffritore/Popups/ModificaScadenzaOffritore";
import AggiungiDocumentoOffritore from "./components/DocumentiOffritore/Popups/AggiungiDocumentoOffritore";
import InterventiOffritore from "./components/InterventiOffritore/InterventiOffritore";
import DettagliInterventoOff from "./components/InterventiOffritore/Popups/DettagliInterventoOff";
import ScadenzeOffritore from "./components/ScadenzeOffritore/ScadenzeOffritore";
import DettagliScadenzaOff from "./components/ScadenzeOffritore/Popups/DettagliScadenzaOff";
import RichiediScadenza from "./components/ScadenzeOffritore/Popups/RichiediScadenza";
import MappeGenerale from "./components/MappeOffritore/MappeOffritore";
import PianiMappe from "./components/MappeOffritore/Popups/PianiMappe";
import EditMappa from "./components/MappeOffritore/Popups/EditMappa";
import AggiungiMappa from "./components/MappeOffritore/Popups/AggiungiMappa";
import Clienti from "./components/Clienti/Clienti";
import AggiungiSottCategoriaOff from "./components/ElementiOffritore/Popups/AggiungiSottCategoriaOff";

import firebase from "firebase";
import { useEffect, useState } from "react";

const App = () => {
  const db = firebase.firestore();
  const auth = firebase.auth();
  const [user,setUser] = useState(null)
  const [disp,setDisp] = useState(false)
  



  // Required for side-effects
  require("firebase/firestore");
  return (
    <AuthProvider>
      <Router>
      <div style={{width:'100vw',height:'100vh',overflow:'hidden'}}>
     
      <div>
          <Route exact path="/home" component={Home} />

          {/*SEDI CLIENTE*/}
          <Route exact path="/sedi" component={Sedi} />
          <Route exact path="/sedi/aggiungisede" component={AggiungiSede} />

          {/*CATEGORIE CLIENTE*/}
          <Route exact path="/categorie" component={Categorie} />
          <Route exact path="/categorie/aggiungicategoria" component={AggiungiCategoria}/>
          <Route exact path="/categorie/categoria/:id" component={Categoria} />    


          {/*ELEMENTI CLIENTE */}
          <Route exact path="/elementi" component={Elementi} />
          <Route exact path="/elementi/aggiungicategoria" component={AggiungiCategoria}/>
          <Route exact path="/elementi/aggiungielemento" component={AggiungiElemento}/>
          <Route exact path="/elementi/elemento/:id" component={Elemento}/>
          {/*ELEMENTI OFFRITORE */}
          <Route exact path="/elementimanutentore" component={ElementiOffritore} />
          <Route exact path="/elementimanutentore/aggiungicategoria" component={AggiungiCategoriaOff} />
          <Route exact path="/elementimanutentore/aggiungielemento" component={AggiungiElementoOff} />
          <Route exact path="/elementimanutentore/aggiungisottocategoria" component={AggiungiSottCategoriaOff} />

          {/*DOCUMENTI CLIENTE */}
          <Route exact path="/documenti" component={Documenti} />
          <Route exact path="/documenti/aggiungicategoria" component={AggiungiCategoria}/>
          <Route exact path="/documenti/aggiungidocumento" component={AggiungiDocumento}/>
          <Route exact path="/documenti/:id/aggiungiscadenza" component={AggiungiScadenza}/>
          <Route exact path="/documenti/:id/modificascadenza" component={ModificaScadenza}/>

          {/*DOCUMENTI OFFRITORE */}
          <Route exact path="/documentimanutentore" component={DocumentiOffritore} />
          <Route exact path="/documentimanutentore/aggiungidocumento" component={AggiungiDocumentoOffritore} />
          <Route exact path="/documentimanutentore/aggiungicategoria" component={AggiungiCategoriaOff} />
          <Route exact path="/documentimanutentore/:id/aggiungiscadenza" component={AggiungiScadenzaOffritore}/>
          <Route exact path="/documentimanutentore/:id/modificascadenza" component={ModificaScadenzaOffritore}/>

          {/*INTERVENTI CLIENTE */}
          <Route exact path="/interventi" component={Interventi} />
          <Route exact path="/interventi/richiediintervento" component={RichiediIntervento} />
          <Route exact path="/interventi/intervento/:id" component={DettagliIntervento}/>

          {/*INTERVENTI OFFRITORE */}
          <Route exact path="/interventimanutentore" component={InterventiOffritore} />
          <Route exact path="/interventimanutentore/intervento/:id" component={DettagliInterventoOff}/>

          {/*SCADENZE CLIENTE */}

          <Route exact path="/scadenze" component={Scadenze} />
          <Route exact path="/scadenze/scadenza/:id" component={DettagliScadenza}/>

          {/*SCADENZE OFFRITORE */}

          <Route exact path="/scadenzemanutentore" component={ScadenzeOffritore} />
          <Route exact path="/scadenzemanutentore/scadenza/:id" component={DettagliScadenzaOff}/>
          <Route exact path="/scadenzemanutentore/programmamanutenzione" component={RichiediScadenza} />

          {/*MAPPE CLIENTE*/}
          <Route exact path="/mappecliente" component={MappeCliente} />
          <Route exact path="/mappecliente/:user_id/:sede_id/piani" component={PianiMappeCli} />
          <Route exact path="/mappecliente/:user_id/:sede_id/piani/:piano_id/:mappa_id/visualizemappa" component={EditMappa} />

          {/*MAPPE OFFRITORE*/}
          <Route exact path="/mappemanutentore" component={MappeGenerale} />
          <Route exact path="/mappemanutentore/:user_id/:sede_id/piani" component={PianiMappe} />
          <Route exact path="/mappemanutentore/:user_id/:sede_id/piani/:piano_id/aggiungimappa" component={AggiungiMappa} />
          <Route exact path="/mappemanutentore/:user_id/:sede_id/piani/:piano_id/:mappa_id/editmappa" component={EditMappa} />
          <Route exact path="/mappemanutentore/:user_id/:sede_id/piani/:piano_id/:mappa_id/visualizemappa" component={EditMappa} />


          <Route exact path="/clienti" component={Clienti} />

          <Route exact path="/manutentori" component={Manutentori} />


          <Route exact path="/error" component={Error}/>
          <Route exact path="/" component={PreLoginPage} />

          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/register" component={SignUp} />
          <Route exact path="/search" component={SearchOff} />
          
          {//<Route exact path="/calendar" component={CalendaryPage} />
          }
          <Route exact path="/impostazioni" component={Impostazioni}/>
          <Route exact path="/prova" component={ProvaMappe}/>

          </div>

</div>

      </Router>
      
    </AuthProvider>
  );
};

export default App;
