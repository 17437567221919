//popup sottointerventi
import React, { useState, useCallback, useEffect } from "react";
import "./Popup.css";
import firebase from "firebase/app";
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from '@mui/material/Tooltip';
import Commenti from './Commenti';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import EvadiAttività from "./EvadiAttività";
import GenerazionePdf from "../GenerazionePdf";
import { checkWriteS } from "../EngineClienti/engineClienti";
function SubActivity(props) {
  const auth = firebase.auth();
  const db = firebase.firestore();
  const [activity,setActivity] = useState([]) //lista sotto interventi
  const [evAtt, setEvAtt] = useState(); //trigger evadi att
  const [attInfo, setAttInfo] = useState(); //info per att

  useEffect(() => {
    auth.onAuthStateChanged(function (user) {
      if (user) {
      getActs(auth.currentUser.email)
        
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    })},[auth]); //chiama get acts

    function getActs(email){
      var acts = [] //att normali
      var acts2 = [] //pianificate
      db.collection("Attività")
      .doc(props.info.Id)
      .collection('Attività')
      .onSnapshot((querySnapshot)=>{
        querySnapshot.docChanges().forEach((act)=>{
          if(act.type==="added"){

            acts.push({
              key: act.doc.id,
              ...act.doc.data()
            })
           
          }
        })
        setActivity(acts)
      })
    
    } //prende le sottoattivita
    function popEvadi(info) { //apre il popup per evadere
      setAttInfo(info)

      setEvAtt(true)

    } //triggera evasione

    function generaPdf(activity){
      db.collection("Users")
      .doc(activity.Op_Email)
      .get()
      .then((operatoreDoc) => {
        var operatoreData = operatoreDoc.data();
        console.log(operatoreDoc);
        var operatore = {
          Nome_Azienda: operatoreData.Nome_Azienda,
          Nome: operatoreData.Nome,
          Cognome: operatoreData.Cognome,
          Indirizzo: operatoreData.Indirizzo,
          Sede: operatoreData.Comune,
          Telefono: operatoreData.Numero,
          Email: operatoreData.Email
        };
        db.collection("Elementi").doc(activity.Oggetto).get().then((elementoDoc)=>{
          if(elementoDoc.exists){
            GenerazionePdf.generaPdfIntervento(activity, operatore, [
              { Nome: elementoDoc.data().Nome, Esito: activity.Esito },
            ]);
          }else{
            GenerazionePdf.generaPdfIntervento(activity, operatore, [
              { Nome: activity.Oggetto, Esito: activity.Esito },
            ]);
          }

          
        })
        
      });
    } //genera pdf

  return props.trigger ? (
    <div className="pop-opac">
            
    <div className="popup" style={{backgroundColor:"white", minHeight:'80vh', height:'fit-content', width:'72vw', left:'18vw'}} >
      {evAtt===true&&<EvadiAttività
      info={props.getInfo}
      data={attInfo}
      sede={props.sede}
      cliente={attInfo.Cliente}
      trigger={evAtt}
      setTrigger={setEvAtt}
      getAtt={getActs}
      userSede={props.sede}
      sede_cli={attInfo.Sede_cli}
      email={props.getInfo.Email}
      sottoRef={props.info.Id}
      acts={activity}
      setActivity={setActivity}
      main={props.info}
      setScad={props.setActivity}
      keyy={attInfo.key}
      reGet={props.reGet}

                        />}
    <div style={{textAlign:'left', width: 'fit-content'}}>
            <button
              className="cancelIconButton"
              
              onClick={() => props.setTriggerPop(false)}
            >
              <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
            </button>
            </div>
        <h2   className="page-title" style={{marginLeft:'2rem',marginTop:'0.5rem'}}>INTERVENTI CORRETTIVI</h2>
            <div style={{display:'flex',alignItems:'center'}}>
            <h2 style={{marginLeft:'2rem',}}  className="sub-text">Intervento selezionato: </h2>
             <h2  style={{marginLeft:'0.5rem'}}  className="normal-text">{props.info.Titolo}</h2>
            
            </div>
            <div style={{marginLeft:'2rem',display:'flex',alignItems:'center'}}>
            <h2 className="sub-text">Sede: </h2>
             <h2  style={{marginLeft:'0.5rem'}}  className="normal-text">{props.sede} {props.info.Indirizzo?'- '+props.info.Indirizzo:null}</h2>
            <h2   className="sub-text" style={{marginLeft:'3rem'}}>Cliente: </h2>
             <h2   style={{marginLeft:'0.5rem'}}  className="normal-text">{props.tipo==='Lavoratore'?(
            props.info.Nome_Azienda + ' ( ' + props.info.Cliente + ' )'
           ):( 
           props.info.Operatore?props.info.Operatore:null
            )}</h2>
            </div>
            <div style={{height:'55vh',width:'70vw',marginTop:'1vh',marginLeft:'2vw',overflowY:'auto'}}>
            <div  style={{display:'flex',alignItems:'center'}}>
                  <h2 style={{ fontSize: "1.5vw",
              color: "#5C6672",
              border:'1px solid lightgrey',
              borderRight:'none',
              fontWeight: "normal",
              fontFamily: "Poppins",
              letterSpacing: "-0.1vw",
              marginBottom: 0,
              marginTop: "1vh",
          
              padding:5,
              textAlign:'center',
              width: "15vw",}}>TITOLO</h2>
               <h2 style={{ fontSize: "1.5vw",
              color: "#5C6672", border:'1px solid lightgrey',
              borderRight:'none',
              fontWeight: "normal",
              fontFamily: "Poppins",
              marginBottom: 0,
              marginTop: "1vh",
              textAlign:'center',
              maxWidth:'17vw',              padding:5,

              width:'17vw',
              }}>DESCRIZIONE</h2>
                
                <h2 style={{ fontSize: "1.5vw",
              color: "#5C6672", border:'1px solid lightgrey',
              borderRight:'none',
              fontWeight: "normal",
              fontFamily: "Poppins",
              letterSpacing: "-0.1vw",              padding:5,
            
              marginBottom: 0,
              marginTop: "1vh",
              width: "10vw",              textAlign:'center',
            }}>STATO</h2>
               <h2 style={{ fontSize: "1.5vw",
              color: "#5C6672", border:'1px solid lightgrey',
            
              fontWeight: "normal",
              fontFamily: "Poppins",
              letterSpacing: "-0.1vw",              padding:5,

              marginBottom: 0,
              marginTop: "1vh",
                textAlign:'center',

              width: "8vw",}}>AZIONI</h2>
                </div>
              {activity.length>0?activity.map((act,index)=>(
                <div key={index} style={{display:'flex',alignItems:'center',padding:1,}}>
                  <h2 
                  onClick={()=>generaPdf(act)}
                  style={{ fontSize: "1.3vw",
              color: "#118ab2",
              fontWeight: "bold",
              fontFamily: "Poppins",
              letterSpacing: "-0.1vw",
              marginBottom: 0,
              marginTop: "1vh",padding:5,
              textAlign:'center',
              width: "15vw",maxWidth:'15vw'}}>{act.Titolo}</h2>
               <h2 style={{ fontSize: "1vw",
              color: "black",
              fontWeight: "normal",
              fontFamily: "Poppins",
              marginBottom: 0,
              marginTop: "1vh",
              maxWidth:'17vw',padding:5,
              width:'17vw',textAlign:'center',
              }}>{act.Descrizione} </h2>
                
                <h2 style={{ fontSize: "1vw",
              color: "black",
              fontWeight: "normal",
              fontFamily: "Poppins",
              letterSpacing: "-0.1vw",padding:5,
              marginBottom: 0,textAlign:'center',
              marginTop: "1vh",
              width: "10vw",}}>{act.Stato}</h2>
                           {checkWriteS(props.getInfo.Permissions)===true? <div style={{width:'10vw'}}>
                            {act.Stato==='In attesa'&&(props.tipo==='Lavoratore'||props.tipo==='Lavoratore')?<label onClick={(e) => {
    
                        }} style={{fontSize:'0.6vw', color:'#ef476f', cursor: 'pointer', fontWeight:'bold',borderBottom:'0.5px solid #ef476f',fontFamily:'Poppins'}}>Pianifica</label>
                            :act.Stato==='Pianificata'&&(props.tipo==='Lavoratore'||props.tipo==='Lavoratore')?<h2 onClick={(e) => {
                              popEvadi(act);
        
                            }} style={{fontSize:'0.8vw', color:'#fb8500', cursor: 'pointer', fontWeight:'bold',borderBottom:'0.5px solid #fb8500',fontFamily:'Poppins',marginLeft:'auto',width:'fit-content',marginRight:'auto',marginTop:0,marginBottom:0,padding:5}}>Evadi</h2>
                            :null}
                            <div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content',}}>
                            
                            {act.Allegati!==undefined&&<Tooltip title="Allegati" style={{width:'fit-content'}}>
                              <IconButton onClick={()=>{}}>
                                <FilePresentIcon/>
                              </IconButton>
                                </Tooltip>}

                            </div>
                           
                            </div>:null}
                </div>
              )):<h2 style={{color:'black'}}>Nessun intervento</h2>}
            </div>
    </div>
    </div>
  ) : (
    ""
  );
}

export default SubActivity;
