import { Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";

function Month(props){

    const [tDays,setTDays] = useState([]) //giorni del mese

    const names = ['Domenica','Lunedì', 'Martedì', 'Mercoledì','Giovedì','Venerdì','Sabato']

    let today = new Date()
    let activeDay;
    let month = props.date.Month
    let year = props.date.Year

    useEffect(()=>{
        initCalendario()
       /* if(props.date.Month!==null||props.date.Month!==undefined){
            if(props.date.Month===12){
                getT(1,props.date)
 
            }else{
                getT(props.date.Month+1,props.date)

            }
        }*/
    },[props.date.Month])


    function initCalendario(){
        setTDays([])
        const firstDay = new Date(year,month,1) //primo giorno di questo mese
        const lastDay = new Date(year,month+1, 0) //ultimo giorno di questo mese
        const prevLastDay = new Date(year,month,0) //ultimo giorno del mese precedente
        const prevDays = prevLastDay.getDate(); //numero dell'ultimo giorno del mese precedente
        const lastDate = lastDay.getDate() //numero dell'ultimo giorno del mese
        const day = firstDay.getDay()-1<0?6:firstDay.getDay()-1 //numero del primo giorno del mese
        const nextDays = 7 - lastDay.getDay() 

        var count = 0

        //giorni del mese precedente
        for(let x = day; x>0; x--){
            count++
            setTDays((prev)=>{
                return [
                    ...prev,
                    {
                        Type: 'prev',
                        Number: prevDays-x+1

                    }
                ]
            })

        }
        //giorni di questo mese

        for(let i = 1; i<=lastDate; i++){
            count++

            setTDays((prev)=>{
                return [
                    ...prev,
                    {
                        Type: 'curr',
                        Number: i

                    }
                
                ]
            })
        }

        //giorni del prossimo mese
        if(nextDays!==7){
            for(let j=1; j<= nextDays; j++){
                if(count===35){
    
                }else{
                    count++
    
                    setTDays((prev)=>{
                        return [
                            ...prev,
                            {
                                Type: 'next',
                                Number:j
        
                            }
                            
                        ]
                    })
                }
    
            }
        }
        

    }

    function returnAnn(date){
        var arr = props.annotazioni
        const monthTo=date.Type==='prev'?month-1:date.Type==='next'?month+1:month
        const list = arr.filter((ann)=> parseInt(ann.data.Day,10)===parseInt(date.Number,10)&&parseInt(ann.data.Month-1,10)===parseInt(monthTo,10)&&parseInt(ann.data.Year,10)===parseInt(year,10))
        if(list.length>0){
            return list

        }else{
            return false
        }
    }


    return <div style={{width:'100%',height:'25rem',marginTop:'1rem',borderRadius:10,display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignContent: "flex-start",}}>
        <div style={{display:'flex',alignItems:'center',width:'100%'}}>
        <div style={{width:'14.18%',borderTopLeftRadius:10,borderLeft:'1px solid rgba(132, 139, 200, 0.18)',borderTop:'1px solid rgba(132, 139, 200, 0.18)',backgroundColor:'white'}}>
        <h4 className="sub-text" style={{marginTop:5,marginBottom:5,width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'#5C6672'}}>Lunedì</h4>
        </div>
        <div style={{width:'14.18%',borderLeft:'1px solid rgba(132, 139, 200, 0.18)',borderTop:'1px solid rgba(132, 139, 200, 0.18)',backgroundColor:'white'}}>
        <h4 className="sub-text" style={{marginTop:5,marginBottom:5,width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'#5C6672'}}>Martedì</h4>
        </div>
        <div style={{width:'14.18%',borderLeft:'1px solid rgba(132, 139, 200, 0.18)',borderTop:'1px solid rgba(132, 139, 200, 0.18)',backgroundColor:'white'}}>
        <h4 className="sub-text" style={{marginTop:5,marginBottom:5,width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'#5C6672'}}>Mercoledì</h4>
        </div>
        <div style={{width:'14.18%',borderLeft:'1px solid rgba(132, 139, 200, 0.18)',borderTop:'1px solid rgba(132, 139, 200, 0.18)',backgroundColor:'white'}}>
        <h4 className="sub-text" style={{marginTop:5,marginBottom:5,width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'#5C6672'}}>Giovedì</h4>
        </div>
        <div style={{width:'14.18%',borderLeft:'1px solid rgba(132, 139, 200, 0.18)',borderTop:'1px solid rgba(132, 139, 200, 0.18)',backgroundColor:'white'}}>
        <h4 className="sub-text" style={{marginTop:5,marginBottom:5,width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'#5C6672'}}>Venerdì</h4>
        </div>
        <div style={{width:'14.18%',borderLeft:'1px solid rgba(132, 139, 200, 0.18)',borderTop:'1px solid rgba(132, 139, 200, 0.18)',backgroundColor:'white'}}>
        <h4 className="sub-text" style={{marginTop:5,marginBottom:5,width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'#5C6672'}}>Sabato</h4>
        </div>
        <div style={{width:'14.18%',borderLeft:'1px solid rgba(132, 139, 200, 0.18)',borderRight:'1px solid rgba(132, 139, 200, 0.18)',borderTop:'1px solid rgba(132, 139, 200, 0.18)',backgroundColor:'white',borderTopRightRadius:10}}>
        <h4 className="sub-text" style={{marginTop:5,marginBottom:5,width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'#5C6672'}}>Domenica</h4>
        </div>
        </div>
        {tDays.map((n,index)=>(
            <div key={index} onClick={()=>{props.setSelectedWeek(parseInt(index/7,10));props.setVisualization(1)}} style={{width:'14.18%',height:tDays.length<=35?'4.55rem':'3.8rem',borderTop:'1px solid rgba(132, 139, 200, 0.18)',borderLeft:'1px solid rgba(132, 139, 200, 0.18)',
            backgroundColor:n.Type==='curr'?'white':'whitesmoke',borderBottomLeftRadius:index===tDays.length-7?10:0,borderBottomRightRadius:index===tDays.length-1?10:0}}>
                <h4 style={{marginBottom:0,color:n.Type==='curr'?'black':'#5C6672',marginLeft:2,marginTop:2,fontFamily:'Poppins',fontSize:'0.8rem',fontWeight:'normal'}}>{n.Number} {}</h4>
                {returnAnn(n)!==false?
                    
                    <div style={{marginTop:3}}>
                        <Tooltip title={returnAnn(n)[0].data.Subject}>
                        <h4 style={{whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',maxWidth:'9vw', fontSize:'0.7rem',margin:0, backgroundColor:'#192bc2',color:'white',borderRadius:5,padding:1,fontFamily:'Poppins',fontWeight:'normal'}}>{returnAnn(n)[0].data.Subject}</h4>
      </Tooltip>
      {returnAnn(n).length>1? <h4 style={{whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',maxWidth:'9vw', fontSize:'0.7rem',margin:0,borderRadius:5,padding:1,paddingLeft:2,color:'#192bc2'}}>+ {returnAnn(n).length-1}</h4>:null}
                    </div>
                :null}
            </div>
        ))}

    </div>
}

export default Month
