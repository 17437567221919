//popup menu excel
import React, { useState, useEffect } from "react";
import '../../Popups/Popup.css'
import CancelIcon from "@material-ui/icons/Cancel";
import { checkWriteS } from "../../EngineClienti/engineClienti";
const ExcelMenu = (props) => {
  const onSubmit = (e) => {
    e.preventDefault();
  };

  return props.trigger ? (
    <div className="pop-opac" style={{ width: "100vw" }}>
      <div
        className="popup"
        style={{
          backgroundColor: "white",
          width: "40vw",
          left: "30vw",
          padding: "1vw",
          height: "40vh",
          top: "20vh",
        }}
      >
        <div style={{ textAlign: "left", width: "30vw" }}>
          <button
            className="cancelIconButton"
            onClick={() => props.setTrigger(false)}
          >
            <CancelIcon
              style={{ width: "15px", height: "15px" }}
              className="cancelIcon"
            />
          </button>
        </div>
        <h4
          style={{
            fontSize: "2.5vw",
            color: "#25a18e",
            fontWeight: "bold",
            fontFamily: "Poppins",
            letterSpacing: "-0.1vw",
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 0,
            width: "fit-content",
          }}
        >
          IMPOSTAZIONI EXCEL
        </h4>
        <div
          style={{
            width: "fit-content",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
        {checkWriteS(props.user.Permissions)===true?  <form
            onSubmit={onSubmit}
            style={{
              cursor: "pointer",
              height: "fit-content",
              marginTop: "3vh",
              marginBottom: "1vh",
            }}
          >
            <label
              className="animate-buttonE"
              style={{
                borderRadius: 5,
                padding: 10,
                marginLeft: "auto",
                marginRight: "auto",
                border: "1px solid #5C6672",
                color: "#5C6672",
                backgroundColor: "white",
                fontFamily: "Poppins",
                fontSize: "0.8vw",
                width: "fit-content",
                cursor: "pointer",
              }}
            >
              IMPORTA ELEMENTI
              <input
                type="file"
                multiple
                onChange={(e) => props.importExcel(e.target.files[0])}
                style={{
                  opacity: 0,
                  position: "absolute",
                  backgroundColor: "blue",
                }}
              />
            </label>
          </form>:null}
          <button
            onClick={() => props.onBtnClickEx()}
            className="animate-buttonE"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "3vh",
              border: "1px solid #5C6672",
              color: "#5C6672",
              backgroundColor: "white",
              fontFamily: "Poppins",
              fontSize: "0.8vw",
              width: "fit-content",
              cursor: "pointer",
            }}
          >
            ESPORTA ELEMENTI
          </button>
          <br />
          <button
            onClick={() => props.routeChange(1)}
            className="animate-buttonE"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "3vh",
              border: "1px solid #5C6672",
              color: "#5C6672",
              backgroundColor: "white",
              fontFamily: "Poppins",
              fontSize: "0.8vw",
              width: "fit-content",
              cursor: "pointer",
            }}
          >
            SCARICA TEMPLATE
          </button>
          <br />
          <button
            onClick={() => props.routeChange(0)}
            className="animate-buttonE"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "3vh",
              border: "1px solid #5C6672",
              color: "#5C6672",
              backgroundColor: "white",
              fontFamily: "Poppins",
              fontSize: "0.8vw",
              width: "fit-content",
              cursor: "pointer",
            }}
          >
            SCARICA ISTRUZIONI
          </button>
          <br />
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default ExcelMenu;
