//popup lista mancanze
import React, { useEffect, useState } from "react";
import "./Popup.css";
import CancelIcon from '@material-ui/icons/Cancel';
import firebase from "firebase/app";

function Mancanze(props) {

  const auth = firebase.auth();
  const db = firebase.firestore();

  const [email,setEmailz] = useState()
  const [mancanze,setMancanze] = useState([])

  useEffect(()=>{
    auth.onAuthStateChanged(function (user) {
      if (user) {
        if(props.email&&props.sede){
          setEmailz(props.email)
          getMancanze(props.email)
        }
      

      }
    });
  },[auth])


  function getMancanze(email){
    db.collection('Users').doc(email).collection('Sedi').doc(props.sede.Id).collection('Dipendenze').get().then((dips)=>{
      dips.forEach((dx)=>{
        db.collection('Users').doc(email).collection('Sedi').doc(props.sede.Id).collection('Dipendenze').doc(dx.id).collection('Sezioni').get().then((sez)=>{
          sez.forEach((sx)=>{
            db.collection('Users').doc(email).collection('Sedi').doc(props.sede.Id).collection('Dipendenze').doc(dx.id).collection('Sezioni')
            .doc(sx.id).collection('Dipendenze').get().then((dipendenze)=>{
              dipendenze.forEach((dip)=>{
                if(dip.data().Checked===false){
                  setMancanze((prev)=>{
                    return [
                      ...prev,
                      dip.data()
                    ]
                  })
                }
              })
            })
          })
        })
      })
    })  
    }

  return props.trigger ? (
    <div className="pop-opac">
        {console.log(props.manc)}
    <div className="popup" style={{backgroundColor:"transparent",width:'40vw', marginTop:"30px", height:'60vh', oveflowY: "auto",left:'30vw'}} >
    
      <div className="popup-inner" style={{border:"1px solid lightgrey",borderRadius:"15px",backgroundColor:"white", width:'40vw', height:"60vh" }}>
    
      <div style={{textAlign:'left', width: '100%'}}>
      <button
              className="cancelIconButton"
              
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
            </button>
            </div>
            <h4 className="page-title" style={{margin:0,marginLeft:'2rem'}}> MANCANZE </h4>
            <h4 className="sub-text-b" style={{marginLeft:'2rem'}}> {props.sede.Comune}</h4>
           <div >
               {mancanze.length > 0?(
                   <ul style={{height:'46vh', overflowY:'auto', marginLeft:'1vw'}}>
                       {mancanze.map((manc, index)=>(
                           <li key={index}>
                               <h4 className="header-normal">{manc.Nome}</h4>
                               <h4 className="sub-text">{manc.Sezione}</h4>

                           </li>
                       ))}
                   </ul>
               ):null}
           </div>
           

      </div>
    </div>
    </div>
  ) : (
    ""
  );
}

export default Mancanze;
