

export function deleteTag(name,auth,tipo,masterAcc,db,sedi,index,setSottoSede){
    var email="";
    if(tipo!=='SottoAccount'){
      email=auth.currentUser.email;
    }else{
      email=masterAcc
    }
   
      db.collection("Users").doc(email).collection("Sedi").doc(name).update({
        Tag: ""
      }).then(()=>{
        const arr = [...sedi];
        if(arr[index].Tag){
        arr[index].Tag = ""
        }
          setSottoSede(arr)
        alert('Tag eliminato')
      })
  
  
  } //funzione per eliminare tag sede

export async function deleteSedez(key, name, email, db, auth,sedi,setSedi){
 
    if(window.confirm("Vuoi davvero eliminare la sede "+name+" ? Eliminerai anche tutti i documenti, interventi, mappe e attività correlate ad essa.")){
      
      return  db.runTransaction(async(transaction)=>{
        return transaction.get(db.collection("Users").doc(email)).then((sfDoc)=>{
          var newcreate = sfDoc.data().Sedi_Create -1;
          transaction.update(db.collection("Users").doc(email),{
            Sedi_Create: newcreate
          })
    
          transaction.delete(db.collection("Users").doc(email).collection("Sedi").doc(key));
      
        })
        
     
      }).then(()=>{
        
        var arr = [...sedi]
        const index = arr.findIndex((e)=>e.Key===key)
        arr.splice(index,1)
        setSedi(arr)
        alert("Sede "+name+ " correttamente eliminata!")})
      
    }
  } //funzione per eliminare sede

export  function activeTag(name,index,setTagSede,setIndex,setTag){
    setTagSede(name)
    setIndex(index)
    setTag(true)
  } //funzione per aggiungere tag sede

export function activeModSede(key,n,i,nu,setModSede,setNomeN,setIndirizzoN,setTelefonoN,setTriggerModSede){
    console.log(key, n, i, nu)
    setModSede(key)
    setNomeN(n)
    setIndirizzoN(i)
    setTelefonoN(nu)
    setTriggerModSede(true)
  } //funzione per attivare la modifica sede

export function setSedePredef(sede, nome,db,auth){

    db.collection('Users').doc(auth.currentUser.email).update({
      sedePredef: sede,
    }).then(()=>{
      alert(nome +' impostata come sede principale!')
    })
  } //funzione per impostare la sede come predefinita

export function getSedi(email,db,setSottoSede,setAskDel,setPerc,setVerified,setSedi,setRiep,setProps){
    console.log(email)
    var arr = []
    var c1=0;
    var c2=0;
    var c3 = 0

    var arr2 = []
     
        
        db.collection("Users") //prende le sedi
        .doc(email)
        .collection("Sedi")
        .get().then((querySnapshot) => {
          
          querySnapshot.forEach((sedea) => {
              setAskDel((prev)=>{
                return [
                  ...prev,
                  {
                    ask: false,
                  }
                ]
              })
              var tot = 0;
              var counted = 0;
              var sede = sedea;
            arr2.push({ Nome: sede.data().Comune, Tag: sede.data().Tag, Planimetrie: sede.data().Planimetrie, Schemi: sede.data().Schemi, Conf: sede.data().Conf, RelazioneTecnica: sede.data().RelazioneTecnica,
                MaterialiUtilizzati: sede.data().MaterialiUtilizzati,
                VisuraCamerale: sede.data().VisuraCamerale,
                Manuale:sede.data().Manuale,
                PrimaVerifica: sede.data().PrimaVerifica,
                OmologazioneImpianto: sede.data().OmologazioneImpianto,
                Indirizzo: sede.data().Indirizzo,
                Numero: sede.data().Numero,

              Key: sede.id });
            ;
            //controlla quali mancano
            if(sede.data().Conf===false){
              tot++
            }else{
              counted++;
              tot++
            }
            if(sede.data().Schemi===false){
              tot++
            }else{
              counted++;
              tot++;
            }
            if(sede.data().RelazioneTecnica===false){
             tot++
            }else{
              counted++;
              tot++;
            }
            if(sede.data().Planimetrie===false){
             tot++
            }else{
              counted++;
              tot++;
            }
            if(sede.data().MaterialiUtilizzati===false){
             tot++
            }else{
              counted++;
              tot++;
            }
            if(sede.data().VisuraCamerale===false){
              tot++
            }else{
              counted++;
              tot++;
            }
            if(sede.data().Manuale===false){
            tot++
            }else{
              counted++;
              tot++;
            }
            if(sede.data().PrimaVerifica===false){
             tot++
            }
            else{
              counted++;
              tot++;
            }
            if(sede.data().OmologazioneImpianto===false){
             tot++
            }
            else{
              counted++;
              tot++;
            }
          
            setPerc((prev)=>{return [...prev, (counted/tot)*100]})
           
            //imposta il layout di verifica
            if(sede.data().Conf===true&&sede.data().Schemi===true&&sede.data().Planimetrie===true&&sede.data().RelazioneTecnica===true&&sede.data().MaterialiUtilizzati===true&&sede.data().VisuraCamerale===true&&sede.data().Manuale===true&&sede.data().PrimaVerifica===true&&sede.data().OmologazioneImpianto===true){
              setVerified((prev) => {
                return [...prev, 0 ]; //apposto
              });
              c1=c1+1
            }else if(sede.data().Conf===false||sede.data().Schemi===false||sede.data().Planimetrie===false){
              setVerified((prev) => {
                return [...prev, 1 ]; //non apposto
              });
              c2=c2+1
            }else{
              setVerified((prev) => {
                return [...prev, 2 ]; //parzialmente apposto
              });
              c3=c3+1
            }
        })
       setSedi(arr2)
       setSottoSede(arr2)
       setProps(arr2)
       var arrk = []
       arrk.push(c1)
       arrk.push(c2)
       arrk.push(c3)
      
       setRiep(arrk)

      })
      
} //funzione che prende le sedi
export function getCategorie(email,db,setCat,setRiepC,setSedi){
  setCat([])
  var d1=0;
  var d2=0;
  var d3 = 0
  var arr = []

    db.collection('Users').doc(email).collection('Sedi').get().then((sedi)=>{
      sedi.forEach((sede)=>{
       
          db.collection('Users').doc(email).collection('Sedi').doc(sede.id).collection('Categorie').get().then((categorie)=>{
            categorie.forEach((cat)=>{
              setCat((prev)=>{return[
                ...prev,
                {
                  Nome: cat.data().Nome,
                  Sede: sede.data().Comune
                }
              ]})

              if(cat.data().Nome==='Elettrico'){
                d1 = d1 + 1
              }else if(cat.data().Nome==='Antincendio'){
                d2 = d2 + 1
              }else{
                d3 = d3 + 1
              }
              var arrL = []

              arrL.push(d1)
              arrL.push(d2)
              arrL.push(d3)
              setRiepC(arrL)
            })
            
          })

      })
    })
} //funzione che prende le categorie
export function getSottoAccount(setSottA,db,email,setRiepS){
  var arr = []
  var c1=0;
  var c2=0;
  var c3 = 0
  setSottA([])
  db.collection('Users').doc(email).collection('SottoAccounts').get().then((doc)=>{
  doc.forEach((docz,index)=>{
            if(index===0){
              setSottA([])
            }
            if(docz.data().Ruolo==='Lettore'){
              c1 = c1 + 1
            }else if(docz.data().Ruolo==='Scrittore'){
              c2 = c2 + 1
            }else{
              c3 = c3 + 1
            }
            setSottA((prev)=>{
              return[
                ...prev,
                docz.data()
              ]
            })
          })
          arr.push(c1)
          arr.push(c2)
          arr.push(c3)
          setRiepS(arr)
    
        })
} //funzione che prende i sottoaccount
export function getNotifiche(db,email,setNotifiche){
  setNotifiche([])
  db.collection("Users")
  .doc(email)
  .collection("Notifiche")
  .orderBy("Data", "asc")
  .get().then((notz) => {
    notz.forEach((not, index) => {
      if (index === 0) {
        setNotifiche([]);
      }
      setNotifiche((prev) => {
        return [
          ...prev,
          {
            ...not.data(),
            NotID: not.id,
          },
        ];
      });
    });
  });
} //funzione che prende le notifiche
export function getClienti(db,email,setClienti){
  db.collection('Users').doc(email).collection('Clienti').onSnapshot((clients)=>{
    clients.docs.forEach((cliente,index)=>{
      if(index===0){
        setClienti([])
      }
      db.collection('Users').doc(cliente.data().email).get().then((cl)=>{
        setClienti((prev)=>{
          return [
            ...prev,
            cl.data()
          ]
        })
      })
    })
  })
}//funzione che prende i clienti per chi è offritore
export function getClientiReq(db,email,setRichieste){
  db.collection('Users').doc(email).collection('Richieste').onSnapshot((clients)=>{
    clients.docs.forEach((richieste,index)=>{
      if(index===0){
        setRichieste([])
      }
        setRichieste((prev)=>{
          return [
            ...prev,
            richieste.data()
          ]
        })
      
    })
  })
}//funzione che prende le richieste dei clienti
export function acceptReq(db,email,emailCli,setRichieste,richieste,index){
  db.collection('Users').doc(email).get().then((user)=>{
    if(user.data().Current_Cli<user.data().Max_Cli){
      db.collection('Users').doc(email).collection('Clienti').doc(emailCli).set({
        email:emailCli,
        Ele: 0,
        Doc: 0,
        Att:0,
        Man:0,
        Mapp:0
      })
      db.collection('Users').doc(email).collection('Richieste').doc(emailCli).delete()
    
      db.collection('Users').doc(emailCli).collection('Offritori').doc(email).update({
        Stato:'accepted'
      })

      db.collection('Users').doc(email).update({
        Current_Cli: parseInt(user.data().Current_Cli,10) + 1
      })
      var arr = [...richieste]
      arr.splice(index,1)
      setRichieste(arr)
    
      alert('Richiesta accettata! Ora potrai gestire la sicurezza del tuo cliente.')
      
    }else{
      alert('Il tuo piano Mysic non ti permette di aggiungere ulteriori clienti, fai un upgrade del tuo piano per poterne aggiungere degli ulteriori.')
    }
    
  })
  



}//funzione che accetta le richieste dei clienti
export function denyReq(db,email,emailCli,setRichieste,richieste,index){
  db.collection('Users').doc(emailCli).collection('Offritori').doc(email).delete() //elimina doc al cliente
  db.collection('Users').doc(email).collection('Richieste').doc(emailCli).delete() //elimina richiesta all'utente

  var arr = [...richieste]
  arr.splice(index,1)
  setRichieste(arr)

  alert('Richiesta rifiutata.')

}//funzione che accetta le richieste dei clienti
export function getCompetenze(db,email,setLingue,setTerritorio,setCapacita){
  
  db.collection('Users').doc(email).onSnapshot((ling)=>{
    //ling è documento lingue che contiene nel parametro Lingue un array con le lingue
    if(ling.data())
    {
      if(ling.data().Lingue){
      ling.data().Lingue.forEach((l,index)=>{
      if(index===0){
        setLingue([])
      }
      setLingue((prev)=>{
        return[
          ...prev,
          l
        ]
      })
    })
  

  }else{
    setLingue([])
  }}
  })
  db.collection('Users').doc(email).onSnapshot((terr)=>{
    //terr è documento territorio che contiene nel parametro territori un array con i territori in cui si opera
    if(terr.data()){
      if(terr.data().Territori){

      terr.data().Territori.forEach((t,index)=>{
      if(index===0){
        setTerritorio([])
      }
      setTerritorio((prev)=>{
        return[
          ...prev,
          t
        ]
      })
    }
    )}else{
      setTerritorio([])
    }
  }
  })
  db.collection('Users').doc(email).onSnapshot((cap)=>{
    //cap è documento capacità che contiene nel parametro capacità un array con le capacità
    
    if(cap.data()){
      if(cap.data().Capacità){
      cap.data().Capacità.forEach((c,index)=>{
      if(index===0){
        setCapacita([])
      }
      setCapacita((prev)=>{
        return[
          ...prev,
          c
        ]
      })
    })}else{
      setCapacita([])
  
    }
  }
  })
}//funzione che prende le competenze
export function addLingua(db,email,lingua,lingue){
  let arr =[...lingue]
  arr.push(lingua)
  if(lingue.indexOf(lingua)===-1)
  {if(lingua!==null&&lingua!==''){
   
      db.collection('Users').doc(email).update({
        Lingue: arr
      })
      alert('Lingua aggiunta!')

  }else{
    alert('Seleziona una lingua!')
  }}else{
    alert('Lingua già aggiunta')
  }
} //funzione per aggiungere la lingua
export function deleteLingua(db,email,lingua,lingue){
  let arr =[...lingue]
  arr.splice(lingue.indexOf(lingua),1)
  db.collection('Users').doc(email).update({
    Lingue: arr
  })
  alert('Lingua rimossa!')
} //funzione per eliminare la lingua
export function addTerritorio(db,email,territorio,territori){
  let arr =[...territori]
  arr.push(territorio)
  if(territori.indexOf(territorio)===-1)
  {if(territorio!==null&&territorio!==''){
    
      db.collection('Users').doc(email).update({
        Territori: arr
      })
      alert('Territorio aggiunto!')
  }else{
    alert('Devi aggiungere un territorio!')
  }}else{
    alert('Territorio già aggiunto')
  }
} //funzione per aggiungere i territori
export function deleteTerritorio(db,email,territorio,territori){
  let arr =[...territori]
  arr.splice(territori.indexOf(territorio),1)
  db.collection('Users').doc(email).update({
    Territori: arr
  })
  alert('Territorio rimosso!')
} //funzione per eliminare i territori
export function addCapacita(db,email,capacita,capacitas){
  let arr =[...capacitas]
  arr.push(capacita)
  if(capacitas.indexOf(capacita)===-1)
  {if(capacita!==null&&capacita!==''){
   
      db.collection('Users').doc(email).update({
        Capacità: arr
      })
      alert('Capacità aggiunta!')

  db.collection('Capacità').doc(capacita).get().then((cap)=>{
    if(cap.data()){
      db.collection('Capacità').doc(capacita).collection('Offritori').doc(email).set({
        email: email
      })

    }else{
      db.collection('Capacità').doc(capacita).set({
        Nome: capacita,
        Click: 0,
      })
      db.collection('Capacità').doc(capacita).collection('Offritori').doc(email).set({
        email: email
      })
    }
  })
  }else{
    alert('Devi aggiungere una capacità!')
  }}else{
    alert('Capacità già aggiunta')
  }
} //funzione per aggiungere i territori
export function deleteCapacita(db,email,capacita,capacitas){
  let arr =[...capacitas]
  arr.splice(capacitas.indexOf(capacita),1)
  db.collection('Users').doc(email).update({
    Capacità: arr
  })
  db.collection('Capacità').doc(capacita).collection('Offritori').doc('email').delete()
  alert('Capacità rimossa!')
} //funzione per eliminare i territori
export function getOff(db,email,setOffritori){
  db.collection('Users').doc(email).collection('Offritori').onSnapshot((offs)=>{
    offs.docs.forEach((off,index)=>{
      if(index===0){
        setOffritori([])
      }
      console.log(off.data())
      db.collection('Users').doc(off.data().Email).get().then((cl)=>{
        setOffritori((prev)=>{
          return [
            ...prev,
            {
              data:  cl.data(),
              stato: off.data().Stato
            }
           
          ]
        })
      })
    })
  })
} //prende la  lista di offritori
export function annullaRichiesta(db,email,setOffritori,emailOff,offritori,index){
  db.collection('Users').doc(email).collection('Offritori').doc(emailOff).delete() //elimina doc sull'utente

      db.collection('Users').doc(emailOff).collection('Richieste').doc(email).delete()


  alert('Richiesta annullata')
  var arr = [...offritori]
  arr.splice(index,1)
  setOffritori(arr)


} //annulla richiesta inviata
export function getScadenzaRecenti(db,email,setScadenze){
  const date = new Date()
  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()
  var arr = []
  setScadenze([])

  db.collection("Users")
  .doc(email)
  .collection("Annotazioni")
  .onSnapshot((notz) => {
    notz.docs.forEach((not, index) => {
      
      for(let i=0;i<16;i++){
        if((day + i) <=31){ //non cambia il mese

          if(day<=parseInt(not.data().Day,10)&&parseInt(not.data().Day,10)<day+15&&(month+1)===parseInt(not.data().Month,10)&&year===parseInt(not.data().Year,10)){
            const isFound = arr.some(element => {
              if (element.ScadID === not.id) {
                return true;
              }
              return false;
            });
            console.log('esito',isFound)
            if(isFound===false){
              arr.push({ScadID:not.id, ...not.data()})
              setScadenze((prev) => {
                return [
                  ...prev,
                  {
                    ...not.data(),
                    ScadID: not.id,
                  },
                ];
              });
            } 
          }
        }else{ //cambia il mese
          if((31-day+i)>=parseInt(not.data().Day,10)&&parseInt(not.data().Month,10)===month+2){
            const isFound = arr.some(element => {
              if (element.ScadID === not.id) {
                return true;
              }
              return false;
            });
            if(isFound===false){
              arr.push({ScadID:not.id, ...not.data()})

            setScadenze((prev) => {
              return [
                ...prev,
                {
                  ...not.data(),
                  ScadID: not.id,
                },
              ];
            });
          }
          }
        } 
      }
     
        
      
      
    });
  });
} //prende le scadenze recenti

export function prendiSedi(db,email,n_sedi,setSedi){
  var arr = []
  var cx = 0
  var sx = 0
  var dx = 0

  setSedi([])
  db.collection('Users').doc(email).collection('Sedi').get().then((sedi)=>{
    sedi.docs.forEach((sed)=>{
      var n = 0
      var sede = sed.id
      db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Dipendenze').get().then((cats)=>{
        if(cats.docs.length>0){
          cx = 0
        cats.docs.forEach((c,i)=>{
          cx++
          db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Dipendenze').doc(c.id).collection('Sezioni').get().then((sez)=>{
            if(sez.docs.length>0){
              sx = 0
            sez.docs.forEach((s)=>{
              sx++
              db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Dipendenze').doc(c.id).collection('Sezioni').doc(s.id).collection('Dipendenze').get().then((dips)=>{
                if(dips.docs.length>0){
                  dx = 0
                dips.docs.forEach((d)=>{
                  dx++
                  if(d.data().Checked===false){
                      console.log(d.data())
                    if(d.data().Essenziale===true){
                      n = 2
                      //non presenti essenziali
                    }else{
                      if(n===2){
                        
                      }else{
                        n=1
                     
                      } //non presenti non essenziali
                    }
                  }
                  console.log(sede,dx,dips.docs.length,dx===dips.docs.length)
                  if(dx===dips.docs.length){
                    var index = arr.findIndex((e)=>e.Id===sede)
                    console.log(sede,index)
                    if(index===-1){
                      console.log(sede,n)
                      arr.push({
                        ...sed.data(),
                        Id: sede,
                        Status: n,
                        ChangeTag: false
                      })
                    }else{
                      arr[index] = { Id: sede, Status: n, ChangeTag: false, ...sed.data()}
                    }
                    
                  if(n_sedi===arr.length){
                      setSedi(arr)
                      return 
                  }
                  }
                })
              }else{
                  var index = arr.findIndex((e)=>e.Id===sede)
                  if(index===-1){
                    arr.push({
                      ...sed.data(),
                      Id: sede,
                      Status: n,
                      ChangeTag: false
                    })
                  }
                  if(n_sedi===arr.length){
                    setSedi(arr)
                    return
                  }
                }
              })
            })
          }else{
              var index = arr.findIndex((e)=>e.Id===sede)
                  if(index===-1){
                    arr.push({
                      ...sed.data(),
                      Id: sede,
                      Status: n,
                      ChangeTag: false
                    })
                  }
              if(n_sedi===arr.length){
                setSedi(arr)
                return 
              }
            }
          })
       
        })
      }else{
          var index = arr.findIndex((e)=>e.Id===sede)
                  if(index===-1){
                    arr.push({
                      ...sed.data(),
                      Id: sede,
                      Status: n,
                      ChangeTag: false
                    })
                  }
          if(n_sedi===arr.length){
            setSedi(arr)
            return
          }
        }
        })
    })
  })
} //prende le sedi 

export function statusSede(db,email,sede,v){
  var arr = []
  var cx = 0
  var sx = 0
  var dx = 0
  var n = 0
  db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Dipendenze').get().then((cats)=>{
    cats.docs.forEach((c,i)=>{
      cx++
      db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Dipendenze').doc(c.id).collection('Sezioni').get().then((sez)=>{
        sez.docs.forEach((s)=>{
          sx++
          console.log(s.data())
          db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Dipendenze').doc(c.id).collection('Sezioni').doc(s.id).collection('Dipendenze').get().then((dips)=>{
            dips.forEach((d)=>{
              dx++
              console.log(sx,cx,dx,cats.docs.length,sez.docs.length,dips.docs.length)
              arr.push(d.data())
              if(d.data().Checked===false){
                if(d.data().Essenziale===true){
                  n=2 //non presenti essenziali
                }else{
                  if(n===2){

                  }else{
                    n=1
                  } //non presenti non essenziali
                }
              }
              if(dx===dips.docs.length&&cx===cats.docs.length&&sx===sez.docs.length){
                console.log(n)
                v = n
              }
            })
          })
        })
      })
   
    })
    })
} //imposta lo stato delle sedi

export function getScadenzeAnnuali(db,email,setMan,year,setManM,month,day,setManF){
  setMan([])
  setManM([])
  setManF([])
  console.log(day)

  db.collection('Users').doc(email).collection('Sedi').get().then((sed)=>{
    sed.forEach((s)=>{
      db.collection('Users').doc(email).collection('Sedi').doc(s.id).collection('Manutenzioni').get().then((man)=>{
        man.forEach((m)=>{
          db.collection('Manutenzioni').doc(m.data().ref).get().then((manz)=>{
            var arr = manz.data().Data.split('-')
            if(parseInt(arr[0],10)===parseInt(year,10)){
              setMan((prev)=>{
                return [
                  ...prev,
                    manz.data()
                  
                ]
              })
            }
            if(parseInt(arr[0],10)===parseInt(year,10)&&parseInt(arr[1],10)-1===parseInt(month,10)){
              setManM((prev)=>{
                return [
                  ...prev,
                    manz.data()
                  
                ]
              })
            }

            if(parseInt(arr[0],10)===parseInt(year,10)&&parseInt(arr[1],10)-1>=parseInt(month,10)&&parseInt(arr[2],10)>=parseInt(day,10)){
              setManF((prev)=>{
                return [
                  ...prev,
                    manz.data()
                  
                ]
              })
            }
          })
        })
      }) 

    })
  })
}
export function getInterventiAnnuali(db,email,setInt,year,setIntM,month){
  setInt([])
  setIntM([])

  db.collection('Users').doc(email).collection('Sedi').get().then((sed)=>{
    sed.forEach((s)=>{
      db.collection('Users').doc(email).collection('Sedi').doc(s.id).collection('Attività').get().then((man)=>{
        man.forEach((m)=>{
          db.collection('Attività').doc(m.data().ref).get().then((manz)=>{
            var arr = manz.data().Data.split('-')
            if(parseInt(arr[0],10)===parseInt(year,10)){
              setInt((prev)=>{
                return [
                  ...prev,
                    manz.data()
                  
                ]
              })
            }
            if(parseInt(arr[0],10)===parseInt(year,10)&&parseInt(arr[1],10)-1===parseInt(month,10)){
              setIntM((prev)=>{
                return [
                  ...prev,
                    manz.data()
                  
                ]
              })
            }
           
          })
        })
      }) 

    })
  })
}