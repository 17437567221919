import React , { useState, useEffect } from 'react'
import firebase from "firebase/app";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getUtente,getSede,getSedeName} from "../../EngineClienti/engineClienti";
import AggiungiAllegato from '../../Popups/AggiungiAllegato';
import { getClienti, getSediCliente, } from '../../EngineOffritore/engineOffritore';
import NavBar2 from '../../NavBar/NavBar2'

import SelectElements from './SelectElements';
const RichiediScadenza = ({history}) => {

  const [user,setUser] = useState(null)
  const [section,setSection] = useState(0)
  const [clienti,setClienti] = useState([])
  const [sedi,setSedi] = useState([])
  const [categorie,setCategorie] = useState([])
  const [sottocategorie,setSottocategorie] = useState([])
  const [elementi,setElementi] = useState([])
  const [infoSede,setInfoSede] = useState(null)
  const [infoMan,setInfoMan] = useState(null)

  const [percentage,setPercentage] = useState(0)

  ////input
  const [data,setData] = useState('')
  const [sede,setSede] = useState('default')
  const [cliente,setCliente] = useState('default')
  const [categoria,setCategoria] = useState('default')
  const [sottocategoria,setSottocategoria] = useState('')
  const [elemento,setElemento] = useState('')
  const [titolo,setTitolo] = useState('')
  const [descrizione,setDescrizione] = useState('')


  
  const db = firebase.firestore()
  const auth = firebase.auth()
  const storage = firebase.storage();
  var storageRef = storage.ref();

  useEffect(()=>{
    auth.onAuthStateChanged(function (user) {
      if (user) {
        getUtente(db,auth,setUser)
      }
    })
  },[auth])

  useEffect(()=>{
    if(user!==null){
      getClienti(db,user.Email,setClienti)
    }
  },[user])

  useEffect(()=>{
    if(cliente!==''){
      getSediCliente(db,cliente,setSedi)
    }
  },[cliente])

  useEffect(()=>{
    if(sede!==''){
      getElementi()
      getSede(db,cliente,sede,setInfoSede)

    }
  },[sede])

  function getElementi(){
    setElementi([])
    setCategorie([])
    var queryResult = new Object();
    db.collection("Users").doc(cliente).collection("Sedi").doc(sede).collection("Categorie").get().then((listaCategorie)=>{
      listaCategorie.forEach((singCat)=>{
        setCategorie((prev)=>[...prev,singCat.id])
          var sottoOgg = new Object();

          singCat.ref.collection("Sottocategorie").get().then((sottoCategorie)=>{

            sottoCategorie.forEach((sottoCat)=>{
              sottoCat.ref.collection("Elementi").get().then((listaElementi)=>{
                var tmp = [];
                listaElementi.forEach((elSing)=>{
              db.collection('Elementi').doc(elSing.data().ref).get().then((ele)=>{
                console.log(ele.data())
                var dataX = {checked: false, path: elSing.ref.path, Macrocategoria: sottoCat.data().Nome, ...ele.data(), NumeroManutenzioni: elSing.data().NumeroManutenzioni, id: ele.id}
                tmp.push(dataX)
                setElementi((prev)=>{
                  return [
                    ...prev,
                    {checked: false, path: elSing.ref.path, Macrocategoria: sottoCat.data().Nome, ...ele.data(), NumeroManutenzioni: elSing.data().NumeroManutenzioni, id: ele.id}
                  ]
                })

                sottoOgg[sottoCat.id] = tmp;
                queryResult[singCat.id]=sottoOgg
              })
                })
              
            })
            
          })
        })

      })
      //setElementi(queryResult)
    })
  } //funzione che prende gli elementi

  const [showAggiungiAllegato,setShowAggiungiAllegato] = useState(false)
  const [allegati,setAllegati] = useState([])


  function handleSubmit() {
    const itemsToProcess = elementi.filter(elemento => elemento.checked);


    console.log(itemsToProcess)

    if (titolo !== "" && descrizione !== "" && data !== "" && itemsToProcess.length > 0) {
       handleManutenzione(itemsToProcess);
    } else {
        alert('Inserire tutti i campi presenti');
    }
} //prepara gli elementi selezionati in un array e chiama handleManutenzione

  function handleManutenzione(itemsToProcess){

    var activityInfo = new Object();
    const sedename = getSedeName(sede,sedi)

    db.collection('Users').doc(cliente).get().then((usx)=>{

      var elementsRef = [];

      activityInfo.Cliente = cliente;
      activityInfo.Nome = usx.data().Nome;
      activityInfo.Cognome = usx.data().Cognome;
      activityInfo.Data = data;
      activityInfo.Descrizione = descrizione;
      activityInfo.Indirizzo = infoSede.Indirizzo;
      activityInfo.Nome_AziendaOff = user.Nome_Azienda;
      activityInfo.Nome_AziendaCli =usx.data().Nome_Azienda;
      activityInfo.Edit =false;

      activityInfo.Oggetto = "Manutenzione: " + titolo;
      activityInfo.Priorità = "Alta";
      activityInfo.Stato = "Pianificata";
      activityInfo.Titolo = titolo;
      activityInfo.Elementi = elementsRef;
      activityInfo.Sede = sede;
      activityInfo.SedeName = sedename;
      activityInfo.Operatore = user.Email
      activityInfo.Categoria = categoria
      itemsToProcess.forEach((item)=>{elementsRef.push(item.path)})

      const dataInput = new Date(data); // newData contiene il valore della nuova data in formato 'YYYY-MM-DD'

  const giorno = dataInput.getDate(); // Ottiene il giorno
  const mese = dataInput.getMonth() + 1; // Ottiene il mese (gennaio è 0, quindi +1)
  const anno = dataInput.getFullYear(); // Ottiene l'anno
      
      var annotazioneCal = new Object();
      annotazioneCal.Day = giorno
      annotazioneCal.Description = descrizione;
      annotazioneCal.Indirizzo = infoSede.Indirizzo;
      annotazioneCal.Month = mese
      annotazioneCal.Subject = titolo;
      annotazioneCal.Year = anno;
      annotazioneCal.endHours = 13;
      annotazioneCal.startHours = 12;
      annotazioneCal.endMinutes = 45;
      annotazioneCal.startMinutes = 30;
      annotazioneCal.Cliente= cliente;
      annotazioneCal.Fornitore=user.Email;

      var batch = db.batch();

      var manutenzioneId = db.collection("Manutenzioni").doc().id;
      
      //Creo la manutenzione
      batch.set(db.collection("Manutenzioni").doc(manutenzioneId),activityInfo)

      // Aggiungo l'attività per il cliente
      batch.set(db.collection("Users").doc(cliente).collection("Sedi").doc(sede).collection("Manutenzioni").doc(manutenzioneId)
      ,{ref: manutenzioneId})
      // Aggiungo l'attività per il fornitore
      batch.set(db.collection("Users").doc(user.Email).collection("Clienti").doc(cliente).collection("Manutenzioni").doc(manutenzioneId)
      ,{ref:manutenzioneId})
      // Annotazione calendario per il cliente
      batch.set(db.collection("Users").doc(cliente).collection("Annotazioni").doc(manutenzioneId)
      ,annotazioneCal)
      // Annotazione calendario per il fornitore
      batch.set(db.collection("Users").doc(user.Email).collection("Annotazioni").doc(manutenzioneId)
      ,annotazioneCal)
      

      // Per ogni elemento gli aggiungo la ref alla manutenzione
      itemsToProcess.forEach((item)=>{
        var numeroManutenzioni = 0;
        if(item.NumeroManutenzioni!==undefined){numeroManutenzioni = item.NumeroManutenzioni}
        numeroManutenzioni++;
        console.log(item)
        batch.update(db.doc(item.path),{NumeroManutenzioni:firebase.firestore.FieldValue.increment(1),ProssimaScadenza:data})
        batch.update(db.collection('Elementi').doc(item.id),{NumeroManutenzioni:firebase.firestore.FieldValue.increment(1),ProssimaScadenza:data})
        batch.set(db.doc(item.path).collection("Manutenzioni").doc(manutenzioneId),{
          ref: manutenzioneId,
          folder:item.Macrocategoria,
          name:item.Nome
        })
      })

      batch.commit().then(()=>{
        db.collection("Users").doc(user.Email).collection("Storico").doc(cliente)
                .collection("Storico").doc().set({
                  Operazione:"Pianificazione Manutenzione",
                  Sede:sede,
                  SoggettoOperazione:titolo,
                  Timestamp:  new Date().toLocaleString()
                }).then(()=>{

                  var date2 = new Date();
                  var days2 = date2.getDate();
                  if(days2<10){
                    days2 = '0' + days2;
                  }
                      var month2 = ('0'+(date2.getMonth()+1)).slice(-2);
                      var minutes2 =  ('0'+(date2.getMinutes())).slice(-2);
                        var dataFormattata = days2+"-"+month2+"-"+date2.getFullYear()+" "+(date2.getHours())+":"+minutes2;
                      db.collection('Users').doc(user.Email).update({
                        Current_Scad: firebase.firestore.FieldValue.increment(1)
                      }) //incrementa scadenze a man
                      db.collection('Users').doc(cliente).update({
                        Current_Scad: firebase.firestore.FieldValue.increment(1)
                      }) //incrementa scadenze a cli
                      db.collection('Users').doc(user.Email).collection('Clienti').doc(cliente).update({
                        Man: firebase.firestore.FieldValue.increment(1)
                      }) //incrementa scadenze a man sul cliente
                      db.collection("Users").doc(cliente).collection("Sedi").doc(sede).update({
                        Man: firebase.firestore.FieldValue.increment(1)
                      }) //incrementa scadenze a cli sulla sede

                      db.collection("Users").doc(cliente).collection("Notifiche").doc().set({
                        Nome_Azienda:user.Nome_Azienda,
                        Nome_Cognome:user.Nome+" "+user.Cognome,
                        Email:user.Email,
                        Messaggio:"ha programmato una manutenzione",
                        Data:dataFormattata,
                        Oggetto:titolo,
                        Edit: false,
                        Id_man: manutenzioneId
                      }).then(() => {
                        var arr = []

                        var arrz = JSON.parse(sessionStorage.getItem('scadenze'));

                        if(arrz){
                          arr = arrz
                        }
                        
                        arr.push({
      Cliente : cliente,
      Nome : usx.data().Nome,
      Cognome : usx.data().Cognome,
      Data : data,
      Descrizione : descrizione,
      Indirizzo : infoSede.Indirizzo,
      Nome_AziendaOff : user.Nome_Azienda,
      Nome_AziendaCli :usx.data().Nome_Azienda,
      Edit :false,
      Oggetto : "Manutenzione: " + titolo,
      Priorità : "Alta",
      Stato : "Pianificata",
      Titolo : titolo,
      Elementi : elementsRef,
      Sede : sede,
      SedeName:sedename,
      Operatore : user.Email,
      Archiviato: false,
      Categoria: categoria,
      Report: '',
      Sott_Attivi: false,
      SottoAttività: false,
      Id: manutenzioneId


                        })
                        sessionStorage.setItem('scadenze', JSON.stringify(arr));
                        alert("Manutenzione programmata correttamente.");
                        history.goBack()

                      });
               
      })
    })

    })




  } //pianifica la manutenzione

function handleClick(elemento){
   setElementi(prevElementi => {
    const newCheckedState = !elemento.checked;

    // Crea una copia aggiornata degli elementi
    const updatedElementi = prevElementi.map(el => {
        if (el.id === elemento.id) {
            return { ...el, checked: newCheckedState };
        }

        // Se l'elemento è il padre dell'elemento corrente, aggiorna anche i figli
        if (el.MyEleFatherId === elemento.id) {
            return { ...el, checked: newCheckedState };
        }

        // Se l'elemento è un figlio dell'elemento corrente, aggiorna lo stato dei figli
        if (elemento.MyEleFatherId && el.id === elemento.MyEleFatherId && newCheckedState) {
            return { ...el, checked: true };
        }

        return el;
    });

    return updatedElementi;
});
} //click selezione sottocategoria

function handleClickElemento(elemento) {
  setElementi(prevElementi => {
      // Determina il nuovo stato del checkbox
      const newCheckedState = !elemento.checked;

      return prevElementi.map(el => {
          // Aggiorna lo stato dell'elemento corrente (sia padre che figlio)
          if (el.id === elemento.id) {
              return { ...el, checked: newCheckedState };
          }
          
          // Se l'elemento è il padre dell'elemento corrente (figlio), seleziona anche il padre
          if (elemento.MyEleFatherId && el.id === elemento.MyEleFatherId && newCheckedState) {
              return { ...el, checked: true };
          }
          
          // Se l'elemento attuale è padre e selezionato, aggiorna lo stato di tutti i figli
          if (!elemento.MyEleFatherId && el.MyEleFatherId === elemento.id) {
              return { ...el, checked: newCheckedState };
          }

          // Altrimenti, restituisci l'elemento invariato
          return el;
      });
  });
} //click selezione elemento 



  return (
    <div className="background" style={{width:'100vw',height:'100vh',overflow:'hidden'}}>
      <NavBar2/>
       {showAggiungiAllegato && <AggiungiAllegato trigger={showAggiungiAllegato} setTrigger={setShowAggiungiAllegato}
            setAllegati={setAllegati} allegati={allegati}/>}
        <div
   style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}
 >
    <div  style={{  width: "50vw",display:'flex',marginTop:'1.2rem' }}>
          <button style={{marginBottom:'auto',marginTop:'1rem'}} onClick={() => history.goBack()}
            className="cancelIconButton"
            
          >
            <ArrowBackIcon
             className="back-button"
            />
          </button>
          <h4 className="page-title" style={{margin:0}}>SCADENZE / PROGRAMMA MANUTENZIONE {percentage!==0?percentage+'%':null}</h4>
        </div>
        {section===0?<div style={{marginLeft:'3.5rem',marginTop:'2rem',width:'30rem'}}>
                             {/*SELEZIONE OPERATORE */}
    
<div ><h4  className="sub-text-b">Seleziona cliente:</h4>
        <select onChange={(e)=>setCliente(e.target.value)} value={cliente} className="input-style2" style={{width:'15rem',fontWeight:'normal'}} defaultValue={"default"}>
            <option value={"default"} disabled>
                  Seleziona cliente...
                </option>
                {clienti.length>0?clienti.map((man,n)=>(
                   <option key={n} value={man.Email}>
                   {man.Nome_Azienda}
                 </option>
                )):null}
        </select>
        </div>

                             {/*SELEZIONE SEDE */}

                             <h4 style={{marginTop:'1rem'}}  className="sub-text-b">Seleziona sede:</h4>
        <select onChange={(e)=>setSede(e.target.value)} value={sede} className="input-style" defaultValue={"default"}
        >
            <option value={"default"} disabled>
                  Seleziona sede...
                </option>
                {sedi.length>0?sedi.map((sed,n)=>(
                   <option key={n} value={sed.Id}>
                   {sed.Nome?sed.Nome!==''?sed.Nome:sed.Comune:sed.Comune}
                 </option>
                )):null}
                
        </select> 

            {/*SELEZIONE DATA */}

<div style={{marginTop:'1rem'}}><h4  className="sub-text-b">Seleziona data:</h4>
        <input onChange={(e)=>setData(e.target.value)} type='date' value={data} className="input-style" style={{marginTop:'0.5rem'}} />
           </div>



        <button onClick={()=>data!==''&&sede!==''&&cliente!==''?setSection(1):alert('Inserire tutti i campi richiesti')} className='main-button' style={{marginTop:'2rem'}}>Avanti</button>
        </div>:null}
        {section===1?<div style={{marginLeft:'3.5rem',marginTop:'2rem',width:'30rem'}}>

        {/*SELEZIONE CATEGORIA */}
    
<div style={{marginTop:'1rem'}}><h4  className="sub-text-b">Seleziona categoria:</h4>
        <select onChange={(e)=>setCategoria(e.target.value)} value={categoria} className="input-style2" style={{width:'15rem',fontWeight:'normal'}} defaultValue={"default"}>
            <option value={"default"} disabled>
                  Seleziona categoria...
                </option>
                {categorie.length>0?categorie.map((cat,n)=>(
                   <option key={n} value={cat}>
                   {cat}
                 </option>
                )):null}
        </select>
        </div>
        {categoria!==''?<div style={{marginTop:'1rem',maxHeight:'60vh',overflowY:'auto'}}><h4  className="sub-text-b">Seleziona elementi:</h4>
        <SelectElements 
                    elementi={elementi} 
                    categoriaSelezionata={categoria} 
                    handleClickElemento={handleClickElemento} 
                    handleClick={handleClick}
                />
        {/*elementi[categoria]!==undefined&&Object.keys(elementi[categoria]).map((key,index)=>{
            return(<div  key={key}>
              <div>
              <input type="checkbox" name={key+"_"+index} checked={fullSelected.indexOf(key)!=-1} key={key} onClick={()=>handleClick(key)} /> 
              <label style={{fontSize:'1.5vw', color:'#5C6672'}}>{key}</label>
              </div>
              {elementi[categoria][key].filter(elemento => !elemento.MyEleFatherId||elemento.MyEleFatherId===null).map((elemento) => {
              return(elemento.Nome?<div style={{ marginLeft:'1vw',width:'18vw'}}>
              <div style={{display:'flex',alignItems:'center'}}>
              <input type="checkbox" onClick={()=>handleClickElemento(elemento,key)} key={elemento.Nome} value={JSON.stringify(elemento)} checked={elemento.checked}/><label style={{fontSize:'1vw',width:'15vw'}}>{elemento.Nome}</label>
              <div style={{marginLeft:'5vw'}}>
              <Tooltip title={elemento.NumeroManutenzioni===undefined?"Numero manutenzioni:0":"Numero Manutenzioni:"+elemento.NumeroManutenzioni}><div style={{display:'flex', alignItems: 'center'}}>
                 <label style={{fontSize:'0.8vw', color: 'black',fontWeight:'bold'}}>{elemento.NumeroManutenzioni>0?elemento.NumeroManutenzioni:0}</label><BuildCircleIcon fontSize="small" style={{color:"grey",marginTop:"auto",marginBottom:"auto"}}/></div>
                 </Tooltip>         
              </div>
              </div>
              {elemento.MyEleChilds ? elemento.MyEleChilds.map((childElementoId, i) => {
                            // Trova l'elemento completo nella lista generale degli elementi
                            const childElemento = getElementFromGeneralList(childElementoId.Id, elementi[categoria]);

                            // Controlla se l'elemento esiste nella lista generale
                            if (childElemento) {
                                return (
                                    <div key={childElemento.id} style={{ display: 'flex', alignItems: 'center', marginLeft: '2vw', width: '18vw', marginTop: i === 0 ? '5px' : null, marginBottom: i === elemento.MyEleChilds.length - 1 ? '10px' : null }}>
                                        <input type="checkbox" onClick={() => handleClickElemento(childElemento, key)} value={JSON.stringify(childElemento)} checked={childElemento.checked} />
                                        <label style={{ fontSize: '1vw', width: '15vw' }}>{childElemento.Nome}</label>
                                        <div style={{ marginLeft: '5vw' }}>
                                            <Tooltip title={childElemento.NumeroManutenzioni === undefined ? "Numero manutenzioni:0" : "Numero Manutenzioni:" + childElemento.NumeroManutenzioni}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label style={{ fontSize: '0.8vw', color: 'black', fontWeight: 'bold' }}>{childElemento.NumeroManutenzioni > 0 ? childElemento.NumeroManutenzioni : 0}</label>
                                                    <BuildCircleIcon fontSize="small" style={{ color: "grey", marginTop: "auto", marginBottom: "auto" }} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                );
                            } else {
                                return null; // Non renderizza se l'elemento non è trovato nella lista generale
                            }
                        }) : null}
              </div>:null)
            })}
            </div>)
          })*/}         
        </div>:null}

        <button onClick={()=>categoria!==''?setSection(2):alert('Inserire la categoria')} className='main-button' style={{marginTop:'2rem'}}>Avanti</button>
        <br/>
        <button onClick={()=>{setSection(0);setCategoria('default')}}  className='main-button' style={{marginTop:'1rem',backgroundColor:'#FF0060'}}>Indietro</button>

        </div>:null}
        {section===2?<div style={{marginLeft:'3.5rem',marginTop:'2rem',width:'30rem'}}>

                {/*TITOLO */}

        <div style={{marginTop:'1rem'}}><h4  className="sub-text-b">Inserisci titolo:</h4>
        <input onChange={(e)=>setTitolo(e.target.value)} type='text' className="input-style" style={{marginTop:'0.5rem'}} placeholder='Titolo...'/>
           </div>
                           {/*DESCRIZIONE */}

           <div style={{marginTop:'1rem'}}><h4  className="sub-text-b">Inserisci descrizione:</h4>
        <input onChange={(e)=>setDescrizione(e.target.value)} type='text' className="input-style" style={{marginTop:'0.5rem'}} placeholder='Descrizione...'/>
           </div>
           <button onClick={()=>titolo!==''&&descrizione!==''?handleSubmit():alert('Inserire tutti i campi richiesti')} className='main-button' style={{marginTop:'2rem',width:'8rem'}}>Crea manutenzione</button>
        <br/>
        <button onClick={()=>setSection(1)}  className='main-button' style={{marginTop:'1rem',backgroundColor:'#FF0060'}}>Indietro</button>
        </div>:null}

 </div>
        </div>
  )
}

export default RichiediScadenza