import React,{useEffect, useState} from 'react'
import NavBar from '../NavBar/NavBar'
import firebase from "firebase";
import { getUtente, prendiCategorie,checkWriteS,checkDeleteS } from '../EngineClienti/engineClienti';
import {  IconButton, Tooltip } from '@mui/material';
import LoopIcon from '@material-ui/icons/Loop';
import Notifiche from '../Notifiche/Notifiche';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
const Categorie = ({history}) => {

    const db = firebase.firestore()
    const auth = firebase.auth()

    const [user,setUser] = useState(null)
    const [sedi,setSedi] = useState([]) //lista sedi
    const [categorie,setCategorie] = useState([]) //lista categorie

    //filtri
    const [sedeF,setSedeF] = useState('default')
    const [findInput,setFindInput] = useState('')

    const [expandedCat, setExpandedCat] = useState(null);


    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
            getUtente(db,auth,setUser)
          }
        })
      },[auth])

      useEffect(()=>{
        if(user!==null){
          prendiCategorie(db,user.Email,setSedi,setCategorie)
      }
    },[user])

    function reGet (){
        prendiCategorie(db,user.Email,setSedi,setCategorie)
      
      }

    const Buttons = () =>{

        return(
          <div style={{display:'flex',alignItems:'center',gap:'1.5rem'}}>
            <div>
            <h4 onClick={()=>history.push('/categorie/aggiungicategoria')}  className='text-buttons' >
                    AGGIUNGI CATEGORIA
                  </h4>
            </div>
          
          </div>
        )
      }




  function deleteCat(cat){
    
    if(cat.Ele!==0&&cat.Doc!==0){
      alert('Prima di eliminare la categoria assicurati di eliminare tutti gli elementi e documenti annessi.')
      return
    }else{
      var cE = false
    var cD = false
    var iE = 0
    var iD = 0
      db.collection('Users').doc(user.Email).collection('Sedi').doc(cat.Sede).collection('Categorie').doc(cat.Id).collection('Sottocategorie').get().then((sottocategorie)=>{
        if(sottocategorie.docs.length>0){
          sottocategorie.forEach((sott)=>{
            iE++
            console.log(sott.id)
            db.collection('Users').doc(user.Email).collection('Sedi').doc(cat.Sede).collection('Categorie').doc(cat.Id).collection('Sottocategorie').doc(sott.id).delete().then(()=>{
              if(iE===sottocategorie.docs.length){
                cE=true
              }
            })
            
          })
        }else{
          console.log('bewlla')
          cE=true
        }
      }).then(()=>{
        console.log(cE)
        db.collection('Users').doc(user.Email).collection('Sedi').doc(cat.Sede).collection('Categorie').doc(cat.Id).collection('Cartelle').get().then((cartelle)=>{
          if(cartelle.docs.length>0){
            cartelle.forEach((cart)=>{
              iD++
            db.collection('Users').doc(user.Email).collection('Sedi').doc(cat.Sede).collection('Categorie').doc(cat.Id).collection('Cartelle').doc(cart.id).delete().then(()=>{
              if(iD===cartelle.docs.length){
                cD=true
              }
            })
            
            })
          }else{
            cD=true
          }
          console.log(cD,cE)
          if(cD===true&&cE===true){
            db.collection('Users').doc(user.Email).collection('Sedi').doc(cat.Sede).collection('Categorie').doc(cat.Id).delete().then(()=>{
              reGet()
              alert('Categoria eliminata con successo.')
            })
          }
        })
       
      })
     
    }
  }
  //raggruppamento categorie
  function groupCategories(categorie) {
    const grouped = {};
  
    categorie.forEach(cat => {
      if (!grouped[cat.Id]) {
        grouped[cat.Id] = {
          Nome: cat.Nome,
          Doc: 0,
          Ele: 0,
          sediCount: 0,
          sedi: [],
          Id: cat.Id
        };
      }
  
      grouped[cat.Id].Doc += cat.Doc;
      grouped[cat.Id].Ele += cat.Ele;
      grouped[cat.Id].sediCount += 1;
      grouped[cat.Id].sedi.push(cat);
    });
  
    return Object.values(grouped);
  }

  const groupedCategories = groupCategories(categorie);

  function toggleExpanded(catName) {
    setExpandedCat(expandedCat === catName ? null : catName);
  }

  function renderCategorieFiltrate(categoria) {
    console.log(categoria)
    var check = categoria.sedi.some(cat => cat.Sede === sedeF)
    if ((sedeF === '' || sedeF === 'default'||check===true)&&(findInput===''||categoria.Nome.toLowerCase().includes(findInput.toLowerCase()))) {
        return true; // Mostra tutte le categorie se nessun filtro è selezionato
    }
}
function renderSubCategorieFiltrate(categoria) {
  console.log(categoria)
  if (sedeF === '' || sedeF === 'default') {
      return true; // Mostra tutte le categorie se nessun filtro è selezionato
  }

  // Mostra solo le categorie che contengono la sede selezionata
  return categoria.Sede===sedeF;
}
      

  return (
    <div className="background" style={{width:'100vw',height:'100vh'}}>
                <NavBar/>
                {user!==null&&user!==null?<Notifiche user={user}/>:null}

    <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
    <div className="container-dash">
        <h4 className="page-title" style={{marginTop:'1.2rem'}}>CATEGORIE</h4>
       
      </div>
      {user!==null?checkWriteS(user.Permissions)===true?<Buttons/>:null:null}
      <div className='box-shadow' style={{marginTop:'0.5rem',borderRadius:5,width:'55vw',backgroundColor:'white',height:'3rem',display:'flex',alignItems:'center'}}>
          <div style={{width:'7vw',height:'3rem',borderRight:'1px solid lightgrey',}}>
          <h4 className="normal-text" style={{marginLeft:'auto',marginRight:'auto',marginTop:'0.3rem',fontSize:'1rem',width:'fit-content'}}>
           {categorie.length}
          </h4>
          <h4 className="sub-text" style={{marginLeft:'auto',marginRight:'auto',fontSize:'0.8rem',width:'fit-content'}}>
          {'Categorie totali'}
          </h4>
          </div>
          
          <select
                style={{marginTop:'auto',marginBottom:'auto',marginLeft:'1vw'}}
                defaultValue={"default"}
                value={sedeF}
                className='input-style'
                onChange={(e) => {
                  setSedeF(e.target.value);
                 
                }}
              >
                <option value={"default"} disabled>
                  Filtra per sede...
                </option>
                <option value={""}>Tutte</option>

                {
                    sedi.length>0?sedi.map((sede,n)=>(
                        <option index={n}  value={sede.Id}>{sede.Comune}</option>

                    )):null
                }


              </select>
             {/* */}
      
              <div  style={{marginLeft:'auto'}}>
                <input onChange={(e)=>
                  setFindInput(e.target.value.toLowerCase())}
                  className="input-style2" style={{width:'30rem',height:'1rem',fontWeight:'normal',fontFamily:'Poppins'}} type='text' placeholder={"Cerca categoria per: nome..."}></input>
                </div>            
          <div style={{marginLeft:10,marginRight:5}}>
          <Tooltip style={{width:'fit-content'}} title="Aggiorna lista">
                 <IconButton onClick={()=>reGet()} style={{backgroundColor:'#192bc2',width:'fit-content',height:'fit-content'}}  
                // onClick={()=>getEle(sedi,email)}
                 >
                 <LoopIcon style={{verticalAlign: 'middle', color: 'white',fontSize:'1rem'}}/>
                 </IconButton>
                 </Tooltip>
          </div>
          </div>
          <div style={{marginTop:10,borderRadius:5,width:'55vw',backgroundColor:'#14213d',height:'3rem',marginRight:'auto',display:'flex',alignItems:'center',boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'}}>
          
          <div style={{borderRight:'1px solid lightgrey',width:'15vw',height:'3rem',alignItems:'center',justifyContent:'center',}}>
            <h4 className="header-table" >Nome</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem'}}>
          <h4 className="header-table" >Sedi </h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem'}}>
          <h4 className="header-table" >Documenti </h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem'}} >
          <h4 className="header-table" >Elementi</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem'}}>
          <h4 className="header-table" >Azioni</h4>

          </div>
          </div>
          <div style={{overflowY:'auto',height:'74vh'}}>
          {groupedCategories.length > 0 ? (
            groupedCategories.map((group, n) => (
              renderCategorieFiltrate(group) === true ?
              <div key={n} style={{}}>
                <div className="table-row" style={{ width: '55vw' }}>
                  <div style={{ width: '15vw', display: 'flex', alignItems: 'center',borderRight:'1px solid lightgrey',height:'6rem' }}>
                    <h4
                      className="medium-text"
                      style={{
                        color: group.Nome === 'Antincendio' ? '#FF0060' : group.Nome === 'Elettrico' ? '#F7D060' : 'green',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        display:'flex',
                        flexDirection:'row',
                        alignItems:'center'
                      }}
                      onClick={() => toggleExpanded(group.Nome)}
                    >
                     {expandedCat === group.Nome?<ArrowDropUpIcon/>:<ArrowDropDownIcon/>} {group.Nome}
                    </h4>
                  </div>
                  <div style={{ width: '10vw', display: 'flex', alignItems: 'center',borderRight:'1px solid lightgrey',height:'6rem' }}>
                    <h4 className="medium-text">Presente in {group.sediCount} sedi</h4>
                  </div>
                  <div style={{ width: '10vw', display: 'flex', alignItems: 'center',borderRight:'1px solid lightgrey',height:'6rem' }}>
                    <h4 className="medium-text">{group.Doc}</h4>
                  </div>
                  <div style={{ width: '10vw', display: 'flex', alignItems: 'center',borderRight:'1px solid lightgrey',height:'6rem' }}>
                    <h4 className="medium-text">{group.Ele}</h4>
                  </div>
                  <div style={{ width: '10vw', display: 'flex', alignItems: 'center',paddingLeft:'5px' }}>
                      <h4 onClick={()=>history.push(`/categorie/categoria/${group.Id}`)} style={{borderBottom:'1px solid #192bc2',color:'#192bc2',cursor:'pointer',fontSize:'0.8rem',}} >
                Dettagli
              </h4>
                      </div>
                  </div>

                {/* Visualizza categorie singole per sede */}
                {expandedCat === group.Nome &&
                  group.sedi.map((ele, i) => (renderSubCategorieFiltrate(ele) === true ?
                    <div key={i} className="table-row" style={{ width: '40vw',height:'4rem',backgroundColor:'whitesmoke',marginLeft:'14.8vw',marginBottom:i===(group.sedi.length-1)?'3vh':null}}>
                    
                      <div style={{ width: '10vw', display: 'flex', alignItems: 'center',height:'4rem',borderRight:'1px solid lightgrey'  }}>
                      <Tooltip title={ele.SedeNome}>
                        <h4 className="medium-text" style={{fontWeight:'bold'}}>{ele.SedeNome}</h4>
                        </Tooltip>
                      </div>
                      <div style={{ width: '10vw', display: 'flex', alignItems: 'center',height:'4rem',borderRight:'1px solid lightgrey'  }}>
                        <h4 className="medium-text">{ele.Doc}</h4>
                      </div>
                      <div style={{ width: '10vw', display: 'flex', alignItems: 'center',height:'4rem',borderRight:'1px solid lightgrey' }}>
                    <h4 className="medium-text">{ele.Ele}</h4>
                  </div>
                  <div style={{ width: '10vw', display: 'flex', alignItems: 'center',height:'4rem',}}>
                  {checkDeleteS(user!==null?user.Permissions:null)===true? <div style={{width:'fit-content',marginLeft:'auto'}}>
              <IconButton onClick={()=>deleteCat(ele)} style={{width:'fit-content'}} >
              <DeleteIcon style={{color:'#FF0060'}}/>
                </IconButton>
                </div>:null}
                  </div>
                    </div>:null
                  ))}
              </div>:null
            ))
          ) : (
            <h4 className="normal-text" style={{ marginTop: '1rem' }}>
              Nessuna categoria trovata.
            </h4>
          )}
          {/*sortedCat.length>0?sortedCat.map((ele,n)=>(
           renderCategorie(ele)===true?
           <div key={n} className='table-row' style={{width:'55vw'}}>
            <div  style={{borderRight:'1px solid lightgrey',width:'15vw',height:'6rem',display:'flex',alignItems:'center'}}>
              <div>
              <Tooltip title={ele.Nome}>

<h4 className="medium-text" style={{whiteSpace: 'nowrap',color:ele.Nome==='Antincendio'?'#FF0060':ele.Nome==='Elettrico'?'#F7D060':'green',fontWeight:'bold',fontSize:'0.9rem',
overflow: 'hidden',
textOverflow: 'ellipsis',maxWidth:'9vw'}}>
                {ele.Nome}
              </h4>
              </Tooltip>
                </div>
              
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
            <div style={{display:'flex',alignItems:'center'}}>
              <Tooltip title={ele.SedeNome}>
              <h4 className="medium-text" style={{maxWidth:'7.3vw'}} >
              {ele.SedeName?ele.SedeName!==''?ele.SedeName:ele.SedeNome!==''?ele.SedeNome:'Non inserito':ele.SedeNome!==''?ele.SedeNome:'Non inserito'}
              </h4>
              </Tooltip>
              
              </div> 
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
            <div style={{display:'flex',alignItems:'center'}}>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
              {ele.Doc}
              </h4>
              
              </div>     
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div style={{display:'flex',alignItems:'center'}}>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Ele}
              </h4>
            
              </div>
            </div>
            <div style={{width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div style={{marginLeft:'auto',alignItems:'center',flexDirection:'row',width:'fit-content',marginRight:'20px'}}>
            <h4 onClick={()=>history.push(`/categorie/categoria/${ele.Id}`)} style={{borderBottom:'1px solid #192bc2',color:'#192bc2',cursor:'pointer',fontSize:'0.8rem',}} >
                Dettagli
              </h4>
             {checkDeleteS(user!==null?user.Permissions:null)===true? <div style={{width:'fit-content',marginLeft:'auto'}}>
              <IconButton onClick={()=>deleteCat(ele)} style={{width:'fit-content'}} >
              <DeleteIcon style={{color:'#FF0060'}}/>
                </IconButton>
                </div>:null}
            
              </div>
            </div>
           
  
            </div>:null
           
          )): <h4 className='normal-text' style={{marginTop:'1rem'}}>Nessuna sede inserita.</h4>*/}
        </div>
        </div>
    
    </div>
  )
}

export default Categorie