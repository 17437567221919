//link che porta alla registrazione

export const RegisterLink = () => {

    return(
    <div style={{marginTop:'3vh', marginLeft:'auto', marginRight:'auto', width:'fit-content',}}>
    <a href="/register" className="btnR" >
     Non hai un account? Registrati
    </a>
    
    </div>
    )
}