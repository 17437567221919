//popup doc essenziali
import React, { useState, useCallback, useEffect } from "react";
import "./Popup.css";
import firebase from "firebase/app";
import CancelIcon from '@material-ui/icons/Cancel';
import { IconButton, Tooltip } from "@material-ui/core";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import "../general.css"
import AddChecks from "./AddChecks";

function Checks(props) {
  const auth = firebase.auth();
  const db = firebase.firestore();

  const [emailz,setEmailz] = useState()

  const [checkExtra,setCheckExtra] = useState([]); //extra

  const [triggerAddExtra,setTriggerAddExtra] = useState(false) //trigger popup aggiunta categoria extra

  const [categorieExtra,setCategorieExtra] = useState([]) //lista categorie extra

  const [triggerSez,setTriggerSez] = useState(false) //trigger input per aggiunta sezione

  const [stringSezione,setStringSezione] = useState('') //nome sezione da aggiungere

  const [sezioni,setSezioni] = useState([]) //lista sezioni extra

  const [triggerDoc,setTriggerDoc] = useState({Categoria:'',Sezione:''}) //trigger aggiunta documento essenziale
  const [stringDoc,setStringDoc] = useState('') //nome documento da aggiungere
  const [docExtra,setDocExtra] = useState([]) //lista documenti extra
  const [isEssential,setIsEssential] = useState(false)


  useEffect(() => {
    auth.onAuthStateChanged(function (user) {
      if (user) {
        if(props.email&&props.sede){
          setEmailz(props.email)
          getExtra(props.email)
        }
      

      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]); //prende i doc salvati
  
  function getExtra(email){
    setCategorieExtra([])
    setSezioni([])
    setDocExtra([])
    db.collection('Users').doc(email).collection('Sedi').doc(props.sede.Id).collection('Dipendenze').get().then((catz)=>{
      catz.forEach((c)=>{
        setCategorieExtra((prev)=>{
          return [
            ...prev,
            {
              Nome:c.data().Nome,
              Id:c.id
            }
          ]
        })
        db.collection('Users').doc(email).collection('Sedi').doc(props.sede.Id).collection('Dipendenze').doc(c.id).collection('Sezioni').get().then((sezz)=>{
          sezz.forEach((s)=>{
            setSezioni((prev)=>{
              return [
                ...prev,
                {
                  Nome: s.data().Nome,
                  Id:s.id,
                  Categoria:c.id,
                  Trigger:false
                }
              ]
            })
            db.collection('Users').doc(email).collection('Sedi').doc(props.sede.Id).collection('Dipendenze').doc(c.id).collection('Sezioni').doc(s.id).collection('Dipendenze')
            .get().then((doczz)=>{
              doczz.forEach((d)=>{
                setDocExtra((prev)=>{
                  return [
                    ...prev,
                    {
                      Id:d.id,
                Nome:d.data().Nome,
                Essenziale: d.data().Essenziale,
                Categoria: d.data().Categoria,
                Sezione: d.data().Sezione,
                Checked: d.data().Checked
                    }
                  ]
                })
              })
            })
          })
          
        })
      })
    })
  } //prende le dipendenze extra

  function addSezione(c){
    if(stringSezione!==''){
      db.collection('Users').doc(emailz).collection('Sedi').doc(props.sede.Id).collection('Dipendenze').doc(c).collection('Sezioni').doc(stringSezione).get().then((sez)=>{
        if(sez.exists){
          alert('Sezione già esistente')
        }else{
          db.collection('Users').doc(emailz).collection('Sedi').doc(props.sede.Id).collection('Dipendenze').doc(c).collection('Sezioni').doc(stringSezione).set({
            Id:stringSezione,
            Nome:stringSezione
          }).then(()=>{
            setSezioni((prev)=>{
              return [
                ...prev,
                {
                  Id: stringSezione,
                  Nome: stringSezione,
                  Categoria:c,
                  Trigger:false
                }
              ]
            })
            alert('Sezione aggiunta con successo')
          })
        }
      })
    }else{
      alert('Inserisci il nome della sezione per aggiungerla!')
    }
    
  } //aggiunte sezione
  function addDocumenti(c,s){
    
    if(stringDoc!==''){
      db.collection('Users').doc(emailz).collection('Sedi').doc(props.sede.Id).collection('Dipendenze').doc(c).collection('Sezioni').doc(s).collection('Dipendenze').doc(stringDoc)
      .get().then((docz)=>{
        if(docz.exists){
          alert('Documento già esistente')
        }else{
          db.collection('Users').doc(emailz).collection('Sedi').doc(props.sede.Id).collection('Dipendenze').doc(c).collection('Sezioni').doc(s).collection('Dipendenze').doc(stringDoc).set({
            Id:stringDoc,
            Nome:stringDoc,
            Essenziale: isEssential,
            Categoria: c,
            Sezione: s,
            Checked: false
          }).then(()=>{
            setDocExtra((prev)=>{
              return [
                ...prev,
                {
                  Id:stringDoc,
            Nome:stringDoc,
            Essenziale: isEssential,
            Categoria: c,
            Sezione: s,
            Checked: false
                }
              ]
            })
            alert('Documento aggiunto con successo')
          })
        }
      })
    }else{
      alert('Inserisci il nome del documento per aggiungerlo!')
    }
    
  } //aggiunte documenti


  function setUpdateElemento(i, nome,value){
    var newA = [...checkExtra];
    newA[i]={
      ...newA[i],
      Nome:nome,
      Value:value}

    setCheckExtra(newA);
  } //funzione che aggiorna la lista

  function returnSezioni(c){
    var arr = sezioni
    
    const list = arr.filter((sez)=> sez.Categoria===c)
        return list
  }
  function returnDocumentiExtra(c,s){
    var arr = [...docExtra]
    const list = arr.filter((doc)=> doc.Categoria===c&&doc.Sezione===s)
    console.log(list,arr)
    
        return list
  }
  function triggerAddDoc(c,sez){
    if(triggerDoc.Categoria===c.Id&&triggerDoc.Sezione===sez.Id){
      setTriggerDoc({Categoria:'',Sezione:''})
    }else if(triggerDoc.Categoria===c.Id&&triggerDoc.Sezione!==sez.Id){
      setTriggerDoc({Categoria:c.Id,Sezione:sez.Id})
    }else{
      setTriggerDoc({Categoria:c.Id,Sezione:sez.Id})

    }
    
  } 

  function checkBoxExtra(d){
    var arr = [...docExtra]
    var index = arr.findIndex((dz)=>dz.Id===d.Id)
    db.collection('Users').doc(emailz).collection('Sedi').doc(props.sede.Id).collection('Dipendenze').doc(d.Categoria).collection('Sezioni').doc(d.Sezione)
     .collection('Dipendenze').doc(d.Id).update({
     Checked: !arr[index].Checked
     }).then(()=>{
        arr[index].Checked =  !arr[index].Checked
            setDocExtra([...arr])

     })
     

  }

  return props.trigger ? (
    <div className="pop-opac">
    <div className="popup" style={{backgroundColor:"white", width:'50vw', left:'25vw', height:'80vh'}}>
    {triggerAddExtra===true?<AddChecks sede={props.sede.Id} trigger={triggerAddExtra} setCategorieExtra={setCategorieExtra} categorieExtra={categorieExtra} setTrigger={setTriggerAddExtra} Email={props.email}/>:null}

          <div style={{textAlign:'left', width: 'fit-content'}}>
            <button
              className="cancelIconButton"
              
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
            </button>
            </div>
      
        <div style={{width: "100%"}}>
          <div style={{marginLeft:'2vw',}}>
        <div style={{display:'flex',alignItems:'center'}}>
        <label className="page-title">DOCUMENTI ESSENZIALI</label>

        </div>
        <br/>
        <label className="sub-text">Aggiungi e spunta i documenti caricati essenziali, così da avere sempre sotto controllo la situazione</label><br/>

        <label className="sub-text-b">I documenti contrassegnati da (E) sono essenziali ai fini di legge</label>
        </div>
              <h4 className="text-buttons" style={{margin:0,marginLeft:'2vw',marginTop:'1vh',cursor:'pointer'}} onClick={()=>setTriggerAddExtra(true)}>Aggiungi Nuova Categoria</h4>
        <div style={{marginLeft:'2vw',marginRight:'auto', width:'fit-content',height:'62vh', overflowY:'auto',}}>
            {categorieExtra.length>0?categorieExtra.map((cat,index)=>(
              <div key={index} style={{marginTop:'1vh'}}>
                <div style={{display:'flex',alignItems:'center'}}>
                <label style={{ fontSize:'2vw',color:cat.Nome==='Antincendio'?'red':cat.Nome==='Elettrico'?'#F7D060':'green',fontWeight:'bold', fontFamily:'Poppins',textTransform:'uppercase'}}>{cat.Nome}</label>
        <Tooltip title='Aggiungi nuova sezione'>
        <IconButton onClick={()=>setTriggerSez(!triggerSez)} style={{height:'fit-content',width:'fit-content',marginBottom:'auto',marginTop:'auto'}}>
       {triggerSez===false? <AddIcon style={{color:'#5C6672',marginBottom:'auto',marginTop:'auto',fontSize:'1.5rem'}}/>:
       <RemoveIcon style={{color:'#5C6672',marginBottom:'auto',marginTop:'auto',fontSize:'1.5rem'}}/>}
        </IconButton>
        </Tooltip>
        {triggerSez===true?<div style={{display:'flex',alignItems:'center',borderLeft:'1px solid #5C6672',paddingLeft:2}}>
          <h4 style={{margin:0,fontFamily:'Poppins',marginLeft:2}}>Inserisci nome sezione:</h4>
              <input type='text' onChange={(e)=>setStringSezione(e.target.value)} className="input-style" style={{marginLeft:5,height:'1rem',width:'10rem'}}></input>
              <button onClick={()=>addSezione(cat.Id)} className="main-button" style={{marginLeft:5,height:'2rem'}}>Aggiungi</button>
        </div>:null}
                  </div>
       
  
        {returnSezioni(cat.Id).length>0?returnSezioni(cat.Id).map((sez,i)=>(
          <div key={i} style={{marginTop:i===0?0:10,marginLeft:'0.5vw'}}>
          <div style={{display:'flex',alignItems:'center'}}>
          <label style={{ fontSize:'1.3vw',color:'#5C6672',fontWeight:'normal', fontFamily:'Poppins',textTransform:'uppercase'}}>{sez.Nome}</label>
          <Tooltip title='Aggiungi nuovo documento essenziale'>
          <IconButton onClick={()=>triggerAddDoc(cat,sez)}
          style={{height:'fit-content',width:'fit-content',marginBottom:'auto',marginTop:'auto'}}>
          {triggerDoc.Categoria!==cat.Id||triggerDoc.Sezione!==sez.Id?
          <AddIcon style={{color:'#5C6672',marginBottom:'auto',marginTop:'auto',fontSize:'1.5rem'}}/>:
          <RemoveIcon style={{color:'#5C6672',marginBottom:'auto',marginTop:'auto',fontSize:'1.5rem'}}/>}
          </IconButton>
          </Tooltip>
          </div>
          
          {docExtra.length>0?docExtra.map((dc,i)=>(dc.Categoria===cat.Id&&dc.Sezione===sez.Id?
            <div key={i} style={{marginLeft:'0.5vw'}}>
               <div style={{display:'flex',alignItems:'center'}}>
            <label style={{ fontSize:'1vw',color:dc.Checked===true?'lightgrey':'black',fontWeight:dc.Essenziale===true?'bold':'normal', fontFamily:'Poppins'}}>{dc.Nome} {dc.Essenziale===true?'(E)':null}</label>
            <input type='checkbox' checked={docExtra[i].Checked} onChange={()=>checkBoxExtra(dc)}></input>
          </div>
            </div>:null
          )):null}
          {triggerDoc.Categoria===cat.Id&&triggerDoc.Sezione===sez.Id?<div style={{alignItems:'center',marginTop:10,borderTop:'1px solid lightgrey',paddingTop:5}}>
          <h4 style={{margin:0,fontFamily:'Poppins'}}>Inserisci nome documento da aggiungere: </h4>
          <div style={{marginTop:10}}>
          <input type='text' value={stringDoc} onChange={(e)=>setStringDoc(e.target.value)} className="input-style" style={{height:'1rem',width:'10rem'}}></input>
          <label style={{margin:0,fontFamily:'Poppins',fontSize:'1rem'}}>
            <input type='checkbox' onChange={()=>setIsEssential(!isEssential)} checked={isEssential}/>Documento Essenziale</label>
          </div>
          <button onClick={()=>addDocumenti(cat.Id,sez.Id)} className="main-button" style={{marginTop:10,height:'2rem'}}>Aggiungi</button>
        </div>:null}
          </div>
        )):<h4 style={{margin:0,fontFamily:'Poppins'}}>Nessuna sezione aggiunta</h4>}
        </div>
        )):null}
                </div>

        </div>
    </div>
    </div>
  ) : (
    ""
  );
}

export default Checks;
