//popup doc essenziali
import React, { useState, useCallback, useEffect } from "react";
import "../Popups/Popup.css";
import firebase from "firebase/app";
import CancelIcon from '@material-ui/icons/Cancel';

function AddChecks(props) {
  const auth = firebase.auth();
  const db = firebase.firestore();

  const [categorie,setCategorie] = useState([]) //lista categorie
  const [selectedCategoria,setSelectedCategoria] = useState('') //categoria selezionata

  const [email,setEmail] = useState('')


  useEffect(() => {
    auth.onAuthStateChanged(function (user) {
      if (user) {
        setEmail(props.Email)
        getCat(props.Email)
       
    
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]); //prende l email utente

  function getCat(email){
    db.collection('Users').doc(email).collection('Sedi').doc(props.sede).collection('Categorie').get().then((catz)=>{
      catz.forEach((c)=>{
        setCategorie((prev)=>{
          return [
            ...prev,
            {
              Nome: c.data().Nome,
              id: c.id
            }
          ]
        

        })
      })
    })
  } //prende le categorie

  function addCategoria(){
    db.collection('Users').doc(email).collection('Sedi').doc(props.sede).collection('Dipendenze').doc(selectedCategoria).get().then((is)=>{
      if(is.exists){
        alert('Categoria già aggiunta')
      }else{
        db.collection('Users').doc(email).collection('Sedi').doc(props.sede).collection('Dipendenze').doc(selectedCategoria).set({
          Id: selectedCategoria,
          Nome: selectedCategoria,
        }).then(()=>{
          var arr = [...props.categorieExtra]
          arr.push({Nome: selectedCategoria, Id: selectedCategoria})
          props.setCategorieExtra(arr)
          alert('Categoria aggiunta con successo!')
          props.setTrigger(false)
        })
      }
    })
    
  }


  return props.trigger ? (
    <div className="pop-opac">
    <div className="popup" style={{backgroundColor:"white", width:'50vw', left:'25vw', height:'40vh',top:'20vh'}}>
          <div style={{textAlign:'left', width: 'fit-content'}}>
            <button
              className="cancelIconButton"
              
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
            </button>
            </div>
      
        <div style={{width: "100%"}}>
          <div style={{}}>

        <h4 className="page-title" style={{margin:0,width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>CATEGORIA DOCUMENTI ESSENZIALI</h4>
        <h4 className="sub-text" style={{margin:0,width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>Aggiungi la categoria a cui vuoi aggiungere documenti essenziali</h4><br/>

        </div>
        <div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content',marginTop:'1rem'}}>
        <select className="input-style2" onChange={(e)=>setSelectedCategoria(e.target.value)}>
        <option value="" hidden selected>
                  Seleziona Categoria
                </option>
                {categorie.map((c)=>(
                  <option value={c.id}>{c.Nome}</option>
                ))}
        </select>
        </div>

                  <br/>
                  <div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content'}}>
                  <button onClick={()=>addCategoria()} className="main-button">Aggiungi</button>

                  </div>
        </div>
    </div>
    </div>
  ) : (
    ""
  );
}

export default AddChecks;
