// prende come parametro un intervento e permette l'aggiunta dell'allegato salvandolo su storage e aggiungendo il collegamento all'intervento
import { useState } from "react";
import "./Popup.css";
import "firebase/storage";
import CancelIcon from '@material-ui/icons/Cancel';
import Button from '@mui/material/Button';

const AggiungiAllegato = (props)=>{

    const [allegati,setAllegati] = useState([]) //lista allegati


    function handleFile(e){
        var files = e.target.files;
        var newAll = props.allegati;
        for(let i=0;i<files.length;i++){
            newAll.push(files[i])
        }
       setAllegati(newAll);
    } //aggiunge allegato

    return(props.trigger==true && <div className="pop-opac">
        <div className="popup" style={{width:"40vw",height:"40vh",flexDirection:"column",left:'30vw',top:'25vh'}}>
        <button
              className="cancelIconButton"
              style={{marginLeft:"0",marginRight:"auto"}}
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon  style={{width: '15px', height: '15px'}} className="cancelIcon"/>
            </button>
        <div style={{width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>
       
            <label className="page-title">AGGIUNGI ALLEGATI</label>

        </div>
            <div style={{width:"fit-content",marginLeft:'auto',marginRight:'auto',}}>
            <label className="sub-text">Carica allegati</label>

            
             
            </div>
            <div style={{width:"fit-content",marginLeft:'auto',marginRight:'auto',marginTop:'3rem',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>

            <input  type="file" multiple
             onChange={handleFile} />
                         </div>

            <div style={{width:"fit-content",display:"flex",justifyContent:"center",marginLeft:'auto',marginRight:'auto',marginTop:'5vh'}}>
                {allegati.length>0&&<Button style={{width:'10vw', fontFamily:'Poppins', fontSize:'1vw', fontWeight:'bold',border:'none',backgroundColor:'#14213d',boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', cursor: 'pointer',color:'white'}} onClick={()=>{props.setAllegati(allegati);props.setTrigger(false)}}>Aggiungi</Button>
                }
           </div>
        </div>
    </div>)
}

export default AggiungiAllegato;