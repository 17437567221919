//popup aggiungi categoria
import React, { useState, useEffect } from "react";
import "../../Popups/Popup.css";
import firebase from "firebase/app";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getUtente} from "../../EngineClienti/engineClienti";
import { getClientiSediCategorie } from "../../EngineOffritore/engineOffritore";
import e from "cors";
import NavBar2 from "../../NavBar/NavBar2";
const AggiungiCategoriaOff = ({history}) => {
  const auth = firebase.auth();
  const db = firebase.firestore();
  const [selectedOption, setOption] = useState("Antincendio"); //categoria selezionata
  const [user,setUser] = useState(null)
  const [custom, setCustom] = useState(); //nome categoria altro


  const [clienti,setClienti]= useState([]) //lista clienti
  const [categorie, setCategorie] = useState([]); //lista categorie
  const [sedi,setSedi] = useState([])

  const [sedeF,setSedeF] = useState('default')
  const [clienteF,setClienteF] = useState('default')

  useEffect(()=>{
    auth.onAuthStateChanged(function (user) {
      if (user) {
        getUtente(db,auth,setUser)
      }
    })
  },[auth])

  useEffect(()=>{
    if(user!==null){
     getClientiSediCategorie(db,user.Email,setClienti,setSedi,setCategorie)      
  }

  },[user])

  useEffect(()=>{
    if(clienteF!=='default'){
      setSedeF('default')
    }
  },[clienteF])



  function returnCategorie(sede){
    var arr = categorie.filter((e)=>e.Sede===sede&&e.Cliente===clienteF)

    return arr
  }

  function addCatz() {
    
    var control = false;
    var name;
    if (selectedOption === "Altro") {
      name = custom.trim();
    } else {
      name = selectedOption;
    }
    if(clienteF!=='default'&&sedeF!=='default'){
    db.collection('Users').doc(clienteF).get().then((cli)=>{
   
    if(window.confirm("Vuoi aggiungere la categoria "+name+" alla sede "+sedeF+" di "+ cli.data().Nome_Azienda+"?")){
    categorie.forEach((cat) => {
      if (cat.Id.trim() === name&&cat.Sede===sedeF&&cat.Cliente===clienteF) {
        control = true;
      }
    });
    if (control === false) {
     db.collection("Users")
        .doc(clienteF)
        .collection("Sedi")
        .doc(sedeF)
        .collection("Categorie")
        .doc(name)
        .set({
          //scrive il nuovo valore sull'acc principale
          Nome: name,
          Sede: sedeF,
          Ele: 0,
          Doc: 0,
        })
        .then(() => {
          //controlla se ci sono sottocategoria extra già aggiunte e in caso le aggiunge
          db.collection('Users').doc(clienteF).collection('SottocategorieAggiuntive').get().then((sotts)=>{
            sotts.forEach((sottExtra)=>{
              if(sottExtra.data().Categoria===name){
                db.collection("Users")
        .doc(clienteF)
        .collection("Sedi")
        .doc(sedeF)
        .collection("Categorie")
        .doc(name).collection('Sottocategorie').doc(sottExtra.id).set({
          Nome: sottExtra.data().Nome,
          Sede: sedeF,
          Valore:0
        })
              }
            })
          })


        //aggiunge alla session storage la categoria appena aggiunta 

          var arr = []
        var c1 = JSON.parse(sessionStorage.getItem('categorie'));
        if(c1!==null&&c1!==undefined){
          if(c1.length>0){
            arr = c1
          }
        }
        
        if(arr.findIndex((e)=>e.Id===name)===-1){
          arr.push({Nome: name,Sede: sedeF,
            Ele: 0,
            Doc: 0, Id: name, Cliente: clienteF})
            sessionStorage.setItem('categorie', JSON.stringify(arr));
        }

        //aggiunge categoria alle categorie della pagina
        var arr1 = [...categorie]
        arr1.push({Nome: name,Sede: sedeF,
          Ele: 0,
          Doc: 0, Id: name,Cliente: clienteF})
          setCategorie(arr1)

          

          alert("Categoria aggiunta con successo");
        }); 

       
    } else {
      alert("Esiste già una categoria con questo nome.");
    }
  }
})
}else{
  alert('Inserire tutti i campi richiesti!')
}
  } //funzione che aggiunge la categoria

  return  (
    <div  className="background" style={{width:'100vw',height:'100vh',overflow:'hidden'}}>
      <NavBar2/>
      <div
        style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}
      >
        <div  style={{  width: "30vw",display:'flex',marginTop:'1.2rem' }}>
          <button style={{marginBottom:'auto',marginTop:'1rem'}} onClick={() => history.goBack()}
            className="cancelIconButton"
            
          >
            <ArrowBackIcon
             className="back-button"
            />
          </button>
          <div>
          <h4 className="page-title" style={{margin:0}}>AGGIUNGI CATEGORIA</h4>
          <h4
          className="sub-text"
        >
          Aggiungi le categorie ai tuoi clienti
        </h4>
          </div>

        </div>
        <div style={{display:'flex',marginLeft:'3.5rem',marginTop:'1rem'}}>
           {clienteF!=='default'? <div style={{width:'20rem',height:'82vh',overflowY:'auto',borderRight:'1px solid #677483'}}>
                <h4 className="normal-text">Categorie aggiunte:</h4>
            {sedi.length>0?sedi.map((s,n)=>(
              s.Cliente===clienteF?
                <div key={n} style={{marginTop:'1rem'}}>
                    <h4 className="sub-text-b">{s.Nome?s.Nome:s.Comune}</h4>
                    {returnCategorie(s.Id).length>0?returnCategorie(s.Id).map((el,i)=>(
                        <div key={i} style={{}}>
                            <h4 className="medium-text">• {el.Nome}</h4>
                        </div>
                    )):<h4 className="medium-text">Nessuna categoria aggiunta</h4>}
                </div>:null
            )):<h4 className="medium-text">Nessuna sede aggiunta</h4>}
            </div>:<div style={{width:'20rem',height:'82vh',overflowY:'auto',borderRight:'1px solid #677483'}}>
                <h4 className="normal-text">Categorie aggiunte:</h4>
                <h4 className="medium-text">Seleziona un cliente per visualizzare le<br/> categorie già aggiunte.</h4>

            </div>}

            <div style={{marginLeft:'1rem'}}>
            <h4 className="normal-text">Aggiungi categorie: </h4>
            <h4 className="sub-text-b" style={{marginTop:'1rem'}}>Seleziona il cliente a cui aggiungere la categoria:</h4>
        <select className="input-style"
        defaultValue={"default"}
        value={clienteF} onChange={(e) => {
            setClienteF(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona cliente...
                </option>
                {clienti && clienti.length > 0 && clienti.map((cliente,n)=>{
                                  return <option key={n} value={cliente.Email}>{cliente.Nome_Azienda}</option>
                              })}
        </select>
       { clienteF!=='default'?<div><h4 className="sub-text-b" style={{marginTop:'1rem'}}>Seleziona la sede a cui aggiungere la categoria:</h4>
        <select className="input-style"
        defaultValue={"default"}
        value={sedeF} onChange={(e) => {
            setSedeF(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona sede...
                </option>
                {sedi && sedi.length > 0 && sedi.map((sede,n)=>{
                  if(sede.Cliente===clienteF){
                    return <option key={n} value={sede.Id}>{sede.Nome?sede.Nome!==''?sede.Nome:sede.Comune:sede.Comune}</option>

                  }else{
                    return null
                  }
                              })}
        </select></div>:null}
       {clienteF!=='default'? <div><h4 className="sub-text-b" style={{marginTop:'1rem'}}>Seleziona categoria da aggiungere:</h4>
        <div style={{display:'flex',alignItems:'center'}}>
        <select className="input-style"
        defaultValue={"default"}
        value={selectedOption}
        onChange={(e) => {
                      setOption(e.target.value)
                    }}>
            <option value={"default"} disabled>
                  Seleziona categoria...
                </option>
                <option value={'Antincendio'}>
                    Antincendio
                </option>
                <option value={'Elettrico'}>
                    Elettrico
                </option>
                <option value={'Altro'}>
                   Altro
                </option>
        </select>
        {selectedOption==='Altro'?<input className="input-style" style={{marginLeft:'1rem',height:'1.6rem',marginTop:'10px',marginBottom:'auto'}} onChange={(e)=>setCustom(e.target.value)} placeholder="Inserisci nome categoria..."></input>:null}
        </div></div>:null}
        <br/>
        <button onClick={()=>addCatz()} className="main-button" style={{marginTop:'2rem'}}>Aggiungi</button>
        </div>
            </div>
      
 
      </div>
    </div>
  );
};

export default AggiungiCategoriaOff;
