//pagina di accesso
import "./Style/login.css";
import React from "react";
import { withRouter } from "react-router";
import { LeftScreen } from "./Components/LeftScreen"; //parte sinistra schermo
import { RightScreen } from "./Components/RightScreen"; //parte destra schermo 
const LoginPage = ({ history }) => {


  return (
    <div
  
     style={{backgroundColor: 'whitesmoke', width:'100vw', height:'100vh', position:'fixed'}}
    >
      <div style={{height:'100vh', width:'100vw',display:'flex',justifyContent: 'center'}}>
      <LeftScreen/>
      <RightScreen history={history}/>
      </div>
    </div>
  );
};

export default withRouter(LoginPage);
