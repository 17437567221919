import React, { useEffect, useState } from 'react'
import NavBar2 from '../../NavBar/NavBar2'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams } from 'react-router-dom'
import firebase from "firebase";
import { getPiano } from '../../EngineOffritore/engineOffritore';
import Sezione1 from './AggiungiMappa/Sezione1';
import Sezione2 from './AggiungiMappa/Sezione2';
import Sezione3 from './AggiungiMappa/Sezione3';
import Sezione4 from './AggiungiMappa/Sezione4';

const AggiungiMappa = ({history}) => {



    const {user_id, sede_id, piano_id} = useParams()
    const db = firebase.firestore()
    const auth = firebase.auth()

    const [piano,setPiano] = useState(null)
    const [section,setSection] = useState(0)

    const [planimetria,setPlanimetria] = useState(null) //planimetria url
    const [file,setFile] = useState(null) //planimetria file

    //punti di riferimento immagine
////PUNTO 1
    const [width1, setWidth1] = useState(40); //x1
    const [height1, setHeight1] = useState(0); //y2
////PUNTO 2
const [width2, setWidth2] = useState(500); //x2
const [height2, setHeight2] = useState(0); //y2

const [widthImage,setWidthImage] = useState()
const [heightImage,setImageHeight] = useState()

const [mapRef,setMapRef] = useState(null)



    useEffect(()=>{
        getPiano(db,user_id,sede_id,piano_id,setPiano)
    },[auth])

  return (
    <div className="background" style={{width:'100vw',height:'100vh',overflow:'hidden'}}>
       <NavBar2/>
       <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
       <div  style={{  width: "60vw",display:'flex',marginTop:'1.2rem' }}>
          <button style={{marginBottom:'auto',marginTop:'1rem'}} onClick={() => history.goBack()}
            className="cancelIconButton"
            
          >
            <ArrowBackIcon
             className="back-button"
            />
          </button>
          <div>
          <h4 className="page-title" style={{margin:0}}>{sede_id?sede_id:null} / {piano!==null?piano.Nome:null}</h4>
          <h4
          className="sub-text-b"
        >
          Aggiungi Mappa {section===0?'/ Carica planimetria':section===1?'/ Imposta referenze geografiche':null}
        </h4>
          </div>
        </div>
        {section===0?<Sezione1 setFile={setFile} planimetria={planimetria} section={section} setSection={setSection}  setPlanimetria={setPlanimetria}/>:null}
        {section===1?<Sezione2 planimetria={planimetria} section={section} setWidth1={setWidth1} setHeight1={setHeight1} setWidthImage={setWidthImage} setHeightImage={setImageHeight}
        setWidth2={setWidth2} setHeight2={setHeight2} setSection={setSection}  setPlanimetria={setPlanimetria}/>:null}
        {section===2?<Sezione3 file={file} setMapRef={setMapRef}  db={db} user_id={user_id} sede_id={sede_id} piano_id={piano_id} planimetria={planimetria} section={section}  setSection={setSection}  width1={width1} width2={width2} heightImage={heightImage} widthImage={widthImage} height1={height1} height2={height2} setPlanimetria={setPlanimetria}/>:null}
        {section===3?<Sezione4 history={history} sede_id={sede_id} piano_id={piano_id} user_id={user_id} mapRef={mapRef}  planimetria={planimetria} section={section}  setSection={setSection}  width1={width1} width2={width2} heightImage={heightImage} widthImage={widthImage} height1={height1} height2={height2} setPlanimetria={setPlanimetria}/>:null}

        </div>
    </div>
  )
}

export default AggiungiMappa