//sezione account
import React, { useState, useEffect } from "react";
import firebase from "firebase";
import BusinessIcon from '@mui/icons-material/Business';
const Sedi = (props) => {

    const db = firebase.firestore(); //database firebase

    const [categoria,setCategoria] = useState(props.categoria)
    


    return <div style={{paddingLeft:'1rem',height:'80vh',overflowY:'auto',width:'71vw'}}>
      <div>
      {/*informazioni di contatto*/}
      <div>
        <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
        <BusinessIcon style={{color:'black'}}/>

      <h4 className="header-normal" style={{marginLeft:'5px'}}>Sedi categoria</h4>
      </div>
      {categoria.Sedi.map((sede,i)=>(
        <div key={i} style={{marginTop:'2vh'}}>
          <h4 className="bold-cool" style={{margin:0,fontSize:'1vw'}}>{sede.Nome}</h4>
          <h4 className="sub-text" style={{margin:0}}>Elementi: <b>{sede.Ele}</b></h4>
          <h4 className="sub-text" style={{margin:0}}>Documenti: <b>{sede.Doc}</b></h4>

          </div>
      ))}

      </div>
      </div>
      <div style={{alignItems:'center',marginTop:'5vh',marginLeft:'auto',width:'fit-content'}}>
        </div>
    </div>
}

export default Sedi;