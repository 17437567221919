import React , { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import firebase from "firebase/app";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getUtente,getManutentori,getDocumento,getSede,getSedeName } from "../../EngineClienti/engineClienti";
import Loading from '../../loading';
import NavBar from '../../NavBar/NavBar';

const AggiungiScadenza = ({history}) => {

    const auth = firebase.auth();
    const db = firebase.firestore();
    const {id} = useParams()

    const [user,setUser] = useState(null)
    const [manutentori,setManutentori] = useState([])
    const [documento,setDocumento] = useState(null)
    const [sede,setSede] = useState(null)

    const [selectedManutentore,setSelectedManutentore] = useState('')
    const [selectedData,setSelectedData] = useState('')

    const [description,setDescription] = useState('')


    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
            getUtente(db,auth,setUser)
          }
        })
      },[auth])

      useEffect(()=>{
        if(user!==null){
         getManutentori(db,user.Email,setManutentori)
         getDocumento(db,user.Email,id,setDocumento)
      }
    
      },[user])

      useEffect(()=>{
        if(documento!==null){
         getSede(db,user.Email,documento.Sede,setSede)   
      }
    
      },[documento])

      useEffect(()=>{
        if(manutentori.length>0){
          setSelectedManutentore(manutentori[0].Email)
        }
    
      },[manutentori])

      function addScadenza(){
        if(selectedData!==''){
    
          const dateSplitted = selectedData.split("-");
          const year = dateSplitted[0];
          const month = dateSplitted[1];
          const day= dateSplitted[2];
          const hours = '12';
          const minutes = '00';
          const arr = JSON.parse(sessionStorage.getItem('documenti'));
          if(selectedManutentore===null||selectedManutentore===''){
            alert("Seleziona un manutentore")
          }
          else{

         
    
          const ref = db
            .collection("Manutenzioni")
            .doc();
            console.log(arr)
          const idR = ref.id;
            if(arr!==null){
              var indexD = arr.findIndex((e)=>e.Id===id)
              arr[indexD].Scadenza = selectedData
              arr[indexD].Act_ID = idR

              sessionStorage.setItem('documenti', JSON.stringify(arr));

            }
          
              db.collection("Documenti")
                .doc(id)
                .update({
                  Scadenza: selectedData,
                  Act_ID: idR,
                  Manutentore: selectedManutentore
                }); //aggiorna doc
    
              db.collection("Users")
                .doc(user.Email)
                .collection("Sedi")
                .doc(documento.Sede)
                .collection('Manutenzioni')
                .doc(idR)
                .set({
                 ref: idR
                }); //attività cliente
    
              db.collection("Users")
                .doc(user.Email)
                .collection("Annotazioni")
                .doc(idR)
                .set({
                  Day: day,
                  Description: '<a style={{display: "table-cell"}} target="_blank" href="'+documento.URL+'">Visualizza</a>',
                  Month: month,
                  Subject: "Scadenza: " + documento.NomeFile,
                  Year: year,
                  startHours: hours,
                  startMinutes: minutes,
                  endHours: hours,
                  endMinutes: minutes,
                  Cliente: user.Email,
                  Fornitore: selectedManutentore
                });
                var datee = new Date();
                var month2 = ('0'+(datee.getMonth()+1)).slice(-2);
                var minutess =  ('0'+(datee.getMinutes())).slice(-2);
    
                  var dataFormattata = datee.getDate()+"-"+month2+"-"+datee.getFullYear()+" "+(datee.getHours())+":"+minutess;
              db.collection("Users").doc(selectedManutentore).collection("Notifiche").doc().set({
                Nome_Azienda:user.Nome_Azienda,
                Nome_Cognome:user.Nome+" "+user.Cognome,
                Email:user.Email,
                Messaggio:"ha aggiunto una scadenza",
                Data:dataFormattata,
                Oggetto: documento.NomeFile
              })
                db.collection("Users")
                  .doc(selectedManutentore)
                  .collection("Clienti")
                  .doc(user.Email)
                .collection('Manutenzioni')
                  .doc(idR)
                  .set({
                    ref: idR,
                  }); //attivita offritore

                  //aggiorna campi manutentore
                  db.collection('Users').doc(selectedManutentore).update({
                    Current_Scad: firebase.firestore.FieldValue.increment(1)
                  })
                  //aggiorna campi cliente
                  db.collection('Users').doc(user.Email).update({
                    Current_Scad: firebase.firestore.FieldValue.increment(1)
                  })
                  //incremente scad manutentore su clienti
                db.collection("Users")
                .doc(selectedManutentore)
                .collection("Clienti")
                .doc(user.Email).update({
                  Man: firebase.firestore.FieldValue.increment(1)
                })
                //incrementa scad cliente su sedi
                db.collection('Users').doc(user.Email).collection("Sedi")
                .doc(documento.Sede).update({
                  Man: firebase.firestore.FieldValue.increment(1)
                })
                  

                  db.collection('Users').doc(selectedManutentore).get().then((doczzz)=>{
                    db.collection('Manutenzioni').doc(idR).set({
                      Cliente: user.Email,
                      Nome: user.Nome,
                      Cognome: user.Cognome,
                      Data: selectedData,
                      Descrizione: description,
                      Oggetto: "Documento: " + documento.URL,
                      Stato: "Pianificata",
                      Indirizzo: sede.Indirizzo,
                      Type:'doc',
                      Nome_AziendaCli: user.Nome_Azienda,
                      Nome_AziendaOff: doczzz.data().Nome_Azienda,
                      Titolo: "Scadenza: " + documento.NomeFile,
                      Sede: documento.Sede,
                      SedeName: documento.SedeName?documento.SedeName:documento.Sede,
                      Categoria: documento.Categoria,
                      Macrocategoria: '',
                      Operatore: selectedManutentore?selectedManutentore:null,
                      ID: idR,
                      Priorità:'Alta',
                      DocId: id
                    })
                  })

                db.collection("Users")
                  .doc(selectedManutentore)
                  .collection("Annotazioni")
                  .doc(idR)
                  .set({
                    Day: day,
                    Description: '<a style={{display: "table-cell"}} target="_blank" href="'+documento.URL+'">Visualizza</a>',
                    Month: month,
                    Subject: "Scadenza: " +documento.NomeFile,
                    Year: year,
                    startHours: hours,
                    startMinutes: minutes,
                    endHours: hours,
                    endMinutes: minutes,
                    Indirizzo: sede.Indirizzo,
                    Cliente: user.Email,
                  Fornitore: selectedManutentore?selectedManutentore:""
                  });
            
           
            alert('Scadenza aggiunta, controlla il calendario per visualizzarla')
            history.goBack()
          };
      }else{
        alert("Errore, inserire data di scadenza")
      }
      }

  return (
    <div className="background" style={{width:'100vw',height:'100vh',overflow:'hidden'}}>
      <NavBar/>
        <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
        <div  style={{  width: "30vw",display:'flex',marginTop:'1.2rem' }}>
          <button style={{marginBottom:'auto',marginTop:'1rem'}} onClick={() => history.goBack()}
            className="cancelIconButton"
            
          >
            <ArrowBackIcon
             className="back-button"
            />
          </button>
          <div>
          <h4 className="page-title" style={{margin:0}}>IMPOSTA SCADENZA</h4>
          <h4
          className="sub-text-b"
        >
          {documento?documento.NomeFile:null} {documento?'('+documento.Categoria+')':null}
        </h4>
          </div>

        </div>
        <div style={{marginLeft:'3.5rem',marginTop:'2rem',width:'30rem'}}>
                     {/*SELEZIONE SEDE */}

        <h4  className="sub-text-b">Seleziona manutentore:</h4>
        <select className="input-style" defaultValue={"default"}
        value={selectedManutentore} onChange={(e) => {
            setSelectedManutentore(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona manutentore...
                </option>
                {manutentori && manutentori.length > 0 && manutentori.map((man,n)=>{
                                  return <option key={n} value={man.Email}>{man.Nome_Azienda}</option>
                              })}
        </select> 
{/*SELEZIONE DATA */}
<h4  className="sub-text-b" style={{marginTop:'1rem'}}>Seleziona data:</h4>
        <input type='date' style={{marginTop:'0.5rem'}} className="input-style"
         onChange={(e) => {
            setSelectedData(e.target.value);
          }} placeholder="Inserisci..."/>
            <br/>
             {/*SELEZIONE DESCRIZIONE */}
<h4  className="sub-text-b" style={{marginTop:'1rem'}}>Inserisci descrizione:</h4>
        <input type='text' style={{marginTop:'0.5rem'}} className="input-style"
         onChange={(e) => {
            setDescription(e.target.value);
          }} placeholder="Inserisci descrizione..."/>
            <br/>
          <button className='main-button' style={{marginTop:'1rem'}} onClick={()=>addScadenza()}>Aggiungi</button>
        </div>
        </div>
        </div>
  )
}

export default AggiungiScadenza