import React from 'react'
import image from './images/8422.png'

const LoadingScreen = ({text}) => {
  return (
    <div style={{position:'absolute',backgroundColor:'rgba(0,0,0,0.9)',width:'100vw',height:'100vh',zIndex:1000000}}>
        <div style={{marginTop:'15vh'}}>
        <div  style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',}}>
        <img src={image} alt='mysic-logo' style={{width:'20vw',marginLeft:'auto',marginRight:'auto',}}></img>
        </div>
        <h4 className='normal-cool' style={{marginLeft:'auto',marginRight:'auto',width:'fit-content',color:'white'}}>{text}</h4>
        </div>
    </div>
  )
}

export default LoadingScreen