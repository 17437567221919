//sezione scelta piano che ospita componenti delle offerte
import '../Style/signup.css'
import { PianiOff } from './Piani/PianiOff'
import { PianiCli } from './Piani/PianiCli'
export function ChoosePlan(props){
    return(
        <div
        style={{
          marginTop: "3vh",
          width: "60vw",
          marginLeft: "auto",
          marginRight: "auto",
          height:'fit-content'
        }}
      >
        <div
          style={{
            width: "fit-content",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div style={{display:'flex',alignItems:'center'}}>
          <h4
            className='titleReg'
            style={{marginRight:0}}
          >
            Scegli un piano
          </h4>
          <h4
            className='titleReg'
            style={{marginLeft:'0.5vw',color:props.optionAz===''?'black':'#0496ff'}}
          >
           {props.optionAz===''?'...':props.optionAz==='Lavoratore'?'Manutentore':'Cliente'}
          </h4>
          </div>
         {props.optionAz==='Lavoratore'?<PianiOff plan={props.plan} setPlan={props.setPlan}/>:<PianiCli plan={props.plan} setPlan={props.setPlan}/>}
        
          {props.plan!==''?<div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content'}}>
          <button
            className="nextButton"
            style={{marginTop:'2vh'}}
              onClick={() => props.setSection(2)}
            >
              Avanti
            </button>
            
          </div>:null}
          <div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content',marginTop:'2vh'}}>
          <button
            className="backButton"
            style={{}}
              onClick={() => props.setSection(0)}
            >
              Indietro
            </button>
            </div>
        </div>
      </div>
    )
}