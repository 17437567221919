////////GENERALE


export function getScadenzeAnnualiOff(db,email,setMan,year,setManM,month,day,setManF){
    setMan([])
    setManM([])
    setManF([])
  
            db.collection('Manutenzioni').where('Operatore','==',email).get().then((manutenzioni)=>{
                manutenzioni.forEach((manz)=>{
                    if(manz.data().Data){
                        var arr = manz.data().Data.split('-')
    
                        if(parseInt(arr[0],10)===parseInt(year,10)){
                            setMan((prev)=>{
                              return [
                                ...prev,
                                  manz.data()
                                
                              ]
                            })
                          }
                            
                  if(parseInt(arr[0],10)===parseInt(year,10)&&parseInt(arr[1],10)-1===parseInt(month,10)){
                    setManM((prev)=>{
                      return [
                        ...prev,
                          manz.data()
                        
                      ]
                    })
                  }
      
                  if(parseInt(arr[0],10)===parseInt(year,10)&&parseInt(arr[1],10)-1>=parseInt(month,10)&&parseInt(arr[2],10)>=parseInt(day,10)){
                    setManF((prev)=>{
                      return [
                        ...prev,
                          manz.data()
                        
                      ]
                    })
                  }
                    }
                   
                    
                })
                
            
            })

}
export function getElementiOff(db,email,setElementi){
    setElementi([])
   db.collection('Elementi').where('Shared','==',email).get().then((elementi)=>{
    elementi.forEach((ele)=>{
        setElementi((prev)=>{
            return [
                ...prev,
                {
                    Id:ele.id,
                    ...ele.data()
                }
            ]
        })
    })
   })
}
export function getInterventiOff(db,email,setInterventi){
    setInterventi([])
   db.collection('Attività').where('Op_Email','==',email).get().then((interventi)=>{
    interventi.forEach((int)=>{
        setInterventi((prev)=>{
            return [
                ...prev,
                {
                    Id:int.id,
                    ...int.data()
                }
            ]
        })
    })
   })
}
export function getDocumentiOff(db,email,setDocumenti){
    setDocumenti([])
   db.collection('Documenti').where('Shared','==',email).get().then((documenti)=>{
    documenti.forEach((int)=>{
        setDocumenti((prev)=>{
            return [
                ...prev,
                {
                    Id:int.id,
                    ...int.data()
                }
            ]
        })
    })
   })
}
export function returnStato(stato,data){ //funzione che ritorna lo stato dell'intervento/manutenzione
    var string = data.split('-')
    var current = new Date()
    var month = null
   
    if(current.getMonth()<=11){
       month = current.getMonth()+1
    }else{
       month=1
    }   
    if(stato==='Conclusa'){
       return stato
    }else{
       if(parseInt(string[0],10)<current.getFullYear()){ //anno più basso di quello di scadenza
           return 'Scaduto'
       }else if(parseInt(string[2],10)<current.getDate()&&parseInt(string[1],10)===month){ //stesso mese giorno più basso
           return 'Scaduto'
       }else if(parseInt(string[1],10)<month&&parseInt(string[0],10)===current.getFullYear()){ //mese più basso stesso anno
           return 'Scaduto'
       }else{
           return stato
       }
    }
   }

export function getSediCliente(db,email,setSedi){
    setSedi([])

    db.collection('Users').doc(email).collection('Sedi').get().then((sedi)=>{
        sedi.forEach((sede)=>{
            setSedi((prev)=>{
                return [
                    ...prev,
                    {
                        Id:sede.id,
                        ...sede.data()
                    }
                ]
            })
        })
    })
}

////////ELEMENTI

export function getElementiClienti(db,email,setClienti,setElementi,setCategorie,setSottocategorie){
    var cli = []
    var cat = []
    var sott = []
    setClienti([])
    setElementi([])
    setCategorie([])
    setSottocategorie([])
    db.collection('Users').doc(email).collection('Clienti').get().then((clienti)=>{
        clienti.forEach((cliz)=>{
            //aggiunta clienti
            db.collection('Users').doc(cliz.data().email).get().then((cli)=>{
                setClienti((prev)=>{
                    return [
                        ...prev,
                        {Id: cli.id,
                        ...cli.data(),
                        }
                    ]
                })
                db.collection('Users').doc(email).collection('Clienti').doc(cli.id).collection('Elementi').get().then((elementi)=>{
                    elementi.forEach((elez)=>{
                        db.collection('Elementi').doc(elez.data().ref).get().then((ele)=>{
                            if(ele.exists){
                            if(!ele.data().Cliente){
                                db.collection('Elementi').doc(elez.data().ref).update({
                                    Cliente: cli.id
                                })
                            }
                            //aggiunta elementi
                            if(ele.data().Nome){
                                setElementi((prev)=>{
                                    return [
                                        ...prev,
                                        {
                                            Id: ele.id,
                                            Cliente: cli.id,
                                            ...ele.data(),
                                        }
                                    ]
                                })
                            }
                            
                            //aggiunta categoria
                            var indexx = cat.findIndex((e)=>e.Nome===ele.data().Categoria&&e.Cliente===cli.id)
                            if(indexx===-1){
                                cat.push( {
                                    Cliente: cli.id,
                                    Nome: ele.data().Categoria
                                })
                                setCategorie((prev)=>{
                                    return [
                                        ...prev,
                                        {
                                            Cliente: cli.id,
                                            Nome: ele.data().Categoria
                                        }
                                        
                                    ]
                                })
                            }
                                
                    
                            //aggiunta sottocategoria
                            var indexxx = sott.findIndex((e)=>e.Nome===ele.data().Macrocategoria&&e.Cliente===cli.id)
                            if(indexxx===-1){
                                sott.push( {
                                    Cliente: cli.id,
                                    Nome: ele.data().Macrocategoria,
                                    Categoria: ele.data().Categoria
                                })
                                setSottocategorie((prev)=>{
                                    return [
                                        ...prev,
                                        {
                                            Cliente: cli.id,
                                            Nome: ele.data().Macrocategoria,
                                            Categoria: ele.data().Categoria
                                        }
                                    ]
                                })
                            }
                            
                        }
                        })
                        
                    })
                })  
            })
           
        })
    })

}
export function getClientiSediCategorie(db,email,setClienti,setSedi,setCategorie){
    setClienti([])
    setSedi([])
    setCategorie([])

    db.collection('Users').doc(email).collection('Clienti').get().then((clienti)=>{
        clienti.forEach((cliz)=>{
            db.collection('Users').doc(cliz.data().email).get().then((cli)=>{
                setClienti((prev)=>{
                    return [
                        ...prev,
                        {
                            Id: cli.id,
                            ...cli.data()
                        }
                    ]
                })
                db.collection('Users').doc(cli.id).collection('Sedi').get().then((sedi)=>{
                    sedi.forEach((sede)=>{
                        setSedi((prev)=>{
                            return [
                                ...prev,
                                {
                                    Id: sede.id,
                                    ...sede.data(),
                                    Cliente: cli.id
                                }
                            ]
                        })
                        db.collection('Users').doc(cli.id).collection('Sedi').doc(sede.id).collection('Categorie').get().then((categorie)=>{
                            categorie.forEach((cat)=>{
                                setCategorie((prev)=>{
                                    return [
                                        ...prev,
                                        {
                                            Id: cat.id,
                                            ...cat.data(),
                                            Cliente: cli.id
                                        }
                                    ]
                                })
                            })
                        })
                    })
                })
            })
           
            
        })
    })
}  
export function getClientiSediSottocategorie(db,email,setClienti,setCategorie,setSottocategorie,arrAnti,arrEle,setAllCategorie){
    setClienti([])
    setCategorie([])
    setSottocategorie([])
    var arr = []
    var arr2 = []
    db.collection('Users').doc(email).collection('Clienti').get().then((clienti)=>{
        clienti.forEach((cliz)=>{
            db.collection('Users').doc(cliz.data().email).get().then((cli)=>{
                setClienti((prev)=>{
                    return [
                        ...prev,
                        {
                            Id: cli.id,
                            ...cli.data()
                        }
                    ]
                })
                db.collection('Users').doc(cli.id).collection('Sedi').get().then((sedi)=>{
                    sedi.forEach((sede)=>{
                        db.collection('Users').doc(cli.id).collection('Sedi').doc(sede.id).collection('Categorie').get().then((categorie)=>{
                            categorie.forEach((cat)=>{

                                setAllCategorie((prev)=>{
                                    return [
                                        ...prev,
                                        {
                                            Id: cat.id,
                                            ...cat.data(),
                                            Cliente: cli.id
                                        }
                                    ]
                                })

                                const index = arr.findIndex((e)=>e.Id===cat.id&&cli.id===e.Cliente)
                                if(arr.length===0||index===-1){
                                    arr.push({Id:cat.id, Cliente: cli.id,...cat.data()})
                                    setCategorie((prev)=>{
                                        return [
                                            ...prev,
                                            {
                                                Id: cat.id,
                                                ...cat.data(),
                                                Cliente: cli.id
                                            }
                                        ]
                                    })
                                }
                               
                                db.collection('Users').doc(cli.id).collection('Sedi').doc(sede.id).collection('Categorie').doc(cat.id).collection('Sottocategorie').get().then((sottocategorie)=>{
                                    sottocategorie.forEach((sottocat)=>{
                                        if(arrAnti.indexOf(sottocat.data().Nome)!==-1||arrEle.indexOf(sottocat.data().Nome)!==-1){
                                        }else{
                                            console.log('bella',sottocat.data())
                                            const index2 = arr2.findIndex((e)=>e.Id===sottocat.id&&cli.id===e.Cliente)
                                            if(arr2.length===0||index2===-1){
                                                arr2.push({Id:sottocat.id, Cliente: cli.id,...sottocat.data()})
                                            setSottocategorie((prev)=>{
                                                return [
                                                    ...prev,
                                                    {
                                                        Id: sottocat.id,
                                                        ...sottocat.data(),
                                                        Cliente: cli.id,
                                                        Categoria: cat.id
                                                    }
                                                ]
                                            })
                                        }
                                    }
                                        
                                    })
                                }) 
                            })
                        })
                    })
                })
            })
           
            
        })
    })
}  
export function getExtraSottocategorie(db,email,setSottocategorieExtra){
    db.collection('Users').doc(email).collection('SottocategorieAggiuntive').get().then((sotts)=>{
        sotts.forEach((sott)=>{
            console.log(sott.data())
            setSottocategorieExtra((prev)=>{
                return [
                    ...prev,
                    {
                        Id: sott.id,
                        ...sott.data()
                    }
                ]
            })
        })
    })
}

//////////////////DOCUMENTI//////////////////////////////

export function getDocumenti(db,email,setClienti,setCategorie,setCartelle,setDocumenti){
    setClienti([])
    setCategorie([])
    setCartelle([])
    setDocumenti([])

    var categorie = []
    var cartelle = []
    //////////SEDI/////////////////
    db.collection('Users').doc(email).collection('Clienti').get().then((clienti)=>{
        clienti.forEach(cliz => {
            db.collection('Users').doc(cliz.data().email).get().then((cli)=>{
                setClienti((prev)=>{ 
                    return [
                        ...prev,
                        {
                            Id: cli.id,
                            ...cli.data(),
    
                        }
                    ]
                }) //aggiunge clienti
                  //////////DOCUMENTI///////////////////
            db.collection('Users').doc(email).collection('Clienti').doc(cli.id).collection('Documenti').get().then((documenti)=>{
                documenti.forEach((docx)=>{
                    db.collection('Documenti').doc(docx.data().ref).get().then((docz)=>{
                        if(docz.exists){
                            setDocumenti((prev)=>{
                                return [
                                    ...prev,
                                    {
                                        Id:docz.id,
                                        ...docz.data(),
                                        Versioni: docz.data().Versioni?docz.data().Versioni:null
                                    }
                                ]
                            })   
                            
                            var index = categorie.findIndex((e)=>e.Nome===docz.data().Categoria&&e.Cliente===docz.data().Autore&&e.Sede===docz.data().Sede)
                            var index2 = cartelle.findIndex((e)=>e.Nome===docz.data().Cartella&&e.Cliente===docz.data().Autore&&e.Sede===docz.data().Sede&&e.Categoria===docz.data().Categoria)
                            if(index===-1){
                                categorie.push({
                                    Nome: docz.data().Categoria,
                                    Cliente: docz.data().Autore,
                                    Sede: docz.data().Sede
                                })
                                setCategorie((prev)=>{
                                    return [
                                        ...prev,
                                        {
                                            Nome: docz.data().Categoria,
                                            Cliente: docz.data().Autore,
                                            Sede: docz.data().Sede
                                        }
                                    ]
                                })
                            }
                            if(index2===-1){
                                cartelle.push({
                                    Nome: docz.data().Cartella,
                                    Cliente: docz.data().Autore,
                                    Sede: docz.data().Sede,
                                    Categoria: docz.data().Categoria
    
                                })
                                setCartelle((prev)=>{
                                    return [
                                        ...prev,
                                        {
                                            Nome: docz.data().Cartella,
                                            Cliente: docz.data().Autore,
                                            Sede: docz.data().Sede,
                                            Categoria: docz.data().Categoria
                                        }
                                    ]
                                })
                            }
                            
                        }
                    })
                    
                })
            })
            })
            

          
        });
    })
}
export function getDocumento(db,id,setDocumento){
    db.collection('Documenti').doc(id).get().then((doc)=>{
        setDocumento(doc.data())
    })
}
export function getCartelle(db,cliente,sede,categoria,setCartelle){
    setCartelle([])
    db.collection('Users').doc(cliente).collection('Sedi').doc(sede).collection('Categorie').doc(categoria)
    .collection('Cartelle').get().then((cartelle)=>{
        cartelle.forEach((cartella)=>{
            setCartelle((prev)=>{
                return [
                    ...prev,
                    {
                        Id:cartella.id,
                        ...cartella.data()
                    }
                ]
            })
        })
    })
}


///////////////INTERVENTI/////////////////

export function getInterventi(db,email,setSedi,setClienti,setInterventi){
    setInterventi([])
    setClienti([])
    setSedi([])

    var arr = []

    db.collection('Users').doc(email).collection("Clienti").get().then((clienti)=>{
        clienti.forEach((clientez)=>{
            db.collection('Users').doc(clientez.data().email).get().then((cliente)=>{
                setClienti((prev)=>{
                    return [
                        ...prev,
                        {
                            Id: cliente.id,
                            ...cliente.data()
                        }
                    ]
                })
                db.collection('Users').doc(email).collection('Clienti').doc(cliente.id).collection('Attività').get().then((interventi)=>{
                    interventi.forEach((intervento)=>{
                        db.collection('Attività').doc(intervento.data().ref).get().then((int)=>{
                            if(int.exists){
                                if(int.data().SottoAttività===true){
                                    var added = false
                        var control = false
                                    db
                                    .collection("Attività")
                                    .doc(int.id)
                                    .collection("Attività").get().then((sottoManutenzioni)=>{
                                        var c = 1
                                        var areNotClosed = null
                                        var arrz =[]

                                        if(sottoManutenzioni.docs.length>0){
                                            sottoManutenzioni.forEach((sott)=>{
                                                arrz.push(sott.data())

                                                if(c===sottoManutenzioni.docs.length){
                                                       areNotClosed = arrz.find((e)=>e.Stato!=='Conclusa')

                                                    console.log(areNotClosed)

                                                    if(areNotClosed){
                                                        areNotClosed=null

                                                        setInterventi((prev)=>{
                                                            return [
                                                                ...prev,
                                                                {
                                                                    Id: int.id,
                                                                    ...int.data(),
                                                                    Stato: returnStato(int.data().Stato,int.data().Data),
                                                                    Archiviato: int.data().Archiviato?int.data().Archiviato:false,
                                                                    Sott_Attivi: true
                                                                }
                                                            ]
                                                        })
                                                    }else{
                                                        setInterventi((prev)=>{
                                                            return [
                                                                ...prev,
                                                                {
                                                                    Id: int.id,
                                                                    ...int.data(),
                                                                    Stato: returnStato(int.data().Stato,int.data().Data),
                                                                    Archiviato: int.data().Archiviato?int.data().Archiviato:false,
                                                                    Sott_Attivi: false
                                                                }
                                                            ]
                                                        })
                                                    }
    
                                                }
                                            
                                                c++
                                              
                                            })
                                        }
                                 
                                    })
                                }else{
                                setInterventi((prev)=>{
                                    return [
                                        ...prev,
                                        {
                                            Id: int.id,
                                            ...int.data(),
                                            Stato: returnStato(int.data().Stato,int.data().Data),
                                            Archiviato: int.data().Archiviato?int.data().Archiviato:false,
                                            Sott_Attivi: false

                                        }
                                    ]
                                })
                            
                            

                            var index = arr.findIndex((e)=>e.Nome===int.data().Sede&&int.data().Cliente===cliente.id)

                            if(index===-1){
                                setSedi((prev)=>{
                                    return [
                                        ...prev,
                                        {
                                            Nome: int.data().Sede,
                                            Cliente: int.data().Cliente
                                        }
                                    
                                    ]
                                })
                                arr.push({ Nome: int.data().Sede,
                                    Cliente: int.data().Cliente})

                            }
                            
                        }
                    }

                        })
                    })
                })

            })
            


        })
    })

}

///////////////SCADENZE/////////////////

export function getScadenze(db,email,setSedi,setClienti,setScadenze){
    setScadenze([])
    setClienti([])
    setSedi([])

    var arr = []
    var arr2 = []

    db.collection('Users').doc(email).collection("Clienti").get().then((clienti)=>{
        clienti.forEach((clientez)=>{
            db.collection('Users').doc(clientez.data().email).get().then((cliente)=>{
                setClienti((prev)=>{
                    return [
                        ...prev,
                        {
                            Id: cliente.id,
                            ...cliente.data()
                        }
                    ]
                })
                db.collection('Users').doc(email).collection('Clienti').doc(cliente.id).collection('Manutenzioni').get().then((scadenze)=>{
                    scadenze.forEach((intervento)=>{
                        var arrz = []


                        db.collection('Manutenzioni').doc(intervento.data().ref).get().then((int)=>{
                            if(int.exists){
                                if(int.data().SottoAttività===true){
                                    var added = false
                                var control = false
                                    db.collection("Users")
                                    .doc(int.data().Cliente)
                                    .collection("Sedi")
                                    .doc(int.data().Sede)
                                    .collection("Manutenzioni")
                                    .doc(int.id)
                                    .collection("Manutenzioni").get().then((sottoManutenzioni)=>{
                                        var c = 1
                                        if(sottoManutenzioni.docs.length>0){
                                            sottoManutenzioni.forEach((sott)=>{


                                              db.collection("Manutenzioni").doc(sott.data().ref).get().then((sottoManutenzione)=>{
                                                arrz.push(sottoManutenzione.data())

                                                if(c===sottoManutenzioni.docs.length){
                                                    var areNotClosed = arrz.find((e)=>e.Stato!=='Conclusa')
                                                    console.log(areNotClosed)

                                                    if(areNotClosed){
                                                        setScadenze((prev)=>{
                                                            return [
                                                             ...prev,
                                                             {
                                                                 Id: int.id,
                                                                 ...int.data(),
                                                                 Stato: returnStato(int.data().Stato,int.data().Data),
                                                                 Archiviato: int.data().Archiviato?int.data().Archiviato:false,
                                                                 Sott_Attivi: true
                 
                                                             }
                                                            ]
                                                             
                                                         })
                                                    }else{
                                                        setScadenze((prev)=>{
                                                            return [
                                                             ...prev,
                                                             {
                                                                 Id: int.id,
                                                                 ...int.data(),
                                                                 Stato: returnStato(int.data().Stato,int.data().Data),
                                                                 Archiviato: int.data().Archiviato?int.data().Archiviato:false,
                                                                 Sott_Attivi: false
                 
                                                             }
                                                            ]
                                                             
                                                         })
                                                    }
    
                                                }
                                            
                                                c++


                                                 
                                              })
                                              
                                            })
                                        }
                                 
                                    })
                                }else{
                                    added=true
                                    setScadenze((prev)=>{
                                        return [
                                         ...prev,
                                         {
                                             Id: int.id,
                                             ...int.data(),
                                             Stato: returnStato(int.data().Stato,int.data().Data),
                                             Archiviato: int.data().Archiviato?int.data().Archiviato:false,
                                             Sott_Attivi: false

                                         }
                                        ]
                                         
                                     })
                                }
                                    var index = arr.findIndex((e)=>e.Nome===int.data().Sede&&int.data().Cliente===cliente.id)
    
                                    if(index===-1){
                                        setSedi((prev)=>{
                                            return [
                                                ...prev,
                                                {
                                                    Nome: int.data().Sede,
                                                    Cliente: int.data().Cliente
                                                }
                                            
                                            ]
                                        })
                                        arr.push({ Nome: int.data().Sede,
                                            Cliente: int.data().Cliente})
        
                                    }
                            }

                          
                            
                        

                        })
                    })
                })

            })
            


        })
    })

}

export function getSottocategorieElementi(db,email,sede,categoria,setSottocategorie,setElementi){
    db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Categorie').doc(categoria).collection('Sottocategorie').get().then((sottocategorie)=>{
        sottocategorie.forEach((sott)=>{
            setSottocategorie((prev)=>{
                return [
                    ...prev,
                    {
                        Id: sott.id,
                        ...sott.data()
                    }
                ]
            })
            db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Categorie').doc(categoria).collection('Sottocategorie')
            .doc(sott.id).collection('Elementi').get().then((elementi)=>{
                elementi.forEach((elemento)=>{
                    setElementi((prev)=>{
                        return [
                            ...prev,
                            {
                                Id:elemento.id,
                                ...elemento.data()
                            }
                        ]
                    })
                })
                
            })  
        })
    })
}

////////////MAPPE///////////////////

export function getSediClienti(db,email,setClienti,setSedi){

    setClienti([])
    setSedi([])

    db.collection('Users').doc(email).collection('Clienti').get().then((clienti)=>{
        clienti.forEach((cli)=>{
            db.collection('Users').doc(cli.id).get().then((cliente)=>{
                setClienti((prev)=>{
                    return [
                        ...prev,
                        {
                            Id: cliente.data().Email,
                            ...cliente.data()
                        }
                    ]

                })
                db.collection('Users').doc(cliente.id).collection('Sedi').get().then((sedi)=>{
                    sedi.forEach((sede)=>{
                        setSedi((prev)=>{
                            return [
                                ...prev,
                                {Id: sede.id,
                                Cliente: cliente.data().Email,
                                ...sede.data()
                                }
                            ]
                        })
                    })
                })

            })
        })
    })
}

export function getPiani(db,email,sede,setPiani,setMappe){
    setPiani([])
    db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Piani').orderBy('Index','desc').get().then((piani)=>{
        piani.forEach((piano)=>{
            setPiani((prev)=>{
                return [
                    ...prev,
                    {
                        Id: piano.id,
                        ...piano.data()
                    }
                ]
            })
            db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Piani').doc(piano.id).collection('Mappe').get().then((mapz)=>{
                mapz.forEach((m)=>{
                    db.collection('Mappe').doc(m.data().ref).get().then((mappa)=>{
                        setMappe((prev)=>{
                            return [
                                ...prev,
                                {
                                    Id: mappa.id,
                                    ...mappa.data(),
                                    Piano: piano.id
                                }
                            ]
                        })
                    })
                })
            })
        })
    })  
}

export function getPiano(db,email,sede,piano,setPiano){
    db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Piani').doc(piano).get().then((p)=>{
        setPiano(p.data())

    })
}
export function getMappa(db,mappa_id,setMappa){
    db.collection('Mappe').doc(mappa_id).get().then((mappa)=>{
        setMappa(mappa.data())
    })
}
export function getElementiCliente(db,email,cliente,setElementi,setCategorie,setSottocategorie,setElementiPosizionati,mappa_id,sede_id){
    var cli = cliente
    var cat = []
    var sott = []
    setElementi([])
    setCategorie([])
    setSottocategorie([])

        db.collection('Users').doc(email).collection('Clienti').doc(cliente).collection('Elementi').get().then((elementi)=>{
            elementi.forEach((elez)=>{
                    db.collection('Elementi').doc(elez.data().ref).get().then((ele)=>{
                            //aggiunta elementi
                            if(ele.exists){
                                if(ele.data().Sede===sede_id){
                                    if(ele.data().Posizione&&ele.data().Mappa===mappa_id){
                                        setElementiPosizionati((prev)=>{
                                            return [
                                                ...prev,
                                                {
                                                    Id: ele.id,
                                                    Cliente: cli,
                                                    ...ele.data()
                                                }
                                            ]
                                           
                                        })
                                    }else if(!ele.data().Mappa&&!ele.data().Posizione){
                                        setElementi((prev)=>{
                                            return [
                                                ...prev,
                                                {
                                                    Id: ele.id,
                                                    Cliente: cli,
                                                    ...ele.data()
                                                }
                                            ]
                                        })
                                    }
                                    
                                    //aggiunta categoria
                                    var indexx = cat.findIndex((e)=>e.Nome===ele.data().Categoria&&e.Cliente===cli)
                                    if(indexx===-1){
                                        cat.push( {
                                            Cliente: cli,
                                            Nome: ele.data().Categoria
                                        })
                                        setCategorie((prev)=>{
                                            return [
                                                ...prev,
                                                {
                                                    Cliente: cli,
                                                    Nome: ele.data().Categoria,
                                                    
                                                }
                                                
                                            ]
                                        })
                                    }
                                        
                            
                                    //aggiunta sottocategoria
                                    var indexxx = sott.findIndex((e)=>e.Nome===ele.data().Macrocategoria&&e.Cliente===cli)
                                    if(indexxx===-1){
                                        sott.push( {
                                            Cliente: cli,
                                            Nome: ele.data().Macrocategoria,
                                            Categoria: ele.data().Categoria
                                        })
                                        setSottocategorie((prev)=>{
                                            return [
                                                ...prev,
                                                {
                                                    Cliente: cli,
                                                    Nome: ele.data().Macrocategoria,
                                                    Categoria: ele.data().Categoria,
                                                    Open: false,
                                                    Visualize:true,
                                                }
                                            ]
                                        })
                                    }
                                }
                            }
                            
                          
                            
                        })
                        
                    })
                })  
           
      
    }

//////////CLIENTI///////////////

export function getClienti(db,email,setClienti){
    setClienti([])
    db.collection('Users').doc(email).collection('Clienti').get().then((clienti)=>{
        clienti.forEach((cli)=>{
            db.collection('Users').doc(cli.id).get().then((cl)=>{
                setClienti((prev)=>{
                 return [
                    ...prev,
                    {
                        Id: cl.id,
                        CurrentEmail: cl.data().CurrentEmail?cl.data().CurrentEmail: cl.data().Email,
                        ...cl.data()
                    }
                 ]   
                })
            })
        })
    })
}
export function getRichieste(db,email,setRichieste){
    setRichieste([])
    db.collection('Users').doc(email).collection('Richieste').get().then((clienti)=>{
        clienti.forEach((cli)=>{
                setRichieste((prev)=>{
                 return [
                    ...prev,
                    {
                        Id: cli.id,
                        ...cli.data()
                    }
                 ]   
                })
        })
    })
}