//piani per i clienti
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
export function PianiCli(props){
    return(
        <div style={{width:'60vw',height:'46vh',display:'flex',marginTop:'2vh',marginBottom:'1vh'}}>
              <div onClick={()=>props.setPlan(0)} style={{cursor:'pointer',height:'45vh',width:'17vw',border:props.plan===0?'1px solid #0496ff':'none',borderRadius:20,position:'relative'}}>
            <h4 style={{transition:'0.5s ease-in-out',color:props.plan===0?'#0496ff':'grey',fontSize:'1.2vw',fontFamily:'Montserrat',marginLeft:'1vw',marginTop:'1vh',marginBottom:0}}>Base</h4>
            <div style={{display:'flex'}}>
                <h4 style={{color:'#5C6672',fontSize:'1.5vw',fontFamily:'Montserrat',marginLeft:'1vw',marginTop:'1vh',marginBottom:'1vh'}}>Gratis</h4>

                </div>
                <h4 style={{marginLeft:'1vw',fontWeight:'normal',color:'grey',fontSize:'0.7vw',fontFamily:'Montserrat',marginTop:0,marginBottom:'1vh'}}>Per attività di piccole dimensioni</h4>

            <div style={{marginLeft:'1vw'}}>
            <div style={{display:'flex'}}>
            <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>

                    <h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Elementi</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Documenti</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Interventi</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Manutenzioni</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Max. Elementi: 70</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Spazio disponibile: 100MB</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Max. Sottoaccount: 1</h4> 
                    </div>
                    </div>
                   
        </div> 
        <div onClick={()=>props.setPlan(1)} style={{cursor:'pointer',marginLeft:'auto',height:'45vh',width:'17vw',border:props.plan===1?'1px solid #0496ff':'none',borderRadius:20,position:'relative'}}>
            <h4 style={{transition:'0.5s ease-in-out',color:props.plan===1?'#0496ff':'grey',fontSize:'1.2vw',fontFamily:'Montserrat',marginLeft:'1vw',marginTop:'1vh',marginBottom:0}}>Pro</h4>
            <div style={{display:'flex'}}>
                <h4 style={{color:'#5C6672',fontSize:'1.5vw',fontFamily:'Montserrat',marginLeft:'1vw',marginTop:'1vh',marginBottom:'1vh'}}>€7,99</h4>
                <h4 style={{color:'grey',fontSize:'1vw',fontFamily:'Montserrat',marginLeft:'0.5vw',marginTop:'auto',marginBottom:'auto'}}>/ Mese</h4>

                </div>
                <h4 style={{marginLeft:'1vw',fontWeight:'normal',color:'grey',fontSize:'0.7vw',fontFamily:'Montserrat',marginTop:0,marginBottom:'1vh'}}>Per attività di medie dimensioni</h4>

            <div style={{marginLeft:'1vw'}}>
            <div style={{display:'flex'}}>
            <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>

                    <h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Elementi</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Documenti</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Interventi</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Manutenzioni</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Mappe</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Max. Elementi: 200</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Spazio disponibile: 1GB</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Max. Sottoaccount: 5</h4> 
                    </div>
                    </div>
                   
        </div> 

        <div onClick={()=>props.setPlan(2)} style={{cursor:'pointer',marginLeft:'auto',height:'45vh',width:'17vw',border:props.plan===2?'1px solid #0496ff':'none',borderRadius:20,position:'relative'}}>
            <h4 style={{transition:'0.5s ease-in-out',color:props.plan===2?'#0496ff':'grey',fontSize:'1.2vw',fontFamily:'Montserrat',marginLeft:'1vw',marginTop:'1vh',marginBottom:0}}>Pro XL</h4>
            <div style={{display:'flex'}}>
                <h4 style={{color:'#5C6672',fontSize:'1.5vw',fontFamily:'Montserrat',marginLeft:'1vw',marginTop:'1vh',marginBottom:'1vh'}}>€19,99</h4>
                <h4 style={{color:'grey',fontSize:'1vw',fontFamily:'Montserrat',marginLeft:'0.5vw',marginTop:'auto',marginBottom:'auto'}}>/ Mese</h4>

                </div>
                <h4 style={{marginLeft:'1vw',fontWeight:'normal',color:'grey',fontSize:'0.7vw',fontFamily:'Montserrat',marginTop:0,marginBottom:'1vh'}}>Per attività di grandi dimensioni</h4>

            <div style={{marginLeft:'1vw'}}>
            <div style={{display:'flex'}}>
            <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>

                    <h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Elementi</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Documenti</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Interventi</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Manutenzioni</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Mappe</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Max. Elementi: 1000</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Spazio Disponibile: 5GB</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Max. Sottoaccount: 10</h4> 
                    </div>
                    </div>
                   
        </div>  
        </div>
    )
}