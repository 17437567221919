//pagina registrazione che ospita i vari step
import React, { useState } from "react";
import { withRouter } from "react-router";
import app from "../base";
import firebase from "firebase/app";
import "./Style/signup.css";
import logo2 from "../images/mysic largo.png";
import emailjs from "emailjs-com"; //libreria per mandare le mail "emailjs.com"
import { StepOne } from "./Components/StepOne"; //info personali
import { FinalStep } from "./Components/FinalStep"; //inserimento credenziali accesso
import { CustomerStep } from "./Components/CustomerStep"; //inserimento email off con cui connettere l account cli
import { offritoreReg } from "./Engine/OffritoreCreation"; //funzione creazione account off
import { customerReg } from "./Engine/CustomerCreation"; //funzione creazione account cli
import { BottomLink } from "./Components/BottomLink"; //link bottom
import { ChooseType } from "./Components/ChooseType"; //selezione tipo cli o off
import { ChoosePlan } from "./Components/ChoosePlan"; //selezione piano
import Riassunto from "./Components/Riassunto"; //riassunto info inserite e checkout
import { IconButton, Tooltip } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CodeStep from "./Components/CodeStep"; //step visualizza info codice inserito
const SignUp = ({ history }) => {
  const [optionAz, setOptionAz] = useState(""); //seconda scelta per az "offritore" o "consumatore"
  const [emaillav, setEmailLav] = useState(""); //ragione sociale offritore
  const [lav,setLav] = useState(null) //profilo offritore
  const db = firebase.firestore();
  const [nome, setNome] = useState(""); //nome utente
  const [cognome, setCognome] = useState(""); //cognome cliente
  const [telefono, setTelefono] = useState(""); //telefono cliente
  const auth = firebase.auth();
  const [section, setSection] = useState(0); //sezione
  const [nomeAz, setNomeAz] = useState(""); //ragione sociale
  const [indirizzo, setIndirizzo] = useState(""); //indirizzo azienda
  const [luogo, setLuogo] = useState(""); //comune azienda
  const [password, setPassword] = useState("");  //password
  const [confirm, setConfirm] = useState(""); //password di conferma
  const [email, setEmail] = useState(""); //email
  const [plan,setPlan] = useState(""); //tipo di piano
  const [condizioni,setCondizioni] = useState(false) //condizione accettate
  const [code,setCode] = useState('') //codice offritore per reg gratuita cliente
  const [codeConfirm,setCodeConfirm] = useState(false) //conferma codice
  const [codeInfo,setCodeInfo] = useState(null) //info codice
  

  const handleSignUp = async () => {
    if (
      password !== "" &&
      email !== "" &&
      nome !== "" &&
      cognome !== "" &&
      telefono !== "" &&
      nomeAz !== "" &&
      luogo !== "" &&
      indirizzo !== "" &&
      optionAz !== ""
    ) {
      if (password === confirm) {
        try {
          if (password.length < 6) {
            //controllo su email e pw inserita
            alert("Inserire una password con + di 6 caratteri!");
          } else if (email.toString().toLowerCase().indexOf("@") === -1) {
            alert("Email non valida!");
          } else {
            try {
              
              var toSend = {
                from_email: email.toString().toLowerCase().trim(),
                from_name: nome,
                from_password: password,
                from_useremail: email.toString().toLowerCase().trim(),
              };

              if (optionAz === "Lavoratore") {
                //se offritore
               offritoreReg(app,auth,email.toString().toLowerCase().trim(),password,db,nome,cognome,nomeAz,indirizzo,luogo,telefono,optionAz,emailjs,toSend,history,plan)


              } else if (optionAz === "Cliente") {
              //se cliente
              var lavz = ''
              if(emaillav!==''){
                lavz = emaillav.toString().toLowerCase().trim()
              }
              customerReg(app,auth,email.toString().toLowerCase().trim(),password,db,nome,cognome,nomeAz,indirizzo,luogo,telefono,optionAz,emailjs,toSend,history,lavz,plan,codeInfo,codeConfirm,lav)

              }
            } catch (error) {
              alert(error.message);
            }
          }
        } catch (e) {
          alert("Errore di registrazione: " + e.message);
        }
      } else {
        alert("Password non coincidono");
      }
    } else {
      alert("Campi mancanti");
    }
  }; //funzione di registrazione
  function checkCode(){
    db.collection('Codes').doc(code).get().then((codez)=>{
      if(codez.exists){
        if(codez.data().Riscattato===false){
          setCodeInfo(codez.data())
          setOptionAz('Cliente')
          setPlan(codez.data().Tipo)
          setCodeConfirm(true)
          setEmailLav(codez.data().Operator)
          setSection(6)
        }else{
          alert('Codice inesistente o già utilizzato')

        }
      }else{
        alert('Codice inesistente o già utilizzato')
      }
    })
  } //controlla codice inserito


  return (
    <div
      className="registerpage"
      style={{ height: "100vh", backgroundColor: "white", position: "fixed" }}
    >
     <BottomLink/>
      <img alt='Mysic Logo Registrazione'
        src={logo2}
        onClick={()=>history.push('/')}
        style={{
          cursor:'pointer',
          width: "15vw",
          position: "fixed",
          left: "5vw",
          right: "auto",
          top: "2vh",
          zIndex: -1,
        }}
      />

      <h4
      className='bigTitle'
     
      >
        Creazione account Mysic
      </h4>
      <h4
      style={{marginTop:'1vh'}}
       className="subtitleReg"
      >
       
        Un account, la completa gestione della tua sicurezza.
      </h4>
      {section === 0 ? (
        <ChooseType setOptionAz={setOptionAz} optionAz={optionAz} setSection={setSection}/>
      ) : null}
      {section === 1 ? (
        <ChoosePlan plan={plan} setPlan={setPlan} setOptionAz={setOptionAz} optionAz={optionAz} setSection={setSection}/>
      ) : null}
      {section === 2 ? (
        <StepOne cConfirm={codeConfirm} setNomeAz={setNomeAz} nomeAz={nomeAz} setLuogo={setLuogo} luogo={luogo} setIndirizzo={setIndirizzo} indirizzo={indirizzo} setCognome={setCognome} setNome={setNome} setTelefono={setTelefono} telefono={telefono} cognome={cognome} nome={nome} setSection={setSection}/>
      ) : null}
      {(section === 3 && optionAz === "Lavoratore") || section === 4? (
         <FinalStep setEmail={setEmail} condizioni={condizioni} setCondizioni={setCondizioni} email={email} setPassword={setPassword} password={password} confirm={confirm} setConfirm={setConfirm}  section={section} setSection={setSection}/>
      ) : null}
      {section === 3 && optionAz === "Cliente"? (
        <CustomerStep lav={lav} setLav={setLav} setEmailLav={setEmailLav} emaillav={emaillav} db={db} setSection={setSection}/>
        ) : null}
        {section === 5? (
        <Riassunto cConfirm={codeConfirm} handleSignUp={handleSignUp} confirm={confirm} password={password} email={email} optionAz={optionAz} plan={plan} telefono={telefono} cognome={cognome} nome={nome} indirizzo={indirizzo} nomeAz={nomeAz} luogo={luogo} emaillav={emaillav}  setSection={setSection}/>
        ) : null}
        {section === 6? (
        <CodeStep cConfirm={setCodeConfirm} codeInfo={codeInfo} password={password} email={email} optionAz={optionAz} plan={plan} telefono={telefono} cognome={cognome} nome={nome} indirizzo={indirizzo} nomeAz={nomeAz} luogo={luogo} emaillav={emaillav}  setSection={setSection}/>
        ) : null}

      <div style={{position:'absolute',bottom:'7vh',right:'1vw',height:'5vh',}}>
          <h4 style={{fontWeight:'normal',color:'grey',fontSize:'0.7vw',fontFamily:'Montserrat',marginTop:0,marginBottom:'1vh',width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>Hai un codice?</h4>
          <div style={{display:'flex',alignItems:'center'}}>
          <input
              onChange={(e) => setCode(e.target.value)}
              value={code}
              type="text"
              placeholder="Inserisci..."
              className='inputReg'

            />
            <Tooltip title='Invia'>
            <IconButton onClick={()=>checkCode()} style={{width:'fit-content',marginLeft:10}}>
            <CheckCircleIcon/>
            </IconButton>
            </Tooltip>
          
          </div>
         
      </div>
    </div>
  );
};
export default withRouter(SignUp);
