//popup info piano utente
import React, { useState, useEffect } from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export function InfoPlan(props){

    return props.trigger===true?<div  style={{
        borderTop: "1px solid #118ab2",
        borderBottom: "1px solid #118ab2",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        width: "100vw",
        zIndex: 1001,
      }}>
        <div  style={{
            position: "absolute",
            left: "25vw",
            top: "15vh",
            backgroundColor: "white",
            width: "50vw",
            padding: "5px",
            height: "70vh",
            borderRadius: 10,
          }}>
 <div style={{ textAlign: "left", width: "fit-content" }}>
            <button
              className="cancelIconButton"
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon
                style={{ width: "15px", height: "15px" }}
                className="cancelIcon"
              />
            </button>
          </div>
          <div>
          <h4
            style={{
              fontSize: "2vw",
              color: "#0496ff",
              fontFamily: "Montserrat",
              letterSpacing: "-0.1vw",
              fontWeight: "bold",
              width: "fit-content",
              marginRight: "auto",
              marginLeft: "auto",
              marginTop: 0,
              marginBottom: 0,
            }}
          >
           Dettagli piano Mysic
          </h4>
          <div style={{display:'flex',alignItems:'center'}}>
          <h4
            style={{
              fontSize: "1.2vw",
              color: "black",
              fontFamily: "Montserrat",
              letterSpacing: "-0.1vw",
              fontWeight: "bold",
              width: "fit-content",
              marginLeft: "auto",
              marginTop: 0,
              marginBottom: 0,
            }}
          >
           Piano selezionato:
          </h4>
          <h4
            style={{
              fontSize: "1.2vw",
              color: "#5C6672",
              fontFamily: "Montserrat",
              fontWeight: "bold",
              width: "fit-content",
              marginRight: "auto",
              marginLeft: "1vw",
              marginTop: 0,
              marginBottom: 0,
            }}
          >
           {props.plan===0?'Standard':props.plan===1?'PRO':'PRO XL'} ({props.tipo==='Cliente'?props.plan===0?'Gratis':props.plan===1?'7,99€/m':'19,99€/m':
           props.plan===0?'4,99€/m':props.plan===1?'10,99€/m':'19,99€/m'})
          </h4>
          </div>
          {props.tipo==='Cliente'?<div style={{display:'flex',marginTop:'3vh'}}>
            <div style={{width:'15vw',height:'55vh',overflowY:'auto',marginLeft:'auto',borderRight:'1px solid lightgrey'}}>
            <h4
            style={{
              fontSize: "1.2vw",
              color: "black",
              fontFamily: "Montserrat",
              letterSpacing: "-0.1vw",
              fontWeight: "bold",
              width: "fit-content",
              marginTop: 0,
              marginBottom: 0,
            }}
          >
           Funzionalità attive:
          </h4>
          <div style={{}}>
            <div style={{display:'flex'}}>
            <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>

                    <h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Elementi</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Documenti</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Interventi</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Manutenzioni</h4> 
                    </div>
                    {props.plan===1||props.plan===2?<div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Mappe</h4> 
                    </div>:null}
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Max. Elementi:
 {props.plan===0?'70':props.plan===1?'200':'1000'}</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Spazio disponibile: {props.plan===0?'100MB':props.plan===1?'1GB':'5GB'}</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Max. Sottoaccount: {props.plan===0?'1':props.plan===1?'5':'10'}</h4> 
                    </div>
                    </div>
            </div>
            <div style={{width:'15vw',height:'55vh',overflowY:'auto',marginLeft:'2vw',marginRight:'auto'}}>
            <h4
            style={{
              fontSize: "1.2vw",
              color: "black",
              fontFamily: "Montserrat",
              letterSpacing: "-0.1vw",
              fontWeight: "bold",
              width: "fit-content",
              marginTop: 0,
              marginBottom: 0,
            }}
          >
           Consumo attuale:
          </h4>
          <div style={{}}>
            <div >

                    <h4 style={{color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Elementi: </h4> 
                    <h4 style={{color:'black',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>{props.info.Current_Ele} / {props.info.Max_Ele} </h4> 

                    </div>
                    <div style={{marginTop:'0.7vh'}}>
<h4 style={{color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Documenti:</h4>
<h4 style={{color:'black',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>{parseFloat(props.info.Current_MB).toFixed(2)}MB / {props.info.Max_MB}MB </h4> 

                    </div>
                    <div style={{marginTop:'0.7vh'}}>
<h4 style={{color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Sottoaccount:</h4>
<h4 style={{color:'black',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>{props.info.Current_Sott} / {props.info.Max_Sott}</h4> 

                    </div>
                    </div>
            </div>
          </div>:<div style={{display:'flex',marginTop:'3vh'}}>
            <div style={{width:'15vw',height:'55vh',overflowY:'auto',marginLeft:'auto',borderRight:'1px solid lightgrey'}}>
            <h4
            style={{
              fontSize: "1.2vw",
              color: "black",
              fontFamily: "Montserrat",
              letterSpacing: "-0.1vw",
              fontWeight: "bold",
              width: "fit-content",
              marginTop: 0,
              marginBottom: 0,
            }}
          >
           Funzionalità attive:
          </h4>
          <div style={{}}>
            <div style={{display:'flex'}}>
            <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>

                    <h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Elementi</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Documenti</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Interventi</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Manutenzioni</h4> 
                    </div>
                   {props.plan===1||props.plan===2? <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Mappe</h4> 
                    </div>:null}
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Max. Clienti: {props.plan===0?'5':props.plan===1?'10':'Illimitato'}</h4> 
                    </div>
                    <div style={{display:'flex',marginTop:'0.7vh'}}>
                    <CheckCircleIcon style={{marginTop:'auto',marginBottom:'auto',width:'1vw',color:'#5C6672'}}/>
<h4 style={{marginLeft:'0.3vw',color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Max. Sottoaccount: {props.plan===0?'1':props.plan===1?'5':'10'}</h4> 
                    </div>
                    
                    </div>
            </div>
            <div style={{width:'15vw',height:'55vh',overflowY:'auto',marginLeft:'2vw',marginRight:'auto'}}>
            <h4
            style={{
              fontSize: "1.2vw",
              color: "black",
              fontFamily: "Montserrat",
              letterSpacing: "-0.1vw",
              fontWeight: "bold",
              width: "fit-content",
              marginTop: 0,
              marginBottom: 0,
            }}
          >
           Consumo attuale:
          </h4>
          <div style={{}}>
            <div >

                    <h4 style={{color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Clienti: </h4> 
                    <h4 style={{color:'black',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>{props.info.Current_Cli} / {props.info.Max_Cli} </h4> 

                    </div>
                    <div style={{marginTop:'0.7vh'}}>
<h4 style={{color:'#5C6672',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>Sottoaccount:</h4>
<h4 style={{color:'black',marginTop:'auto',marginBottom:'auto',fontFamily:'Montserrat',fontSize:'0.9vw',fontWeight:'bold'}}>{props.info.Current_Sott} / {props.info.Max_Sott}</h4> 

                    </div>
                    </div>
            </div>
          </div>}
          </div>
          </div>

    </div>:null
}