import React from 'react'
import Tooltip from '@mui/material/Tooltip';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
const SelectElements = ({ elementi, categoriaSelezionata, handleClickElemento,handleClick }) => {

        // Filtriamo gli elementi in base alla categoria selezionata
    const elementiFiltrati = elementi.filter(elemento => elemento.Categoria === categoriaSelezionata);

     // Organizzazione degli elementi per Macrocategoria
     const macrocategorie = {};
     const elementiById = {};

     elementiFiltrati.forEach(elemento => {
        elementiById[elemento.id] = elemento; // Memorizziamo gli elementi per ID per un accesso rapido
        if (!elemento.MyEleFatherId) {
            if (!macrocategorie[elemento.Macrocategoria]) {
                macrocategorie[elemento.Macrocategoria] = [];
            }
            macrocategorie[elemento.Macrocategoria].push(elemento);
        }
    });

    // Funzione per selezionare/deselezionare tutti gli elementi di una macrocategoria
    const handleMacrocategoriaClick = (macrocategoria) => {
        const elements = macrocategorie[macrocategoria];
        elements.forEach(elemento => {
            // Seleziona o deseleziona anche i figli
            handleClick(elemento);
            elementiFiltrati
                .filter(child => child.MyEleFatherId === elemento.id)
                .forEach(childElemento => handleClick(childElemento));
        });
    };



  return (
    <div style={{  }}>
        {console.log(macrocategorie,elementi)}
        {Object.keys(macrocategorie).map((macrocategoria, index) => (
            <div key={index}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            onChange={() => handleMacrocategoriaClick(macrocategoria)}
                        />
                        <h4 style={{ fontSize: '1.5vw', color: '#5C6672', margin: '0 0 0 0.5vw' }}>
                            {macrocategoria}
                        </h4>
                    </div>                {macrocategorie[macrocategoria].map((elemento) => (
                    <div key={elemento.Nome} style={{ marginLeft: '1vw', width: '18vw' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                type="checkbox"
                                onClick={() => handleClickElemento(elemento)}
                                value={JSON.stringify(elemento)}
                                checked={elemento.checked}
                            />
                            <label style={{ fontSize: '1vw', width: '15vw',fontWeight:'bold' }}>{elemento.Nome}</label>
                            <div style={{ marginLeft: '5vw' }}>
                                <Tooltip title={elemento.NumeroManutenzioni === undefined ? "Numero manutenzioni:0" : "Numero Manutenzioni:" + elemento.NumeroManutenzioni}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <label style={{ fontSize: '0.8vw', color: 'black', fontWeight: 'bold' }}>
                                            {elemento.NumeroManutenzioni > 0 ? elemento.NumeroManutenzioni : 0}
                                        </label>
                                        <BuildCircleIcon fontSize="small" style={{ color: "grey", marginTop: "auto", marginBottom: "auto" }} />
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        {/* Renderizziamo gli eventuali elementi figli */}
                        {elementiFiltrati
                                .filter(child => child.MyEleFatherId === elemento.id)
                                .map((childElemento, i) => (
                                    <div key={childElemento.id} style={{ display: 'flex',marginLeft:'1vw', alignItems: 'center', width: '16vw', marginTop: i === 0 ? '1px' : null, marginBottom: i === elemento.MyEleChilds?.length - 1 ? '10px' : null }}>
                                        <input
                                            type="checkbox"
                                            onClick={() => handleClickElemento(childElemento)}
                                            value={JSON.stringify(childElemento)}
                                            checked={childElemento.checked}
                                        />
                                        <label style={{ fontSize: '0.9vw', width: '15vw' }}>{childElemento.Nome}</label>
                                        <div style={{ marginLeft: '5vw' }}>
                                            <Tooltip title={childElemento.NumeroManutenzioni === undefined ? "Numero manutenzioni:0" : "Numero Manutenzioni:" + childElemento.NumeroManutenzioni}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label style={{ fontSize: '0.8vw', color: 'black', fontWeight: 'bold' }}>
                                                        {childElemento.NumeroManutenzioni > 0 ? childElemento.NumeroManutenzioni : 0}
                                                    </label>
                                                    <BuildCircleIcon fontSize="small" style={{ color: "grey", marginTop: "auto", marginBottom: "auto" }} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                ))}
                    </div>
                ))}
            </div>
        ))}
    </div>
);
}

export default SelectElements