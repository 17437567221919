import React, {useState,useEffect} from 'react'
import ArticleIcon from '@mui/icons-material/Article';
import { IconButton, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import firebase from "firebase/app";
import CancelIcon from '@material-ui/icons/Cancel';
import { getElementiSede, getSelectedElementi,checkDeleteS,checkWriteS, isShared } from '../../EngineClienti/engineClienti';
import Draggable from 'react-draggable';
import { getIcon } from '../../EngineClienti/engineClienti';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { useHistory } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import RemoveIcon from '@mui/icons-material/Remove';
import ReplayIcon from '@mui/icons-material/Replay';
import SearchIcon from '@mui/icons-material/Search';

const MyElementBuilder = (props) => {
  const auth = firebase.auth();
  const db = firebase.firestore();
  const storage = firebase.storage();
  var storageRef = storage.ref();
  const history = useHistory();


    const [images,setImages] = useState([])
    const [file,setFile] = useState(null)

    const [triggerAdd,setTriggerAdd] = useState(false)
    const [selectedElementi,setSelectedElementi] = useState([])

    const [imgSection,setImgSection] = useState(0)

    const [percentage,setPercentage] = useState(0)

    const [isDragging,setIsDragging] = useState(false)

    const [triggerSelected,setTriggerSelected] = useState(false)

    

    useEffect(()=>{

      if(props.elemento.MyElementImg){
        setImages(props.elemento.MyElementImg)

      }

    },[props.elemento])

    
    useEffect(()=>{
      getSelectedElementi(db,props.elemento.Id,setSelectedElementi)

    },[auth,props.id])

    function changeImg(e){
        setFile(e.target.files[0])
        var arr = [...images]
          
        const uploadTask = storageRef.child(
          props.user.Id+"/MyElement/"+props.elemento.Id+"/"
          +imgSection+e.target.files[0].name
        ).put(e.target.files[0]); //percorso del file su storage
        uploadTask.on('state_changed', 
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(2);
          setPercentage(progress); // aggiorna la percentuale di caricamento
        }, 
        (error) => {
          // Handle unsuccessful uploads
        }, 
        // eslint-disable-next-line no-loop-func
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        
          console.log(arr)

          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            if(arr[imgSection]){ //sostituisce se ce già foto nella posizione corrente
              arr[imgSection] = downloadURL
            }else{
              arr.push(downloadURL)
            }
            console.log(arr)
            setImages(arr)     
            db.collection('Elementi').doc(props.elemento.Id).update({
              MyElementImg: arr
            }).then(()=>{
              setPercentage(0)
            })
            
            })
          })
        }

      const AddElements = (props) =>{

        const [elementi,setElementi] = useState([])
        const [searchBar,setSearchBar] = useState('')

        useEffect(()=>{
          if(props.user.Tipo==='Lavoratore'){
            getElementiSede(db,props.elemento.Cliente,props.elemento.Sede,setElementi,props.id)

          }else{
            getElementiSede(db,props.user.Email,props.elemento.Sede,setElementi,props.id)

          }
        },[props.trigger])

        function clickEle(i){
          var arr = [...elementi]
          var arr2 = [...elementi]
          arr[i].Checked = !arr2[i].Checked
          setElementi(arr)
        }

        function addElements(){
          var arr = [...elementi]
          var i = 0
          var arr2 = arr.filter((e)=>e.Checked===true)
          var arr3 = [...selectedElementi]
          arr2.forEach((ele)=>{
            console.log(i,arr2.length)
            if(ele.Checked===true){
              db.collection('Elementi').doc(ele.Id).update({
                MyEleFatherId: props.elemento.Id,
                MyEleFatherName: props.elemento.Nome,
                MyEleX: 0,
                MyEleY:0,
                MyEleImg: imgSection
              })
              arr3.push(ele)
              setSelectedElementi((prev)=>{
                return [
                  ...prev,
                 
                  {
                    ...ele,
                    MyEleImg:imgSection
                  }
                ]
              })
              if(i===arr2.length-1){
                db.collection('Elementi').doc(props.elemento.Id).update({
                  MyEleChilds: arr3
                }).then(()=>{
                  props.setTrigger(false)
                })
               
              }
              i++

            }
          })
        }

        function returnEle(id){
          var arr = [...selectedElementi]
          const index = arr.findIndex((e)=>e.Id===id)
          if(index!==-1){

          }else{
            return true
          }
        }
        function renderEle(ele){
          console.log(ele,searchBar)
          if(searchBar===''||ele.Nome.toLowerCase().includes(searchBar.toLowerCase())||ele.Macrocategoria.toLowerCase().includes(searchBar.toLowerCase())||ele.Categoria.toLowerCase().includes(searchBar.toLowerCase())){
            return true
          }else{
            return false
          }
          
        }

        return (
          <div className="pop-opac">
    <div className="popup" style={{backgroundColor:"white", minHeight:'80vh', height:'fit-content', oveflowY: "auto", width:'40vw', left:'30vw'}} >
    <div style={{textAlign:'left', width: 'fit-content'}}>
            <button
              className="cancelIconButton"
              
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
            </button>
            </div>
            <h4 className='page-title' style={{margin:0,marginLeft:'auto',marginRight:'auto'}}>Seleziona elementi</h4>
            {/* BARRA DI RICERCA */}
            <div style={{width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>
            <div style={{width:'20vw',border:'1px solid #14213d',height:'3vh',borderRadius:'50px',display:'flex',flexDirection:'row',alignItems:'center'}}>
            <SearchIcon style={{marginLeft:'0.2vw'}}/>
            <input onChange={(e)=>setSearchBar(e.target.value)} type='text' style={{width:'88%',fontFamily:'Poppins', border:'none',backgroundColor:'transparent'}} placeholder={'Cerca elemento...'}/>
            </div>
            </div>
            <div style={{height:'60vh',overflowY:'auto'}}>
            {elementi.map((ele, i)=>(
              returnEle(ele.Id)===true?renderEle(ele)===true?<div key={i} onClick={()=>clickEle(i)} style={{display:'flex',alignItems:'center',marginTop:'1vh',marginLeft:'auto',width:'fit-content',marginRight:'auto',cursor:'pointer'}}>
                <input type='checkbox' checked={elementi[i].Checked}/>
                <h4 style={{margin:0}}>{ele.Nome} </h4>
                <h4 style={{margin:0,marginLeft:'10px'}} className='sub-text'>{ele.Categoria} - {ele.Macrocategoria} </h4>

              </div>:null:null
            ))}
            </div>
            <button className='main-button' onClick={()=>addElements()} style={{width:'5vw',marginLeft:'17.5vw',marginRight:'auto',marginTop:'1vh'}}>Aggiungi</button>
          </div>
          </div>
        )
      }

      const handleStop = (e, data, id) => {

        setSelectedElementi(prevPositions =>
          prevPositions.map(pos =>
            pos.Id === id ? { ...pos, MyEleX: data.x, MyEleY: data.y } : pos
          )
        );
        db.collection('Elementi').doc(id).update({
          MyEleX: data.x,
          MyEleY: data.y
        })
      };
       
      const handleNavigate = (ele) => {
        history.push(`/elementi/elemento/${ele.Id}`);
        window.location.reload();  // Ricarica la pagina dopo la navigazione
      }
      function deleteEle(ele){

        var arr = [...selectedElementi]
        const index = arr.findIndex((e)=>e.Id===ele.Id)
        arr.splice(index,1)

        db.collection('Elementi').doc(props.elemento.Id).update({
          MyEleChilds: arr
        }).then(()=>{
          db.collection('Elementi').doc(ele.Id).update({
            MyEleFatherId: null,
                  MyEleFatherName: null,
                  MyEleX: 0,
                  MyEleY:0,
          }).then(()=>{
            setSelectedElementi(arr)
          })
        })

        
      }

      function returnEle(ele){
        if((!ele.MyEleImg&&imgSection===0)||ele.MyEleImg===imgSection){
          return true
        }else{
          return false
        }
      }

      const SelectedElements = (props) =>{


        function resetPosition(ele){
          var arr = [...selectedElementi]
          const index = arr.findIndex((e)=>e.Id===ele.Id)
          console.log(index)
          arr[index].MyEleX = 0
          arr[index].MyEleY = 0
          setSelectedElementi(arr)

          db.collection('Elementi').doc(ele.Id).update({
            MyEleX: 0,
            MyEleY:0
          })
        }

        return (
          <div className="pop-opac">
          <div className="popup" style={{backgroundColor:"white", minHeight:'80vh', height:'fit-content', oveflowY: "auto", width:'40vw', left:'30vw'}} >
          <div style={{textAlign:'left', width: 'fit-content'}}>
            <button
              className="cancelIconButton"
              
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
            </button>
            </div>
            <h4 className='page-title' style={{margin:0,marginLeft:'auto',marginRight:'auto'}}>Elementi aggiunti</h4>
            <div style={{height:'60vh',overflowY:'auto'}}>
            {selectedElementi.length>0?selectedElementi.map((elez,i)=>(
              <div key={i} style={{display:'flex',alignItems:'center',marginTop:'1vh',marginLeft:'auto',width:'39vw',marginRight:'auto',cursor:'pointer',borderBottom:'1px solid lightgrey'}}>
                <div>
                   <h4 style={{margin:0}}>{elez.Nome} </h4>
                   <h4 style={{margin:0}} className='sub-text'>X: {elez.MyEleX} Y: {elez.MyEleY} </h4>
                   </div>
                   <Tooltip title='Visualizza'>
                   <button onClick={() => handleNavigate(elez)} style={{cursor:"pointer",marginLeft:'auto',width:'fit-content',backgroundColor:'transparent',border:'none'}}>
                    <VisibilityIcon style={{color:'#192bc2'}}/>
                   </button>
                   </Tooltip>
                  {checkDeleteS(props.user.Permissions)===true?<Tooltip title='Rimuovi elemento'>
                   <button onClick={() => deleteEle(elez)} style={{cursor:"pointer",marginLeft:'5px',width:'fit-content',backgroundColor:'transparent',border:'none'}}>
                    <DeleteIcon style={{color:'red'}}/>
                   </button>
                   </Tooltip>:null}
                   {checkWriteS(props.user.Permissions)===true?<Tooltip title='Resetta posizione'>
                   <button onClick={()=>resetPosition(elez)} style={{cursor:"pointer",marginLeft:'5px',width:'fit-content',backgroundColor:'transparent',border:'none'}}>
                    <ReplayIcon style={{color:'#5C6672'}}/>
                   </button>
                   </Tooltip>:null}
              </div>
            )):null}
            </div>
            </div>
          </div>
        )
      }

  return (
    <div style={{paddingLeft:'1rem',height:'80vh',overflowY:'auto',width:'71vw',position:'relative'}}>
      {triggerAdd===true?<AddElements id={props.id} trigger={triggerAdd} setTrigger={setTriggerAdd} user={props.user} elemento={props.elemento}/>:null}
      {triggerSelected===true?<SelectedElements trigger={triggerSelected} user={props.user} setTrigger={setTriggerSelected}/>:null}
        <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
        <ArticleIcon style={{color:'black'}}/>

      <h4 className="header-normal" style={{marginLeft:'5px'}}>MyElement Builder</h4>
        <Tooltip title="MyElement Builder permette all'utente di caricare un'immagine dell'elemento e popolarla con evenutali altri elementi interagibili (es. Int. Differenziali su Quadro El.).">
            <HelpIcon style={{marginLeft:'1vw',color:'#677483'}}/>
        </Tooltip>

      {images.length>0?<div style={{display:'flex',alignItems:'center',marginLeft:'1vw'}}><Tooltip title="Aggiungi sezione. Per creare la nuova sezione, clicca sul + e Carica immagine elemento.">
        <IconButton style={{width:'fit-content'}} onClick={()=>setImgSection(imgSection+1)}>
        <AddIcon/>
      </IconButton>
      </Tooltip>
      {images.length>=1?<div style={{display:'flex',alignItems:'center',marginLeft:'1vw'}}>
      {imgSection>0?<IconButton style={{width:'fit-content'}} onClick={()=>setImgSection(imgSection-1)}>
        <ArrowBackIcon/>
      </IconButton>:null}
      <h4 className="header-normal" style={{color:'#5C6672',fontWeight:'normal',fontSize:'0.8vw'}}>{imgSection+1}/{images.length}</h4>
      {imgSection+1<images.length?<IconButton style={{width:'fit-content'}} onClick={()=>setImgSection(imgSection+1)}>
        <ArrowForwardIcon/>
      </IconButton>:null}
      </div>:null}
      </div>:null
      }

      </div>
    
    <div style={{marginTop:'1rem',position:'relative'}}>

      {props.user.Tipo==='Lavoratore'?<div style={{display:'flex',alignItems:'center'}}>
      {images[imgSection]?checkWriteS(props.user.Permissions)?<div className='main-button' style={{width:'10vw',position:'relative'}}>
      <input type='file' style={{position:'absolute',left:0,width:'10vw',cursor:'pointer',opacity:0}} onChange={(e)=>changeImg(e)}></input>
      <h4 className='medium-text' style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'white'}}>Cambia immagine elemento</h4>
      </div>:null: checkWriteS(props.user.Permissions)?
      <div className='main-button' style={{width:'10vw',position:'relative'}}>
      <input type='file' style={{position:'absolute',left:0,width:'10vw',cursor:'pointer',opacity:0}} onChange={(e)=>changeImg(e)}></input>
      <h4 className='medium-text' style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'white'}}>Carica immagine elemento</h4>
      </div>:null}
      {images[imgSection]?checkWriteS(props.user.Permissions)?<div onClick={()=>setTriggerAdd(true)} className='main-button' style={{width:'7vw',marginLeft:'1vw'}}>
      <h4 className='medium-text' style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'white'}}>Aggiungi elementi</h4>
      </div>:null:null}
      {images[imgSection]&&selectedElementi.length>0?<div onClick={()=>setTriggerSelected(true)} className='main-button' style={{width:'7vw',marginLeft:'1vw'}}>
      <h4 className='medium-text' style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'white'}}>Elementi aggiunti</h4>
      </div>:null}
      </div>:null}
      {percentage!==0?<h4 className='normal-cool' style={{position:'absolute',left:0}}>Caricamento immagine in corso: <b>{percentage}%</b></h4>:null}

        </div>
        {images[imgSection]?<div style={{width:'fit-content',height:'62vh',marginTop:'3vh',position:'relative',display:'flex'}}>
        <TransformWrapper
        defaultScale={1}
        defaultPositionX={0}
        defaultPositionY={0}
        wheel={{ step: 0.1 }}
        pinch={{ step: 5 }}
        doubleClick={{ disabled: true }}
        panning={{ disabled: isDragging }} // Disable panning when dragging an element

      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <div className="tools" style={{ position: 'absolute', zIndex: 1000, top: 10, right: '-8vw', gap:'5px',display:'flex' }}>
              <button onClick={() => zoomIn()} style={{backgroundColor:'white',width:'2vw',border:'none',borderRadius:50,height:'2vw',cursor:'pointer'}}><AddIcon style={{color:'#192bc2',width:'1vw',height:'1vw'}}/></button>
              <button onClick={() => zoomOut()} style={{backgroundColor:'white',width:'2vw',border:'none',borderRadius:50,height:'2vw',cursor:'pointer'}}><RemoveIcon style={{color:'#192bc2',width:'1vw',height:'1vw'}}/></button>
              <button onClick={() => resetTransform()} style={{backgroundColor:'white',width:'2vw',border:'none',borderRadius:50,height:'2vw',cursor:'pointer'}}><ReplayIcon style={{color:'#192bc2',width:'1vw',height:'1vw'}}/></button>
            </div>
            <TransformComponent>
              <div style={{ position: 'relative', width: '100%', height: '100%' }}>
              {selectedElementi.map((ele, i) => (
                  returnEle(ele) === true ? (
                    <Draggable
                      key={i}
                      position={{ x: ele.MyEleX, y: ele.MyEleY }}
                      onStop={(e, data) => {
                        setIsDragging(false);
                        handleStop(e, data, ele.Id);
                      }}
                      onStart={() => setIsDragging(true)}
                      
                      disabled={props.user.Tipo==='Lavoratore'?false:isShared(ele)===false?false:true}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          cursor: 'pointer',
                          borderRadius: '4px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',                          
                          transformOrigin: 'top left', // transform origin to top left
                          zIndex:1000,
                          width:'0.7vw'
                        }}
                        className='myele-elem'
                      >
                        <img
                          src={getIcon(ele)}
                          alt='icon_ele'
                          style={{
                            cursor: 'pointer',
                            width: '0.7vw', // set your width
                            height: '0.7vw', // set your height
                            zIndex:1000,
                            backgroundColor:'white'

                          }}
                          
                        />
                        <h4 style={{ margin: 0, fontSize:'0.5vw',marginTop:'1vh' }} className='medium-text'> {ele.Nome}</h4>
                        <div style={{alignItems: 'center',height:'-1.5vh'}}>
                         <div className='myele-button'  style={{ cursor: 'pointer', width: 'fit-content', backgroundColor: 'transparent', border: 'none',}}>
                            <VisibilityIcon  onClick={() => handleNavigate(ele)} style={{ color: '#192bc2',height:'0.5rem',width:'0.5rem',backgroundColor:'white',borderRadius:50,cursor:'pointer' }} />
                          </div>
                          {checkDeleteS(props.user.Permissions)===true?props.user.Tipo==='Lavoratore'?<div className='myele-button' onClick={() => deleteEle(ele)} style={{ cursor: 'pointer', width: 'fit-content', backgroundColor: 'transparent', border: 'none', marginTop:'0.1vh' }}>
                            <DeleteIcon style={{ color: 'red',backgroundColor:'white',height:'0.5rem',width:'0.5rem',borderRadius:50 }} />
                          </div>:isShared(ele)===false?<div className='myele-button' onClick={() => deleteEle(ele)} style={{ cursor: 'pointer', width: 'fit-content', backgroundColor: 'transparent', border: 'none', marginTop:'0.1vh' }}>
                            <DeleteIcon style={{ color: 'red',backgroundColor:'white',height:'0.5rem',width:'0.5rem',borderRadius:50 }} />
                          </div>:null:null}
                        </div>
                      </div>
                    </Draggable>
                  ) : null
                ))}
                <img src={images[imgSection]} style={{ height:'62vh',width:'fit-content',zIndex:99, objectFit: 'contain' }} alt='image_myel' />
                
              </div>
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
        </div>:null}
        
    </div>
  )
}

export default MyElementBuilder