import { Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import PersonIcon from '@mui/icons-material/Person';

function Week(props){

    const [tDays,setTDays] = useState([]) //giorni del mese
    let month = props.date.Month
    let year = props.date.Year
    const [selectedWeek,setSelectedWeek] = useState(0)

    useEffect(()=>{
        initCalendario()
       /* if(props.date.Month!==null||props.date.Month!==undefined){
            if(props.date.Month===12){
                getT(1,props.date)
 
            }else{
                getT(props.date.Month+1,props.date)

            }
        }*/
    },[props.date.Month])


    function initCalendario(){
        setTDays([])
        const firstDay = new Date(year,month,1) //primo giorno di questo mese
        const lastDay = new Date(year,month+1, 0) //ultimo giorno di questo mese
        const prevLastDay = new Date(year,month,0) //ultimo giorno del mese precedente
        const prevDays = prevLastDay.getDate(); //numero dell'ultimo giorno del mese precedente
        const lastDate = lastDay.getDate() //numero dell'ultimo giorno del mese
        const day = firstDay.getDay()-1<0?6:firstDay.getDay()-1 //numero del primo giorno del mese
        const nextDays = 7 - lastDay.getDay() 

        var count = 0

        //giorni del mese precedente
        for(let x = day; x>0; x--){
            count++
            setTDays((prev)=>{
                return [
                    ...prev,
                    {
                        Type: 'prev',
                        Number: prevDays-x+1

                    }
                ]
            })

        }
        //giorni di questo mese

        for(let i = 1; i<=lastDate; i++){
            count++

            setTDays((prev)=>{
                return [
                    ...prev,
                    {
                        Type: 'curr',
                        Number: i

                    }
                
                ]
            })
        }

        //giorni del prossimo mese
        if(nextDays!==7){
            for(let j=1; j<= nextDays; j++){
                if(count===35){
    
                }else{
                    count++
    
                    setTDays((prev)=>{
                        return [
                            ...prev,
                            {
                                Type: 'prev',
                                Number:j
        
                            }
                            
                        ]
                    })
                }
    
            }
        }
        

    }
    function returnAnn(date){
        var arr = props.annotazioni
        const monthTo=date.Type==='prev'?month-1:date.Type==='next'?month+1:month
        const list = arr.filter((ann)=> parseInt(ann.data.Day,10)===parseInt(date.Number,10)&&parseInt(ann.data.Month-1,10)===parseInt(monthTo,10)&&parseInt(ann.data.Year,10)===parseInt(year,10))
        if(list.length>0){
            console.log(list)
            return list

        }else{
            return false
        }
    }
    function getA(string){
        var v = string.split('"')
        var x = v[5].replace('"', '')
        return x
    }

    return <div style={{width:'100%',height:'25rem',marginTop:'1rem',borderRadius:10,display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignContent: "flex-start"}}>
  <div style={{display:'flex',alignItems:'center',width:'100%'}}>
        <div style={{width:'14.18%',borderTopLeftRadius:10,borderLeft:'1px solid lightgrey',borderTop:'1px solid lightgrey',backgroundColor:'white'}}>
        <h4 style={{marginTop:5,marginBottom:5,width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'#5C6672'}}>Lunedì</h4>
        </div>
        <div style={{width:'14.18%',borderLeft:'1px solid lightgrey',borderTop:'1px solid lightgrey',backgroundColor:'white'}}>
        <h4 style={{marginTop:5,marginBottom:5,width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'#5C6672'}}>Martedì</h4>
        </div>
        <div style={{width:'14.18%',borderLeft:'1px solid lightgrey',borderTop:'1px solid lightgrey',backgroundColor:'white'}}>
        <h4 style={{marginTop:5,marginBottom:5,width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'#5C6672'}}>Mercoledì</h4>
        </div>
        <div style={{width:'14.18%',borderLeft:'1px solid lightgrey',borderTop:'1px solid lightgrey',backgroundColor:'white'}}>
        <h4 style={{marginTop:5,marginBottom:5,width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'#5C6672'}}>Giovedì</h4>
        </div>
        <div style={{width:'14.18%',borderLeft:'1px solid lightgrey',borderTop:'1px solid lightgrey',backgroundColor:'white'}}>
        <h4 style={{marginTop:5,marginBottom:5,width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'#5C6672'}}>Venerdì</h4>
        </div>
        <div style={{width:'14.18%',borderLeft:'1px solid lightgrey',borderTop:'1px solid lightgrey',backgroundColor:'white'}}>
        <h4 style={{marginTop:5,marginBottom:5,width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'#5C6672'}}>Sabato</h4>
        </div>
        <div style={{width:'14.18%',borderLeft:'1px solid lightgrey',borderRight:'1px solid lightgrey',borderTop:'1px solid lightgrey',backgroundColor:'white',borderTopRightRadius:10}}>
        <h4 style={{marginTop:5,marginBottom:5,width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'#5C6672'}}>Domenica</h4>
        </div>
        </div>
        {tDays.map((n,index)=>(
            index>=(props.selectedWeek)*7&&index<=((props.selectedWeek+1)*7)-1?
            <div key={index} style={{width:'14.18%',height:'23rem',borderTop:'1px solid lightgrey',borderLeft:'1px solid lightgrey',
            backgroundColor:n.Type==='curr'?'white':'whitesmoke',overflowY:'auto'}}>
                <h4 style={{marginBottom:0,color:n.Type==='curr'?'black':'#5C6672',marginLeft:2,marginTop:2}}>{n.Number}</h4>
                {returnAnn(n).length>0?returnAnn(n).map((ann)=>(
                  <Tooltip title={ann.data.Subject}>

                   <div style={{marginTop:10, border:'1px solid #192bc2',borderRadius:5,}}>
                   <div style={{display:'flex',alignItems:'center'}}>
            <PersonIcon style={{color:ann.User==='Assente'?'#5C6672':'#192bc2',fontSize:'1rem'}}></PersonIcon>
            
            <h4 style={{margin:0,fontSize:'0.8rem',color:ann.User==='Assente'?'#5C6672':'#192bc2',fontFamily:'Rubik'}}>{ann.User}</h4>
            </div>
                   <h4 style={{whiteSpace: 'nowrap',
 overflow: 'hidden',
 textOverflow: 'ellipsis',maxWidth:'99%',margin:0,padding:1,fontFamily:'Rubik',fontSize:'0.8rem'}}>{ann.data.Subject}</h4>
 <div style={{marginTop:5,width:'99%',borderTop:'1px solid #192bc2'}}>
                {ann.data.Description?ann.data.Description.includes('<a style')?
                <div style={{marginLeft:5}}>
                    <h4 style={{margin:0,fontSize:'0.7rem',fontFamily:'Rubik',marginTop:5 }}>Premere sul bottone sottostante per visualizzare il documento.</h4>
                    <br/>
                    <a href={getA(ann.data.Description)} target="_blank" rel="noreferrer" 
                style={{borderRadius:5,color:'#192bc2',fontFamily:'Rubik',fontSize:'0.8rem'}}>Visualizza documento</a>
                </div>
               
                :<h4 style={{margin:0,fontSize:'0.7rem',fontFamily:'Rubik',marginLeft:2,marginTop:5 }}>{ann.data.Description}</h4>:
                <h4 style={{margin:0,fontSize:'0.7rem',fontFamily:'Rubik',marginLeft:2,marginTop:5 }}>Nessuna descrizione inserita.</h4>}
                

            
            </div>

               </div>
               </Tooltip>

                )):<h4 style={{margin:0, fontFamily:'Rubik',fontSize:'0.8rem',marginTop:10,marginLeft:2}}>Nessuna annotazione presente...</h4>
}
            </div>:null
        ))}
    </div>
}

export default Week
