//visualizzazione manutentore 
import React, { useState, useEffect } from "react";
import firebase from "firebase";
import Richiesta from "./Popup/Richiesta";
import { checkWriteS } from "../EngineClienti/engineClienti";
export function VisualizeUser(props){
    const db = firebase.firestore();
    const auth = firebase.auth();
    const [interventi,setInterventi] = useState(0);
    const [manutenzioni,setManutenzioni] = useState(0);
    const [opTot,setOpTot] = useState(0)
    const [trigger,setTrigger] = useState(false)

    useEffect(() => {
      setInterventi(0)
      setManutenzioni(0)
      setOpTot(0)

            getInterventi()
            getManutenzioni()
            getOp()
   },[props.selectedUser.Email]); //chiama tutte le funzioni

            function getInterventi(){
                db.collection('Users').doc(props.selectedUser.Email).collection('Attività').doc(props.selectedUser.Nome_Azienda).collection('Attività').get().then((int)=>{
                    if(int.docs.length>0){
                    setInterventi(int.docs.length)
                    }
                })
            } //prende interventi totali
            function getManutenzioni(){

                db.collection('Users').doc(props.selectedUser.Email).collection('Manutenzioni').doc(props.selectedUser.Nome_Azienda).collection('Manutenzioni').get().then((int)=>{
                    if(int.docs.length>0){
                    setManutenzioni(int.docs.length)
                    }
                })
            } //prende manutenzioni totali
           
            function getOp(){
                var n = 0
                db.collection('Users').doc(props.selectedUser.Email).collection('Storico').get().then((int)=>{

                        int.forEach((cl)=>{
                            db.collection('Users').doc(props.selectedUser.Email).collection('Storico').doc(cl.id).collection('Storico').get().then((ops)=>{
                              
                                    n = n + ops.docs.length
                                    setOpTot(n)

                            })

                        })
                })
            } //prende storico operazioni totali NON IN USO IL DATO

    return (
        <div style={{position:'absolute',bottom:'5vh',right:'5vw',width:'40vw',height:'70vh',backgroundColor:'white',borderRadius:20,boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',padding:10}}>
            {trigger===true?<Richiesta info={props.info} selectedUser={props.selectedUser} trigger={trigger} setTrigger={setTrigger}/>:null}
            <div style={{display:'flex'}}>
            {props.selectedUser.Photo?<div style={{width:'3vw',height:'3vw',backgroundColor:'#0496ff',borderRadius:50}}>
           <img src={props.selectedUser.Photo} alt='profile_photo' style={{width:'3vw',height:'3vw',borderRadius:'50%'}}></img>
            </div>:<div style={{width:'3vw',height:'3vw',backgroundColor:'#0496ff',borderRadius:50}}>
            <h4 style={{fontSize:'1vw',color:'white',fontWeight:'bold',marginTop:17,marginBottom:'auto',marginLeft:'auto',marginRight:'auto',width:'fit-content',
            height:'fit-content',fontFamily:'Poppins',}}>{props.selectedUser.Nome_Azienda[0]}</h4>
            </div>}
            <div>
            <h4 style={{fontSize:'1.5vw',color:'black',fontWeight:'bold',marginTop:'auto',marginBottom:'auto',width:'fit-content',
            height:'fit-content',marginLeft:'0.5vw',fontFamily:'Poppins',}}>{props.selectedUser.Nome_Azienda}</h4>
            <h4 style={{fontSize:'0.8vw',color:'#5C6672',fontWeight:'normal',marginTop:'auto',marginBottom:'auto',width:'fit-content',
            height:'fit-content',marginLeft:'0.5vw',fontFamily:'Poppins',}}>{props.selectedUser.Email}</h4>
            </div>
            {checkWriteS(props.info.Permissions)===true?<div style={{marginLeft:'auto'}}>
                <button onClick={()=>setTrigger(true)} className='blue-button'>
                    Aggiungi Manutentore</button>
            </div>:null}
            </div>
            <div style={{marginTop:15,borderBottom:'1px solid lightgrey',paddingBottom:10}}>
            <h4 style={{fontSize:'0.9vw',color:'#118ab2',fontWeight:'bold',marginTop:'auto',marginBottom:'auto',width:'fit-content',
            height:'fit-content',fontFamily:'Poppins',}}>Informazioni utente</h4>
            <div style={{display:'flex',marginTop:10}}>
            <div style={{marginLeft:'0.7vw',width:'8vw'}}>
            <h4 style={{fontSize:'0.6vw',color:'#5C6672',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>Nome Azienda</h4>
          <h4 style={{fontSize:'0.7vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>{props.selectedUser.Nome_Azienda}</h4>   
            </div>
            <div style={{width:'12vw'}}>
            <h4 style={{fontSize:'0.6vw',color:'#5C6672',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>Email</h4>
          <h4 style={{fontSize:'0.7vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>{props.selectedUser.Email}</h4>   
            </div>
            <div style={{marginLeft:'0.7vw',width:'10vw'}}>
            <h4 style={{fontSize:'0.6vw',color:'#5C6672',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>Clienti</h4>
          <h4 style={{fontSize:'0.7vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,width:'fit-content',fontFamily:'Poppins',}}>{props.selectedUser.Current_Cli}</h4>   
            </div>
            </div>
            <div style={{display:'flex',marginTop:10}}>
            <div style={{marginLeft:'0.7vw',width:'8vw'}}>
            <h4 style={{fontSize:'0.6vw',color:'#5C6672',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>Telefono</h4>
          <h4 style={{fontSize:'0.7vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>{props.selectedUser.Numero}</h4>   
            </div>
            <div style={{width:'12vw'}}>
            <h4 style={{fontSize:'0.6vw',color:'#5C6672',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>Indirizzo</h4>
          <h4 style={{fontSize:'0.7vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,width:'fit-content',fontFamily:'Poppins',}}>{props.selectedUser.Indirizzo} - {props.selectedUser.Comune}</h4>   
           
            </div>
            <div style={{marginLeft:'0.7vw',width:'10vw'}}>
            <h4 style={{fontSize:'0.6vw',color:'#5C6672',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>Sedi/Luoghi</h4>
          <h4 style={{fontSize:'0.7vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>{props.selectedUser.Sedi_Create}</h4>   
            </div>
            </div>
            </div>
            <div style={{marginTop:15,borderBottom:'1px solid lightgrey',paddingBottom:10}}>
            <h4 style={{fontSize:'0.9vw',color:'#118ab2',fontWeight:'bold',marginTop:'auto',marginBottom:'auto',width:'fit-content',
            height:'fit-content',fontFamily:'Poppins',}}>Competenze</h4>
            <div style={{display:'flex',marginTop:10}}>
            <h4 style={{fontSize:'0.8vw',color:'#5C6672',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>Lingue parlate:</h4>
            {props.selectedUser.Lingue?props.selectedUser.Lingue.length>0?props.selectedUser.Lingue.map((lingua,index)=>(
           <h4 key={index} style={{fontSize:'0.8vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,marginLeft:5}}>{lingua}{index===props.selectedUser.Lingue.length-1?null:','}</h4>
            )):<h4 style={{fontSize:'0.8vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,marginLeft:5,fontFamily:'Poppins',}}>Nessuna lingua inserita</h4>:<h4 style={{fontSize:'0.8vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,marginLeft:5,fontFamily:'Poppins',}}>Nessuna lingua inserita</h4>}
            </div>
            <div style={{display:'flex',marginTop:10}}>
            <h4 style={{fontSize:'0.8vw',color:'#5C6672',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>Territori:</h4>
            {props.selectedUser.Territori?props.selectedUser.Territori.length>0?props.selectedUser.Territori.map((lingua,index)=>(
           <h4 key={index} style={{fontSize:'0.8vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,marginLeft:5,fontFamily:'Poppins',}}>{lingua}{index===props.selectedUser.Territori.length-1?null:','}</h4>
            )):<h4 style={{fontSize:'0.8vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,marginLeft:5,fontFamily:'Poppins',}}>Nessun territorio inserito</h4>:
            <h4 style={{fontSize:'0.8vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,marginLeft:5,fontFamily:'Poppins',}}>Nessun territorio inserito</h4>}
            </div>
            <div style={{display:'flex',marginTop:10}}>
            <h4 style={{fontSize:'0.8vw',color:'#5C6672',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>Capacità:</h4>
            {props.selectedUser.Capacità?props.selectedUser.Capacità.length>0?props.selectedUser.Capacità.map((lingua,index)=>(
           <h4 key={index} style={{fontSize:'0.8vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,marginLeft:5,fontFamily:'Poppins',}}>{lingua}{index===props.selectedUser.Capacità.length-1?null:','}</h4>
            )):<h4 style={{fontSize:'0.8vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,marginLeft:5,fontFamily:'Poppins',}}>Nessuna capacità inserita</h4>:
            <h4 style={{fontSize:'0.8vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,marginLeft:5,fontFamily:'Poppins',}}>Nessuna capacità inserita</h4>}
            </div>
            </div>
            <div style={{marginTop:15,borderBottom:'1px solid lightgrey',paddingBottom:10}}>
            <h4 style={{fontSize:'0.9vw',color:'#118ab2',fontWeight:'bold',marginTop:'auto',marginBottom:'auto',width:'fit-content',
            height:'fit-content',}}>Statistiche Mysic</h4>
            <div style={{display:'flex',marginTop:10}}>
            <div style={{marginLeft:'0.7vw',width:'7vw'}}>
            <h4 style={{fontSize:'0.7vw',color:'#5C6672',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>Interventi</h4>
          <h4 style={{fontSize:'0.7vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>{interventi}</h4>   
            </div>
            <div style={{width:'7vw'}}>
            <h4 style={{fontSize:'0.7vw',color:'#5C6672',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>Manutenzioni</h4>
          <h4 style={{fontSize:'0.7vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>{manutenzioni}</h4>   
            </div>
           {/* <div style={{marginLeft:'0.7vw',width:'7vw'}}>
            <h4 style={{fontSize:'0.7vw',color:'#5C6672',fontWeight:'bold',marginTop:0,marginBottom:0}}>Storico operazioni</h4>
          <h4 style={{fontSize:'0.7vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,width:'fit-content'}}>{opTot}</h4>   
            </div>*/}
            </div>
            </div>
        </div>
    )

}