import React,{useEffect,useState} from 'react'
import { useParams } from 'react-router-dom'
import NavBar2 from '../../NavBar/NavBar2'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import firebase from "firebase";
import { getUtente } from '../../EngineClienti/engineClienti';
import { getPiani } from '../../EngineOffritore/engineOffritore';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AggiungiPiano from './AggiungiPiano';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { checkWriteS,checkDeleteS } from '../../EngineClienti/engineClienti';
import VisibilityIcon from '@mui/icons-material/Visibility';
const PianiMappe = ({history}) => {

    const [piani,setPiani] = useState([])
    const [mappe,setMappe] = useState([])
    const [user,setUser] = useState(null)
    const db = firebase.firestore()
    const auth = firebase.auth()
    const {user_id, sede_id} = useParams()

    const [triggerAddPiano,setTriggerAddPiano] = useState(false)
    const [sedeName,setSedeName] = useState('')

    useEffect(()=>{
      if(user!==null&&sede_id!==null){
        getSedeNamez(sede_id)

      }
    },[user])

    function getSedeNamez(s_id){
      db.collection('Users').doc(user_id).collection('Sedi').doc(s_id).get().then((sedez)=>{
        console.log(sedez.data())
        const nome = sedez.data().Nome?sedez.data().Nome:sedez.data().Comune
        setSedeName(nome)
      })
    }

    const Buttons = () =>{

        return(
          <div style={{display:'flex',alignItems:'center',gap:'1.5rem',marginLeft:'2vw'}}>
            <div>
            <h4 onClick={()=>setTriggerAddPiano(true)}  className='text-buttons' >
                    AGGIUNGI PIANO
                  </h4>
            </div>
          
          </div>
        )
      }

      useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
            getUtente(db,auth,setUser)
          }
        })
      },[auth])
    
      useEffect(()=>{
        if(user!==null){
          getPiani(db,user_id,sede_id,setPiani,setMappe)
          
      }
    },[user])

    function switchPlace(piano,n,t){
      var arr = [...piani]
      console.log(piano,n)
      if(t===0){ //cambia di un posto verso l'alto
        db.collection('Users').doc(user_id).collection('Sedi').doc(sede_id).collection('Piani').doc(arr[n].Id).update({
          Index: arr[n].Index+1
        }).then(()=>{
          db.collection('Users').doc(user_id).collection('Sedi').doc(sede_id).collection('Piani').doc(arr[n-1].Id).update({
            Index: arr[n].Index
          }).then(()=>{
            var n_1 = arr[n]
            var n_2 = arr[n-1]
            n_1.Index = arr[n].Index+1
            n_2.Index = arr[n-1].Index
            arr[n] = n_2
            arr[n-1] = n_1 
            setPiani(arr)
          })
        
          
        })
      
      }else{
        db.collection('Users').doc(user_id).collection('Sedi').doc(sede_id).collection('Piani').doc(arr[n].Id).update({
          Index: arr[n].Index-1
        }).then(()=>{
          db.collection('Users').doc(user_id).collection('Sedi').doc(sede_id).collection('Piani').doc(arr[n+1].Id).update({
            Index: arr[n].Index
          }).then(()=>{
            var n_1 = arr[n]
            var n_2 = arr[n+1]
            n_1.Index = arr[n].Index-1
            n_2.Index = arr[n+1].Index
            arr[n] = n_2
            arr[n+1] = n_1 
            setPiani(arr)
          })
        
        })
        
        
      }
    
    }
    function returnMappe(piano){
      var arr = [...mappe]

      var n = arr.filter((e)=>e.Piano===piano.Id)

      return n
    }

    function deleteMappa(mappa){
      var arr = [...mappe]
      const index = arr.findIndex((e)=>e.Id===mappa.Id)
      arr.splice(index,1)
      setMappe(arr)

      if(mappa.Elementi){
        if(mappa.Elementi.length>0){
          alert('Eliminare prima gli elementi posizionati nella mappa.')
        }else{
          db.collection('Users').doc(user_id).collection('Sedi').doc(sede_id).collection('Piani').doc(mappa.Piano).collection('Mappe').doc(mappa.Id).delete().then(()=>{
            db.collection('Mappe').doc(mappa.Id).delete().then(()=>{
              var arr = [...mappe]
              const index = arr.findIndex((e)=>e.Id===mappa.Id)
              arr.splice(index,1)
              setMappe(arr)
              alert('Mappa eliminata con successo.')
            })
          })
        }
      }else{
        db.collection('Users').doc(user_id).collection('Sedi').doc(sede_id).collection('Piani').doc(mappa.Piano).collection('Mappe').doc(mappa.Id).delete().then(()=>{
          db.collection('Mappe').doc(mappa.Id).delete().then(()=>{
            var arr = [...mappe]
            const index = arr.findIndex((e)=>e.Id===mappa.Id)
            arr.splice(index,1)
            setMappe(arr)

            alert('Mappa eliminata con successo.')
          })
        })
      }
      
    }

  return (
    <div className="background" style={{width:'100vw',height:'100vh',overflow:'hidden'}}>
    <NavBar2/>
    {triggerAddPiano===true?<AggiungiPiano setTrigger={setTriggerAddPiano} trigger={triggerAddPiano} sede={sede_id} user={user_id} piani={piani} setPiani={setPiani}/>:null}
    <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
    <div  style={{  width: "60vw",display:'flex',marginTop:'1.2rem' }}>
          <button style={{marginBottom:'auto',marginTop:'1rem'}} onClick={() => history.goBack()}
            className="cancelIconButton"
            
          >
            <ArrowBackIcon
             className="back-button"
            />
          </button>
          <div>
          <h4 className="page-title" style={{margin:0}}>{sedeName?sedeName:null}</h4>
          <h4
          className="sub-text-b"
        >
          Piani
        </h4>
          </div>

        </div>
        {user!==null?checkWriteS(user.Permissions)===true?<Buttons/>:null:null}
    <div style={{height:'80vh',width:'80vw',overflowY:'auto',}}>
    {piani.length>0?piani.map((piano,n)=>(
        <div key={n} style={{display:'flex',marginTop:'3vh'}}>
            {checkWriteS(user.Permissions)===true?<div style={{display:'flex',flexDirection:'column'}}>
           {n===0?null: <IconButton onClick={()=>switchPlace(piano,n,0)} style={{width:'fit-content'}}>
                <ArrowUpwardIcon/>
            </IconButton>}
           {n===piani.length-1?null: <IconButton onClick={()=>switchPlace(piano,n,1)} style={{width:'fit-content'}}>
                <ArrowDownwardIcon/>
            </IconButton>}
            </div>:null}
            <div className='box-shadow' style={{height:'15vh',width:'70vw',backgroundColor:'white',marginLeft:'10px',borderRadius:10,position:'relative',display:'flex'}}>
            <div style={{padding:5,alignItems:'center',justifyContent:'center',width:'10vw',height:'14vh',display:'flex',flexDirection:'column',borderRight:'1px solid lightgrey'}}>
            <h4 className='normal-text' style={{color:'#192bc2',fontWeight:'bold',fontSize:'1rem',marginBottom:'1vh'}}>{piano.Nome}</h4>
            {checkWriteS(user.Permissions)===true?<Tooltip title='Aggiungi mappa'>
                <IconButton onClick={()=>history.push(`/mappemanutentore/${user_id}/${sede_id}/piani/${piano.Id}/aggiungimappa`)} style={{width:'2vw',height:'2vw',backgroundColor:'#14213d'}}>
                <AddIcon style={{fontSize:'1.5vw',color:'white'}}/>
                </IconButton>
            </Tooltip>:null}
            </div>
            <div style={{display:'flex',width:'55vw',height:'15vh',marginLeft:'1vw',overflow:'hidden',alignItems:'center'}}>
            {returnMappe(piano).map((map,i)=>( 
              <div key={i} style={{border:'1px solid black',width:'18vw',height:'6vw',marginLeft:'1vw',borderRadius:10,display:'flex' }}>
                <div  style={{borderRight:'1px solid black',width:'10vw',height:'6vw',paddingLeft:'2px',position:'relative' }}>
                  <Tooltip title={map.Nome}>
                <h4 className='bold-cool' style={{fontSize:'13px',margin:0,whiteSpace: 'nowrap',overflow: 'hidden',
textOverflow: 'ellipsis',maxWidth:'7.9vw'}}>{map.Nome}</h4>
                </Tooltip>
                <Tooltip title={map.Nome}>
                <h4 className='normal-cool' style={{fontSize:'11px',margin:0,whiteSpace: 'nowrap',overflow: 'hidden',
textOverflow: 'ellipsis',maxWidth:'7.9vw',maxHeight:'2vh',position:'relative'}}>{map.Descrizione}</h4>
                </Tooltip>
                <div style={{position:'absolute',bottom:0,left:0}}>
                  <div style={{display:'flex',marginLeft:'2px'}}>
                    <h4 className='sub-text' style={{fontSize:'11px'}}>Ultima modifica:</h4>
                    <h4 className='normal-cool' style={{fontSize:'11px',margin:0,whiteSpace: 'nowrap',overflow: 'hidden',
textOverflow: 'ellipsis',maxWidth:'7.9vw',maxHeight:'2vh',position:'relative',marginLeft:'2px'}}>{map.UltimaModifica}</h4>
                  
                  </div>
                  <div style={{display:'flex'}}>
                  <Tooltip title='Visualizza mappa'><IconButton onClick={()=>history.push(`/mappemanutentore/${user_id}/${sede_id}/piani/${piano.Id}/${map.Id}/visualizemappa`)} style={{width:'fit-content'}}>
                    <VisibilityIcon style={{fontSize:'1vw'}}/>
                    </IconButton></Tooltip>
                   {checkWriteS(user.Permissions)===true? <Tooltip title='Modifica mappa'><IconButton onClick={()=>history.push(`/mappemanutentore/${user_id}/${sede_id}/piani/${piano.Id}/${map.Id}/editmappa`)} style={{width:'fit-content'}}>
                    <EditIcon style={{fontSize:'1vw'}}/>
                    </IconButton></Tooltip>:null}
                   {checkDeleteS(user.Permissions)===true? <Tooltip title='Elimina mappa'>
                    <IconButton onClick={()=>deleteMappa(map)} style={{width:'fit-content'}}>
                    <DeleteIcon  style={{fontSize:'1vw'}}/>
                    </IconButton>
                    </Tooltip>:null}
                   
                  </div>
                </div>
                </div>
                <div  style={{width:'8vw',height:'6vw',borderTopRightRadius:10,borderBottomRightRadius:10 }}>
                <img alt='plan' src={map.Planimetria} style={{width:'100%',height:'100%',borderTopRightRadius:10,borderBottomRightRadius:10,objectFit:'cover'}}></img>
                </div>
              </div>
            ))}
            </div>
            </div>
        </div>
    )):<h4 className='normal-text' style={{marginLeft:'1vw',marginTop:'2vh'}}>Nessun piano aggiunto.</h4>}
    </div>
    </div>
    </div>
  )
}

export default PianiMappe