//popup evadi attivita
import React, { useState, useCallback, useEffect } from "react";
import "./Popup.css";
import firebase from "firebase/app";

import CancelIcon from "@material-ui/icons/Cancel";

const EvadiAttività = (props) => {
  const auth = firebase.auth();
  const db = firebase.firestore();
  const [selectedOptionEsito, setOptionEsito] = useState(""); //esito
  const [reportz, setReport] = useState(''); //report
  const [tipo, setTipo] = useState();
  const [sede_cli, setSedeCli] = useState(""); //sede cliente
  const [data, setData] = useState("");
  const [titolo, setTitolo] = useState(""); //titolo
  const [descrizione, setDesc] = useState(""); //descrizione

  const [emailz,setEmailz] = useState()


  useEffect(() => {
    setSedeCli(props.sede_cli);
    auth.onAuthStateChanged(function (user) {
      if (user) {
        if (props.info.Tipo !== "SottoAccount") {
          // Non è un sottoaccount
          db.collection("Users")
            .doc(auth.currentUser.email)
            .get()
            .then((doc) => {
              setTipo(doc.data().Tipo);
              setEmailz(auth.currentUser.email)
            });
        } else {
          // è un sottoaccount
          db.collection("Users")
            .doc(props.info.MasterAccount)
            .get()
            .then((doc) => {
              setTipo(doc.data().Tipo);
              setEmailz(props.info.MasterAccount)

            });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]); //prende info utente


  const handleSubmitS = () => {
    var arr = [...props.acts];

    if (sede_cli !== "") {
      if(reportz!==''){
        
      
        if (selectedOptionEsito === "Positivo") {
          //se l'esito è positivo

          db.collection("Attività") //setta come conclusa l'attività 
            .doc(props.sottoRef)
            .collection("Attività")
            .doc(props.keyy)
            .update({
              Stato: "Conclusa",
              Esito: "Positivo",
              Report: reportz,

            });
            db.collection("Attività") //setta come conclusa l'attività 
            .doc(props.sottoRef).update({
              Sott_Attivi: false,
              Esito: 'Positivo'
            })
            const index = arr.findIndex((actz) => actz.key === props.keyy);
            arr[index].Stato = "Conclusa";
            arr[index].Esito = "Positivo";
            arr[index].Report = reportz;
            props.setActivity(arr);
            props.reGet()
          
          alert("Intervento evaso con successo!");

          props.setTrigger(false);
        } else if (selectedOptionEsito === "Negativo") {
          //se negativo
          const titoloRe = titolo.toString();
          const descrizioneRe = descrizione.toString();
          const ref = db
            .collection("Attività")
            .doc(props.sottoRef)
            .collection("Attività")
            .doc();
          const id = ref.id;

          db.collection("Attività")
            .doc(props.sottoRef)
            .collection("Attività")
            .doc(props.keyy)
            .update({
              Stato: "Conclusa",
              Esito: "Negativo",
              Report: reportz,
              SottoAttività: true,
            });
          ref.set({
              Titolo: titoloRe,
              Descrizione: descrizioneRe,
              Stato: "Pianificata",
              Nome: props.info.Nome,
              Cognome: props.info.Cognome,
              Oggetto: props.data.Oggetto,
              Cliente: props.data.Cliente,
              //Cliente: auth.currentUser.email,
              ID: id,
              Sede: props.data.Sede,
              Nome_Azienda: props.data.Nome_Azienda,
              Indirizzo: props.data.Indirizzo,
              Categoria: props.data.Categoria,
              Data: '',
              Macrocategoria: props.data.Macrocategoria,
              Op_Email: props.info.Email,
              Operatore: props.info.Nome_Azienda,
              
            });
            arr.push({
              Titolo: titoloRe,
              Descrizione: descrizioneRe,
              Stato: "Pianificata",
              Nome: props.info.Nome,
              Cognome: props.info.Cognome,
              Oggetto: props.data.Oggetto,
              Cliente: props.data.Cliente,
              ID: id,
              Sede: props.data.Sede,
              Nome_Azienda: props.data.Nome_Azienda,
              Indirizzo: props.data.Indirizzo,
              Categoria: props.data.Categoria,
              Data: '',
              Macrocategoria: props.data.Macrocategoria,
              Op_Email: props.info.Email,
              Operatore: props.info.Nome_Azienda,
              key: id,
            })

            const index = arr.findIndex((actz) => actz.key === props.keyy);
            arr[index].Stato = "Conclusa";
            arr[index].Esito = "Negativo";
            arr[index].Report = reportz;
            props.setActivity(arr);
            props.reGet()
          alert(
            "Intervento evaso con successo, è stata creata un'ulteriore intervento di recupero"
          );

          props.setTrigger(false);
        }
      }else{
        alert("Inserire i parametri per l'intervento correttivo.")
      }
    }else{
      alert("Inserire i parametri per l'intervento correttivo.")

    }
  }; //scrittura database off
  return props.trigger ? (
    <div className="pop-opac">
      <div
        className="popup"
        style={{
          backgroundColor: "white",
          width: "72vw",
          left: "18vw",
          height: "80vh",
        }}
      >
        <div style={{ textAlign: "left", width: "fit-content" }}>
          <button
            className="cancelIconButton"
            onClick={() => props.setTrigger(false)}
          >
            <CancelIcon
              style={{ width: "15px", height: "15px" }}
              className="cancelIcon"
            />
          </button>
        </div>
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            width: "fit-content",
          }}
        >
                        <label  className="page-title">EVADI AZIONE CORRETTIVA</label>

        </div>
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            width: "fit-content",
          }}
        >
                    <label  style={{ fontSize:'1vw',color:'#5C6672', fontWeight:'normal',fontFamily:'Montserrat'}}>Compila il riepilogo dell'azione correttiva per concluderla.</label>

        </div>{" "}
        <br />
        <div style={{ display: "flex" }}>
          <div
            style={{padding:"10px",backgroundColor:"white",border: '1px solid lightgrey', width:'25vw', borderRadius:'5px', marginLeft:'auto', marginRight:'5vw',boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', height:'58vh',overflowY:'auto', overflowX:'hidden'}}
          >
            <div style={{ borderBottom: "1px solid lightgrey" }}>
              <div
                style={{width:'fit-content',marginBottom:'5px'}}
              >
                <h4  className="header-normal" style={{fontSize:'1.2rem'}}>INFO AZIONE CORRETTIVA</h4>
              </div>
         
            </div>
         
            <div style={{marginTop:'0.5rem'}}>
          
          <div style={{width:'100%',borderBottom:'1px solid lightgrey',marginBottom:'1vh',paddingBottom:'0.5vh'}}>
          <div style={{width:'fit-content'}}>
          <label  className="sub-text-b">TITOLO</label></div>
          <h4 className="normal-text">{props.data.Titolo}</h4> 
          </div> 
          <div style={{width:'100%',borderBottom:'1px solid lightgrey',marginBottom:'0.5vh',paddingBottom:'0.5vh'}}>
          <div style={{width:'fit-content'}}>
          <label  className="sub-text-b">CLIENTE</label></div>
          <h4 className="normal-text">{props.data.Nome_Azienda} ({props.data.Cliente})</h4> 
          </div> 
          <div style={{width:'100%',borderBottom:'1px solid lightgrey',marginBottom:'0.5vh',paddingBottom:'0.5vh'}}>
          <div style={{width:'fit-content'}}>
          <label  className="sub-text-b">SEDE D'INTERVENTO</label></div>
          <h4 className="normal-text">{props.data.Sede}</h4> 
          </div> 
          <div style={{width:'100%',borderBottom:'1px solid lightgrey',marginBottom:'0.5vh',paddingBottom:'0.5vh'}}>
          <div style={{width:'fit-content'}}>
          <label  className="sub-text-b">OGGETTO</label></div>
          <h4 className="normal-text">{props.data.Oggetto}</h4> 
          </div> 
          <div style={{width:'100%',borderBottom:'1px solid lightgrey',marginBottom:'0.5vh',paddingBottom:'0.5vh'}}>
          <div style={{width:'fit-content'}}>
          <label  className="sub-text-b">DESCRIZIONE</label></div>
          <h4 className="normal-text">{props.data.Descrizione}</h4> 
          </div> 
          <div style={{width:'100%',marginBottom:'0.5vh',paddingBottom:'0.5vh'}}>
          <div style={{width:'fit-content'}}>
          <label  className="sub-text-b">DATA D'INTERVENTO</label></div>
          <h4 className="normal-text">Da fissare con il cliente</h4> 
          </div> 
          </div>
          </div>

          <div
           style={{padding:"10px",backgroundColor:"white", border: '1px solid lightgrey', width:'25vw', borderRadius:'5px',  marginRight:'auto',boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', height:'58vh',overflowY:'auto', overflowX:'hidden'}}
          >
                          <div style={{ borderBottom: "1px solid lightgrey" }}>
                <div
                  style={{width:'fit-content',marginBottom:'5px'}}
                >
                  <label
                   className="header-normal" style={{fontSize:'1.2rem'}}
                  >
                    COMPILA ESITO
                  </label>
                </div>
              </div>

              <div
                style={{width:'fit-content',marginTop:'0.5rem'}}
              >
                         <label   className="sub-text-b">ESITO COMPLESSIVO*</label>

              </div>
              <div style={{ display: "flex" }}>
                <label
                  className="normal-text"
                >
                  <input
                    value="Positivo"
                    type="radio"
                    onChange={() => setOptionEsito("Positivo")}
                    checked={selectedOptionEsito === "Positivo"}
                  ></input>
                  Positivo
                </label>
                <label
                 className="normal-text" style={{marginLeft:'5px'}}
                >
                  <input
                    type="radio"
                    value="Negativo"
                    onChange={() => setOptionEsito("Negativo")}
                    checked={selectedOptionEsito === "Negativo"}
                  ></input>
                  Negativo
                </label>
              </div>
              {selectedOptionEsito !== "" ? (
                <div>
               
                  <div
                    style={{

                      width: "fit-content",
                    }}
                  >
              
              <label  className="sub-text-b">OSSERVAZIONI*</label>
                  </div>
                  <div
                    style={{width:'fit-content',marginTop:'0.5rem'}}
                  >
                    <input
                      type="text"
                      name="reportP"
                      className="input-style"
                      placeholder="Inserisci..."
                      onChange={(e) => setReport(e.target.value)}
                    ></input>
                  </div>
                </div>
              ) : null}
              <br />
              {selectedOptionEsito === "Negativo" ? (
                <div>
                  <div
                   style={{width:'fit-content'}}
                  >
                                           <label  className="title-section">AGGIUNTA AZIONE CORRETTIVA</label>

                  </div>
                  <div
                    style={{
                      width: "fit-content",
                    }}
                  >
                              <label className="normal-text">AZIONE CORRETTIVA*</label>

                  </div>
                  <div
                    style={{
                     
                      width: "fit-content",
                    }}
                  >
                    <input
                      type="text"
                      name="titolo"
                      onChange={(e) => setTitolo(e.target.value)}
                      className="input-style"
                      placeholder="Inserisci..."
                    ></input>
                  </div>
                  <div
                    style={{width:'fit-content',marginTop:'0.5rem'}}
                  >
                                              <label  className="normal-text">DESCRIZIONE AZIONE CORRETTIVA*</label>

                  </div>
                  <div
                    style={{
                      width: "fit-content",
                    }}
                  >
                    <input
                      type="text"
                      name="descrizione"
                      onChange={(e) => setDesc(e.target.value)}
                      className="input-style"
                      placeholder="Inserisci..."
                    ></input>
                  </div>                 
                  <br />
                  <div
                    style={{
                    
                      width: "fit-content",
                    }}
                  >
                    <button
           className='main-button'   onClick={handleSubmitS }
                      
                      
                      value="Pianifica intervento"
                    >
                      EVADI
                    </button>
                  </div>
                  <br />
                  <br />
                </div>
              ) : selectedOptionEsito === "Positivo" ? (
                <div
                  style={{
                   
                    width: "fit-content",
                  }}
                >
                  <button
                    className='main-button'
                    onClick={handleSubmitS}
                    value="Pianifica intervento"
                  >
                    EVADI
                  </button>
                </div>
              ) : null}
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default EvadiAttività;
