import React , { useState, useEffect } from 'react'
import firebase from "firebase/app";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getUtente,getManutentori, getCategorie,getSottocategorie,getElementiG,getSede,getSedi,getSedeName} from "../../EngineClienti/engineClienti";
import Loading from '../../loading';
import CancelIcon from '@material-ui/icons/Cancel';
import AggiungiAllegato from '../../Popups/AggiungiAllegato';
import { IconButton, useRadioGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import NavBar from '../../NavBar/NavBar';

const RichiediIntervento = ({history}) => {

  const [user,setUser] = useState(null)
  const [section,setSection] = useState(0)
  const [manutentori,setManutentori] = useState([])
  const [sedi,setSedi] = useState([])
  const [categorie,setCategorie] = useState([])
  const [sottocategorie,setSottocategorie] = useState([])
  const [elementi,setElementi] = useState([])
  const [infoSede,setInfoSede] = useState(null)
  const [infoMan,setInfoMan] = useState(null)

  const [percentage,setPercentage] = useState(0)

  ////input
  const [data,setData] = useState('')
  const [sede,setSede] = useState('')
  const [manutentore,setManutentore] = useState('')
  const [categoria,setCategoria] = useState('')
  const [sottocategoria,setSottocategoria] = useState('')
  const [elemento,setElemento] = useState('')
  const [titolo,setTitolo] = useState('')
  const [descrizione,setDescrizione] = useState('')
  const [hour,setHour] = useState('')

  
  const db = firebase.firestore()
  const auth = firebase.auth()
  const storage = firebase.storage();
  var storageRef = storage.ref();

  const listaSe = JSON.parse(sessionStorage.getItem('sedi'));


  useEffect(()=>{
    auth.onAuthStateChanged(function (user) {
      if (user) {
        getUtente(db,auth,setUser)
      }
    })
  },[auth])


  useEffect(()=>{
    if(user!==null){
      getManutentori(db,user.Email,setManutentori)
      getSedi(db,user.Email,setSedi)
    }
  },[user])

  useEffect(()=>{
    if(categoria!==''){
      getSottocategorie(db,user.Email,sede,categoria,setSottocategorie)
    }
  },[categoria])

  useEffect(()=>{
    if(sottocategoria!==''&&categoria!==''){
      getElementiG(db,user.Email,sede,categoria,sottocategoria,setElementi)
    }
  },[sottocategoria])

  useEffect(()=>{
    if(sede!==''){
      getCategorie(db,user.Email,sede,setCategorie)
      getSede(db,user.Email,sede,setInfoSede)

    }
  },[sede])


  const [showAggiungiAllegato,setShowAggiungiAllegato] = useState(false)
  const [allegati,setAllegati] = useState([])

  function rimuoviAllegato(indice){
    setAllegati((current)=>current.filter((allegato,index)=>index!==indice))
  } //rimossione allegati

  function returnMan(em){
    var arr = [...manutentori]
    var m= arr.find((e)=>e.Email===em)
    return m
  }


  const handleSubmit = () => {

    //const { nome, cognome, StartTime, titolo, descrizione } =
    if(window.confirm("Vuoi richiedere l'intervento: "+titolo+"?")){

    const docRef = db.collection("Attività").doc();
    const id = docRef.id; //id dell'attività con cui verrà poi salvata nel db


          var infoMan = returnMan(manutentore)


          console.log(infoSede)
          if(infoSede!==null){
            const sedename = getSedeName(sede,sedi)
          docRef.set({ //crea documento su collezione attività 
            Titolo: titolo,
            Descrizione: descrizione,
            Nome: user.Nome,
            Cognome: user.Cognome,
            Data: data,
            Hour:hour,
            Categoria: categoria,
            Macrocategoria: sottocategoria!==''?sottocategoria:"",
            Oggetto: elemento!==''?elemento:sottocategoria!==''?sottocategoria:categoria,
            Stato: "In attesa",
            Cliente: user.Email,
            ID: id,
            Operatore: infoMan.Nome_Azienda,
            Sede: sede,
            SedeName: sedename,
            Nome_Azienda: user.Nome_Azienda,
            Indirizzo: infoSede.Indirizzo,
            Op_Email: manutentore
          })

          db.collection("Users") //aggiunge attività all'utente
          .doc(user.Email)
          .collection("Sedi")
          .doc(sede)
          .collection("Attività")
          .doc(id)
          .set({
            ref: id
          })
          if (manutentore!=='') {
            db.collection("Users")
              .doc(manutentore)
              .collection("Clienti")
              .doc(user.Email)
              .collection("Attività")
              .doc(id)
              .set({ //scrive nelle attività clienti dell'offritore
                ref: id
              }).then(()=>{
                db.collection("Users")
                .doc(manutentore).update({
                  Current_Int: firebase.firestore.FieldValue.increment(1)
                })
              });
          }


          if(elemento!==''){
            console.log(user.Email,sede,categoria,sottocategoria,elemento)
            db.collection("Users")
           .doc(user.Email)
           .collection("Sedi")
           .doc(sede)
           .collection("Categorie")
           .doc(categoria)
           .collection("Sottocategorie")
           .doc(sottocategoria)
           .collection("Elementi")
           .doc(elemento)
           .collection("Interventi")
           .doc(id)
           .set({ //scrive sugli interventi ricevuti dall'elemento
             ref:id
           });
           }
           db.collection('Users')
           .doc(user.Email).update({
            Current_Int: firebase.firestore.FieldValue.increment(1)
           })
           db.collection("Users")
           .doc(user.Email)
           .collection("Sedi")
           .doc(sede).update({
            Int: firebase.firestore.FieldValue.increment(1)
           })
           db.collection("Users")
           .doc(manutentore)
           .collection("Clienti")
           .doc(user.Email).update({
            Int: firebase.firestore.FieldValue.increment(1)
           })
           

 // Notifica per l'offritore
 var date2 = new Date();
 var days2 = date2.getDate();
 if(days2<10){
   days2 = '0' + days2;
 }
     var month2 = ('0'+(date2.getMonth()+1)).slice(-2);
     var minutes2 =  ('0'+(date2.getMinutes())).slice(-2);
       var dataFormattata = days2+"-"+month2+"-"+date2.getFullYear()+" "+(date2.getHours())+":"+minutes2;
        db.collection("Users").doc(manutentore).collection("Notifiche").doc().set({
 Nome_Azienda:user.Nome_Azienda,
 Nome_Cognome:user.Nome+" "+user.Cognome,
 Email:user.Email,
 Messaggio:"ha richiesto un intervento",
 Data:dataFormattata,
 Oggetto: titolo,
 InterventoID:id
          })
          if(allegati.length>0){
            addAllegati(user.Email,"Attività",sede,id,infoMan.Nome_Azienda,infoSede.Indirizzo)
          }else{
            var arr = JSON.parse(sessionStorage.getItem('interventi'));
            arr.push({
              Titolo: titolo,
              Descrizione: descrizione,
              Nome: user.Nome,
              Cognome: user.Cognome,
              Data: data,
              Hour:hour,
              Categoria: categoria,
              Macrocategoria: sottocategoria!==''?sottocategoria:"",
              Oggetto: elemento!==''?elemento:sottocategoria!==''?sottocategoria:categoria,
              Stato: "In attesa",
              Cliente: user.Email,
              ID: id,
              Operatore: infoMan.Nome_Azienda,
              Sede: sede,
              Nome_Azienda: user.Nome_Azienda,
              Indirizzo: infoSede.Indirizzo,
              Op_Email: manutentore
            })
            sessionStorage.setItem('interventi', JSON.stringify(arr));
            alert('Intervento richiesto con successo')
          }
        }else{
          console.log(infoSede)
        }
  
    
  }
  }; //creazione attivita

  function addAllegati(ClienteEmail,tipoAttivita,SedeCliente,AttivitaId,manAzienda,indirizz){
    console.log("hey:",allegati)

    db.collection("Attività").doc(AttivitaId).get().then((doc)=>{
        var attivitaData = doc.data();

        var allegatiAggiunti = [];

        for(let i=0;i<allegati.length;i++){
          const nameTmp = allegati[i].name;
          const fileRef = storageRef.child(
                user.Email+
                "/"+
                AttivitaId+
                "/"+
                nameTmp
            )
            fileRef.put(allegati[i]).then((snapshot)=>{
              var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
              setPercentage(progress)
                snapshot.ref.getDownloadURL().then((downloadURL)=>{
                    allegatiAggiunti.push({Allegato:downloadURL,AggiuntoDa:user.Email,Nome:nameTmp, StorageRef: fileRef.fullPath })

                    if(allegatiAggiunti.length === allegati.length){ // ho aggiunto tutti gli allegati, quindi posso caricare le ref sull'intervento
                        db.collection("Attività").doc(AttivitaId)
                        .update({
                            Allegati:allegatiAggiunti
                        })
                        .then(()=>{
                            
                          var arr = JSON.parse(sessionStorage.getItem('interventi'));
            arr.push({
              Titolo: titolo,
              Descrizione: descrizione,
              Nome: user.Nome,
              Cognome: user.Nome,
              Data: data,
              Categoria: categoria,
              Macrocategoria: sottocategoria!==''?sottocategoria:"",
              Oggetto: elemento!==''?elemento:sottocategoria!==''?sottocategoria:categoria,
              Stato: "In attesa",
              Cliente: user.Email,
              ID: AttivitaId,
              Operatore: manAzienda,
              Sede: sede,
              Nome_Azienda: user.Nome_Azienda,
              Indirizzo: indirizz,
              Op_Email: manutentore,
              Allegati: allegati
            })
            sessionStorage.setItem('interventi', JSON.stringify(arr));


                            alert('Intervento richiesto con successo')
                        })
                    }
                })
                
            })  
      }
    })
  
    
} //aggiunta allegati

  return (
    <div className="background" style={{width:'100vw',height:'100vh',overflow:'hidden'}}>
      <NavBar/>
       {showAggiungiAllegato && <AggiungiAllegato trigger={showAggiungiAllegato} setTrigger={setShowAggiungiAllegato}
            setAllegati={setAllegati} allegati={allegati}/>}
        <div
   style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}
 >
    <div  style={{  width: "30vw",display:'flex',marginTop:'1.2rem' }}>
          <button style={{marginBottom:'auto',marginTop:'1rem'}} onClick={() => history.goBack()}
            className="cancelIconButton"
            
          >
            <ArrowBackIcon
             className="back-button"
            />
          </button>
          <h4 className="page-title" style={{margin:0}}>RICHIEDI INTERVENTO {percentage!==0?percentage+'%':null}</h4>
        </div>
        {section===0?<div style={{marginLeft:'3.5rem',marginTop:'2rem',width:'30rem'}}>
            {/*SELEZIONE DATA */}

<div ><h4  className="sub-text-b">Proponi una data per l'intervento*</h4>
        <input onChange={(e)=>setData(e.target.value)} type='date' className="input-style" style={{marginTop:'0.5rem'}} />
           </div>
           {/*SELEZIONE ORARIO */}

<div ><h4  className="sub-text-b"  style={{marginTop:'1rem'}}>Proponi un orario per l'intervento*</h4>
<input type="time" id="orario" name="orario" onChange={(e)=>setHour(e.target.value)}
 required className="input-style" ></input>           </div>
                     {/*SELEZIONE SEDE */}

        <h4 style={{marginTop:'1rem'}}  className="sub-text-b">Seleziona sede*</h4>
        <select onChange={(e)=>setSede(e.target.value)} className="input-style" defaultValue={"default"}
        >
            <option value={"default"} disabled>
                  Seleziona sede...
                </option>
                {sedi.length>0?sedi.map((sed,n)=>(
                   <option key={n} value={sed.Id}>
                   {sed.Nome?sed.Nome!==''?sed.Nome:sed.Comune:sed.Comune}
                 </option>
                )):null}
                
        </select> 

                   {/*SELEZIONE OPERATORE */}
    
<div style={{marginTop:'1rem'}}><h4  className="sub-text-b">Seleziona operatore*</h4>
        <select onChange={(e)=>setManutentore(e.target.value)} className="input-style2" style={{width:'15rem',fontWeight:'normal'}} defaultValue={"default"}>
            <option value={"default"} disabled>
                  Seleziona operatore...
                </option>
                {manutentori.length>0?manutentori.map((man,n)=>(
                   <option key={n} value={man.Email}>
                   {man.Nome_Azienda}
                 </option>
                )):null}
        </select>
        </div>
        <button onClick={()=>data!==''&&sede!==''&&manutentore!==''?setSection(1):alert('Inserire tutti i campi richiesti')} className='main-button' style={{marginTop:'2rem'}}>Avanti</button>
        </div>:null}
        {section===1?<div style={{marginLeft:'3.5rem',marginTop:'2rem',width:'30rem'}}>

        {/*SELEZIONE CATEGORIA */}
    
<div style={{marginTop:'1rem'}}><h4  className="sub-text-b">Seleziona categoria*</h4>
        <select onChange={(e)=>setCategoria(e.target.value)} className="input-style2" style={{width:'15rem',fontWeight:'normal'}} defaultValue={"default"}>
            <option value={"default"} disabled>
                  Seleziona categoria...
                </option>
                {categorie.length>0?categorie.map((cat,n)=>(
                   <option key={n} value={cat.Id}>
                   {cat.Nome}
                 </option>
                )):null}
        </select>
        </div>
        {categoria!==''?<div style={{marginTop:'1rem'}}><h4  className="sub-text-b">Seleziona sottocategoria (facoltativo):</h4>
        <select  onChange={(e)=>{setSottocategoria(e.target.value);setElemento('')}}className="input-style2" style={{width:'15rem',fontWeight:'normal'}} defaultValue={"default"}>
            <option value={"default"} disabled>
                  Seleziona sottocategoria...
                </option>
                <option value={""}>
                  Nessuna
                </option>
                {sottocategorie.length>0?sottocategorie.map((sott,n)=>(
                   <option key={n} value={sott.Id}>
                   {sott.Nome}
                 </option>
                )):null}
        </select>
        </div>:null}
        {sottocategoria!==''?<div style={{marginTop:'1rem'}}><h4  className="sub-text-b">Seleziona elemento (facoltativo):</h4>
        <select  onChange={(e)=>setElemento(e.target.value)} className="input-style2" style={{width:'15rem',fontWeight:'normal'}} defaultValue={"default"}>
            <option value={"default"} disabled>
                  Seleziona elemento...
                </option>
                <option value={""} >
                  Nessuno
                </option>
                {elementi.length>0?elementi.map((ele,n)=>(
                   <option key={n} value={ele.Id}>
                   {ele.Nome}
                 </option>
                )):null}
        </select>
        </div>:null}

        <button onClick={()=>categoria!==''?setSection(2):alert('Inserire la categoria')} className='main-button' style={{marginTop:'2rem'}}>Avanti</button>
        <br/>
        <button onClick={()=>setSection(0)}  className='main-button' style={{marginTop:'1rem',backgroundColor:'#FF0060'}}>Indietro</button>

        </div>:null}
        {section===2?<div style={{marginLeft:'3.5rem',marginTop:'2rem',width:'30rem'}}>

                {/*TITOLO */}

        <div style={{marginTop:'1rem'}}><h4  className="sub-text-b">Inserisci titolo*</h4>
        <input onChange={(e)=>setTitolo(e.target.value)} type='text' className="input-style" style={{marginTop:'0.5rem'}} placeholder='Titolo...'/>
           </div>
                           {/*DESCRIZIONE */}

           <div style={{marginTop:'1rem'}}><h4  className="sub-text-b">Inserisci descrizione*</h4>
        <input onChange={(e)=>setDescrizione(e.target.value)} type='text' className="input-style" style={{marginTop:'0.5rem'}} placeholder='Descrizione...'/>
           </div>
        <button onClick={()=>titolo!==''&&descrizione!==''?setSection(3):alert('Inserire tutti i campi richiesti')} className='main-button' style={{marginTop:'2rem'}}>Avanti</button>
        <br/>
        <button onClick={()=>setSection(1)}  className='main-button' style={{marginTop:'1rem',backgroundColor:'#FF0060'}}>Indietro</button>
        </div>:null}
        {section===3?<div style={{marginLeft:'3.5rem',marginTop:'2rem',width:'35rem'}}>


                           {/*ALLEGATI */}

           <div style={{marginTop:'1rem',display:'flex',flexDirection:'row',alignItems:'center'}}><h4  className="sub-text-b">Aggiungi allegati</h4>
           <IconButton style={{width:'fit-content'}} onClick={()=>setShowAggiungiAllegato(true)}>
            <AddIcon className="sub-text-b"/>
           </IconButton>
           </div>
           {allegati.length===0?<label className='medium-text' style={{marginLeft:"auto",marginRight:"auto",float:"center"}}>Nessun allegato inserito.</label>:
          <div style={{display:"inline-flex",flexDirection:"column",width:"34vw",maxHeight:"35vh",overflowY:"auto"}}>
            {allegati.map((allegato,index)=>{
            return <div style={{alignItems:"center",marginRight:'auto',width:'33vw',marginBottom:'1vh',borderBottom:'1px solid lightgrey',padding:'5px'}}>
              <div style={{display:'flex'}}>
              <InsertDriveFileIcon style={{height:"1vw",width:"1vw",color:"#118ab2"}}/>
              <h4 className='medium-text' style={{whiteSpace: 'nowrap',color:'#192bc2',fontWeight:'bold',fontSize:'0.9rem',
overflow: 'hidden',
textOverflow: 'ellipsis',maxWidth:'29vw',margin:0}}>{allegato.name}</h4></div>

              <Tooltip title="Rimuovi allegato" style={{width:'fit-content'}}>
                <DeleteIcon style={{height:"1.2vw",width:"1.2vw",color:"#FF0060",verticalAlign:'middle',cursor:'pointer',marginTop:'0.5rem'}} onClick={()=>rimuoviAllegato(index)}/>
              </Tooltip>
              </div>
            })
          }
            </div>
        }        <br/>

        <button onClick={()=>titolo!==''&&descrizione!==''?handleSubmit():alert('Inserire tutti i campi richiesti')} className='main-button' style={{marginTop:'2rem',width:'8rem'}}>Crea intervento</button>
        <br/>
        <button onClick={()=>setSection(1)}  className='main-button' style={{marginTop:'1rem',backgroundColor:'#FF0060'}}>Indietro</button>
        </div>:null}
 </div>
        </div>
  )
}

export default RichiediIntervento