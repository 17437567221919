import React,{useEffect, useState} from 'react'
import NavBar from '../NavBar/NavBar'
import Notifiche from '../Notifiche/Notifiche'
import firebase from "firebase";
import {  IconButton, Tooltip } from '@mui/material';
import LoopIcon from '@material-ui/icons/Loop';
import { getManutentori,getUtente,checkWriteS } from '../EngineClienti/engineClienti';
import CancelIcon from '@material-ui/icons/Cancel';


const Manutentori = ({history}) => {

    const db = firebase.firestore()
    const auth = firebase.auth()

    const [user,setUser] = useState(null)
    const [manutentori,setManutentori] = useState([]) //lista manutentori
    const [findInput,setFindInput] = useState('')

    const [triggerAdd,setTriggerAdd] = useState(false)

                    //sorting
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
            getUtente(db,auth,setUser)
          }
        })
      },[auth])

      const Buttons = () =>{

        return(
          <div style={{display:'flex',alignItems:'center',gap:'1.5rem'}}>
           {checkWriteS(user?user.Permissions:null)===true? <div>
            <h4 onClick={()=>setTriggerAdd(true)}  className='text-buttons' >
                    AGGIUNGI MANUTENTORE
                  </h4>
            </div>:null}
            <div>
            <h4 onClick={()=>history.push(`/search`)}  className='text-buttons' >
                    CERCA MANUTENTORE
                  </h4>
            </div>
          
          </div>
        )
      }

      useEffect(()=>{
        if(user!==null){
            getManutentori(db,user.Email,setManutentori)
        }
    },[user])
    
    function reGet (){
        getManutentori(db,user.Email,setManutentori)
      
      }

      function renderManutentore(manutentore){
      
        if(findInput === '' ||
          manutentore.Nome_Azienda.toLowerCase().includes(findInput.toLowerCase()) ||
          manutentore.Email.toLowerCase().includes(findInput.toLowerCase()) ||
          manutentore.Numero.toLowerCase().includes(findInput.toLowerCase()) ||
          manutentore.Indirizzo.toLowerCase().includes(findInput.toLowerCase()) ||
          manutentore.Comune.toLowerCase().includes(findInput.toLowerCase()) ||
          manutentore.Nome.toLowerCase().includes(findInput.toLowerCase()) ||
          manutentore.Cognome.toLowerCase().includes(findInput.toLowerCase())){
          return true
        }else{
          return false
        }
      }

      const AddManutentore = (props) => {

        const [emailMan,setEmailMan] = useState('')

        function sendRequest(){
            try{
                if(emailMan!==''){
                    const emailz= emailMan.toLowerCase()
                    db.collection('Users').doc(user.Email).collection('Offritori').doc(emailz).get().then((x)=>{
                        if(x.exists){
                            alert('Hai già aggiunto o mandato una richiesta a questo utente, attendi la sua risposta.')
                        }else{
                            db.collection('Users').doc(emailz).get().then((us)=>{
                                if(us.exists){
                                    if(us.data().Tipo==='Lavoratore'){
                                        db.collection("Users").doc(emailz).collection("Richieste").doc(user.Email).set({
                                            Nome_Azienda: user.Nome_Azienda,
                                            email: user.Email,
                                            Message: 'Vorrebbe aggiungerti come manutentore.'
                                              }).then(()=>{
                                                db.collection("Users") 
                                                .doc(user.Email)
                                                .collection("Offritori")
                                                .doc(emailz)
                                                .set({
                                                  Email: emailz,
                                                  Stato: 'pending'
                                                }).then(()=>{
                                                    alert('Richiesta inviata con successo, attendi una risposta dal manutentore.')
                                                    props.setTrigger(false)
                                                })
                                              })
                                    }else{
                                        alert("L'utente selezionato non è un manutentore.")
                                    }
                                }else{
                                    alert('Non esiste alcun utente con questa email.')
                                }
                            })
                        }
                    })
                 
                } 
            }catch(e){
                alert('Errore: '+e)
            }
           
        }

        return (
            <div className="pop-opac">
                <div className="popup" style={{backgroundColor:"white", height:'40vh',overflowY: "auto", width:'66vw', left:'20vw',top:'10vh'}} >
                <div style={{textAlign:'left', width: 'fit-content'}}>
            <button
              className="cancelIconButton"
              
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
            </button>
            </div>
            <div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content',alignItems:'center',display:'flex',flexDirection:'column',marginTop:'2vh'}}>
            <h4 className='page-title' style={{margin:0}}>AGGIUNGI MANUTENTORE</h4>
            <h4 className='sub-text' style={{margin:0}}>Invia una richiesta di aggiunta ad un manutentore</h4>

            </div>
            <div style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',marginTop:'2vh',alignItems:'center',display:'flex',flexDirection:'column'}}>
            <input type='text' className='input-style2' style={{width:'20vw',textAlign:'center'}} placeholder="Inserisci l'email del manutentore da aggiungere..." onChange={(e)=>setEmailMan(e.target.value)}></input>
            <br/>
            <button className='main-button' onClick={sendRequest}>Invia richiesta</button>
            </div>
                </div>
            </div>
        )
      }

      const onSort = (columnKey) => {
        let direction = 'ascending';
        if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
          direction = 'descending';
        }
        setSortConfig({ key: columnKey, direction });
      };
    
      const sortedMan = React.useMemo(() => {
        let sortableItems = [...manutentori];
        if (sortConfig.key !== null) {
          sortableItems.sort((a, b) => {
            if (typeof a[sortConfig.key] === 'string') {
              return a[sortConfig.key].localeCompare(b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
            } else if (sortConfig.key === 'date') {
              return new Date(a[sortConfig.key]) - new Date(b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
            }else if (sortConfig.key === 'number') {
              return (a[sortConfig.key] - b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
            } else {
              return 0;
            }
          });
        }
        return sortableItems;
      }, [manutentori, sortConfig]);


  return (
    <div className="background" style={{width:'100vw',height:'100vh'}}>
        <NavBar/>
        {user!==null&&user!==null?<Notifiche user={user}/>:null}
        {triggerAdd===true?<AddManutentore setTrigger={setTriggerAdd} trigger={triggerAdd}/>:null}
        <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
        <div className="container-dash">
        <h4 className="page-title" style={{marginTop:'1.2rem'}}>MANUTENTORI</h4>
       
      </div>
     {user!==null?<Buttons/>:null}
      <div className='box-shadow' style={{marginTop:'0.5rem',borderRadius:5,width:'75vw',backgroundColor:'white',height:'3rem',display:'flex',alignItems:'center'}}>
          <div style={{width:'10vw',height:'3rem',borderRight:'1px solid lightgrey',}}>
          <h4 className="normal-text" style={{marginLeft:'auto',marginRight:'auto',marginTop:'0.3rem',fontSize:'1rem',width:'fit-content'}}>
           {manutentori.length}
          </h4>
          <h4 className="sub-text" style={{marginLeft:'auto',marginRight:'auto',fontSize:'0.8rem',width:'fit-content'}}>
          {'Manutentori aggiunti'}
          </h4>
          </div>
          
             {/* */}
      
              <div  style={{marginLeft:'auto'}}>
                <input onChange={(e)=>
                  setFindInput(e.target.value.toLowerCase())}
                  className="input-style2" style={{width:'30rem',height:'1rem',fontWeight:'normal',fontFamily:'Poppins'}} type='text' placeholder={"Cerca manutentore..."}></input>
                </div>            
          <div style={{marginLeft:10,marginRight:5}}>
          <Tooltip style={{width:'fit-content'}} title="Aggiorna lista">
                 <IconButton onClick={()=>reGet()} style={{backgroundColor:'#192bc2',width:'fit-content',height:'fit-content'}}  
                // onClick={()=>getEle(sedi,email)}
                 >
                 <LoopIcon style={{verticalAlign: 'middle', color: 'white',fontSize:'1rem'}}/>
                 </IconButton>
                 </Tooltip>
          </div>
          </div>
          <div style={{marginTop:10,borderRadius:5,width:'75vw',backgroundColor:'#14213d',height:'3rem',marginRight:'auto',display:'flex',alignItems:'center',boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'}}>
          
          <div style={{borderRight:'1px solid lightgrey',width:'15vw',height:'3rem',alignItems:'center',justifyContent:'center',cursor:'pointer'}} onClick={() => onSort('Nome_Azienda')}>
            <h4 className="header-table" >Ragione Sociale {sortConfig.key === 'Nome_Azienda' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',alignItems:'center',justifyContent:'center',cursor:'pointer'}} onClick={() => onSort('CurrentEmail')}>
            <h4 className="header-table" >Email {sortConfig.key === 'CurrentEmail' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Numero')}>
          <h4 className="header-table" >Telefono {sortConfig.key === 'Numero' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Indirizzo')}>
          <h4 className="header-table" >Indirizzo {sortConfig.key === 'Indirizzo' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Nome')}>
          <h4 className="header-table" >Nome {sortConfig.key === 'Nome' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Cognome')}>
          <h4 className="header-table" >Cognome {sortConfig.key === 'Cognome' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
         
          </div>
          <div style={{overflowY:'auto',height:'74vh'}}>
          {sortedMan.length>0?sortedMan.map((ele,n)=>(
           renderManutentore(ele)===true?
           <div key={n} className='table-row'>
            <div  style={{borderRight:'1px solid lightgrey',width:'15vw',height:'6rem',display:'flex',alignItems:'center'}}>
              <div>
              <Tooltip title={ele.Nome_Azienda}>

<h4 className="medium-text" style={{whiteSpace: 'nowrap',color:'#192bc2',fontWeight:'bold',fontSize:'0.9rem',
overflow: 'hidden',
textOverflow: 'ellipsis',maxWidth:'9vw'}}>
                {ele.Nome_Azienda}
              </h4>
              </Tooltip>
                </div>
              
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
            <div style={{display:'flex',alignItems:'center'}}>
              <Tooltip title={ele.CurrentEmail}>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
              {ele.CurrentEmail}
              </h4>
              </Tooltip>
              
              </div> 
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
            <div style={{display:'flex',alignItems:'center'}}>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
              {ele.Numero}
              </h4>
              
              </div>     
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div style={{display:'flex',alignItems:'center'}}>
                <Tooltip title={ele.Indirizzo+' - '+ele.Comune}>
                <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Indirizzo} - {ele.Comune}
              </h4>
                </Tooltip>
            
            
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div style={{display:'flex',alignItems:'center'}}>
                <Tooltip title={ele.Nome}>
                <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Nome}
              </h4>
                </Tooltip>
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div style={{display:'flex',alignItems:'center'}}>
                <Tooltip title={ele.Cognome}>
                <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Cognome}
              </h4>
                </Tooltip>
            
            
              </div>
            </div>

            <div style={{width:'7vw',height:'6rem',display:'flex',alignItems:'center'}}>
         
            </div>
  
            </div>:null
           
          )): <h4 className='normal-text' style={{marginTop:'1rem'}}>Nessun manutentore presente.</h4>}
        </div>
        </div>
        </div>
  )
}

export default Manutentori