//pagina di ricerca manutentore
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import NavBar from "../NavBar/NavBar";
import NavBar2 from "../NavBar/NavBar2";
import firebase from "firebase";
import SearchIcon from '@mui/icons-material/Search';
import { Filters } from "./Filters";
import EngineeringIcon from '@mui/icons-material/Engineering';
import { Tooltip } from "@mui/material";
import { VisualizeUser } from "./VisualizeUser";

 const SearchOff = () => {
    const db = firebase.firestore();
    const auth = firebase.auth();
    const [getInfo, setInfo] = useState(); //info utente
    const [emaill,setEmaill] = useState(null);
    const [manutentori,setManutentori] = useState([]) //manutentori
    const [territorio,setTerritorio] = useState('') //filtro territorio
    const [lingua,setLingua] = useState('') //filtro lingua
    const [capacità,setCapacità] = useState([]) //lista capacità
    const [search, setSearch] = useState('') //barra di ricerca
    const [filterComp,setFilterComp] = useState([]) //filtri per la competenza

    const [selectedUser,setSelectedUser] = useState('') //utente selezionato

    useEffect(() => {
        auth.onAuthStateChanged(function (user) {
          if (user) {
            try {    
              db.collection("UsersId") //funzione per verificare se l'utente è uno sottoaccount
                .doc(auth.currentUser.uid)
                .get()
                .then((querySnapshott) => {
                  db.collection('Users').doc(querySnapshott.data().Email).get().then((querySnapshot)=>{
                    if(querySnapshot.exists){
                      if(querySnapshot.data().Tipo==="SottoAccount"){
                        setEmaill(querySnapshot.data().MasterAccount);
                        db.collection("UsersId").doc(querySnapshot.data().MasterAccount).get().then((masterDocc)=>{
                          db.collection('Users').doc(masterDocc.data().Email).get().then((masterDoc)=>{
                            var sottoAccountInfo = {...masterDoc.data(),Nome:querySnapshot.data().Nome,
                              Cognome:querySnapshot.data().Cognome,
                              Tipo: querySnapshot.data().Tipo,
                              MasterAccount:querySnapshot.data().MasterAccount,
                              TipoSott:querySnapshot.data().TipoSott,
                              Permissions:querySnapshot.data().Permissions};
                              console.log(querySnapshot.data().Permissions)
                          setInfo(sottoAccountInfo)
                          })
                        })       
                        //setTipo(querySnapshot.data().Tipo)
                    }else{
                      setEmaill(querySnapshott.data().Email);
  
                        setInfo(querySnapshot.data());
                      }
                    }
                 
                  })
             
              getMan()
              getCapacità()
              })

              }catch(e){
    
              }
            }
        })
            },[auth]); //prende info

    function getMan(){
      var arr = []
      db.collection('Users').where('Tipo','==','Lavoratore').get().then((mans)=>{
        mans.docs.forEach((man)=>{
          arr.push(man.data())
        })
        setManutentori(arr)
      })
    } //prende manutentori
    function getCapacità(){
      var arr = []
      db.collection('Capacità').orderBy('Click','desc').limit(15).get().then((caps)=>{
        caps.docs.forEach((cap)=>{
          arr.push(cap.data())
        })
        setCapacità(arr)
      })
    } //prende tutte le capacità

    function matchT(man){
      if(territorio===''||territorio==='Tutti'){
        return true
      }else if(territorio!==''&&territorio!=='Tutti'){
        if(man.Territori!==undefined){
          if(man.Territori.indexOf(territorio)!==-1){
            return true
          }else{
            return false
          }
        }else{
          return false
        }
      }
    } //filtro territorio
    function matchL(man){
      if(lingua===''||lingua==='Tutte'){
        return true
      }else if(lingua!==''&&lingua!=='Tutte'){
        if(man.Lingue!==undefined){
          if(man.Lingue.indexOf(lingua)!==-1){
            return true
          }else{
            return false
          }
        }else{
          return false
        }
      }
    } //filtro lingue
    function matchS(man){
      if(man.Nome_Azienda.toLowerCase().includes(search)){
        return true;
      }else if(man.Email.toLowerCase().includes(search)){
        return true;
      }else if(man.Numero.toString().includes(search)){
        return true;
      }else{
        return false;
      }
    } //filtro ragione soc, email e numero
    function matchC(man){
      var l = filterComp.length
      var control = true
      if(l===0){
        return true;
      }else{
        if(man.Capacità){
          const found = filterComp.every(r=> man.Capacità.includes(r.Nome))
          if(found===true){
            return true
          }else{
            return false
          }
        }else{
          return false
        }
       

      {/*  filterComp.forEach((c)=>{
          l = l-1
          if(man.Capacità){
            if(man.Capacità.indexOf(c.Nome)!==-1){
              console.log('bella1',man,man.Capacità.indexOf(c.Nome))
            }else{
              control = false
            } 
          }else{
            console.log(man)
            return false;
          }
          if(l===0){
            console.log('bella3',man,control)
            if(control===true){
              console.log('visualizzo')
              return true
            }else{
              console.log('nonvisualizzo')

              return false
            }
          }
        })*/}
        
       
      }
    }
    function selectComp(comp){
      var arr = [...filterComp]
      arr.push(comp)
      setFilterComp(arr)

    } //filtro capacità
    function unselectComp(comp){
      var arr = [...filterComp]
      if(arr.length===1){
        arr = []
      }else{
        const index = arr.indexOf(comp);
        arr.splice(index,1)
      }
      setFilterComp(arr)
    } //unselect filtro capacita

    return(
        <div style={{width:'100vw',height:'100vh',overflow:'hidden'}}>
            <div style={{position: "fixed",top: '0',zIndex:1001}}>
        <NavBar page="/search "/>
       </div>
      {selectedUser!==''?<VisualizeUser info={getInfo} selectedUser={selectedUser}/>:null}
       <div style={{marginLeft:'15vw'}}>
       <h4 className="page-title" style={{marginBottom:0,marginTop:'1vh'}}>CERCA MANUTENTORE:</h4>
       <h4 className="page-subtitle" style={{fontWeight:'normal',marginTop:0}}>Aggiungi i tuoi manutentori o trovane di nuovi</h4>

       </div>
       <div style={{height:"10vh",width:'85vw',position:'absolute',left:'15vw',top:'13vh'}}>
        <div style={{display:'flex'}}>
           
        <SearchIcon style={{verticalAlign:'middle',marginTop:'auto',marginBottom:"auto",color:'#5C6672'}}/>
        <input type='text' onChange={(e)=>setSearch(e.target.value)} placeholder='Cerca...' style={{width:'30vw',
        fontFamily:'Poppins',marginTop:'auto',marginBottom:"auto",backgroundColor:'white',borderRadius:20,border:'1px solid lightgrey',padding:5,fontSize:'0.8vw',marginLeft:'0.5vw'}}/>
        <Filters setTerritorio={setTerritorio} setLingua={setLingua}/>

        </div>
        <div style={{marginTop:'1.5vh',display:'flex'}}>
          <Tooltip title='Competenze più cercate'>
            <EngineeringIcon style={{color:'#5C6672'}}/>
          </Tooltip>
          {capacità.length>0?capacità.map((cap,index)=>(
            <div key={index} onClick={()=>filterComp.indexOf(cap)!==-1?unselectComp(cap):selectComp(cap)} style={{cursor:'pointer',marginLeft:5,width:'fit-content',backgroundColor:filterComp.indexOf(cap)!==-1?'#0496ff':'white',
            borderRadius:20,padding:5,border:'1px solid #0496ff'}}>
            <h4 style={{fontSize:'0.7vw',color:filterComp.indexOf(cap)!==-1?'white':'#5C6672',fontWeight:filterComp.indexOf(cap)!==-1?'bold':'normal',marginTop:0,marginBottom:0}}>
              {cap.Nome}</h4>
            </div>
          )):null}
           
        </div>
</div>
       <div style={{height:"73vh",width:'43vw',position:'absolute',left:'15vw',top:'23vh',overflowY:'auto',}}>
       <h4 style={{fontSize:'1.5vw',color:'black',marginBottom:0,marginTop:0,fontFamily:'Poppins',}}>Manutentori:  ({manutentori.length})</h4>
      <div style={{paddingLeft:'1vw'}}>
        {manutentori.length>0?manutentori.map((man,index)=>(matchT(man)===true&&matchL(man)===true&&matchS(man)===true&&matchC(man)===true?
          <div key={index} onClick={()=>selectedUser===man?setSelectedUser(''):setSelectedUser(man)} style={{cursor:'pointer',width:'33vw',backgroundColor:'white',borderRadius:10,padding:5
          ,boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',marginTop:'1vh',display:'flex',border:selectedUser===man?'1px solid #0496ff':null}}>
           {man.Photo?<div style={{backgroundColor:'#0496ff',borderRadius:50,width:'2vw',height:'2vw'}}>
           <img src={man.Photo} alt='profile_photo' style={{width:'2vw',height:'2vw',borderRadius:'50%'}}></img>
            </div>:<div style={{backgroundColor:'#0496ff',borderRadius:50,width:'2vw',height:'2vw'}}>
            <h4 style={{fontSize:'1vw',color:'white',fontWeight:'bold',marginTop:7,marginBottom:'auto',marginLeft:'auto',marginRight:'auto',width:'fit-content',
            height:'fit-content'}}>{man.Nome_Azienda[0]}</h4>
            </div>}
          <div style={{marginLeft:'0.7vw',width:'8vw'}}>
          <h4 style={{fontSize:'0.6vw',color:'#5C6672',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>Nome Azienda</h4>
          <h4 style={{fontSize:'0.7vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>{man.Nome_Azienda}</h4>

          </div>
          <div style={{width:'11vw'}}>
          <h4 style={{fontSize:'0.6vw',color:'#5C6672',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>Email</h4>
          <h4 style={{fontSize:'0.65vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>{man.Email}</h4>

          </div>
          <div style={{width:'6vw'}}>
          <h4 style={{fontSize:'0.6vw',color:'#5C6672',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>Telefono</h4>
          <h4 style={{fontSize:'0.65vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>{man.Numero}</h4>

          </div>
          <div style={{width:'11vw'}}>
          <h4 style={{fontSize:'0.6vw',color:'#5C6672',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>Clienti</h4>
          <h4 style={{fontSize:'0.65vw',color:'black',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>{man.Current_Cli}</h4>

          </div>
              </div>:null
        )):<h4 style={{fontSize:'1vw',color:'#5C6672',fontWeight:'bold',marginTop:0,marginBottom:0,fontFamily:'Poppins',}}>Nessun manutentore trovato</h4>}
      </div>
      
       </div>
        </div>
    )
}

export default withRouter(SearchOff);