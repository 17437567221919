//step per info codice
export default function CodeStep(props){

    return(
        <div  style={{
            marginTop: "5vh",
            width: "50vw",
            marginLeft: "auto",
            marginRight: "auto",
          }}>
            <div
            style={{
              width: "fit-content",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
             <h4
                        className='titleReg'

            >
              CODICE ACCOUNT
            </h4>
            <div style={{display:'flex'}}>
               
            <div style={{width:'15vw'}}>
            <h4 className='headerInput' >
         Email operatore
          </h4>
          <h4 style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'black',fontSize:'0.8vw',fontFamily:'Montserrat',fontWeight:'bold'}}>{props.codeInfo.Operator}</h4>
            </div>
            <div style={{marginLeft:'1vw',width:'15vw'}}>
            <h4 className='headerInput' >
         Azienda operatore
          </h4>
          <h4 style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'black',fontSize:'0.8vw',fontFamily:'Montserrat',fontWeight:'bold'}}>{props.codeInfo.Azienda}</h4>
            </div>
            </div>
              
            <div style={{display:'flex'}}>
               
            <div style={{width:'15vw'}}>
            <h4 className='headerInput' >
         Piano Selezionato
          </h4>
          <h4 style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'black',fontSize:'0.8vw',fontFamily:'Montserrat',fontWeight:'bold'}}>
            {props.codeInfo.Tipo===0?'Base':props.codeInfo.Tipo===1?'PRO':'PRO XL'}
          </h4>
            </div>
         
            </div>
        
          </div>
          <div
              style={{
                width: "fit-content",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "3vh",
              }}
            >
              <button
className="nextButton"
                onClick={() => props.setSection(2)}
              >
                Avanti
              </button>
            </div>
            <div
              style={{
                width: "fit-content",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "1vh",
              }}
            >
              <button
className="backButton"
                onClick={() =>{ props.setSection(0);props.cConfirm(false)}}
              >
                Indietro
              </button>
            </div>
        </div>
    )
}