//barra superiore
import React from "react";
import { withRouter } from "react-router";
import '../Style/prelogin.css'
import logo from '../../images/mysic-lungo.png'
import { useHistory } from "react-router-dom";

const UpperBar = ({ history }) => {
  const navigate = useHistory();

  function handleClick() {
    navigate.push("/login");
  }
  function handleClick2() {
    navigate.push("/register");
  }
  return (
    <div
  
     style={{backgroundColor: 'white', width:'100vw', height:'6vh',top:0,display:'flex',borderBottom:'1px solid lightgrey',left:0,right:0}}
    >
        <img src={logo} alt='logo_mysic' style={{marginLeft:'1vw'}}></img>
        <div className="pre-content2" style={{width:'fit-content',display:'flex',marginLeft:'auto',marginRight:'0.5vw'}}>
            <div style={{borderRight:'1px solid lightgrey',}}>
        <button onClick={handleClick} style={{height:'6vh',border:'none',backgroundColor:'white',}}>
            <h4 className="pre-link" style={{fontFamily:'Montserrat',color:"#5C6672",cursor:'pointer',marginTop:'auto',marginBottom:'auto'}}>Accedi</h4></button>
            </div>
            <div style={{}}>
        <button onClick={handleClick2} style={{height:'6vh',border:'none',backgroundColor:'white',}}>
            <h4 className="pre-link" style={{fontFamily:'Montserrat',color:"#5C6672",cursor:'pointer',marginTop:'auto',marginBottom:'auto'}}>Registrati</h4></button>
            </div>

        </div>
    </div>
  );
};

export default UpperBar;