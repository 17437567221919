import React from 'react'
import Lottie from "lottie-react";
import loading from './images/loading.json'
import { Player } from '@lottiefiles/react-lottie-player';

function Loading(){


  return (
    <div style={{marginLeft:'auto',marginRight:'auto',marginTop:'auto',marginBottom:'auto'}}>
       <Player
  src={loading}
  className="player"
  loop
  autoplay
  speed={1}
  style={{width:'10rem',height:'10rem'}}
/>
    </div>
  )
}


export default Loading