import React , { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import firebase from "firebase/app";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getUtente,getIntervento } from "../../EngineClienti/engineClienti";
import Loading from '../../loading';
import Dettagli from '../DettagliIntervento/Dettagli';
import Cliente from '../DettagliIntervento/Cliente'
import Manutentore from '../DettagliIntervento/Manutentore';
import Allegati from '../DettagliIntervento/Allegati';
import Operazioni from '../DettagliIntervento/Operazioni';
import NavBar2 from '../../NavBar/NavBar2'
import LoadingScreen from '../../LoadingScreen';
const DettagliInterventoOff = ({history}) => {
    const auth = firebase.auth();
    const db = firebase.firestore();
    const {id} = useParams()

    const [user,setUser] = useState(null)
    const [intervento,setIntervento] = useState('')
    const [section,setSection] = useState(0)
    const [cliente,setCliente] = useState(null)
    const [manutentore,setManutentore] = useState(null)

    const [loading,setLoading] = useState(false)

    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
            getUtente(db,auth,setUser)
          }
        })
      },[auth])

      useEffect(()=>{
        if(user!==null){
         getIntervento(db,id,setIntervento)      
      }
    
      },[user])

      function getUtenteD(em,i){
        db.collection('Users').doc(em).get().then((us)=>{
            console.log(us.data())
            if(i===0){
                setCliente(us.data())

            }else{
                setManutentore(us.data())
            }
        })
      }

      function changeSection(i){
        if(i===1){
            if(cliente!==null){
                setSection(1)

            }else{
                getUtenteD(intervento.Cliente,0)
                setSection(1)
            }
            
        }
        if(i===2){
            if(manutentore!==null){
                setSection(2)

            }else{
                getUtenteD(intervento.Op_Email,1)
                setSection(2)
            }
            
        }


      }

      

  return (
    <div className="background" style={{width:'100vw',height:'100vh',overflow:'hidden'}}>
            {loading===true?<LoadingScreen text={'Generazione PDF in corso, attendere prego...'}/>:null}

      <NavBar2/>
         <div
        style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
            <div  style={{  width: "60vw",display:'flex',marginTop:'1.2rem' }}>
          <button style={{marginBottom:'auto',marginTop:'1rem'}} onClick={() => history.goBack()}
            className="cancelIconButton"
            
          >
            <ArrowBackIcon
             className="back-button"
            />
          </button>
          <div>
          <h4 className="page-title" style={{margin:0}}>{intervento?intervento.Titolo:null}</h4>
          <h4
          className="sub-text-b"
        >
          DETTAGLI INTERVENTO
        </h4>
          </div>

        </div>
        {intervento!==null?
        <div className="box-shadow" style={{display:'flex',width:'80vw',backgroundColor:'white',marginTop:'1.7rem',borderRadius:'15px',marginLeft:'3rem'}}>
        <div style={{alignItems:'center',height:'80vh',backgroundColor:'white',borderBottomLeftRadius:'15px',borderTopLeftRadius:'15px',width:'10rem',border:'1px solid #7d8da1'}}>
        <button onClick={()=>setSection(0)} style={{borderTopLeftRadius:'15px'}} className={section===0?"section-style-on":'section-style-off'}>Dettagli</button><br/>
        <button onClick={()=>changeSection(1)} className={section===1?"section-style-on":'section-style-off'}>Cliente</button><br/>
        <button onClick={()=>changeSection(2)} className={section===2?"section-style-on":'section-style-off'}>Manutentore</button>
        <button onClick={()=>setSection(3)} className={section===3?"section-style-on":'section-style-off'}>Allegati</button>
        {intervento.Stato!=='Conclusa'&&intervento.Stato!=='Scaduto'?
        <button onClick={()=>setSection(4)} className={section===4?"section-style-on":'section-style-off'}>{intervento.Stato==='In attesa'?'Pianifica':'Evadi'}</button>:null}

        </div>
       <div>
        {section===0?<Dettagli id={id}  user={user} intervento={intervento} setLoading={setLoading}/>:null}
        {section===1?<Cliente id={id} cliente={cliente} user={user} intervento={intervento}/>:null}
        {section===2?<Manutentore id={id} manutentore={manutentore} user={user} intervento={intervento}/>:null}
        {section===3?<Allegati  user={user} intervento={intervento} setIntervento={setIntervento}/>:null}
        {section===4?<Operazioni  user={user} intervento={intervento} setSection={setSection} setIntervento={setIntervento}/>:null}

        </div>
        </div>:<Loading/>}
      </div>

    </div>
  )
}

export default DettagliInterventoOff