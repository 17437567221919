//pagina per privacypolicy
import "../../Popups/Popup.css";
import "firebase/storage";
import CancelIcon from '@material-ui/icons/Cancel';

const PrivacyPolicy = (props)=>{



    return(props.trigger===true && <div className="pop-opac">
        <div className="popup" style={{width:"60vw",height:"70vh",flexDirection:"column",left:'20vw',top:'10vh'}}>
        <button
              className="cancelIconButton"
              style={{marginLeft:"0",marginRight:"auto"}}
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon  style={{width: '15px', height: '15px'}} className="cancelIcon"/>
            </button>
        <div style={{width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>
       
            <label style={{display:"flex",alignItems:"center",height:"100%",marginLeft:"2vw",fontFamily:'Rubik',fontWeight:'bold',fontSize:'2vw',color:'#0496ff'}}>PRIVACY POLICY</label>

        </div>
        <div style={{paddingLeft:'1vw',height:'50vh',overflowY:'auto'}}>
        <h4 style={{fontSize:'1.5rem', marginTop:0,marginBottom:0,fontWeight:'bold',fontFamily:'Rubik',color:'#5C6672'}}>1.1 LOREM IPSUM</h4>
    	<h4 style={{fontSize:'1rem', marginTop:0,marginBottom:0,fontWeight:'normal',fontFamily:'Rubik',width:'50vw'}}>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</h4>
        </div>
        <div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content'}}>
        <button className="blue-button2" onClick={()=>{props.setCondizioni(true); props.setTrigger(false)}}>ACCETTA</button>
        </div>
           

            
        </div>
    </div>)
}

export default PrivacyPolicy;