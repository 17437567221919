import { useMemo, useState, useEffect, useRef } from "react";
import plan from "../images/MappaSandrini.jpeg";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  OverlayView,
} from "@react-google-maps/api";
import MapComponent2 from "../Mappe/MapComponent2";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
const ProvaMappe = (props) => {
  const [index, setIndex] = useState(0); //numero click per il posizionamento
  const [firstBound, setFirstBound] = useState(null); //prima lat lng
  const [secondBound, setSecondBound] = useState(null); //seconda lat lng
  const [boundss, setBoundss] = useState(); //lat lng dei due punti click planimetria
  const [centerz, setCenterz] = useState({ lat: 0, lng: 0 }); //centro planimetria
  const [rotation, setRotation] = useState(0); //rotazione planimetria
  const [width, setWidth] = useState(100); //grandezza planimetria
  const [opacity, setOpacity] = useState(0.55); //opacità planimetria

  const refContainer = useRef();

  const [transelateSxPerc, setTranselateSxPerc] = useState(0);
  const [transelateTopPerc, setTranselateTopPerc] = useState(0);

  const [mapImageZoom, setMapImageZoom] = useState(100);

  const [width1, setWidth1] = useState(0); //x1
  const [width2, setWidth2] = useState(0); //x2
  const [s1, setS1] = useState(1); //valore barra x1
  const [s2, setS2] = useState(1); //valore barra x2
  const [s3, setS3] = useState(1); //valore barra y1
  const [s4, setS4] = useState(1); //valore barra y2

  const [height1, setHeight1] = useState(0); //y2
  const [height2, setHeight2] = useState(0); //y2

  const [heightImage, setImageHeight] = useState(0); //altezza immagine
  const [widthImage, setImageWidth] = useState(0); //larghezza immagine

  const ref = useRef();

  const handleWidth1 = (event, newValue) => {
    var c = (newValue * widthImage) / 100;
    setWidth1(c);
    setS1(newValue);
  }; //cambia x punto 1
  const handleWidth2 = (event, newValue) => {
    var c = (newValue * widthImage) / 100;
    setWidth2(c);
    setS2(newValue);
  }; //cambia x punto 2
  const handleHeight1 = (event, newValue) => {
    var c = (newValue * heightImage) / 100;

    setHeight1(c);
    setS3(newValue);
  }; //cambia y punto 1
  const handleHeight2 = (event, newValue) => {
    var c = (newValue * heightImage) / 100;

    setHeight2(c);
    setS4(newValue);
  }; //cambia y punto 2

  function onImgLoad({ target: img }) {
    console.log(img.offsetHeight, img.offsetWidth);
    setImageHeight(img.offsetHeight);
    setImageWidth(img.offsetWidth);
  }

  const { isLoaded } = useLoadScript({
    googleMapsApiKey:
      "AIzaSyBy4500tb8vImwBqAyfom60eOXjoW1fQfI&libraries=places&libraries=geometry",
  }); //chiave api per la mappa

  function Map() {
    const center = useMemo(
      () => ({ lat: 45.902124273585734, lng: 13.519987882519766 }),
      []
    );

    function handleMapReady(mapProps, map) {
      const { google } = mapProps;
      const mapz = new google.maps();
      mapz.events.addListener(map, function (e) {
        map.setOptions({ draggableCursor: "default" });
      });
    }
    return !isLoaded ? (
      <h4>caricamento</h4>
    ) : (
      <div
        style={{
          position: "relative",
          zIndex: 5,
          maxWidth: "100%",
          marginLeft: "-2.5rem",
        }}
      >
        <GoogleMap
          onClick={handleClick}
          zoom={20}
          center={center}
          cli
          style={{ width: "100%" }}
          mapContainerStyle={{ width: "100%" }}
          mapContainerClassName="map-container"
          onReady={handleMapReady}
          options={{ minZoom: 10, maxZoom: 25 }}
        >
          {index === 2 && boundss && (
            <OverlayView
              mapPaneName="overlayLayer"
              ref={refContainer}
              id="supercontainer"
              position={new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(firstBound.lat, firstBound.lng),
                new window.google.maps.LatLng(secondBound.lat, secondBound.lng)
              ).getCenter()}
              bounds={boundss}
              style={{
                border: "1px solid black",
              }}
            >
              <div
                style={{
                  border: "1px solid red",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                }}
              >
                <div
                  style={{
                    border: "1px solid black",
                    position: "relative",
                    left: "50%",
                    top: "50%",
                  }}
                >
                  {index >= 2 ? (
                    <img
                      src={plan}
                      style={{
                        width: "100%",
                        opacity: opacity,
                        transformOrigin:
                          transelateSxPerc + "% " + transelateTopPerc + "%",
                        transform:
                          "translate(-" +
                          transelateSxPerc +
                          "%, -" +
                          transelateTopPerc +
                          "%) rotate(" +
                          rotation +
                          "deg) scale(" +
                          mapImageZoom +
                          ")",
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </OverlayView>
          )}

          {index >= 1 && firstBound && (
            <Marker
              position={
                new window.google.maps.LatLng(firstBound.lat, firstBound.lng)
              }
            />
          )}
          {index >= 2 && secondBound && (
            <Marker
              position={
                new window.google.maps.LatLng(secondBound.lat, secondBound.lng)
              }
            />
          )}
        </GoogleMap>
      </div>
    );
  }

  function handleClick(e) {
    console.log("u:", e);
    if (index === 0) {
      setFirstBound({ lat: e.latLng.lat(), lng: e.latLng.lng() });
      console.log(e.latLng.lat());
      setIndex(index + 1);
    } else if (index === 1) {
      setSecondBound({ lat: e.latLng.lat(), lng: e.latLng.lng() });
      setIndex(index + 1);
      setBoundss(
        new window.google.maps.LatLngBounds(
          new window.google.maps.LatLng(firstBound.lat, firstBound.lng),
          new window.google.maps.LatLng(e.latLng.lat(), e.latLng.lng())
        )
      );
      console.log(
        firstBound.lat,
        firstBound.lng,
        e.latLng.lat(),
        e.latLng.lng(),
        (firstBound.lat - e.latLng.lat()) * 1852
      );
      setCenterz(
        new window.google.maps.LatLngBounds(
          new window.google.maps.LatLng(firstBound.lat, firstBound.lng),
          new window.google.maps.LatLng(e.latLng.lat(), e.latLng.lng())
        ).getCenter()
      );
      console.log(" first bound " + firstBound, " second bound " + secondBound);
    } else {
      console.log(
        " first bound " + firstBound.lat,
        firstBound.lng,
        " second bound " + secondBound.lat,
        secondBound.lng
      );

      console.log(
        "wd1 " + width1,
        "wd2  " + width2,
        "wt " + widthImage,
        "h1 " + height1,
        "h2 " + height2,
        "ht " + heightImage
      );

      setRotation(calculateDiffGradi());
      console.log("Diff Gradi: " + calculateDiffGradi());

      setMapImageZoom(calculateZoomFoto());
      console.log("Zoom: " + calculateZoomFoto());

      setTranselateSxPerc(calculateTranselateSxPerc());
      console.log("TranselateSx: " + calculateTranselateSxPerc());

      setTranselateTopPerc(calculateTranselateTopPerc());
      console.log("TransleateTop: " + calculateTranselateTopPerc());
    }
  } //gestisce i click sulla mappa

  function calculateAngoloMappa() {
    //calcolo angolo mappa

    var d_ab_gps_y_m = (secondBound.lat - firstBound.lat) * 111121;
    var h_m = 6637000 * (1 - Math.sin((firstBound.lat * Math.PI) / 180));
    var r_m = Math.sqrt(2 * h_m * 6637000 - h_m * h_m);
    var a_l_m = 2 * Math.PI * r_m;
    var d_ab_gps_x_m = ((secondBound.lng - firstBound.lng) / 360) * a_l_m;
    var alpha = Math.atan2(d_ab_gps_y_m, d_ab_gps_x_m);

    var ang = alpha * (180 / Math.PI);
    return ang;
  }

  function calculateAngoloFoto() {
    var c = Math.atan2(height2 - height1, width2 - width1);
    var ang = (c * (-180 / Math.PI)) % 360;
    return ang;
  }

  function calculateDiffGradi() {
    var diffGradi = calculateAngoloFoto() - calculateAngoloMappa();
    return diffGradi;
  }

  function calculateZoomFoto() {
    var d_ab_gps_y_m = (secondBound.lat - firstBound.lat) * 111121;
    var h_m = 6637000 * (1 - Math.sin((firstBound.lat * Math.PI) / 180));
    var r_m = Math.sqrt(2 * h_m * 6637000 - h_m * h_m);
    var a_l_m = 2 * Math.PI * r_m;
    var d_ab_gps_x_m = ((secondBound.lng - firstBound.lng) / 360) * a_l_m;

    var diagReal = Math.sqrt(
      (width2 - width1) * (width2 - width1) +
        (height2 - height1) * (height2 - height1)
    );

    var heightBounds = widthImage * (d_ab_gps_y_m / d_ab_gps_x_m);

    //console.log("Height Bounds: " + heightBounds);

    var diagPointers = Math.sqrt(
      heightBounds * heightBounds + widthImage * widthImage
    );

    var zoom = diagPointers / diagReal;
    return zoom;
  }

  function calculateTranselateSxPerc() {
    var TransSx = ((width2 + width1) * 100) / (2.0 * widthImage);
    return TransSx;
  }

  function calculateTranselateTopPerc() {
    var TransTop = ((height2 + height1) * 100) / (2.0 * heightImage);
    return TransTop;
  }

  return (
    <div className="background">
      <div style={{ height: "100vh", width: "85vw", marginLeft: "15vw" }}>
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ width: "50%", backgroundColor: "blue" }}>
            <Map />
          </div>
          <div style={{ width: "50%" }}>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1, borderBottom: "1px solid lightgray", padding: 2 }}
              alignItems="center"
            >
              <label className="subtitle2">X1</label>

              <RemoveIcon style={{ marginLeft: "auto" }} />
              <Slider
                min={0}
                max={100}
                step={1}
                aria-label="Volume"
                defaultValue={1}
                style={{ width: "12vw" }}
                value={s1}
                onChange={handleWidth1}
              />
              <AddIcon />
            </Stack>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1, borderBottom: "1px solid lightgray", padding: 2 }}
              alignItems="center"
            >
              <label className="subtitle2">X2</label>

              <RemoveIcon style={{ marginLeft: "auto" }} />
              <Slider
                min={0}
                max={100}
                step={1}
                aria-label="Volume"
                defaultValue={1}
                style={{ width: "12vw" }}
                value={s2}
                onChange={handleWidth2}
              />
              <AddIcon />
            </Stack>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1, borderBottom: "1px solid lightgray", padding: 2 }}
              alignItems="center"
            >
              <label className="subtitle2">Y1</label>

              <RemoveIcon style={{ marginLeft: "auto" }} />
              <Slider
                min={0}
                max={100}
                step={1}
                aria-label="Volume"
                defaultValue={1}
                style={{ width: "12vw" }}
                value={s3}
                onChange={handleHeight1}
              />
              <AddIcon />
            </Stack>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1, borderBottom: "1px solid lightgray", padding: 2 }}
              alignItems="center"
            >
              <label className="subtitle2">Y2</label>

              <RemoveIcon style={{ marginLeft: "auto" }} />
              <Slider
                min={0}
                max={100}
                step={1}
                aria-label="Volume"
                defaultValue={1}
                style={{ width: "12vw" }}
                value={s4}
                onChange={handleHeight2}
              />
              <AddIcon />
            </Stack>
            <div style={{ width: "35rem", position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "blue",
                  top: height1 + "px",
                  left: width1 + "px",
                  width: "1rem",
                  height: "1rem",
                  borderRadius: 50,
                }}
              ></div>
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "red",
                  top: height2 + "px",
                  left: width2 + "px",
                  width: "1rem",
                  height: "1rem",
                  borderRadius: 50,
                }}
              ></div>

              <img
                src={plan}
                onLoad={onImgLoad}
                alt="plan"
                style={{ width: "100%" }}
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProvaMappe;
