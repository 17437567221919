//info personali
import '../Style/signup.css'

export function StepOne(props){
    return(
        <div
        style={{
          marginTop: "3vh",
          width: "60vw",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div
          style={{
            width: "fit-content",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <h4
            className='titleReg'
            style={{color:props.nome!==''&&props.cognome!==''&&props.telefono!==''?'#0496ff':'black'}}
          >
            Informazioni personali
          </h4>
          <div style={{display:'flex',alignItems:'center'}}>
          <div>
          <h4 className='headerInput' >
            Nome*
          </h4>
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "fit-content",
            }}
          >
            <input
              onChange={(e) => props.setNome(e.target.value)}
              value={props.nome}
              type="text"
              placeholder="Inserisci..."
              className='inputReg'

            />
          </div>
          </div>
          <div style={{marginLeft:'1vw'}}>
          <h4
                     className='headerInput'

          >
            Cognome*
          </h4>
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "fit-content",
            }}
          >
            <input
                          className='inputReg'

              type="text"
              value={props.cognome}
              onChange={(e) => props.setCognome(e.target.value)}
              placeholder="Inserisci..."
            />
          </div>
          </div>
          </div>

          <h4
          className='headerInput'
           
          >
            Recapito telefonico*
          </h4>
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "fit-content",
            }}
          >
            <input
              type="number"
              value={props.telefono}
              className='inputReg'
              onChange={(e) => props.setTelefono(e.target.value)}
           
              placeholder="Inserisci..."
            ></input>
          </div>
          <h4
            className='titleReg'
            style={{marginTop:'2vh',color:props.nomeAz!==''&&props.luogo!==''&&props.indirizzo!==''?'#0496ff':'black'}}
          >
            Informazioni aziendali
          </h4>
          <div style={{display:'flex',alignItems:'center'}}>
          <div>
          <h4 className='headerInput' >
          Ragione Sociale*
          </h4>
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "fit-content",
            }}
          >
           <input
                onChange={(e) => props.setNomeAz(e.target.value)}
                type="text"
                className='inputReg'

                placeholder="Inserisci..."
                value={props.nomeAz}
              />
          </div>
          </div>
          <div style={{marginLeft:'1vw'}}>
          <h4
                     className='headerInput'

          >
            Comune*
          </h4>
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "fit-content",
            }}
          >
            <input
                         className='inputReg'

                type="text"
                onChange={(e) => props.setLuogo(e.target.value)}
                value={props.luogo}
                placeholder="Inserisci..."
              />
          </div>
          </div>
          </div>

          <h4
          className='headerInput'
           
          >
            Indirizzo*
          </h4>
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "fit-content",
            }}
          >
           <input
                type="text"
                value={props.indirizzo}
                onChange={(e) => props.setIndirizzo(e.target.value)}
                className='inputReg'

                placeholder="Inserisci..."
              ></input>
          </div>
          <div
            style={{
              width: "fit-content",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "3vh",
            }}
          >
            {props.nome!==''&&props.cognome!==''&&props.indirizzo!==''&&props.nomeAz!==''&&props.indirizzo!==''&&props.luogo!==''?<button
            className="nextButton"
              onClick={() => {props.cConfirm===true?props.setSection(4):props.setSection(3)}}
            >
              Avanti
            </button>:null}
            <div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content',marginTop:'2vh'}}>
          <button
            className="backButton"
            style={{}}
              onClick={() => props.setSection(1)}
            >
              Indietro
            </button>
            </div>
          </div>
        </div>
      </div>
    )
}