//popup aggiungi categoria
import React, { useState, useEffect } from "react";
import "../../Popups/Popup.css";
import firebase from "firebase/app";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getUtente} from "../../EngineClienti/engineClienti";
import { getClientiSediSottocategorie } from "../../EngineOffritore/engineOffritore";
import e from "cors";
import NavBar2 from "../../NavBar/NavBar2";
const AggiungiSottCategoriaOff = ({history}) => {
  const auth = firebase.auth();
  const db = firebase.firestore();
  const [selectedOption, setOption] = useState("default"); //categoria selezionata
  const [user,setUser] = useState(null)
  const [custom, setCustom] = useState(''); //nome sottocategoria da aggiungere


  const [clienti,setClienti]= useState([]) //lista clienti
  const [categorie, setCategorie] = useState([]); //lista categorie
  const [sottocategorie,setSottocategorie] = useState([]) //lista sottocategorie
  const [allCategorie,setAllCategorie] = useState([]) //lista tutte categorie anche doppiate
  const [clienteF,setClienteF] = useState('default')

  const arrAnti = ["Estintore polvere", "Estintore CO2", "Estintore schiuma", "Estintore acqua", "Porta-Portone REI", "Dispositivo antipanico", "Gruppo di pressurizzazione", "Attacco Mandata VVF", "Rivelatore Antincendio", "Aspiratore", "Idrante a muro", "Idrante a colonna", "Segnalazione ottico-acustica", "Pulsante"]
  const arrEle = ["Lampada emergenza", "Quadro elettrico", "Interruttore differenziale"]
  useEffect(()=>{
    auth.onAuthStateChanged(function (user) {
      if (user) {
        getUtente(db,auth,setUser)
      }
    })
  },[auth])

  useEffect(()=>{
    if(user!==null){
     getClientiSediSottocategorie(db,user.Email,setClienti,setCategorie,setSottocategorie,arrAnti,arrEle,setAllCategorie)      
  }

  },[user])


  function returnCategorie(){
    var arr = categorie.filter((e)=>e.Cliente===clienteF)

    return arr
  }

  function returnSottoCategorie(categoria){
    var arr = sottocategorie.filter((e)=>e.Categoria===categoria&&e.Cliente===clienteF)

    return arr
  }

  function addSottoCatz() {
    
    var control = false;
    var name;

    if(clienteF!=='default'&&selectedOption!=='default'&&custom!==''){
    db.collection('Users').doc(clienteF).get().then((cli)=>{
   
    if(window.confirm("Vuoi aggiungere la sottocategoria "+custom+" alla categoria "+selectedOption+" di "+ cli.data().Nome_Azienda+"?")){
    sottocategorie.forEach((cat) => { //controlla che la sottocategoria non esita già
      if (cat.Id.trim().toLowerCase() === custom.trim().toLowerCase()&&cat.Cliente===clienteF&&cat.Categoria===selectedOption) {
        control = true;
      }
    });

    const indexA = arrAnti.findIndex((e)=>e.trim().toLowerCase()===custom.trim().toLowerCase()) //controlla se si inseriscono sottocategorie già esistenti
    const indexE = arrEle.findIndex((e)=>e.trim().toLowerCase()===custom.trim().toLowerCase())

    console.log(indexA,indexE)

    if(indexA!==-1||indexE!==-1){ //se già esistente return
      alert('Sottocategoria già inserita!')
      return
    }

    const arrCat = allCategorie.filter((e)=>e.Nome===selectedOption&&e.Cliente===clienteF) //serve per prendere path delle sottocategorie da aggiungere

    	
    if (control === false) {
      var i = 0
      arrCat.forEach((categ)=>{
        db.collection('Users').doc(clienteF).collection('Sedi')
        .doc(categ.Sede).collection('Categorie').doc(categ.Id)
        .collection('Sottocategorie').doc(custom.trim()).set({ //aggiunge sottocat
          Nome: custom.trim(),
          Sede: categ.Sede,
          Valore:0
        })
        i++
        if(i===arrCat.length){
          console.log('done',categ)
          
          var arr = []
       var c1 = JSON.parse(sessionStorage.getItem('sottocategorie'));
        if(c1!==null&&c1!==undefined){
          if(c1.length>0){
            arr = c1
          }
        }
        
        if(arr.findIndex((e)=>e.Nome===custom)===-1){
          arr.push({Nome: custom,Categoria: selectedOption, Cliente: clienteF})
            sessionStorage.setItem('sottocategorie', JSON.stringify(arr));
        }

        //aggiunge sottocategoria alle sottocategorie della pagina
        var arr1 = [...sottocategorie]
        arr1.push({Nome: custom,Categoria: selectedOption, Id: custom,Cliente: clienteF, Valore: 0,})
          setSottocategorie(arr1)

          db.collection('Users').doc(clienteF).collection('SottocategorieAggiuntive').doc(custom.trim()).set({
            Nome: custom.trim(),
            Categoria: selectedOption,
          })

          alert("Sottocategoria aggiunta con successo");
        }
      })
    } else {
      alert("Esiste già una categoria con questo nome.");
    }
  }
})
}else{
  alert('Inserire tutti i campi richiesti!')
}
  } //funzione che aggiunge la sottocategoria

  return  (
    <div  className="background" style={{width:'100vw',height:'100vh',overflow:'hidden'}}>
      <NavBar2/>
      <div
        style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}
      >
        <div  style={{  width: "50vw",display:'flex',marginTop:'1.2rem' }}>
          <button style={{marginBottom:'auto',marginTop:'1rem'}} onClick={() => history.goBack()}
            className="cancelIconButton"
            
          >
            <ArrowBackIcon
             className="back-button"
            />
          </button>
          <div >
          <h4 className="page-title" style={{margin:0}}>AGGIUNGI SOTTOCATEGORIA</h4>
          <h4
          className="sub-text"
        >
          Aggiungi sottocategorie aggiuntive ai tuoi clienti
        </h4>
          </div>

        </div>
        <div style={{display:'flex',marginLeft:'3.5rem',marginTop:'1rem'}}>
           {clienteF!=='default'? <div style={{width:'20rem',height:'82vh',overflowY:'auto',borderRight:'1px solid #677483'}}>
                <h4 className="normal-text">Sottocategorie aggiunte:</h4>
            {categorie.length>0?categorie.map((s,n)=>(
              s.Cliente===clienteF?
                <div key={n} style={{marginTop:'1rem'}}>
                    <h4 className="sub-text-b">{s.Nome}</h4>
                    
                    {returnSottoCategorie(s.Id).length>0?returnSottoCategorie(s.Id).map((el,i)=>(
                        <div key={i} style={{}}>
                            <h4 className="medium-text">• {el.Nome}</h4>                         
                        </div>
                    )):<h4 className="medium-text">Nessuna sottocategoria aggiuntiva aggiunta</h4>}
                    {s.Nome==='Antincendio'?arrAnti.map((p,k)=>(
                       <div key={k} style={{}}>
                       <h4 className="medium-text">• {p}</h4>                         
                   </div>
                    )):s.Nome==='Elettrico'?arrEle.map((p,k)=>(
                      <div key={k} style={{}}>
                      <h4 className="medium-text">• {p}</h4>                         
                  </div>
                   )):null}
                </div>:null
            )):<h4 className="medium-text">Nessuna categoria aggiunta</h4>}
            </div>:<div style={{width:'20rem',height:'82vh',overflowY:'auto',borderRight:'1px solid #677483'}}>
                <h4 className="normal-text">Sottocategorie aggiunte:</h4>
                <h4 className="medium-text">Seleziona un cliente per visualizzare le<br/>sottocategorie già aggiunte.</h4>
            </div>}
            <div style={{marginLeft:'1rem'}}>
            <h4 className="normal-text">Aggiungi sottocategorie: </h4>
            <h4 className="sub-text-b" style={{marginTop:'1rem'}}>Seleziona il cliente a cui aggiungere la sottocategoria:</h4>
        <select className="input-style"
        defaultValue={"default"}
        value={clienteF} onChange={(e) => {
            setClienteF(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona cliente...
                </option>
                {clienti && clienti.length > 0 && clienti.map((cliente,n)=>{
                                  return <option key={n} value={cliente.Email}>{cliente.Nome_Azienda}</option>
                              })}
        </select>
       {clienteF!=='default'? <div><h4 className="sub-text-b" style={{marginTop:'1rem'}}>Seleziona categoria a cui aggiungere la sottocategoria:</h4>
        <div style={{display:'flex',alignItems:'center'}}>
        <select className="input-style"
        defaultValue={"default"}
        value={selectedOption}
        onChange={(e) => {
                      setOption(e.target.value)
                    }}>
                       <option value={"default"} disabled>
                  Seleziona categoria...
                </option>
          {returnCategorie().length>0?returnCategorie().map((cat,i)=>(
            <option key={i} value={cat.Id}>
                  {cat.Nome}
                </option>
          )):<h4 className="medium-text">Nessuna sottocategoria aggiuntiva aggiunta</h4>}
        </select>
        </div></div>:null}
        {selectedOption!=='default'? <div><h4 className="sub-text-b" style={{marginTop:'1rem'}}>Inserisci il nome della sottocategoria da aggiungere:</h4>
        <input className="input-style2" style={{height:'1.6rem',marginTop:'10px',marginBottom:'auto',width:'20vw'}} onChange={(e)=>setCustom(e.target.value)} placeholder="Inserisci nome sottocategoria..."></input>
        </div>:null}
        <br/>
        <button onClick={()=>addSottoCatz()} className="main-button" style={{marginTop:'2rem'}}>Aggiungi</button>
        </div>
            </div>
      
 
      </div>
    </div>
  );
};

export default AggiungiSottCategoriaOff;
