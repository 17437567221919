//pagina iniziale di quando si accede al sito
import React from "react";
import { withRouter } from "react-router";
import UpperBar from "./Components/UpperBar";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import logoMan from ".././images/home-mysic.png"
import logoCli from ".././images/home_cli.jpg"
import logo from '.././images/logo.png'
import opt from '.././images/optimized.png'
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import AssignmentLateRoundedIcon from '@mui/icons-material/AssignmentLateRounded';
import BuildIcon from '@mui/icons-material/Build';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GridLayout from "./Components/GridLayout";
import './Style/prelogin.css'

import Footer from "./Components/Footer";
const PreLoginPage = ({ history }) => {
  const navigate = useHistory();

  function handleClick() {
    navigate.push("/register");
  } //manda alla pagina registrati

  return (
 <div
  className='pre-all-cont'
  style={{backgroundColor: '#d6eeff38', width:'100vw',overflowX:'hidden',height:'100vh'}}
 >
 <UpperBar/>
 <div style={{height:'80vh'}} className="pre-container2">
    <div className="pre-content2"></div>


    <div style={{}} className="pre-content">

 <h4 className="pre-text-big" style={{fontFamily:'Poppins',color:'black',fontWeight:'500',marginBottom:0,marginTop:0}}>Sicurezza</h4>
 <h4 className="pre-text-big" style={{fontFamily:'Poppins',color:'black',fontWeight:'500',marginTop:'-1.5vh',marginBottom:0}}>in Cloud</h4>
 <h4 className="pre-text-big" style={{fontFamily:'Poppins',fcolor:'black',fontWeight:'200',marginTop:'-1.5vh',marginBottom:0}}>365 giorni</h4>
 <div className="pre-content2" style={{display:'flex',alignItems:'center'}}>
 <button
     style={{
       boxShadow: "rgba(0, 0, 0, 0.35) 1.95px 1.95px 1.6px",
       marginRight: 20,
       backgroundColor: "#0496ff",
       color: "white",
       fontWeight: "bold",
       fontFamily: "Montserrat",
       fontSize: "0.7vw",
       border: "2px solid #0496ff",
       borderRadius: 50,
       width: "10vw",
       cursor: "pointer",
       height: "6vh",
       display: "flex",
       marginTop:"2.5vh",
       
     }}
     onClick={handleClick}
   >
     <h4 style={{marginTop:'auto',marginBottom:'auto',fontSize:'0.8vw',fontFamily:'Poppins',fontWeight:'500',marginLeft:'auto',marginRight:'auto'}}>Crea account Mysic</h4>
   </button>

     <button onClick={(e)=> {e.preventDefault();window.location.href='#more';}} style={{cursor:'pointer',display:'flex',alignItems:'center',width:'fit-content', height: "6vh",backgroundColor:'transparent',border:'none',marginTop:"2.5vh"}}>
       <ArrowCircleDownIcon style={{color:'black'}}/>
       <h4 style={{marginTop:'auto',marginBottom:'auto',fontSize:'0.8vw',fontFamily:'Poppins',fontWeight:'400',color:'black'}}>Scopri di più</h4>

     </button>
 </div>

 </div>
 <div className="pre-content2">
 </div>
 <div className='pre-content2'>
  <div style={{display:'flex'}}>
     <div>
     <div style={{boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',width:'21vw',height:'fit-content',border:'none'
 ,borderRadius:50,backgroundColor:'white',padding:5,display:'flex'}}>
   <img src={opt} style={{width:'1vw',marginLeft:'0.5vw'}} alt='optimized'></img>
         <h4 style={{marginTop:0,fontFamily:'Poppins',fontWeight:'400',color:'gray',marginBottom:0,height:'fit-content',
         fontSize:'0.75vw',marginLeft:'0.5vw',marginRight:'auto',width:'fit-content'}}>Account ottimizzati per manutentori e clienti</h4>

     </div>
     <img style={{width:'24vw',right:'25vw',borderRadius:20,marginTop:10}} alt='off' src={logoCli}></img>
     <div style={{marginTop:10,marginLeft:'auto',boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',width:'fit-content',height:'fit-content',border:'none',borderRadius:20,backgroundColor:'white',padding:5,display:'flex'}}>
         <img src={logo} style={{width:'2vw',marginLeft:'0.5vw'}} alt='logo_mysic'></img>

       <h4 style={{marginLeft:'0.5vw',marginTop:'auto',fontFamily:'Poppins',fontWeight:'400',color:'gray',marginBottom:'auto',fontSize:'0.75vw'}}>Un account, la totale gestione della tua sicurezza.</h4>
 </div>
 <div style={{width:'fit-content',height:'fit-content',border:'none'
 ,borderRadius:20,padding:5,display:'flex',marginTop:10,marginLeft:'auto'}}>
   <Tooltip title='Dashboard'>
 <IconButton onClick={(e)=> {e.preventDefault();window.location.href='#more1';}} style={{width:'2vw',height:'2vw',borderRadius:50,backgroundColor:'#0496ff'}}>
 <CalendarMonthIcon style={{color:'white'}}/>
 </IconButton>
 </Tooltip>
 <Tooltip title='Documenti'>
 <IconButton onClick={(e)=> {e.preventDefault();window.location.href='#more1';}} style={{marginLeft:'1vw',width:'2vw',height:'2vw',borderRadius:50,backgroundColor:'#0496ff'}}>
 <ArticleRoundedIcon style={{color:'white'}}/>
 </IconButton>
 </Tooltip>
 <Tooltip title='Elementi'>
 <IconButton onClick={(e)=> {e.preventDefault();window.location.href='#more2';}} style={{marginLeft:'1vw',width:'2vw',height:'2vw',borderRadius:50,backgroundColor:'#0496ff'}}>
 <SecurityRoundedIcon style={{color:'white'}}/>
 </IconButton>
 </Tooltip>
 <Tooltip title='Interventi'>
 <IconButton onClick={(e)=> {e.preventDefault();window.location.href='#more2';}} style={{marginLeft:'1vw',width:'2vw',height:'2vw',borderRadius:50,backgroundColor:'#0496ff'}}>
 <AssignmentLateRoundedIcon style={{color:'white'}}/>
 </IconButton>
 </Tooltip>
 <Tooltip title='Manutenzioni'>
 <IconButton onClick={(e)=> {e.preventDefault();window.location.href='#more3';}} style={{marginLeft:'1vw',width:'2vw',height:'2vw',borderRadius:50,backgroundColor:'#0496ff'}}>
 <BuildIcon style={{color:'white'}}/>
 </IconButton>
 </Tooltip>
 <Tooltip title='Mappe'>
 <IconButton onClick={(e)=> {e.preventDefault();window.location.href='#more3';}} style={{marginLeft:'1vw',width:'2vw',height:'2vw',borderRadius:50,backgroundColor:'#0496ff'}}>
 <MapRoundedIcon style={{color:'white'}}/>
 </IconButton>
 </Tooltip>

 </div>
     </div>
     <div style={{marginLeft:10}}>
     <img style={{height:'50vh',borderRadius:20}} alt='off' src={logoMan}></img>

     </div>
  </div>
 </div>
 <div className="pre-content2"></div>
 </div>
 <GridLayout/>
 <Footer></Footer>
 </div>

   
  );
};

export default withRouter(PreLoginPage);
