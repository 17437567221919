//ultimo step con riassunto info inserite e checkout
export default function Riassunto(props){

    return(
        <div  style={{
            marginTop: "5vh",
            width: "50vw",
            marginLeft: "auto",
            marginRight: "auto",
          }}>
            <div
            style={{
              width: "fit-content",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
             <h4
                        className='titleReg'

            >
              RIASSUNTO ACCOUNT
            </h4>
            <div style={{display:'flex'}}>
               
            <div style={{width:'15vw'}}>
            <h4 className='headerInput' >
         Nome e Cognome
          </h4>
          <h4 style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'black',fontSize:'0.8vw',fontFamily:'Montserrat',fontWeight:'bold'}}>{props.nome} {props.cognome}</h4>
            </div>
            <div style={{marginLeft:'1vw',width:'15vw'}}>
            <h4 className='headerInput' >
         Recapito telefonico
          </h4>
          <h4 style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'black',fontSize:'0.8vw',fontFamily:'Montserrat',fontWeight:'bold'}}>{props.telefono}</h4>
            </div>
            </div>
            <div style={{display:'flex'}}>
               
            <div style={{width:'15vw'}}>
            <h4 className='headerInput' >
         Ragione Sociale
          </h4>
          <h4 style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'black',fontSize:'0.8vw',fontFamily:'Montserrat',fontWeight:'bold'}}>{props.nomeAz}</h4>
            </div>
            <div style={{marginLeft:'1vw',width:'15vw'}}>
            <h4 className='headerInput' >
         Indirizzo aziendale
          </h4>
          <h4 style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'black',fontSize:'0.8vw',fontFamily:'Montserrat',fontWeight:'bold'}}>{props.indirizzo} - {props.luogo}</h4>
            </div>
            </div>
            <div style={{display:'flex'}}>
               
            <div style={{width:'15vw'}}>
            <h4 className='headerInput' >
         Piano Selezionato
          </h4>
          <h4 style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'black',fontSize:'0.8vw',fontFamily:'Montserrat',fontWeight:'bold'}}>
            {props.plan===0?'Base':props.plan===1?'PRO':'PRO XL'}
          </h4>
            </div>
            <div style={{marginLeft:'1vw',width:'15vw'}}>
            <h4 className='headerInput' >
         Tipo di Account
          </h4>
          <h4 style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'black',fontSize:'0.8vw',fontFamily:'Montserrat',fontWeight:'bold'}}>
            {props.optionAz==='Cliente'?'Cliente':'Manutentore'}</h4>
            </div>
            </div>
            <div style={{display:'flex'}}>
               
            <div style={{width:'15vw'}}>
            <h4 className='headerInput' >
         Email Accesso
          </h4>
          <h4 style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'black',fontSize:'0.8vw',fontFamily:'Montserrat',fontWeight:'bold'}}>
            {props.email}
          </h4>
            </div>
            <div style={{marginLeft:'1vw',width:'15vw'}}>
            <h4 className='headerInput' >
         Password Accesso
          </h4>
          <h4 style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'black',fontSize:'0.8vw',fontFamily:'Montserrat',fontWeight:'bold'}}>
            Termina con {props.password[props.password.length-3]}{props.password[props.password.length-2]}{props.password[props.password.length-1]}</h4>
            </div>
            </div>
          </div>
          <div
              style={{
                width: "fit-content",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "3vh",
              }}
            >
             { props.cConfirm===true?<button
className="nextButton"
                onClick={() => props.handleSignUp()}
              >
             Registrati
              </button>:<button
className="nextButton"
                onClick={() => props.handleSignUp()}
              >
                {props.optionAz==='Cliente'?props.plan===0?'Registrati':'Checkout':'Checkout'}
              </button>}
            </div>
            <div
              style={{
                width: "fit-content",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "1vh",
              }}
            >
              <button
className="backButton"
                onClick={() => props.optionAz==='Cliente'?props.setSection(4):props.setSection(3)}
              >
                Indietro
              </button>
            </div>
        </div>
    )
}