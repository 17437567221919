
//lista pagine cliente
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import AssignmentLateRoundedIcon from '@mui/icons-material/AssignmentLateRounded';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import SettingsApplicationsRoundedIcon from '@mui/icons-material/SettingsApplicationsRounded';
import BuildIcon from '@mui/icons-material/Build';
import SearchIcon from '@mui/icons-material/Search';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import ConstructionIcon from '@mui/icons-material/Construction';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
export const MenuItem = [
 
  {
    title: (
      <div className='box-nav-bar' style={{borderTopLeftRadius:'15px',borderTopRightRadius:'15px'}}>
        <HomeRoundedIcon className='nav-bar-icon' />
        <p className='nav-bar-text' >Dashboard</p>
      </div>
    ),
    url: "/home",
    cName: "nav-links",
  },
  {
    title: (
      <div className='box-nav-bar'>
        <MapsHomeWorkIcon className='nav-bar-icon' />
        <p className='nav-bar-text' >Sedi</p>
      </div>
    ),
    url: "/sedi",
    cName: "nav-links",
  },
  {
    title: (
      <div className='box-nav-bar'>
        <ConstructionIcon className='nav-bar-icon' />
        <p className='nav-bar-text' >Categorie</p>
      </div>
    ),
    url: "/categorie",
    cName: "nav-links",
  },
  {title:(
    <div className='box-nav-bar'>
      <SecurityRoundedIcon className='nav-bar-icon'/>
      <p className='nav-bar-text'> Elementi</p>
    </div>
  ),
    url:"/elementi",
    cName:"nav-links"
  },
  {
    title: (
      <div className='box-nav-bar'>
        <ArticleRoundedIcon className='nav-bar-icon' />
        <p className='nav-bar-text'>Documenti</p>
      </div>
    ),
    url: "/documenti",
    cName: "nav-links",
  },
  {
    title: (
      <div className='box-nav-bar'>
        <AssignmentLateRoundedIcon className='nav-bar-icon'/>
        <p className='nav-bar-text'>Interventi</p>
      </div>
    ),
    url: "/interventi",
    cName: "nav-links",
  },{
    title: (
      <div className='box-nav-bar'>
        <ScheduleIcon className='nav-bar-icon'/>
        <p className='nav-bar-text'>Scadenze</p>
      </div>
    ),
    url:"/scadenze",
    cName:"nav-links"
  },
  {title:(
    <div className='box-nav-bar'>
      <MapRoundedIcon className='nav-bar-icon'/>
      <p className='nav-bar-text'>Mappe</p>
    </div>
  ),
    url:"/mappecliente",
    cName:"nav-links"
  },
  {
    title: (
      <div className='box-nav-bar'>
        <ConnectWithoutContactIcon className='nav-bar-icon' />
        <p className='nav-bar-text'>Manutentori</p>
      </div>
    ),
    url: "/manutentori",
    cName: "nav-links",
  },
  {
    title: (
      <div className='box-nav-bar'>
        <SettingsApplicationsRoundedIcon className='nav-bar-icon'/>
        <p className='nav-bar-text'>Impostazioni</p>
      </div>
    ),
    url:"/impostazioni",
    cName:"nav-links"
  }
  

];
