//popup aggiungi categoria
import React, { useState, useEffect } from "react";
import "../../Popups/Popup.css";
import firebase from "firebase/app";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getUtente,getSediCategorie, getElementi,getSedeName } from "../../EngineClienti/engineClienti";
import NavBar from '../../NavBar/NavBar'
import NavBar2 from '../../NavBar/NavBar2'
const AggiungiElemento = ({history}) => {

    const auth = firebase.auth();
    const db = firebase.firestore();

    const [user,setUser] = useState(null)
    const [categorie, setCategorie] = useState([]); //lista categorie
    const [sedi,setSedi] = useState([])

    const [selectedSede,setSelectedSede] = useState('')
    const [selectedCategoria,setSelectedCategoria] = useState('')
    const [selectedSottocategoria,setSelectedSottocategoria] = useState('')
    const [extraDet,setExtraDet] = useState(false)
    const [marcaE, setMarca] = useState(""); //var per marca
    const [inst, setInst] = useState(""); //var per data installazione
    const [number,setNumber] = useState(1)

    const [added,setAdded] = useState([]) //elementi aggiunti

    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
            getUtente(db,auth,setUser)
          }
        })
      },[auth])
    
      useEffect(()=>{
        if(user!==null){
         getSediCategorie(db,user.Email,setSedi,setCategorie)      
      }
    
      },[user])

      useEffect(()=>{
        if(sedi.length>0){
          setSelectedSede(sedi[0].Id)
        }
    
      },[sedi])

      useEffect(()=>{
        if(categorie.length>0){
          setSelectedCategoria(categorie[0].Id)
        }
    
      },[categorie])

      useEffect(()=>{
        if(selectedCategoria==='Antincendio'){
            setSelectedSottocategoria("Estintore polvere")
        }else if(selectedCategoria==='Elettrico'){
            setSelectedSottocategoria("Lampada emergenza")
            
        }else{
            setSelectedSottocategoria("")

        }
      },[selectedCategoria])

      useEffect(()=>{
       setSelectedCategoria('default')
      },[selectedSede])


      function addElement(){
        var startingIndex = 0 //n elemento da inserire
        var marca = '' //marca elementi da inserire
        var date = '' //data installazione elementi da inserire
        var array = [] //aggiunge gli elementi da inserire con il batch

        var batch = db.batch(); //batch è una funzione di quando si vuole inserire più documenti sul db allo stesso tempo
        const sedename = getSedeName(selectedSede,sedi)
        console.log(selectedCategoria,selectedSottocategoria)
        const tot = parseInt(user.Current_Ele, 10) + parseInt(number, 10);
            if (tot <= user.Max_Ele) { //controllo se si possono aggiungere nuovi elementi
                if(parseInt(number, 10)>=1){ //controllo numero negativo di elementi da aggiungere

        db.collection('Users').doc(user.Email).collection('Sedi').doc(selectedSede).collection('Categorie').doc(selectedCategoria)
        .collection('Sottocategorie').doc(selectedSottocategoria).collection('Elementi').get().then((elements)=>{
            if(elements.docs.length>0){ //imposta indice come il numero di elementi già aggiunti
                startingIndex = elements.docs.length
            }

            if(extraDet===true){ //se dettagli aggiuntivi imposta le variabili
                marca = marcaE
                date= inst
            }

            db.collection('Users').doc(user.Email).update({ //aggiorna valore ele utente
                Current_Ele: firebase.firestore.FieldValue.increment(parseInt(number,10))
            })

            db.collection('Users').doc(user.Email).collection('Sedi').doc(selectedSede).update({ //aggiorna valore ele sede
                Ele: firebase.firestore.FieldValue.increment(parseInt(number,10))
            })

            db.collection('Users').doc(user.Email).collection('Sedi').doc(selectedSede).collection('Categorie').doc(selectedCategoria).update({ //aggiorna valore ele categoria
                Ele: firebase.firestore.FieldValue.increment(parseInt(number,10))
            })

            if(startingIndex===0){ //crea sottocategoria
                db.collection('Users').doc(user.Email).collection('Sedi').doc(selectedSede).collection('Categorie').doc(selectedCategoria)
        .collection('Sottocategorie').doc(selectedSottocategoria).set({
            Nome: selectedSottocategoria,
            Sede: selectedSede,
            Valore: number
        })
            }else{ //aggiorna sott
                db.collection('Users').doc(user.Email).collection('Sedi').doc(selectedSede).collection('Categorie').doc(selectedCategoria)
        .collection('Sottocategorie').doc(selectedSottocategoria).update({

            Valore: firebase.firestore.FieldValue.increment(parseInt(number,10))
        })
            }


            for (var q = 0; q < number; q++) {  //riempie l'array nel numero totale degli elementi selezionati

              console.log()
                const nome_ele =
                  selectedSottocategoria[0].toUpperCase() +
                  selectedSottocategoria[1].toUpperCase() +
                  selectedSottocategoria[2].toUpperCase() +
                  startingIndex;

                array.push({
                  ID: nome_ele,
                  Descrizione: "",
                  Sede: selectedSede,
                  SedeName: sedename,
                  Code: "",
                  Posizione: "",
                  note: "",
                  RFID: "",
                  BARCODE: "",
                  Categoria: selectedCategoria,
                  Marca: marca,
                  Macrocategoria: selectedSottocategoria,
                  Data_Inst: date,
                  Data_Scad: "",
                  Nome: nome_ele,
                  Valore: 0,
                  Cliente: user.Email
                });
                setAdded((prev) => [...prev, 
                    {
                        Nome: nome_ele,
                        Categoria: selectedCategoria,
                        Sottocategoria: selectedSottocategoria,
                        Marca: marca,
                        Inst: date,
                    }    
                ]);
               
                startingIndex++;
              }

              array.forEach((doc) => {
                const docRef = db.collection("Elementi").doc();

                var arr = []
                var arr2 = []
                var c1 = JSON.parse(sessionStorage.getItem('elementi'));
                var c2 = JSON.parse(sessionStorage.getItem('sottocategorie'))
                if(c1){
                  if(c1.length>0){
                    arr = c1
                  }
                }
                if(c2){
                  if(c2.length>0){
                    arr2 = c2
                  }
                }

                  //aggiunta elementi a session storage
                  arr.push({ Id: docRef.id,
                    ID: docRef.id,
                    Descrizione: "",
                    Sede: selectedSede,
                    SedeName: sedename,
                    Code: "",
                    Posizione: "",
                    note: "",
                    RFID: "",
                    BARCODE: "",
                    Categoria: selectedCategoria,
                    Marca: marca,
                    Macrocategoria: selectedSottocategoria,
                    Data_Inst: date,
                    Data_Scad: "",
                    Nome: doc.Nome,
                    Cliente: user.Email,
                    Valore: 0,})

                    sessionStorage.setItem('elementi', JSON.stringify(arr));
                                      //aggiunta sottocategorie a session storage

                    if(arr2.findIndex((e)=>e.Nome===selectedSottocategoria)===-1){
                        arr2.push({
                            
                            Nome: selectedSottocategoria,
                            Sede: selectedSede,
                        })
                    }
                    sessionStorage.setItem('sottocategorie', JSON.stringify(arr2));


                db.collection("Users")
                  .doc(user.Email)
                  .collection("Sedi")
                  .doc(selectedSede)
                  .collection("Categorie")
                  .doc(selectedCategoria)
                  .collection("Sottocategorie")
                  .doc(selectedSottocategoria)
                  .collection("Elementi")
                  .doc(docRef.id)
                  .set({
                    ref: docRef.id,
                  });

                batch.set(docRef, doc);
              }); //prepara il batch per la scrittura con tutti gli elementi da inserire
            
              batch.commit().then(() => {

                alert("Caricamento completato");

              })

        })
    }else{
        alert('Non puoi aggiungere un numero negativo di elementi')
    }
    }else{
        alert('Non puoi aggiungere ulteriori elementi, fai un upgrade del tuo account Mysic per aggiungere nuovi elementi.')
    }
      } //funzione per aggiungere elementi cliente


  return (
    <div className="background" style={{width:'100vw',height:'100vh',overflow:'hidden'}}>
              <NavBar/>

         <div
        style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}
      >
        <div  style={{  width: "30vw",display:'flex',marginTop:'1.2rem' }}>
          <button style={{marginBottom:'auto',marginTop:'1rem'}} onClick={() => history.goBack()}
            className="cancelIconButton"
            
          >
            <ArrowBackIcon
             className="back-button"
            />
          </button>
          <div>
          <h4 className="page-title" style={{margin:0}}>AGGIUNGI ELEMENTO</h4>
          <h4
          className="sub-text" style={{width:'50vw'}}
        >
          Aggiungi i tuoi elementi per visualizzarne i dettagli e tenere traccia degli interventi
        </h4>
          </div>

        </div>
        <div style={{display:'flex'}}>
                   {/*SELEZIONE SEDE */}

      <div style={{marginLeft:'3.5rem',marginTop:'2rem',width:'22rem'}} >
        <h4  className="sub-text-b">Seleziona sede:</h4>
        <select className="input-style" defaultValue={"default"}
        value={selectedSede} onChange={(e) => {
            setSelectedSede(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona sede...
                </option>
                {sedi && sedi.length > 0 && sedi.map((sede,n)=>{
                                  return <option key={n} value={sede.Id}>{sede.Nome?sede.Nome!==''?sede.Nome:sede.Comune:sede.Comune}</option>
                              })}
        </select>
               {/*SELEZIONE CATEGORIA */}

        {selectedSede!==''?<div style={{marginTop:'1rem'}}><h4  className="sub-text-b">Seleziona categoria:</h4>
        <select className="input-style" defaultValue={"default"}
        value={selectedCategoria} onChange={(e) => {
            setSelectedCategoria(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona categoria...
                </option>
                {categorie && categorie.length > 0 && categorie.map((cat,n)=>{if(cat.Sede===selectedSede){
                                  return <option key={n} value={cat.Id}>{cat.Nome}</option>
                }
                              })}
        </select></div>:null}
               {/*SELEZIONE SOTTOCATEGORIA */}

        {selectedCategoria!==''&&selectedCategoria!=='default'?<div style={{marginTop:'1rem'}}><h4 className="sub-text-b">Seleziona sottocategoria:</h4>
        {/*se la categoria è antincendio*/}
        {selectedCategoria==='Antincendio'?<select className="input-style" defaultValue={"default"}
        value={selectedSottocategoria} onChange={(e) => {
            setSelectedSottocategoria(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona sottocategoria...
                </option>
                <option value="Estintore polvere">
                          Estintore a polvere
                        </option>
                        <option value="Estintore CO2">Estintore CO2</option>
                        <option value="Estintore schiuma">
                          Estintore schiuma
                        </option>
                        <option value="Estintore acqua">Estintore acqua</option>
                        <option value="Porta-Portone REI">
                          Porta/Portone REI
                        </option>
                        <option value="Dispositivo antipanico">
                          Dispositivo antipanico
                        </option>
                        <option value="Gruppo di pressurizzazione">
                          Gruppo di pressurizzazione
                        </option>
                        <option value="Attacco Mandata VVF">
                          Attacco Mandata VVF
                        </option>
                        <option value="Rivelatore Antincendio">
                          Rivelatore antincendio
                        </option>
                        <option value="Aspiratore">Aspiratori</option>
                        <option value="Idrante a muro">Idrante a muro</option>
                        <option value="Idrante a colonna">
                          Idrante a colonna
                        </option>
                        <option value="Segnalazione ottico-acustica">
                          Segnalazione ottico-acustica
                        </option>
                        <option value="Pulsante">Pulsante</option>
        </select>:null}
         {/*se la categoria è elettrico*/}
        {selectedCategoria==='Elettrico'?<select className="input-style" defaultValue={"default"}
        value={selectedSottocategoria} onChange={(e) => {
            setSelectedSottocategoria(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona sottocategoria...
                </option>
                <option value="Lampada emergenza">
                              Lampada d'emergenza
                            </option>
                            <option value="Quadro elettrico">
                              Quadro elettrico
                            </option>
                            <option value="Interruttore differenziale">
                              Interruttore differenziale
                            </option>
        </select>:null}
        {selectedCategoria!=='Antincendio'&&selectedCategoria!=='Elettrico'?<input className="input-style" style={{marginTop:'0.6rem',height:'1.5rem',paddingLeft:'5px'}} value={selectedSottocategoria} onChange={(e) => {
            setSelectedSottocategoria(e.target.value);
          }} placeholder='Nome sottocategoria...'>
           
        </input>:null}
       
       </div>:null}
       {/*SELEZIONE NUMERO */}
       {selectedSottocategoria!==''?<div style={{marginTop:'1rem'}}>
               <h4  className="sub-text-b">Quanti elementi vuoi aggiungere?</h4>

        <input className="input-style2" value={number} onChange={(e)=>setNumber(e.target.value)} style={{width:'2rem',marginTop:'5px'}} type='number'/>
       </div>:null}
       {number!==0&&selectedSottocategoria!==''&&selectedCategoria!==''&&selectedCategoria!=='default'&&
       selectedSede!==''?
       <button onClick={()=>addElement()} className="main-button" style={{marginTop:'2rem'}}>Aggiungi</button>:null}

       </div>
              {/*INFORMAZIONI AGGIUNTIVE */}

       <div style={{marginTop:'1rem'}}>
       {selectedSottocategoria!==''?<div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
          <input type='checkbox' onChange={()=>setExtraDet(!extraDet)} checked={extraDet}></input>
          <h4 className="medium-text">Informazioni aggiuntive</h4>
        </div>:null}
       {extraDet===true?<div>
        <div
                              style={{
                                marginTop:'1rem',
                                alignItems: "center",
                              }}
                            >
                              <div style={{ width: "fit-content" }}>
                                <h4
                                  hidden={extraDet === false ? true : false}
                                  className="sub-text-b"
                                 
                                >
                                  Marca:{" "}
                                </h4>

                                {extraDet === true ? (
                                  <input
                                    type="text"
                                    onChange={(e) => setMarca(e.target.value)}
                                    className="input-style"
                                    placeholder="Inserisci marca..."
                                  />
                                ) : null}
                              </div>
                              <div style={{marginTop:'1rem'}}>
                                <h4
                                  hidden={extraDet === false ? true : false}
                                  className="sub-text-b"
                                >
                                  Data installazione:{" "}
                                </h4>
                                {extraDet === true ? (
                                  <div
                                    style={{
                                     
                                      width: "fit-content",
                                    }}
                                  >
                                    <input
                                      type="date"
                                      style={{marginTop:'2px'}}
                                      onChange={(e) => setInst(e.target.value)}
                                      className="input-style"
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
       </div>:null}
       </div>
              {/*AGGIUNTI DI RECENTE */}
        <div className="box-shadow" style={{height:'50rem',width:'30rem',marginLeft:'auto',marginRight:'2rem',borderRadius:'10px'}}>
         <h4 className="title-medium" style={{marginTop:'1rem',marginLeft:'1rem'}}>Aggiunti di recente</h4>
         {added.length>0?added.map((add,n)=>(
            <div key={n} style={{marginTop:'1rem',marginLeft:'1rem'}}>
            <h4 className="normal-text">• {add.Nome}</h4>
            <h4 className="sub-text">{add.Categoria} - {add.Sottocategoria}</h4>
            {add.Marca!==''?<h4 className="sub-text">Marca: {add.Marca}</h4>:null}
            {add.Inst!==''?<h4 className="sub-text">Installazione: {add.Inst}</h4>:null}
            </div>
         )):<h4 className="medium-text" style={{marginTop:'1rem',marginLeft:'1rem'}}>Nessun elemento aggiunto di recente...</h4>}                           
        </div>

      </div>
      
 
      </div></div>
  )
}

export default AggiungiElemento