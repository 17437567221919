//sezione esprotazioni
import React, { useState, useEffect } from "react";
import firebase from "firebase";
import * as XLSX from "xlsx/xlsx.mjs";
import { IconButton, Tooltip } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import Loading from "../loading";
import e from "cors";
const Esportazioni = () => {

    const db = firebase.firestore(); //database firebase
    const auth = firebase.auth(); //autentication firebase


    const [ele,setEle] = useState() //numero elementi acc
    const [elements,setElements] = useState([]) //lista elementi
    const [plan,setPlan] = useState(null) //tipo di piano mysic account
    const [email,setEmail] = useState(null) //email
    const [azienda,setAzienda] = useState('') //nome azienda 
    const [tipo,setTipo] = useState(null) //tipo account
    const [interventi,setInterventi] = useState([]) //lista interventi
    const [documenti, setDocumenti] = useState([]) //lista doc
    const [manutenzioni,setManutenzioni] = useState([]) //lista manutenzioni
    const [loading,setLoading] = useState(false)
    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
            if (user) {
        db.collection("UsersId")
        .doc(auth.currentUser.uid)
        .get()
        .then((doc) => {
          db.collection('Users').doc(doc.data().Email).get().then((docc)=>{
            var emailz = ''

            if (docc.data().Tipo === "SottoAccount") {
              db.collection('UsersId').doc(docc.data().MasterAccount).get().then((doccc)=>{
                setEmail(doccc.data().Email)

                db.collection("Users")
          .doc(doccc.data().Email)
          .get()
          .then((docz) => {
              setTipo(docz.data().Tipo)
              setEle(docz.data().Current_Ele);
              setPlan(docz.data().Plan)
              setAzienda(docz.data().Nome_Azienda)
  
              if(docz.data().Tipo==='Cliente'){
                prepExcel(docz.data().Email)
                prepExcelInterventi(docz.data().Email)
                prepExcelDocumenti(docz.data().Email)
                prepExcelManutenzioni(docz.data().Email)
              }else{
                prepExcelInterventiOff(doccc.data().Email)
                prepExcelManutenzioniOff(doccc.data().Email)
  
              }
          })

              })
          }else{
            setEmail(doc.data().Email)

              db.collection("Users")
              .doc(doc.data().Email)
              .get()
              .then((docz) => {
                  setTipo(docz.data().Tipo)
                  setEle(docz.data().Current_Ele);
                  setPlan(docz.data().Plan)
                  setAzienda(docz.data().Nome_Azienda)
      
                  if(docz.data().Tipo==='Cliente'){
                    prepExcel(docz.data().Email)
                    prepExcelInterventi(docz.data().Email)
                    prepExcelDocumenti(docz.data().Email)
                    prepExcelManutenzioni(docz.data().Email)
                  }else{
                    prepExcelInterventiOff(doc.data().Email)
                    prepExcelManutenzioniOff(doc.data().Email)
      
                  }
              })
          }

          
          })

                  
        });
  
    }
})
    },[auth]) //prende dati e chiama funzione getCodes

    

///////////EXCEL ELEMENTI

    function prepExcel(emailz){
        setElements([])
        if(emailz!==null&&emailz!==undefined){

            db.collection('Users').doc(emailz).collection('Sedi').get().then((sedi)=>{
                sedi.forEach((s)=>{
                    db.collection('Users').doc(emailz).collection('Sedi').doc(s.id).collection('Categorie').get().then((categorie)=>{
                        categorie.forEach((c)=>{
                            db.collection('Users').doc(emailz).collection('Sedi').doc(s.id).collection('Categorie').doc(c.id).collection('Sottocategorie').get().then((sotto)=>{
                                sotto.forEach((sott)=>{
                                    db.collection('Users').doc(emailz).collection('Sedi').doc(s.id).collection('Categorie').doc(c.id).collection('Sottocategorie')
                                    .doc(sott.id).collection('Elementi').get().then((elementi)=>{
                                        elementi.forEach((e)=>{
                                  
                                        db.collection('Elementi').doc(e.data().ref).get().then((elem)=>{
                                            setElements((prev)=>{
                                                return [
                                                    ...prev,
                                                    {key:elem.id,
                                                    Manutenzioni:e.data().NumeroManutenzioni,
                                                        ...elem.data()
                                                        }, 
                                                        
                                                ]
                                            })
                                        })

                                        })

                                    }) 
                                })
                            })
                        })
                    })
                })
                
            })
          }
      
    } //prende gli elementi per stamparli su excel
   
    function stampEx() {
        var arrData = [];
        console.log(elements)
        if (elements.length > 0) {
          arrData.push([
            "ID",
            "Nome",
            "Categoria",
            "Sottocategoria",
            "Sede",
            "Mappa",
            "Manutenzioni",
            "Data_Inst",
            "Data_Scad",
            "Descrizione",
            "Marca",
            "RFID",
            "BARCODE",
            "Note",
            
          ]);
    
          elements.forEach((ele) => {
            arrData.push([
              ele.key,
              ele.Nome,
              ele.Categoria,
              ele.Macrocategoria,
              ele.Sede,
              ele.Mappa,
              ele.Manutenzioni,
              ele.Data_Inst,
              ele.Data_Scad,
              ele.Descrizione,
              ele.Marca,
              ele.RFID,
              ele.BARCODE,
              ele.Note,
            ]);
          });
          stampEx2(arrData);
        } else {
          console.log(elements);
        }
      } //funzione per esportare i tuoi elementi, triggera stampEx2
      function stampEx2(arrData) {
        console.log(arrData);
        var wb = XLSX.utils.book_new(),
          ws = XLSX.utils.aoa_to_sheet(arrData);
        console.log(ws, arrData);
        XLSX.utils.book_append_sheet(wb, ws, "Elementi");
        XLSX.writeFile(wb,"Elementi_MYSIC.xlsx");
      } //funzione per esportare i tuoi elementi come excel

//////EXCEL INTERVENTI CLI

      function prepExcelInterventi(emailz){
        setInterventi([])
        if(emailz!==null&&emailz!==undefined){
            db.collection('Users').doc(emailz).collection('Sedi').get().then((sedi)=>{
                sedi.forEach((s)=>{
                    db.collection('Users').doc(emailz).collection('Sedi').doc(s.id).collection('Attività').get().then((attivita)=>{
                        attivita.forEach((a)=>{
    
                                        db.collection('Attività').doc(a.data().ref).get().then((elem)=>{
                                            setInterventi((prev)=>{
                                                return [
                                                    ...prev,
                                                    {key:elem.id,
                                                    SottoAttività:elem.data().SottoAttività?elem.data().SottoAttività:false,
                                                        ...elem.data()
                                                        }, 
                                                        
                                                ]
                                            })
                                        })
                        })
                    })
                })
                
            })
      }
    } //prende gli interventi per stamparli su excel
    function stampExInterventi() {
        var arrData = [];
        console.log(elements)
        if (interventi.length > 0) {
          arrData.push([
            "ID",
            "Titolo",
            "Descrizione",
            "Categoria",
            "Data",
            "Sede",
            "Cliente",
            "Email Cliente",
            "Manutentore",
            "Email Manutentore",
            "Stato",
            "Esito",
            "Report",
            "Elemento",
            "Sotto Interventi",
            
          ]);
    
          interventi.forEach((ele) => {
            arrData.push([
              ele.key,
              ele.Titolo,
              ele.Descrizione,
              ele.Categoria,
              ele.Data,
              ele.Sede,
              ele.Nome_Azienda,
              ele.Cliente,
              ele.Operatore,
              ele.Op_Email,
              ele.Stato,
              ele.Esito,
              ele.Report,
              ele.Oggetto,
              ele.SottoAttività===true?"Presenti":'Assenti',
            ]);
          });
          stampEx2Interventi(arrData);
        } else {
         
        }
      } //funzione per esportare i tuoi elementi, triggera stampEx2
      function stampEx2Interventi(arrData) {
        console.log(arrData);
        var wb = XLSX.utils.book_new(),
          ws = XLSX.utils.aoa_to_sheet(arrData);
        console.log(ws, arrData);
        XLSX.utils.book_append_sheet(wb, ws, "Interventi");
        XLSX.writeFile(wb,"Interventi_MYSIC.xlsx");
      } //funzione per esportare i tuoi elementi come excel


//////EXCEL INTERVENTI OFF

function prepExcelInterventiOff(emailz){
  setInterventi([])
  if(emailz!==null&&emailz!==undefined){
    db.collection('Users').doc(emailz).collection('Clienti').get().then((sedi)=>{
      sedi.forEach((s)=>{
          db.collection('Users').doc(emailz).collection('Clienti').doc(s.id).collection('Attività').get().then((attivita)=>{
              attivita.forEach((a)=>{

                              db.collection('Attività').doc(a.data().ref).get().then((elem)=>{
                                  setInterventi((prev)=>{
                                      return [
                                          ...prev,
                                          {key:elem.id,
                                          SottoAttività:elem.data().SottoAttività?elem.data().SottoAttività:false,
                                              ...elem.data()
                                              }, 
                                              
                                      ]
                                  })
                              })
              })
          })
      })
      
  })
  }
      
} //prende gli interventi per stamparli su excel
function stampExInterventiOff() {
  var arrData = [];
  console.log(elements)
  if (interventi.length > 0) {
    arrData.push([
      "ID",
      "Titolo",
      "Descrizione",
      "Categoria",
      "Data",
      "Sede",
      "Cliente",
      "Email Cliente",
      "Manutentore",
      "Email Manutentore",
      "Stato",
      "Esito",
      "Report",
      "Elemento",
      "Sotto Interventi",
      
    ]);

    interventi.forEach((ele) => {
      arrData.push([
        ele.key,
        ele.Titolo,
        ele.Descrizione,
        ele.Categoria,
        ele.Data,
        ele.Sede,
        ele.Nome_Azienda,
        ele.Cliente,
        ele.Operatore,
        ele.Op_Email,
        ele.Stato,
        ele.Esito,
        ele.Report,
        ele.Oggetto,
        ele.SottoAttività===true?"Presenti":'Assenti',
      ]);
    });
    stampEx2InterventiOff(arrData);
  } else {
   
  }
} //funzione per esportare i tuoi elementi, triggera stampEx2
function stampEx2InterventiOff(arrData) {
  var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.aoa_to_sheet(arrData);
  XLSX.utils.book_append_sheet(wb, ws, "Interventi");
  XLSX.writeFile(wb,"Interventi_MYSIC.xlsx");
} //funzione per esportare i tuoi elementi come excel


//////EXCEL DOCUMENTI

function prepExcelDocumenti(emailz){
    setDocumenti([])
    if(emailz!==null&&emailz!==undefined){
        db.collection('Users').doc(emailz).collection('Sedi').get().then((sedi)=>{
            sedi.forEach((s)=>{
                db.collection('Users').doc(emailz).collection('Sedi').doc(s.id).collection('Categorie').get().then((categorie)=>{
                    categorie.forEach((c)=>{
                        db.collection('Users').doc(emailz).collection('Sedi').doc(s.id).collection('Categorie').doc(c.id).collection('Cartelle').get().then((sotto)=>{
                            sotto.forEach((sott)=>{
                                db.collection('Users').doc(emailz).collection('Sedi').doc(s.id).collection('Categorie').doc(c.id).collection('Cartelle')
                                .doc(sott.id).collection('Documenti').get().then((elementi)=>{
                                    elementi.forEach((e)=>{
                              
                                    db.collection('Documenti').doc(e.data().ref).get().then((elem)=>{
                                        setDocumenti((prev)=>{
                                            return [
                                                ...prev,
                                                {key:elem.id,
                                                Cartella: sott.data().Nome,
                                                    ...elem.data()
                                                    }, 
                                                    
                                            ]
                                        })
                                    })
                                    })
                                }) 
                            })
                        })
                    })
                })
            })
            
        })
      }
} //prende i documenti per stamparli su excel

function stampExDocumenti() {
    var arrData = [];
    console.log(elements)
    if (documenti.length > 0) {
      arrData.push([
        "ID",
        "Nome File",
        "Categoria",
        "Cartella",
        "Sede",
        "Dimensioni",
        "Estensione",
        "Autore",
        "Scadenza",
        "Condiviso con",
        "URL"
        
      ]);

      documenti.forEach((ele) => {
        arrData.push([
          ele.key,
          ele.NomeFile,
          ele.Categoria,
          ele.Cartella,
          ele.Sede,
          ele.Size,
          ele.Extension,
          ele.Autore,
          ele.Scadenza,
          ele.Shared,
          ele.URL,
        ]);
      });
      stampEx2Documenti(arrData);
    } else {
    }
  } //funzione per esportare i tuoi documenti, triggera stampEx2
  function stampEx2Documenti(arrData) {
    console.log(arrData);
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(arrData);
    console.log(ws, arrData);
    XLSX.utils.book_append_sheet(wb, ws, "Documenti");
    XLSX.writeFile(wb,"Documenti_MYSIC.xlsx");
  } //funzione per esportare i tuoi documenti come excel


  //////EXCEL MANUTENZIONI CLI

  function prepExcelManutenzioni(emailz){
    setManutenzioni([])
    if(emailz!==null&&emailz!==undefined){

        db.collection('Users').doc(emailz).collection('Sedi').get().then((sedi)=>{
            sedi.forEach((s)=>{
                db.collection('Users').doc(emailz).collection('Sedi').doc(s.id).collection('Manutenzioni').get().then((attivita)=>{
                    attivita.forEach((a)=>{

                                    db.collection('Manutenzioni').doc(a.data().ref).get().then((elem)=>{
                                        setManutenzioni((prev)=>{
                                            return [
                                                ...prev,
                                                {key:elem.id,
                                                SottoAttività:elem.data().SottoAttività?elem.data().SottoAttività:false,
                                                    ...elem.data()
                                                    }, 
                                                    
                                            ]
                                        })
                                    })
                    })
                })
            })
            
        })
      }
} //prende le manutenzioni per stamparli su excel
function stampExManutenzioni() {
    var arrData = [];
    if (manutenzioni.length > 0) {
      arrData.push([
        "ID",
        "Titolo",
        "Descrizione",
        "Categoria",
        "Data",
        "Sede",
        "Cliente",
        "Email Cliente",
        "Manutentore",
        "Email Manutentore",
        "Stato",
        "Esito",
        "Report",
        "Oggetto",
        "Sotto Interventi",
        "Tipo",
        
      ]);

      manutenzioni.forEach((ele) => {
        arrData.push([
          ele.key,
          ele.Titolo,
          ele.Descrizione,
          ele.Categoria,
          ele.Data,
          ele.Sede,
          ele.Nome_AziendaCli,
          ele.Cliente,
          ele.Nome_AziendaOff,
          ele.Operatore,
          ele.Stato,
          ele.Esito,
          ele.Report,
          ele.Oggetto,
          ele.SottoAttività===true?"Presenti":'Assenti',
          ele.Type?ele.Type==='doc'?'Scadenza documento':'Manutenzione elementi':'Manutenzione elementi'
        ]);
      });
      stampEx2Manutenzioni(arrData);
    } else {
     
    }
  } //funzione per esportare le tuoe manutenzioni, triggera stampEx2
  function stampEx2Manutenzioni(arrData) {
    console.log(arrData);
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(arrData);
    console.log(ws, arrData);
    XLSX.utils.book_append_sheet(wb, ws, "Scadenze");
    XLSX.writeFile(wb,"Scadenze_MYSIC.xlsx");
  } //funzione per esportare le tue manutenzioni come excel

    //////EXCEL MANUTENZIONI OFF

    function prepExcelManutenzioniOff(emailz){
      var elementsz = []
      setManutenzioni([])
      if(emailz!==null){
        db.collection('Users').doc(emailz).collection('Clienti').get().then((sedi)=>{
          sedi.forEach((s)=>{
              db.collection('Users').doc(emailz).collection('Clienti').doc(s.id).collection('Manutenzioni').get().then((attivita)=>{
                  attivita.forEach((a)=>{

                                  db.collection('Manutenzioni').doc(a.data().ref).get().then((elem)=>{
                                      setManutenzioni((prev)=>{
                                          return [
                                              ...prev,
                                              {key:elem.id,
                                                  ...elem.data()
                                                  }, 
                                                  
                                          ]
                                      })
                                  })
                  })
              })
          })
          
      })
      }
          
  } //prende le manutenzioni per stamparli su excel
  function stampExManutenzioniOff() {
      var arrData = [];
      if (manutenzioni.length > 0) {
        arrData.push([
          "ID",
          "Titolo",
          "Descrizione",
          "Categoria",
          "Data",
          "Sede",
          "Cliente",
          "Email Cliente",
          "Manutentore",
          "Email Manutentore",
          "Stato",
          "Esito",
          "Report",
          "Oggetto",
          "Sotto Interventi",
          "Tipo",
          
        ]);
  
        manutenzioni.forEach((ele) => {
          arrData.push([
            ele.key,
            ele.Titolo,
            ele.Descrizione,
            ele.Categoria,
            ele.Data,
            ele.Sede,
            ele.Nome_AziendaCli,
            ele.Cliente,
            ele.Nome_AziendaOff,
            ele.Operatore,
            ele.Stato,
            ele.Esito,
            ele.Report,
            ele.Oggetto,
            ele.SottoAttività?"Presenti":'Assenti',
            ele.Type?ele.Type==='doc'?'Scadenza documento':'Manutenzione elementi':'Manutenzione elementi'
          ]);
        });
        stampEx2ManutenzioniOff(arrData);
      } else {
       
      }
    } //funzione per esportare le tuoe manutenzioni, triggera stampEx2
    function stampEx2ManutenzioniOff(arrData) {
      console.log(arrData);
      var wb = XLSX.utils.book_new(),
        ws = XLSX.utils.aoa_to_sheet(arrData);
      console.log(ws, arrData);
      XLSX.utils.book_append_sheet(wb, ws, "Scadenze");
      XLSX.writeFile(wb,"Scadenze_MYSIC.xlsx");
    } //funzione per esportare le tue manutenzioni come excel

  return (
    <div style={{paddingLeft:'1rem',height:'88vh',overflowY:'auto',width:'71vw'}}>
              <h4 className="title-section" style={{marginTop:'0.2rem'}}>Esportazioni</h4>
              {loading===false?<div>
              {/*Elementi*/}
              {tipo==='Cliente'?<div>
              <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
      <h4 className="header-normal" style={{}}>Elementi</h4>
      </div>
              <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{elements.length}</h4>
    <h4 className="sub-text">Elementi inseriti</h4>
        </div>
        <div style={{marginLeft:'auto',width:'fit-content'}}>
            <Tooltip title='Scarica Excel'>
          <IconButton style={{width:'fit-content'}} onClick={()=>stampEx()}>
            <DownloadIcon></DownloadIcon>
          </IconButton>
          </Tooltip>
        </div>
      </div>
      </div>:null}
      {/*Documenti*/}
      {tipo==='Cliente'?<div>
      <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
      <h4 className="header-normal" style={{}}>Documenti</h4>
      </div>
              <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{documenti.length}</h4>
    <h4 className="sub-text">Documenti inseriti</h4>
        </div>
        <div style={{marginLeft:'auto',width:'fit-content'}}>
            <Tooltip title='Scarica Excel'>
          <IconButton style={{width:'fit-content'}} onClick={()=>stampExDocumenti()}>
            <DownloadIcon></DownloadIcon>
          </IconButton>
          </Tooltip>
        </div>
      </div>
      </div>:null}
       {/*Interventi*/}
       <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
      <h4 className="header-normal" style={{}}>Interventi</h4>
      </div>
              <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{interventi.length}</h4>
    <h4 className="sub-text">Interventi inseriti</h4>
        </div>
        <div style={{marginLeft:'auto',width:'fit-content'}}>
            <Tooltip title='Scarica Excel'>
          <IconButton style={{width:'fit-content'}} onClick={()=>tipo==='Cliente'?stampExInterventi():stampExInterventiOff()}>
            <DownloadIcon></DownloadIcon>
          </IconButton>
          </Tooltip>
        </div>
      </div>
       {/*Manutenzioni*/}
       <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
      <h4 className="header-normal" style={{}}>Scadenze</h4>
      </div>
              <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{manutenzioni.length}</h4>
    <h4 className="sub-text">Scadenze inserite</h4>
        </div>
        <div style={{marginLeft:'auto',width:'fit-content'}}>
            <Tooltip title='Scarica Excel'>
          <IconButton style={{width:'fit-content'}} onClick={()=>tipo==='Cliente'?stampExManutenzioni():stampExManutenzioniOff()}>
            <DownloadIcon></DownloadIcon>
          </IconButton>
          </Tooltip>
        </div>
      </div>
      </div>:<Loading/>}
    </div>
  )
}


export default Esportazioni