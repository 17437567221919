//componente per barra di ricerca con suggerimento google maps
import { useState } from "react";

import PlacesAutocomplete from "react-places-autocomplete";
import PlaceIcon from '@mui/icons-material/Place';



export default function Places(props) {

    const [address, setAddress] = useState("");
    const [show,setShow] = useState(false)

    const handleSelect = async (address) => {
      //props.setposition(address);
    

      setShow(true)
      setAddress(address)
      

  
   

    };

    const handleClick = async (address) => {
      //props.setposition(address);
      
      var geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({
        'address':address
      },function(results,status){
        if(status=== window.google.maps.GeocoderStatus.OK){
            var latitude = results[0].geometry.location.lat();
            var longitude = results[0].geometry.location.lng();
            console.log(latitude,longitude)
            setAddress('')
            setShow(false)
            props.setCenter({lat:latitude,lng:longitude})
        }
      })
    };
  
    return (
        <div style={{zIndex:1200}}>
        <PlacesAutocomplete
          value={address}
          onChange={handleSelect}
          onSelect={handleClick}
          
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
           <div style={{position:'relative'}}>
           
            <label></label>
           <input style={{outline:'none',width:'18vw', boxShadow: 'rgba(0, 0, 0, 0.3) 0.5px 1.95px 1.95px 2px', padding:5,backgroundColor:"white", color:'black', border:'1px solid #14213d',borderRadius:5,borderBottomLeftRadius:suggestions.length===0&&!loading?5:0}} {...getInputProps({ placeholder: "Cerca luogo..." })} />

           {show===true?<div  style={{position:'absolute', left:'0', maxHeight:'30vh', overflowY: 'auto', zIndex:1000, backgroundColor:'white',width:'fit-content',}}>
             {loading ? <div>...loading</div> : null}
            {suggestions.length===0?null:<div style={{maxHeight:'30vh',overflowY:'auto',backgroundColor:'white',border:'1px solid #14213d',borderRadius:10,borderTopLeftRadius:0,borderTopRightRadius:0,width:'18vw',paddingBottom:'2vh',borderTop:'none'}}>
            {suggestions.map((suggestion,i) => {
                 const style = {
                    backgroundColor: suggestion.hover ? "#41b6e6" : "#fff"
                  };
               return suggestion.description?<div key={i} {...getSuggestionItemProps(suggestion, { style })} onClick={()=>handleClick(suggestion.description)} elevation={2} style={{zIndex:1000,padding:'0.1vw', marginTop:'1vh', cursor: 'pointer',fontSize:'1vw',height:'fit-content',display:'flex'}}><PlaceIcon style={{color:'#FF0060',fontSize:'1vw'}}/> <h4 className="medium-text">{suggestion.description} </h4></div>:null
              ;

               
             })}
            </div>}

           </div>:null}
         </div>
          )}
        </PlacesAutocomplete>
      </div>
    );
  };