//step inserimento credenziali di accesso
import { useState } from 'react'
import PrivacyPolicy from './PrivacyPolicy'
export function FinalStep(props){

  const [triggerPrivacy,setTriggerPrivacy] = useState(false)


    return(
        <div
        style={{
          marginTop: "5vh",
          width: "50vw",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        >
          {triggerPrivacy===true?<PrivacyPolicy condizioni={props.condizioni} setCondizioni={props.setCondizioni} trigger={triggerPrivacy} setTrigger={setTriggerPrivacy}/>:null}
          <div
            style={{
              width: "fit-content",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <h4
                        className='titleReg'

            >
              CREDENZIALI MYSIC
            </h4>
            <h4
                       className='headerInput'

            >
              EMAIL
            </h4>
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "fit-content",
              }}
            >
              <input
                onChange={(e) => props.setEmail(e.target.value)}
                type="text"
                className='inputReg'

                placeholder="Inserisci..."
                value={props.email}
              />
            </div>

            <h4
                      className='headerInput'

            >
              PASSWORD
            </h4>

            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "fit-content",
              }}
            >
              <input
                              className='inputReg'

                type="password"
                onChange={(e) => props.setPassword(e.target.value)}
                value={props.password}
                placeholder="Inserisci..."
              />
            </div>
            <h4
                       className='headerInput'

            >
              CONFERMA PASSWORD
            </h4>
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "fit-content",
              }}
            >
              <input
                type="password"
                value={props.confirm}
                onChange={(e) => props.setConfirm(e.target.value)}
                className='inputReg'

                placeholder="Inserisci..."
              ></input>
            </div>
            <div  style={{
                width: "fit-content",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "3vh",
                display:'flex',
              }}>
                <input type='checkbox' checked={props.condizioni} onChange={()=>props.setCondizioni(!props.condizioni)}/>
                <h4 style={{marginLeft:5,fontFamily:'Montserrat', color:'black',fontWeight:'normal', fontSize:'0.8vw',marginTop:0,marginBottom:0}}>Confermi di aver preso visione e accettare le nostre</h4>
                <h4 onClick={()=>setTriggerPrivacy(true)} style={{cursor:'pointer',fontFamily:'Montserrat', color:'#0496ff',fontWeight:'bold', fontSize:'0.8vw',marginTop:0,marginBottom:0,marginLeft:5,borderBottom:'1px solid #0496ff'}}>CONDIZIONI D'USO</h4>
            </div>
            {props.email!==''&&props.password!==''&&props.confirm!==''&&props.condizioni===true?<div
              style={{
                width: "fit-content",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "3vh",
              }}
            >
              <button
className="nextButton"
                onClick={()=>props.setSection(5)}
              >
                Avanti
              </button>
            </div>:null}
            <div
              style={{
                width: "fit-content",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "1vh",
              }}
            >
              <button
className="backButton"
                onClick={() => (props.section === 3 ? props.setSection(2) : props.setSection(3))}
              >
                Indietro
              </button>
            </div>
          </div>
        </div>
    )
}