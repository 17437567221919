import React, {useEffect, useState} from 'react'
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import { Password } from '@mui/icons-material';
import firebase from 'firebase/app';
import 'firebase/functions';
import Loading from "../../loading";
import { getSottoaccounts } from '../../EngineClienti/engineClienti';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import BadgeIcon from '@mui/icons-material/Badge';
import HelpIcon from '@mui/icons-material/Help';
import { IconButton, Tooltip } from '@mui/material';
const SottoaccountS = (props) => {

    const [triggerAdd,setTriggerAdd] = useState(false)
    const [triggerPermissions,setTriggerPermissions] = useState(false)
    const [mainUser,setMainUser] = useState(null) //utente loggato master
    const [sottoaccount,setSottoaccount] = useState([])
    const [userM,setUserM] = useState(null) //var in cui salvare i dati dell utente da modificare

    useEffect(()=>{

        if(mainUser===null){
        setMainUser(props.user)
      }
    },[props.user])

    useEffect(()=>{
        if(mainUser!==null){
            getSottoaccounts(props.db,mainUser.Email,setSottoaccount)
        }
    },[mainUser])

    const AddSottoaccount = (props) => {
      const [nome, setNome] = useState('');
      const [cognome, setCognome] = useState('');
      const [numeroDiTelefono, setNumeroDiTelefono] = useState('');
      const [email, setEmail] = useState('');
      const [password, setPassword] = useState('');

      const [loading,setLoading] = useState(false)


      function validaEmail(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }
  
      function aggiungiSottoaccount() {
        if (!nome || !cognome || !numeroDiTelefono || !email || !password) {
          alert('Tutti i campi devono essere compilati.');
          return;
      }

      if (!validaEmail(email)) {
          alert('Inserisci un indirizzo email valido.');
          return;
      }

      // Funzione per aggiungere il sottoaccount
      const createSottoaccount = firebase.functions().httpsCallable('createSottoaccount');
      setLoading(true)
      createSottoaccount({ email: email, password: password })
          .then(result => {
              console.log('User created with UID:', result.data.uid);
                props.db.collection('Users').doc(email).get().then((exist)=>{
                    if(exist.exists){
                        alert('Questa Email è già stata utilizzata. Utilizzarne una diversa')
                    }else{
                        props.db.collection('UsersId').doc(result.data.uid).set({
                            CurrentEmail:email,
                            Email:email
                        }).then(()=>{
                            props.db.collection("Users").doc(email).set({
                                Id:result.data.uid,
                                Nome: nome,
                                Cognome: cognome,
                                Email: email,
                                CurrentEmail:email,
                                Numero: numeroDiTelefono,
                                Password: password,
                                Tipo:'SottoAccount',
                                MasterAccount: mainUser.Id,
                                Permissions: 0
                              }).then(()=>{
                               props.db.collection('Users').doc(mainUser.Email).collection('Sottoaccount').doc(result.data.uid).set({
                                Id: result.data.uid,
                                Email: email,
                                Nome:nome,
                                Cognome:cognome
                               }).then(()=>{
                                props.db.collection('Users').doc(mainUser.Email).update({
                                    Current_Sott: firebase.firestore.FieldValue.increment(1)
                                }).then(()=>{
                                    var arr = [...sottoaccount]
                                    arr.push({  Id:result.data.uid,
                                        Nome: nome,
                                        Cognome: cognome,
                                        Email: email,
                                        CurrentEmail:email,
                                        Numero: numeroDiTelefono,
                                        Password: password,
                                        Tipo:'SottoAccount',
                                        MasterAccount: mainUser.Id,
                                        Permissions: 0 })
                                        setSottoaccount(arr)
                                    setLoading(false)
                                    props.setTrigger(false)
                                    alert('Sottoaccount aggiunto')
                                })
                               })
                              })
                        })
                    }
                })

            /*  */
      
          })
          .catch(error => {
              console.error('Error creating user:', error.message);
              setLoading(false)
              alert('Errore: '+error.message)
          });
     


      }
  
      return (
          <div className="pop-opac">
              <div className="popup" style={{ backgroundColor: "white", minHeight: '80vh', height: 'fit-content', overflowY: "auto", width: '40vw', left: '30vw' }}>
                  <div style={{ textAlign: 'left', width: 'fit-content' }}>
                      <button
                          className="cancelIconButton"
                          onClick={() => props.setTrigger(false)}
                      >
                          <CancelIcon style={{ width: '15px', height: '15px' }} className="cancelIcon" />
                      </button>
                  </div>
                  <h4 className='page-title' style={{ margin: 0, marginLeft: 'auto', marginRight: 'auto' }}>Aggiungi sottoaccount</h4>
                  {loading===false?<div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2vh' }}>
                      <h4 className='sub-text-b'>Anagrafica cliente</h4>
                      <div style={{ alignItems: 'center', marginTop: '1vh', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ alignItems: 'center',display: 'flex', flexDirection: 'column' }}>
                          <h4 className='sub-text' style={{ marginBottom: '5px',fontSize:'0.8vw' }}>Nome</h4>
                          <input
                              type='text'
                              placeholder='Inserisci nome...'
                              className='input-style'
                              value={nome}
                              onChange={(e) => setNome(e.target.value)}
                          />
  </div>
  <div style={{ alignItems: 'center',display: 'flex', flexDirection: 'column' }}>

                          <h4 className='sub-text' style={{ marginLeft: '10px', marginBottom: '5px',fontSize:'0.8vw' }}>Cognome</h4>
                          <input
                          style={{marginLeft:'10px'}}
                              type='text'
                              placeholder='Inserisci cognome...'
                              className='input-style'
                              value={cognome}
                              onChange={(e) => setCognome(e.target.value)}
                          />
                            </div>

                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                          <h4 className='sub-text' style={{ marginTop: '10px', marginBottom: '5px',fontSize:'0.8vw' }}>Numero di telefono</h4>
                          <input
                              type='number'
                              placeholder='Inserisci numero...'
                              className='input-style'
                              value={numeroDiTelefono}
                              onChange={(e) => setNumeroDiTelefono(e.target.value)}
                          />
                      </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2vh' }}>
                      <h4 className='sub-text-b'>Credenziali Mysic</h4>
                      <div style={{ alignItems: 'center', marginTop: '1vh', display: 'flex', flexDirection: 'column' }}>
                          <h4 className='sub-text' style={{ marginBottom: '5px',fontSize:'0.8vw' }}>Email</h4>
                          <input
                              type='text'
                              placeholder='Inserisci email...'
                              className='input-style'
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                          />
  
                          <h4 className='sub-text' style={{ marginTop: '10px', marginBottom: '5px',fontSize:'0.8vw' }}>Password</h4>
                          <input
                              type='password'
                              placeholder='Inserisci password...'
                              className='input-style'
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                          />
                      </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2vh' }}>
                      <button className='main-button' onClick={() => aggiungiSottoaccount()}>Aggiungi</button>
                  </div>
                  </div>:<Loading/>}
              </div>
          </div>
      );
  }

  const ChangePermissions= (props) =>{

    const [selectedOption, setSelectedOption] = useState(0);


    const handleChange = (event) => {
        setSelectedOption(event.target.value);
      };

      function change(){
        props.db.collection('Users').doc(userM.Email).update({
            Permissions: parseInt(selectedOption,10)
        }).then(()=>{
            var arr = [...sottoaccount]
            console.log(userM.Email)
            var index = arr.findIndex((e)=>e.Email===userM.Email)
            console.log(index)
            arr[index].Permissions = parseInt(selectedOption,10)
            setSottoaccount(arr)
            alert('Permessi aggiornati!')
        })
      }

    return (
        <div className="pop-opac">
        <div className="popup" style={{ backgroundColor: "white", minHeight: '50vh', height: 'fit-content', overflowY: "auto", width: '40vw', left: '30vw',top:'20vh' }}>
        <div style={{ textAlign: 'left', width: 'fit-content' }}>
                      <button
                          className="cancelIconButton"
                          onClick={() => props.setTrigger(false)}
                      >
                          <CancelIcon style={{ width: '15px', height: '15px' }} className="cancelIcon" />
                      </button>
                  </div>
                  <h4 className='page-title' style={{ margin: 0, marginLeft: 'auto', marginRight: 'auto' }}>Cambia permessi</h4>

                  <div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content',marginTop:'2vh'}}>
                  <h4 className='sub-text'>Seleziona permessi:</h4>
                  </div>
                  <div style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',marginTop:'1vh'}}>
                  <select className='input-style' value={selectedOption} onChange={handleChange}>
                    <option value={0}>Lettore</option>
                    <option value={1}>Scrittore</option>
                    <option value={2}>Editore</option>
      </select>
                  </div>
                  <div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content',marginTop:'2vh'}}>
                  <button className='main-button' onClick={()=>change()}>Conferma</button>
                  </div>

                  <div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content',marginTop:'2vh'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <h4 className='normal-cool' style={{margin:0}}>Lettore</h4>
                <Tooltip title='Il sottoaccount potrà solamente leggere i dati'>
                <HelpIcon style={{fontSize:'1vw', color:'#5C6672',marginLeft:'5px'}}/>
                </Tooltip>
                </div>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:'1vh'}}>
                <h4 className='normal-cool' style={{margin:0}}>Scrittore</h4>
                <Tooltip title='Il sottoaccount potrà solamente leggere e aggiungere dati'>
                <HelpIcon style={{fontSize:'1vw', color:'#5C6672',marginLeft:'5px'}}/>
                </Tooltip>
                </div>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:'1vh'}}>
                <h4 className='normal-cool' style={{margin:0}}>Editore</h4>
                <Tooltip title='Il sottoaccount potrà leggere, aggiungere ed eliminare i dati'>
                <HelpIcon style={{fontSize:'1vw', color:'#5C6672',marginLeft:'5px'}}/>
                </Tooltip>
                </div>
                </div>
        </div>
        </div>
    )
  }

  return (
    <div style={{paddingLeft:'1rem',height:'88vh',overflowY:'auto',width:'71vw'}}>
        {triggerAdd===true?<AddSottoaccount db={props.db} trigger={triggerAdd} setTrigger={setTriggerAdd}/>:null}
        {triggerPermissions===true?<ChangePermissions db={props.db} trigger={triggerPermissions} setTrigger={setTriggerPermissions}/>:null}

        <div style={{display:'flex',alignItems:'center',marginTop:'0.2rem'}}>
        <h4 className="title-section" >Sottoaccount</h4>
        <button className='btnD' onClick={()=>setTriggerAdd(true)}><AddIcon style={{color:'#14213d',cursor:'pointer'}}/></button>
        </div>
        {sottoaccount.length>0?sottoaccount.map((sott,i)=>(
            <div key={i} style={{marginTop:'1vh',borderBottom:'1px solid lightgrey',paddingBottom:'0.5vh'}}>
            <h4 className='bold-cool'  style={{margin:0}}>{sott.Nome} {sott.Cognome}</h4>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            <EmailIcon style={{fontSize:'0.8vw', color:'#5C6672'}}/>
            <h4 className='sub-text' style={{margin:0,fontSize:'0.7vw',marginLeft:'5px'}}>{sott.CurrentEmail}</h4>
            </div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            <PhoneIcon style={{fontSize:'0.8vw', color:'#5C6672'}}/>
            <h4 className='sub-text' style={{margin:0,fontSize:'0.7vw',marginLeft:'5px'}}>{sott.Numero}</h4>
            </div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:'5px'}}>
            <BadgeIcon style={{fontSize:'1vw', color:'black'}}/>
            <h4 className='normal-cool' style={{margin:0,fontSize:'0.8vw',marginLeft:'5px'}}>Permessi sottoaccount: <b>{sott.Permissions===0?'Lettore':sott.Permissions===1?'Scrittore':'Editore'}</b></h4>
            <Tooltip title={sott.Permissions===0?'Il sottoaccount può solamente visualizzare i dati.':sott.Permission===1?'Il sottoaccount può solamente leggere e aggiungere i dati.':'Il sottoaccount può leggere, aggiungere ed eliminare i dati.'}>
            <button className='btnD'><HelpIcon style={{fontSize:'1vw', color:'#5C6672'}}/></button>
            </Tooltip>
           
            </div>
            <Tooltip title='Cambia permessi sottoaccount'>
            <IconButton onClick={()=>{setTriggerPermissions(true);setUserM(sott)}} style={{backgroundColor:'#192bc2',width:'fit-content',height:'fit-content',marginTop:'1vh'}}> 
            <BadgeIcon style={{fontSize:'1vw', color:'white'}}/>
            </IconButton>
            </Tooltip>

                </div>
        )):<h4>Nessun sottoaccount aggiunto</h4>}
        </div>
  )
}

export default SottoaccountS