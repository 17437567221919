//lista pagine manutentore
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import AssignmentLateRoundedIcon from '@mui/icons-material/AssignmentLateRounded';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import SettingsApplicationsRoundedIcon from '@mui/icons-material/SettingsApplicationsRounded';
import BuildIcon from '@mui/icons-material/Build';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
export const MenuItem2 = [
  {
    title: (
      <div  className='box-nav-bar' style={{borderTopLeftRadius:'15px',borderTopRightRadius:'15px'}}>
        <HomeRoundedIcon className='nav-bar-icon' />
        <p className='nav-bar-text'>Dashboard</p>
      </div>
    ),
    url: "/home",
    cName: "nav-links",
  },
  {title:(
    <div className='box-nav-bar'>
      <SecurityRoundedIcon className='nav-bar-icon'/>
      <p className='nav-bar-text'>Elementi</p>
    </div>
  ),
    url:"/elementimanutentore",
    cName:"nav-links"
  },
  {
    title: (
      <div className='box-nav-bar'>
        <ArticleRoundedIcon className='nav-bar-icon' />
        <p className='nav-bar-text'>Documenti</p>
      </div>
    ),
    url: "/documentimanutentore",
    cName: "nav-links",
  },
  {
    title: (
      <div className='box-nav-bar'>
        <AssignmentLateRoundedIcon className='nav-bar-icon'/>
        <p className='nav-bar-text'>Interventi</p>
      </div>
    ),
    url: "/interventimanutentore",
    cName: "nav-links",
  },{
    title: (
      <div className='box-nav-bar'>
        <ScheduleIcon className='nav-bar-icon'/>
        <p className='nav-bar-text'>Scadenze</p>
      </div>
    ),
    url:"/scadenzemanutentore",
    cName:"nav-links"
  }
  ,
  {title:(
    <div className='box-nav-bar'>
      <MapRoundedIcon className='nav-bar-icon'/>
      <p className='nav-bar-text'>Mappe</p>
    </div>
  ),
    url:"/mappemanutentore",
    cName:"nav-links"
  },
  {title:(
    <div className='box-nav-bar'>
      <ConnectWithoutContactIcon className='nav-bar-icon'/>
      <p className='nav-bar-text'>Clienti</p>
    </div>
  ),
    url:"/clienti",
    cName:"nav-links"
  },
  {
    title: (
      <div className='box-nav-bar'>
        <SettingsApplicationsRoundedIcon className='nav-bar-icon'/>
        <p className='nav-bar-text'>Impostazioni</p>
      </div>
    ),
    url:"/impostazioni",
    cName:"nav-links"
  }
];
