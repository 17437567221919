import React, { useState, useEffect } from "react";
import Month from "./Components/Month";
import Week from "./Components/Week";
import Agenda from "./Components/Agenda"; 
import './Style/calendario.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IconButton } from "@mui/material";
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import firebase from "firebase";

function Calendario(props){

    const [visualization,setVisualization] = useState(0) //0 mese //1 settimanale //2 giornaliera //3 agenda
    const [date,setDate] = useState({})

    const [selectedWeek,setSelectedWeek] = useState(0)

    const months = ['Gennaio','Febbraio','Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre','Ottobre','Novembre','Dicembre']

    const [selectedMonth,setSelectedMonth] = useState([]) //mese selezionato

    const dateInput = document.querySelector('#date-input')

    const auth = firebase.auth(); //autentication firebase
    const db = firebase.firestore() //path database

    const [annotazioni,setAnnotazioni] = useState([]) //lista annotazioni


    useEffect(()=>{
            if (props.utente!==null) {
                getDate()
                getAnnotazioni()
            }
    
    },[props.utente])

    function getDate(){
        const data = new Date()
        const day = data.getDate()
        const month = data.getMonth()
        const year = data.getFullYear()
        const name = data.getDay()

        setDate({
            Day: day,
            Month: month,
            Year: year,
            Name: name
        })
        setSelectedMonth(month)

    } //imposta data di oggi
    function change(type){
        var backup = date

        if(type===0){
            if(backup.Month===0){
                backup.Year = backup.Year - 1
                backup.Month = 11
                setDate(backup)
                setSelectedMonth(11)
            }else{
                backup.Month = selectedMonth-1
                setDate(backup)
                setSelectedMonth(selectedMonth-1)
            }
           

        }else{
            if(backup.Month===11){
                backup.Year = backup.Year + 1

                backup.Month = 0
                setDate(backup)
                setSelectedMonth(0)
            }else{
                backup.Month = selectedMonth+1
                setDate(backup)
                setSelectedMonth(selectedMonth+1)
            }
        }
    } //cambio mese

    function goToday(){
        const data = new Date()
        var backup = date
        const nowMonth = data.getMonth()
        backup.Month = nowMonth
        setSelectedMonth(nowMonth)
        setDate(backup)
    } //funzione che manda ad oggi

    //input di data
     dateInput?.addEventListener("keyup", (e)=>{
        //permette solo numeri
        dateInput.value = dateInput.value.replace(/[^0-9/]/g,"")
                //se si preme cancella

        if(e.key === "Backspace"){
            if(dateInput.value.length===3){
                console.log('bella',dateInput.value.slice(0,2).toString())

             dateInput.value = ''
            } 
         }
        //aggiunge lo slash dopo due numeri
        if(dateInput.value.length===2){
            if(parseInt(dateInput.value,10)>12){
                dateInput.value = '12'
            }
            dateInput.value += "/"
        }
      
        //non permette più di 7 caratteri
        if(dateInput.value.length>7){
            dateInput.value = dateInput.value.slice(0,7)
        }
        
        
    })

    function goToDate(){
        var backup = date
        const dateArr = dateInput.value.split('/')

        if(dateArr.length===2){
            if(dateArr[0]>0&&dateArr[0]<13&&dateArr[1].length===4){
            backup.Month = dateArr[0] - 1
            backup.Year = dateArr[1];

            setSelectedMonth(dateArr[0] - 1)
            setDate(backup) 
            }
        }
    }
    function changeMode(i){
        var backup = date
        var w = selectedWeek
        if(i+1===3){
            setVisualization(0)
        }else{
            setVisualization(i+1)
        }
        setDate(backup)
        setSelectedWeek(w)
    }
    function changeWeek(type){
        var backup = date

        if(type===0){
            if(selectedWeek===0){
                if(backup.Month===0){
                    backup.Year = backup.Year - 1
                    backup.Month = 11
                    setDate(backup)
                    setSelectedMonth(11)
                }else{
                    backup.Month = selectedMonth-1
                    setDate(backup)
                    setSelectedMonth(selectedMonth-1)
                }
                setSelectedWeek(4)
            }else{
                setSelectedWeek(selectedWeek-1)
            }

        }else{
            if(selectedWeek===4){
                if(backup.Month===11){
                    backup.Year = backup.Year + 1
    
                    backup.Month = 0
                    setDate(backup)
                    setSelectedMonth(0)
                }else{
                    backup.Month = selectedMonth+1
                    setDate(backup)
                    setSelectedMonth(selectedMonth+1)
                }
                setSelectedWeek(0)
            }else{
                setSelectedWeek(selectedWeek+1)
            }
        }

      
    } //cambio settimana
    function getAnnotazioni(){
        setAnnotazioni([])
       db.collection('Users').doc(props.utente.Email).collection('Annotazioni').orderBy('Year','asc').orderBy('Month','asc').orderBy('Day','asc').get().then((lista)=>{
           //
           if(lista.docs.length>0){
               lista.docs.forEach((ann)=>{
                   if(props.type==='Lavoratore'){
                       if(ann.data().Cliente){
                           db.collection('Users').doc(ann.data().Cliente).get().then((ux)=>{
                               setAnnotazioni((prev)=>{
                                   return [
                                       ...prev,
                                       {
                                           data: ann.data(),
                                           User: ux.data().Nome_Azienda,
                                           id: ann.id
                                       }
                                   ]
                               })
                            })
                       }else{
                           setAnnotazioni((prev)=>{
                               return [
                                   ...prev,
                                   {
                                       data: ann.data(),
                                       User: 'Assente',
                                       id: ann.id
                                   }
                               ]
                           })
                       }
                   }else{
                       if(ann.data().Fornitore){
                           db.collection('Users').doc(ann.data().Fornitore).get().then((ux)=>{
                               setAnnotazioni((prev)=>{
                                   return [
                                       ...prev,
                                       {
                                           data: ann.data(),
                                           User: ux.data().Nome_Azienda,
                                           id: ann.id
                                       }
                                   ]
                               })
                            })
                       }else{
                           setAnnotazioni((prev)=>{
                               return [
                                   ...prev,
                                   {
                                       data: ann.data(),
                                       User: 'Assente',
                                       id: ann.id
                                   }
                               ]
                           })
                       }
                   }
                   
               
               })
           }
           
       })
   } //prende tutte le annotazioni del calendario


    return (
        <div className="calendar-box"  >
        {/*Sezione bottoni sopra*/}
        <div  className="calendar-box-inner">
        <div style={{display:"flex",alignItems:'center',marginLeft:'0.5rem'}}>
                <IconButton onClick={()=>changeMode(visualization)} style={{width:'fit-content',height:'fit-content'}}>
                <ChangeCircleIcon style={{color:'#14213d'}}/>
                </IconButton>
                <h4 className="normal-text" style={{margin:0,marginLeft:5,fontSize:'1rem'}}>{visualization===0?'Mensile':visualization===1?"Settimanale":"Agenda"}</h4>

            </div>
            {visualization===0?<div style={{display:"flex",alignItems:'center',marginLeft:'2rem'}}>
            <IconButton style={{width:'fit-content',height:'fit-content'}} onClick={()=>change(0)}><ArrowBackIcon style={{color:'#14213d'}}/></IconButton>
            <h4 className="normal-text" style={{margin:0, width:'10rem',alignItems:'center',textAlign:'center',fontSize:'1rem'}}>{months[selectedMonth]}, {date.Year}</h4>
            <IconButton style={{width:'fit-content',height:'fit-content'}} onClick={()=>change(1)}><ArrowForwardIcon style={{color:'#14213d'}}/></IconButton>
            </div>:null}
            {visualization===1?<div style={{display:"flex",alignItems:'center',marginLeft:'2rem'}}>
            <IconButton style={{width:'fit-content',height:'fit-content'}} onClick={()=>changeWeek(0)}><ArrowBackIcon/></IconButton>
            <h4 className="normal-text" style={{margin:0, fontSize:'1rem',width:'10rem',alignItems:'center',textAlign:'center'}}>Settimana {selectedWeek+1}, {months[selectedMonth]}, {date.Year}</h4>
            <IconButton style={{width:'fit-content',height:'fit-content'}} onClick={()=>changeWeek(1)}><ArrowForwardIcon/></IconButton>
            </div>:null}
          
           {visualization!==2? <div style={{width:'fit-content',marginLeft:'auto',marginRight:5,border:'1px solid #14213d',borderRadius:5}}>
            <input type='text'  id='date-input' style={{backgroundColor:'transparent',height:'fit-content',width:'6rem',borderRadius:5,border:'none',outline:'none',padding:'0 20px',fontFamily:'Poppins'}} placeholder="mm/yyyy"/>
            <button onClick={()=>goToDate()} className="butt-calendar-top" style={{cursor:'pointer',height:'fit-content',width:'fit-content',color:'#14213d',borderRight:'none',
            borderTop:'none',borderBottom:'none',borderRadius:5,borderLeft:'1px solid #14213d',padding:'5px 10px',backgroundColor:'white',fontFamily:'Poppins',borderTopLeftRadius:0,borderBottomLeftRadius:0}}>Vai</button>
            </div>:null}
            {visualization!==2? <div style={{width:'fit-content',}}>
                <button  className="butt-calendar-top" style={{cursor:'pointer',border:'1px solid #14213d',color:'white',fontFamily:'Poppins', height:'30px',backgroundColor:'#14213d'}} onClick={()=>goToday()}>
                OGGI
                </button>
            </div>:null}
        
        </div>
         {/*Sezione calendario data*/}
         <div className="box-shadow" style={{borderRadius:10}}>
         {visualization===0?<Month setSelectedWeek={setSelectedWeek} annotazioni={annotazioni} type={props.type} setVisualization={setVisualization} date={date}/>:null}
         {visualization===1?<Week  date={date} selectedWeek={selectedWeek} annotazioni={annotazioni} type={props.type} setSelectedWeek={setSelectedWeek}/>:null}
         {visualization===2?<Agenda date={date} annotazioni={annotazioni} type={props.type} db={db}/>:null}
        </div>
        </div>
    )
}

export default Calendario