//sezione documenti elemento
import React, { useState, useEffect } from "react";
import firebase from "firebase";

import DownloadIcon from '@mui/icons-material/Download';
import CancelIcon from '@material-ui/icons/Cancel';
import ArticleIcon from '@mui/icons-material/Article';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
const Documenti = (props) => {

    const db = firebase.firestore(); //database firebase
    const auth = firebase.auth(); //autentication firebase

    const [documenti,setDocumenti] = useState([])

    useEffect(()=>{
      setDocumenti(props.documenti)
    },[props.documenti])

    
    const [addDoc,setAddDoc] = useState(false) //trigger popup aggiungi doc

    //popup aggiungi doc
  
    const AddDoc = (props)=>{
  
      const [cartelle,setCartelle] = useState([]) //lista cartelle NON UTILIZZATA
      const [documentiz,setDocumentiz] = useState([]) //lista documenti
  
      const [selected,setSelected] = useState([]) //documenti selezionati
  
      useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
          
        getDocumenti()
          }
        })
      },[auth]) //triggera funzione getDocumenti
  
      function getDocumenti(){
        db.collection('Users').doc(props.email).collection('Sedi').doc(props.sede)
        .collection("Categorie")
        .doc(props.elemento.Categoria)
        .collection("Cartelle")
        .get().then((cartz)=>{
          cartz.forEach((car)=>{
            setCartelle((prev)=>{
              return [
                ...prev,
                car.data()
              ]
            })
            db.collection('Users').doc(props.email).collection('Sedi').doc(props.sede)
            .collection("Categorie")
            .doc(props.elemento.Categoria)
            .collection("Cartelle")
            .doc(car.id).collection('Documenti').get().then((docs)=>{
              docs.forEach((doczz)=>{
  
                db.collection('Documenti').doc(doczz.data().ref).get().then((docz)=>{
                  if(props.documenti.length>0){
                    var arrz = props.documenti
                    if(arrz.find((element) => {
                      return element.data.NomeFile === docz.data().NomeFile;
                    })){
  
                    }else{
                      setDocumentiz((prev)=>{
                        return [
                          ...prev,
                          
                          {
                            Id: docz.id,
                            ...docz.data(),
                            Cartella: car.id
                            
                          }
                        ]
                      }) 
                    }
                  }else{
                    setDocumentiz((prev)=>{
                      return [
                        ...prev,
                       
                        {
                          Id: docz.id,
                          ...docz.data(),
                          Cartella: car.id

                          
                        }
                      ]
                    })
                    

                  }
                  
                })
              })
            })
          })
        })
      } //funzione che prende documenti
  
      function check(item){
        console.log(item)
        var arr = [...selected]
        var index = arr.findIndex(obj => obj.NomeFile === item.NomeFile)
        console.log(index)
        if(arr.length===0){
          arr.push(item)
          setSelected(arr)
          console.log('yau',arr)
  
        }else if(index!==-1){
          console.log('yaoooo')
          arr.splice(index,1)
          setSelected(arr)
  
        }else{
          arr.push(item)
          setSelected(arr)
  
        }
  
      } //aggiorna le liste dei documenti selezionati
  
      function add(){
        var i = 0
        var arr =[...props.documenti]
        selected.forEach((docz)=>{
          db.collection('Elementi').doc(props.elemento.Id).collection('Documenti').doc().set({
            NomeFile: docz.NomeFile,
            Cartella: docz.Cartella,
            URL: docz.URL,
          }).then(()=>{
            i++
            
            if(i===selected.length){
              props.getDocs()
              
              alert('Aggiunti '+selected.length+ ' elementi')
            }
          })
        })
      } //aggiungi doc annessi
  
      return (
        <div className='pop-opac'>
          <div className="popup"  style={{
              backgroundColor: "white",
              top: "5vh",
              zIndex: "90",
              left: "18vw",
              width: "70vw",
              borderRadius: 10,
              height: "90vh",
            }}>
             
          <div style={{ textAlign: "left", width: "50vw" }}>
              <button
                className="cancelIconButton"
                onClick={() => props.setTrigger(false)}
              >
                <CancelIcon
                  style={{ width: "15px", height: "15px" }}
                  className="cancelIcon"
                />
              </button>
            </div>
            <div style={{display:'flex', marginLeft:'3rem'}}>
            <h4 className="page-title" style={{marginLeft:'1vw',marginBottom:0,marginTop:'1vh'}}>Aggiungi documenti</h4>
          
            </div>
            <div style={{display:'flex',alignItems:'center',marginTop:'1vh'}}>
            <div style={{width:'40vw',maxHeight:'67vh',overflowY:'auto',paddingLeft:10,marginLeft:'3vw'}}>
            <h4  className="sub-text-b">Documenti caricati</h4>
  
            {documentiz.length>0?documentiz.map((d,index)=>(
              <div key={index} style={{display:'flex',alignItems:'center',marginTop:10}}>
                <input onChange={()=>check(d)} type='checkbox'></input>
                <h4 className='body-table2' style={{maxWidth:'40vw'}}>{d.NomeFile}</h4>
              </div>
            )):<h4>Nessun documento caricato, aggiungine dalla pagina "Documenti"</h4>}
  
            </div>
          
            {/*<div style={{width:'30vw',height:'75vh',overflowY:'hidden',paddingLeft:10}}>
            <h4  style={{marginTop:10,fontSize:'1.2vw',color:'#5C6672'}}>Carica nuovi documenti</h4>
            
            </div>*/}
          </div>
          <button
                    onClick={() => selected.length>0?add():alert('Seleziona almeno un documento')}
                    className="blue-button"
                    style={{marginLeft:'3.5rem',marginTop:'1rem'}}
                  >
                    Aggiungi documenti
                  </button>
        </div>
        </div>
      )
    }
    function deleteDoc(id){
      db.collection('Elementi').doc(props.elemento.Id).collection('Documenti').doc(id).delete().then(()=>{
        var arr = [...props.documenti]
        arr.splice(arr.findIndex(obj => obj.id=== id),1)
        setDocumenti(arr)
        props.setDocumenti(arr)
      })
  
    }



    return <div style={{paddingLeft:'1rem',height:'80vh',overflowY:'auto',width:'71vw'}}>
            {addDoc===true?<AddDoc  getDocs={props.getDocs} documenti={documenti} email={props.user.Email} sede={props.elemento.Sede} elemento={props.elemento} type={props.user.Tipo} trigger={addDoc} setTrigger={setAddDoc}/>:null}
      <div>
      {/*informazioni di contatto*/}
        <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
        <ArticleIcon style={{color:'black'}}/>

      <h4 className="header-normal" style={{marginLeft:'5px'}}>Documenti elemento</h4>
      {props.user!==null?props.user.TipoS?props.user.Permissions!==0?<button  onClick={()=>setAddDoc(true)} style={{cursor:'pointer',width:'fit-content',height:'fit-content',border:'none',
                backgroundColor:'transparent',marginTop:'auto',marginBottom:'auto'}}><AddIcon style={{color:'black'}}/></button>:null:<button  onClick={()=>setAddDoc(true)} style={{cursor:'pointer',width:'fit-content',height:'fit-content',border:'none',
                  backgroundColor:'transparent',marginTop:'auto',marginBottom:'auto'}}><AddIcon style={{color:'black'}}/></button>:null}
      </div>
      {documenti.length>0?<div style={{marginTop:'1rem'}}>
        {documenti.map((int,n)=>(
          <div key={n} style={{marginTop:'1rem'}}>
            {console.log(int)}
            <h4 className="header-normal" style={{color:'#192bc2'}}>{int.data.NomeFile}</h4>
            <div style={{display:'flex',alignItems:'center'}}>
            <h4 className="sub-text">Sede: </h4>
            <h4 className="sub-text" style={{marginLeft:'5px', color:'black'}}>{props.elemento.Sede}</h4>
            </div>
            <div style={{display:'flex',alignItems:'center'}}>
            <h4 className="sub-text">Cartella: </h4>
            <h4 className="sub-text" style={{marginLeft:'5px', color:'black'}}>{int.data.Cartella}</h4>
            </div>
            
            <div onClick={()=> window.open(int.data.URL, "_blank")} style={{display:'flex',alignItems:'center',cursor:'pointer',width:'fit-content',marginTop:'10px'}}>
            <h4   className="medium-text" style={{marginLeft:0}}>Visualizza documento</h4>
            <DownloadIcon style={{color:'black',fontSize:'1rem'}}/>
            </div>
            <div onClick={()=> deleteDoc(int.id)} style={{display:'flex',alignItems:'center',cursor:'pointer',width:'fit-content',marginTop:'10px'}}>
            <DeleteIcon style={{color:'#FF0060',fontSize:'1rem'}}/>
            </div>
          </div>
        ))}
      </div>:<h4 style={{marginTop:'1rem'}} className="medium-text">Nessun documento aggiunto...</h4>}

      <div style={{alignItems:'center',marginTop:'5vh',marginLeft:'auto',width:'fit-content'}}>
        </div>
    </div>
    </div>
}

export default Documenti;