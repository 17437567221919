//pagina homepage/dashboard in cui vengono inseriti tutti i componenti
import React, { useState, useEffect} from "react";
import { withRouter } from "react-router";
import "./Style/home.css";
import NavBar from "../NavBar/NavBar";
import NavBar2 from "../NavBar/NavBar2";
import firebase from "firebase";
import CircleLoader from "../CircleLoader.js";
import { ScadBrev } from "./Components/ScadBrev";
import {getScadenzaRecenti, prendiSedi,getScadenzeAnnuali, } from "./Engine/engine";
import { RightMenu } from "./Components/RightMenu";
import Calendario from '../Calendario/Calendario'
import { getCategorie2, getDocumentiGenerale, getElementiOnly, getUtente } from "../EngineClienti/engineClienti.js";
import { getClienti, getScadenzeAnnualiOff,getElementiOff,getInterventiOff,getDocumentiOff } from "../EngineOffritore/engineOffritore.js";
import Loading from '../loading.js'
import Notifiche from "../Notifiche/Notifiche.js";

import { IconButton, Tooltip } from "@mui/material";
const Home = ({ history }) => {
  const [type, setType] = useState(); //var per tipo account
  const [changepass, setChangePass] = useState(false); //var per trigger popup cambio password -- NON IMPLEMENTATO
  const [loading, setLoading] = useState(true); //var per loading iniziale -- NON IMPLEMENTATO
  const db = firebase.firestore(); //database firebase
  const auth = firebase.auth(); //autentication firebase
  const [scadenze,setScadenze] = useState([]) //var per le scadenze

  const [sedi,setSedi] = useState([]) //lista sedi
  const [manutenzioni,setManutenzioni] = useState([]) //lista manutenzioni annuali
  const [manutenzioniM,setManutenzioniM] = useState([]) //lista manutenzioni mensili
  const [manutenzioniF,setManutenzioniF] = useState([]) //lista manutenzioni future quest anno
  const [categorie,setCategorie] = useState([]) //lista categorie
  const [utente,setUtente] = useState(null) //info utente
  const [elementi,setElementi] = useState([]) //lista elementi
  const [documenti,setDocumenti] = useState([]) //lista documenti
  const [clienti,setClienti] = useState([]) //lista clienti
  const [interventi,setInterventi] = useState([]) //lista interventi

  const listaSe = JSON.parse(sessionStorage.getItem('sedi_home'));
  const listaE = JSON.parse(sessionStorage.getItem('elementi'));
  const listaD = JSON.parse(sessionStorage.getItem('documenti_home'))
  const listaC = JSON.parse(sessionStorage.getItem('categorie_home'))
  const listaCli = JSON.parse(sessionStorage.getItem('clienti'))
  const listaI = JSON.parse(sessionStorage.getItem('interventi'));



  useEffect(() => {
    auth.onAuthStateChanged(function (user) {
      if (user) {
        try {
          getUtente(db,auth,setUtente)
        } catch (e) {}
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]); //use effect che prende i dati utente

  useEffect(()=>{
    if(utente!==null){
      var date = new Date();
      setType(utente.Tipo)
      if(utente.Tipo==='Cliente'){
        getScadenzaRecenti(db,utente.Email,setScadenze);
        if(listaSe!==null){
          if(listaSe.length===0){
            prendiSedi(db,utente.Email,utente.Sedi_Create,setSedi)
    
          }else{
            console.log('prendo sedi')
            setSedi(listaSe)
          }
        }else{
          prendiSedi(db,utente.Email,utente.Sedi_Create,setSedi)
        }
        
        getScadenzeAnnuali(db,utente.Email,setManutenzioni,date.getFullYear(),setManutenzioniM,date.getMonth(),date.getDate(),setManutenzioniF)
        if(listaE!==null){
          if(listaE.length===0){
            getElementiOnly(db,utente.Email,setElementi)
    
          }else{
            console.log('prendo ele')
            setElementi(listaE)
          }
        }else{
          getElementiOnly(db,utente.Email,setElementi)
        }
        if(listaD!==null){
          if(listaD.length===0){
            getDocumentiGenerale(db,utente.Email,setDocumenti)
          }else{
            console.log('prendo documenti')
  
            setDocumenti(listaD)
          }
        }else{
          getDocumentiGenerale(db,utente.Email,setDocumenti)
        }
      }else{
        getScadenzaRecenti(db,utente.Email,setScadenze);
        getScadenzeAnnualiOff(db,utente.Email,setManutenzioni,date.getFullYear(),setManutenzioniM,date.getMonth(),date.getDate(),setManutenzioniF)

        if(listaCli!==null){
          if(listaCli.length===0){
            getClienti(db,utente.Email,setClienti)
    
          }else{
            setClienti(listaCli)
          }
        }else{
          getClienti(db,utente.Email,setClienti)
        }
        if(listaE!==null){
          if(listaE.length===0){
            getElementiOff(db,utente.Email,setElementi)
    
          }else{
            setElementi(listaE)
          }
        }else{
          getElementiOff(db,utente.Email,setElementi)
        }
        if(listaI!==null){
          if(listaI.length===0){
            getInterventiOff(db,utente.Email,setInterventi)
    
          }else{
            setInterventi(listaI)
          }
        }else{
          getInterventiOff(db,utente.Email,setInterventi)
        }
        if(listaD!==null){
          if(listaD.length===0){
            getDocumentiOff(db,utente.Email,setDocumenti)
    
          }else{
            setDocumenti(listaD)
          }
        }else{
          getDocumentiOff(db,utente.Email,setDocumenti)
        }
      }
      
      
    }
  },[utente])

  useEffect(()=>{
    if(utente!==null){

    if(sedi.length>0&&utente.Email!==''){
      sessionStorage.setItem('sedi_home', JSON.stringify(sedi));
      if(listaC){
        if(listaC.length>0){
          setCategorie(listaC)
        }else{
        sedi.forEach((sede)=>{
          getCategorie2(db,utente.Email,sede.Id,setCategorie)
  
        })
      }
      }else{
        sedi.forEach((sede)=>{
          getCategorie2(db,utente.Email,sede.Id,setCategorie)
  
        })
      }
      
    }
          
  }
  },[sedi])

  useEffect(()=>{
    if(utente!==null){
      if(utente.Current_Cat){
        if(categorie.length===utente.Current_Cat){
          sessionStorage.setItem('categorie_home', JSON.stringify(categorie));

        }
      }else{
        if(categorie.length>0){
          db.collection('Users').doc(utente.Email).update({
            Current_Cat: categorie.length
          })
        }
       
      }
    }
  },[categorie])

  useEffect(()=>{
    if(utente!==null){

      if(utente.Current_Doc){
        if(documenti.length===utente.Current_Doc){
          sessionStorage.setItem('documenti_home', JSON.stringify(documenti));
        }
      }else{
        db.collection('Users').doc(utente.Email).update({
          Current_Doc: documenti.length
        })
      }

    
  }
  },[documenti])
  useEffect(()=>{
    if(utente!==null){
      if(utente.Current_Ele){
    if(elementi.length===utente.Current_Ele){ 
      sessionStorage.setItem('elementi', JSON.stringify(elementi));
    }
  }else{
    db.collection('Users').doc(utente.Email).update({
      Current_Ele: elementi.length
    })
  }
  }
  },[elementi])
  useEffect(()=>{
    if(utente!==null){

    if(clienti.length===utente.Current_Cli){ 
      sessionStorage.setItem('clienti', JSON.stringify(clienti));

    }
  }
  },[clienti])
  useEffect(()=>{
    if(utente!==null){

    if(interventi.length===utente.Current_Int){ 
      sessionStorage.setItem('interventi', JSON.stringify(interventi));

    }
  }
  },[interventi])

  function getApposto(){
    var s= [...sedi]
    var r = {Apposto:'',QApposto:'',NApposto:''}
    
    
    r.Apposto = s.filter((sx)=>sx.Status===0).length //apposto
    r.QApposto = s.filter((sx)=>sx.Status===1).length // quasi apposto
    r.NApposto = s.filter((sx)=>sx.Status===2).length // non apposto

    return r

  } //ritorna riassunto sedi

  function getScadStatus(i){
    var conc = manutenzioni.filter((o)=>o.Stato==='Conclusa')
    var inatt = manutenzioni.filter((o)=>o.Stato==='Pianificata')
    
    if(i===0){ //concluse positive
      var pos = conc.filter((o)=>o.Esito==='Positivo')
      return pos
    }else if(i===1){ //concluse negative
      var neg = conc.filter((o)=>o.Esito==='Negativo')
      return neg
    }else{ //non svolte
      return inatt
    }
  } //ritorna riassunto scadenze annuali

  function getScadStatusM(i){
    var conc = manutenzioniM.filter((o)=>o.Stato==='Conclusa')
    var inatt = manutenzioniM.filter((o)=>o.Stato==='Pianificata')
    
    if(i===0){ //concluse positive
      var pos = conc.filter((o)=>o.Esito==='Positivo')
      return pos
    }else if(i===1){ //concluse negative
      var neg = conc.filter((o)=>o.Esito==='Negativo')
      return neg
    }else{ //non svolte
      return inatt
    }
  } //ritorna riassunto scadenze mensili
  function getNCategorie(categoria){
    var arr = [...categorie]
    if(categoria==='Antincendio'){
      var list  = arr.filter((e)=>e.Id===categoria)
      return list.length

    }else if(categoria==='Elettrico'){
      var list  = arr.filter((e)=>e.Id===categoria)
      return list.length
    }else{
      var list  = arr.filter((e)=>e.Id!=='Antincendio'&&e.Id!=='Elettrico')
      return list.length
    }
  } //ritorna riassunto tipo di categorie
  function getElementiCat(cat){
    var arr = [...elementi]
    if(cat!=='Antincendio'&&cat!=='Elettrico'){
      var list = arr.filter((e)=>e.Categoria!=='Antincendio'&&e.Categoria!=='Elettrico')

      return list.length
    }else{
      var list = arr.filter((e)=>e.Categoria===cat)
      return list.length
    }

  }
  function getDocumentiCat(cat){
    var arr = [...documenti]
    if(cat!=='Antincendio'&&cat!=='Elettrico'){
      var list = arr.filter((e)=>e.Categoria!=='Antincendio'&&e.Categoria!=='Elettrico')
      return list.length
    }else{
      var list = arr.filter((e)=>e.Categoria===cat)
      return list.length
    }
  }
  function getCliTipo(tipo){
    var arr = [...clienti]
    var list = arr.filter((e)=>e.Plan===tipo)
    return list.length
  }
  function getIntCat(cat){
    var arr = [...interventi]
    if(cat!=='Antincendio'&&cat!=='Elettrico'){
      var list = arr.filter((e)=>e.Categoria!=='Antincendio'&&e.Categoria!=='Elettrico')
      return list.length
    }else{
      var list = arr.filter((e)=>e.Categoria===cat)
      return list.length
    }
  }

  return (
    <div className="background">
      {utente!==null&&utente!==null?<Notifiche user={utente}/>:null}
      {utente!==null&&utente!==undefined?utente.Tipo==='Lavoratore'?<NavBar2/>:<NavBar/>:null}
    <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
      <div className="container-dash">
        <h4 className="page-title" style={{marginTop:'1.2rem'}}>DASHBOARD</h4>
      
      </div>
      <div style={{display:'flex',}}>
        <div>
      <div className="container-dash" style={{}}>
         {/*CALENDARIO*/}
      <div style={{marginTop:'1.5rem',zIndex:1000}}>
      <Calendario utente={utente} type={type}/>
      </div>
       {/*INFO 1*/}
       {utente!==null? <div style={{display:'flex',gap:'3rem',marginTop:'1.5rem'}}>
     {utente.Tipo==='Lavoratore'?<div className="item1">
      <div style={{width:'40%',height:'98%',marginLeft:'auto',marginTop:'auto',marginBottom:'auto',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
      <h4 style={{fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}} className="sub-text">Clienti</h4>
      <h4 className="title-section" style={{}}>{clienti?clienti.length:null}</h4>

      </div>
      <div style={{width:'50%',height:'98%',marginRight:'auto',marginTop:'auto',marginBottom:'auto',display:'flex',flexDirection:'column',justifyContent:'space-evenly'}}>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Accettati'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'green',borderRadius:50}}></div>
      </Tooltip>
      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getCliTipo(2)}</b> Piano PRO XL</h4>
      </div>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Manutenzione portata a termine negativamente'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'red',borderRadius:50,marginBottom:'auto',marginTop:'0.1rem'}}></div>
      </Tooltip>
      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getCliTipo(1)}</b> Piano PRO</h4>
      </div>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='In attesa'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'orange',borderRadius:50,marginBottom:'auto',marginTop:'0.1rem'}}></div>
      </Tooltip>

      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getCliTipo(0)}</b> Piano Standard</h4>
      </div>

      </div>
      </div>:
      <div className="item1">
      <div style={{width:'40%',height:'98%',marginLeft:'auto',marginTop:'auto',marginBottom:'auto',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
      <h4 style={{fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}} className="sub-text">Sedi create</h4>
      <h4 className="title-section" style={{}}>{sedi?sedi.length:null}</h4>

      </div>
      <div style={{width:'50%',height:'98%',marginRight:'auto',marginTop:'auto',marginBottom:'auto',display:'flex',flexDirection:'column',justifyContent:'space-evenly'}}>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Tutti i documenti sono presenti'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'green',borderRadius:50}}></div>
      </Tooltip>
      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getApposto().Apposto}</b> in regola</h4>
      </div>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Documenti essenziali mancanti'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'red',borderRadius:50,marginBottom:'auto',marginTop:'0.1rem'}}></div>
      </Tooltip>
      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getApposto().NApposto}</b> non in regola</h4>
      </div>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Documenti non essenziali mancanti'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'orange',borderRadius:50,marginBottom:'auto',marginTop:'0.1rem'}}></div>
      </Tooltip>

      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getApposto().QApposto}</b> parzialmente in regola</h4>
      </div>

      </div>
      </div>}
      <div className="item1">
      <div style={{width:'40%',height:'98%',marginLeft:'auto',marginTop:'auto',marginBottom:'auto',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
      <h4 style={{fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}} className="sub-text">Scadenze annuali</h4>
      <h4 className="title-section" style={{}}>{manutenzioni?manutenzioni.length:null}</h4>

      </div>
      <div style={{width:'50%',height:'98%',marginRight:'auto',marginTop:'auto',marginBottom:'auto',display:'flex',flexDirection:'column',justifyContent:'space-evenly'}}>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Manutenzione portata a termine positivamente'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'green',borderRadius:50}}></div>
      </Tooltip>
      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getScadStatus(0).length}</b> terminate positive</h4>
      </div>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Manutenzione portata a termine negativamente'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'red',borderRadius:50,marginBottom:'auto',marginTop:'0.1rem'}}></div>
      </Tooltip>
      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getScadStatus(1).length}</b> terminate negative</h4>
      </div>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Manutenzione non ancora portata a termine'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'orange',borderRadius:50,marginBottom:'auto',marginTop:'0.1rem'}}></div>
      </Tooltip>

      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getScadStatus(2).length}</b> pianificate</h4>
      </div>

      </div>
      </div>
      <div className="item1">
      <div style={{width:'40%',height:'98%',marginLeft:'auto',marginTop:'auto',marginBottom:'auto',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
      <h4 style={{fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}} className="sub-text">Scadenze mensili</h4>
      <h4 className="title-section" style={{}}>{manutenzioniM?manutenzioniM.length:null}</h4>

      </div>
      <div style={{width:'50%',height:'98%',marginRight:'auto',marginTop:'auto',marginBottom:'auto',display:'flex',flexDirection:'column',justifyContent:'space-evenly'}}>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Manutenzione portata a termine positivamente'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'green',borderRadius:50}}></div>
      </Tooltip>
      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getScadStatusM(0).length}</b> terminate positive</h4>
      </div>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Manutenzione portata a termine negativamente'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'red',borderRadius:50,marginBottom:'auto',marginTop:'0.1rem'}}></div>
      </Tooltip>
      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getScadStatusM(1).length}</b> terminate negative</h4>
      </div>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Manutenzione non ancora portata a termine'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'orange',borderRadius:50,marginBottom:'auto',marginTop:'0.1rem'}}></div>
      </Tooltip>

      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getScadStatusM(2).length}</b> pianificate</h4>
      </div>

      </div>
      </div>
      </div>:<Loading/>}
      {/*INFO 2*/}
    
      {utente!==null? <div style={{display:'flex',gap:'3rem',marginTop:'1.5rem'}}>
      {utente.Tipo==='Lavoratore'?<div className="item1">
      <div style={{width:'40%',height:'98%',marginLeft:'auto',marginTop:'auto',marginBottom:'auto',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
      <h4 style={{fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}} className="sub-text">Interventi</h4>
      <h4 className="title-section" style={{}}>{interventi?interventi.length:null}</h4>

      </div>
      <div style={{width:'50%',height:'98%',marginRight:'auto',marginTop:'auto',marginBottom:'auto',display:'flex',flexDirection:'column',justifyContent:'space-evenly'}}>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Categorie altro'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'green',borderRadius:50}}></div>
      </Tooltip>
      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getIntCat('Altro')}</b> Altro</h4>
      </div>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Categorie antincendio'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'red',borderRadius:50,marginBottom:'auto',marginTop:'0.1rem'}}></div>
      </Tooltip>
      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getIntCat('Antincendio')}</b> Antincendio</h4>
      </div>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Categorie elettrico'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'orange',borderRadius:50,marginBottom:'auto',marginTop:'0.1rem'}}></div>
      </Tooltip>

      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getIntCat('Elettrico')}</b> Elettrico</h4>
      </div>

      </div>
      </div>:<div className="item1">
      <div style={{width:'40%',height:'98%',marginLeft:'auto',marginTop:'auto',marginBottom:'auto',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
      <h4 style={{fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}} className="sub-text">Categorie create</h4>
      <h4 className="title-section" style={{}}>{categorie?categorie.length:null}</h4>

      </div>
      <div style={{width:'50%',height:'98%',marginRight:'auto',marginTop:'auto',marginBottom:'auto',display:'flex',flexDirection:'column',justifyContent:'space-evenly'}}>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Categorie altro'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'green',borderRadius:50}}></div>
      </Tooltip>
      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getNCategorie('Altro')}</b> Altro</h4>
      </div>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Categorie antincendio'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'red',borderRadius:50,marginBottom:'auto',marginTop:'0.1rem'}}></div>
      </Tooltip>
      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getNCategorie('Antincendio')}</b> Antincendio</h4>
      </div>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Categorie elettrico'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'orange',borderRadius:50,marginBottom:'auto',marginTop:'0.1rem'}}></div>
      </Tooltip>

      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getNCategorie('Elettrico')}</b> Elettrico</h4>
      </div>

      </div>
      </div>}
      <div className="item1">
      <div style={{width:'40%',height:'98%',marginLeft:'auto',marginTop:'auto',marginBottom:'auto',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
      <h4 style={{fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}} className="sub-text">Elementi</h4>
      <h4 className="title-section" style={{}}>{elementi?elementi.length:null}</h4>

      </div>
      <div style={{width:'50%',height:'98%',marginRight:'auto',marginTop:'auto',marginBottom:'auto',display:'flex',flexDirection:'column',justifyContent:'space-evenly'}}>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Elementi tipo altro'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'green',borderRadius:50}}></div>
      </Tooltip>
      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getElementiCat('Altro')}</b> Altro</h4>
      </div>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Elementi antincendio'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'red',borderRadius:50,marginBottom:'auto',marginTop:'0.1rem'}}></div>
      </Tooltip>
      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getElementiCat('Antincendio')}</b> Antincendio</h4>
      </div>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Elementi elettrico'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'orange',borderRadius:50,marginBottom:'auto',marginTop:'0.1rem'}}></div>
      </Tooltip>

      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getElementiCat('Elettrico')}</b> Elettrico</h4>
      </div>

      </div>
      </div>
      <div className="item1">
      <div style={{width:'40%',height:'98%',marginLeft:'auto',marginTop:'auto',marginBottom:'auto',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
      <h4 style={{fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}} className="sub-text">Documenti</h4>
      <h4 className="title-section" style={{}}>{documenti?documenti.length:null}</h4>

      </div>
      <div style={{width:'50%',height:'98%',marginRight:'auto',marginTop:'auto',marginBottom:'auto',display:'flex',flexDirection:'column',justifyContent:'space-evenly'}}>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Documenti altro'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'green',borderRadius:50}}></div>
      </Tooltip>
      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getDocumentiCat('Altro')}</b> Altro</h4>
      </div>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Documenti antincendio'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'red',borderRadius:50,marginBottom:'auto',marginTop:'0.1rem'}}></div>
      </Tooltip>
      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getDocumentiCat('Antincendio')}</b> Antincendio</h4>
      </div>
      <div style={{display:'flex',alignItems:'center'}}>
      <Tooltip title='Documenti elettrico'>
      <div style={{width:'1rem',height:'1rem',backgroundColor:'orange',borderRadius:50,marginBottom:'auto',marginTop:'0.1rem'}}></div>
      </Tooltip>

      <h4 className="sub-text" style={{marginLeft:5,fontSize:window.innerWidth>1440?'0.8rem':'0.7rem'}}><b style={{color:'black'}}>{getDocumentiCat('Elettrico')}</b> Elettrico</h4>
      </div>

      </div>
      </div>
      </div>:<Loading/>}
      </div>
      </div>
      <div style={{marginTop:'1rem'}}>
      <div className="container-dash-c" style={{width:window.innerWidth>1440?'25rem':'18rem',marginRight:'2rem'}}>
      <h4 className="title-section" style={{marginTop:'0rem',padding:'5px'}}>Scadenze a breve ({scadenze.length})</h4>
      <div  style={{height:'49rem',overflowY:'auto',overflowX:'hidden',padding:'5px'}}>
        {scadenze.length>0?scadenze.map((not, index)=>(
          <ScadBrev not={not} index={index}/>
        )): <div
        style={{
          
          borderRadius: 10,
          marginLeft:10
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <h4
            style={{
              fontSize: "1rem",
              color: "black",
              fontWeight: "normal",
              fontFamily: "Poppins",
              marginBottom: 0,
              marginTop: "0.5vw",
              marginLeft: "0.5vw",

              width: "fit-content",
            }}
          >
            Nessuna notifica presente
          </h4>
        </div>
      </div>}
      </div>

    
      </div>
      </div>
      </div>
      
    </div>

      {loading === true ? (
        <CircleLoader />
      ) : (
        <div>
         

         {/* <RightMenu type={type} email={email} auth={auth} db={db} />*/}

          <div
            style={{
              position: "absolute",
              top: "0",
              zIndex: "1",
              width: "90vw",
              height: "100vh",
              overflowY: "scroll",
              marginLeft: "5vw",
              paddingLeft: "3vw",
            }}
          >
    
            
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(Home);
