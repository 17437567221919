//parte sinistra della schermata di login
import back from '../../images/backlog.jpg';
import logo from '../../images/mysic-lungo.png'
export const LeftScreen= () =>{
    return(
        <div style={{height:'100vh', width:'55vw',backgroundColor:'#d6eeff38'}}>
        {/*<img alt='Background Login Page' src={back} style={{height:'350vh',position:'absolute',zIndex:1,width:'55vw',marginTop:'-20vh'}}></img>*/}
    <div style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',zIndex:199,position:'absolute',left:'12.5vw',top:'15vh', width:'30vw', height:'30vw', backgroundColor:'white',borderRadius:20}}>
    <img alt='Logo' src={logo} style={{width:'10vw',marginTop:'1vh',marginLeft:'1vw'}}></img>

    <h4 style={{marginBottom:0,color:'black', marginLeft:'2vw', fontSize:'2.9vw', maxWidth:'28vw',marginTop:'3vh',fontFamily:'Montserrat',}}>‣Sperimenta la tranquillità di</h4>
    <h4 style={{color:'#0496ff', marginLeft:'2vw', fontSize:'3vw', maxWidth:'25vw',fontFamily:'Montserrat',marginTop:0,padding:0,marginBottom:0,}}>essere sicuro.</h4>
    <h4 style={{color:'#5C6672', marginLeft:'2vw', fontSize:'1.5vw', maxWidth:'25vw',fontFamily:'Montserrat',marginTop:'5vh',padding:0,fontWeight:400}}>‣ Un account, la completa gestione della tua sicurezza aziendale.</h4>

  
    </div>
</div>
    )
}