import React from "react";
import { withRouter } from "react-router";
import "./Dashboard/Style/home.css";


const Error = ({ history }) => {


  return (
    <div style={{width: '100%', height: '100vh', textAlign: 'center'}}>
      
      <h2 style={{marginTop: '10%'}}>Error 404</h2>
      <a href="/"style={{marginBottom: 'auto'}}>Try to re-login</a>
      
    </div>
  );
};

export default withRouter(Error);
