//navbar offritore
import React, { Component } from "react";
import { MenuItem2 } from "./MenuItem2"; //lista pagine
import "./style.css";
import firebase from "firebase";
import logo from "../images/mysic-lungo2.png";
import IconButton from '@mui/material/IconButton';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';

async function exit(){
  sessionStorage.clear()

  const auth = firebase.auth();
 await auth.signOut().then(() => {
    window.location.href="/"
  });
} //funzione di uscita

class NavBar2 extends Component {
  state = { clicked: false };
  constructor() {
    super();
    this.state = { name: "" };
  }
  async componentDidMount() {
    if (firebase.auth().currentUser && firebase.auth().currentUser.email) {
      this.setState({ name: await firebase.auth().currentUser.email });
    } else {
      this.setState({ name: "utente" });
    }
  }

  render() {
    return (
      <nav
      style={{  zIndex: '999', position: 'absolute',overflow:'hidden',width:'15vw',height:'100vh' }}
      >
            <a href="/home" >
            <img
              src={logo}
              alt='logo-nav'

              style={{ width:window.innerWidth>1440?"10rem":'6rem',marginLeft:window.innerWidth>1440?'2rem':'1rem',marginTop:'1rem',marginBottom:'1rem'  }}
            />
            </a>

          <div className="NavBarItems" >
          {MenuItem2.map((item, index) => {
              return (
                <div key={index}>
                
  <a
 style={{
    
  textDecoration: "none",
  fontWeight: "normal",
  borderTopLeftRadius:index===0?'15px':0
}}

className={window.location.pathname===item.url?item.cName+'2':item.cName}
href={item.url}
>
  {item.title}
</a>
               
                
                </div>
              );
            })}
            
              <IconButton onClick={exit} style={{position: 'absolute', bottom: '1vh', left: '0.5vw', width: 'fit-content',padding:5,borderRadius:10}}>
                <ExitToAppRoundedIcon/><i className="exit" style={{fontSize: '17px', fontFamily: 'Poppins'}}>Logout</i>
              </IconButton>
          </div>
        
      
      </nav>
    );
  }
}
export default NavBar2;
