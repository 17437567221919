import React from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import HelpIcon from '@mui/icons-material/Help';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Tooltip, keyframes } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import MapIcon from '@mui/icons-material/Map';
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import RemoveIcon from "@mui/icons-material/Remove";
import { checkWriteS,checkDeleteS } from '../../../EngineClienti/engineClienti';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
const Menu = (props) => {

    function returnSottocategorie(cat){
        var arr = [...props.sottocategorie]
        const arr2 = arr.filter((e)=>e.Categoria===cat)
        return arr2
    }

    function returnElementi(cat,sott){
        var arr = [...props.elementi]
        const arr2 = arr.filter((e)=>e.Categoria===cat&&e.Macrocategoria===sott)
        return arr2

    }
    function returnElementiP(cat,sott){
        var arr = [...props.elementiPosizionati]
        const arr2 = arr.filter((e)=>e.Categoria===cat&&e.Macrocategoria===sott)
        return arr2

    }

    function openSott(sott){
        var arr = [...props.sottocategorie]
        const index = arr.findIndex((e)=>e.Nome===sott)
        if( arr[index].Open===true){
            arr[index].Open=false
            props.setSottocategorie(arr)
        }else{
            arr[index].Open=true
            props.setSottocategorie(arr)
        }
        
    }

    const handleOpacity = (event, newValue) => {
        console.log(newValue)
        props.setOpacity(newValue);
      }; //cambia x punto 1

      function changeMode(){
        if(props.mode===0){ //cambia in planimetria
            props.setMode(2) 
        } 
        if(props.mode===2){ //cambia in inserimento
            if(checkWriteS(props.user.Permissions)===true){
                props.setMode(1) 

            }else{
                props.setMode(0)
            }
        }
        if(props.mode===1){ //cambia in visualizzazione
            props.setMode(0) 
        }
       
      }

      function changeVisualize(sott){
        var arr = [...props.sottocategorie]
        const index = arr.findIndex((e)=>e.Nome===sott)
        if( arr[index].Visualize===true){
            arr[index].Visualize=false
            props.setSottocategorie(arr)
        }else{
            arr[index].Visualize=true
            props.setSottocategorie(arr)
        }
      }

  return (
    props.user?<div style={{width:'35%',height:'85vh',border:'1px solid black'}}>
            <div style={{width:'100%',alignItems:'center'}}>
            <div onClick={()=>props.user.Tipo==='Lavoratore'?changeMode():null} style={{cursor:'pointer',position:'relative',width:'5vw',height:'5vw',borderRadius:50,backgroundColor:'white',border:'1px solid #14213d',marginLeft:'auto',marginRight:'auto',marginTop:'-8vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
            {props.mode===0?<VisibilityIcon style={{fontSize:'2vw',color:'#14213d'}}/>:props.mode===2?<MapIcon style={{fontSize:'2vw',color:'#192bc2'}}/>:<EditIcon style={{fontSize:'2vw',color:'#192bc2'}}/>}
            {props.user.Tipo==='Lavoratore'?<ChangeCircleIcon style={{position:'absolute',bottom:'-1.5vh',color:'black',backgroundColor:'white',borderRadius:50}}/>:null}
            </div>
            <div style={{alignItems:'center',display:'flex',flexDirection:'column'}}>
            <div style={{display:'flex',alignItems:'center'}}>
            <h4 className='normal-cool' style={{width:'fit-content',marginLeft:'auto',}}>Modalità: <b>{props.mode===0?'Visualizzazione':props.mode===2?'Planimetria':'Modifica'}  {props.mode===1?props.editMode===0?'/ Inserimento':'/ Eliminazione':null}</b></h4>
            <Tooltip title={props.mode===0?'La modalità Visualizzazione ti permette di visualizzare gli elementi posizionati e le loro info.':props.mode===2?'La modalità Planimetria ti permette di visualizzare e modificare alcuni aspetti della planimetria.':'La modalità Modifica ti permette di aggiungere, rimuovere e spostare elementi già posizionati.'}>
            <HelpIcon style={{marginRight:'auto',color:'#677483',fontSize:'1vw'}}/>
            </Tooltip>
            </div>
            {props.mode===1?<div style={{display:'flex',alignItems:'center',marginTop:'-10px'}}>
            {checkWriteS(props.user.Permissions)===true?<Tooltip title='La modalità inserimento ti permette di inserire nuovi elementi e spostare quelli già posizionati.'>
            <IconButton onClick={()=>props.setEditMode(0)} style={{width:'fit-content',backgroundColor:props.editMode===0?'lightskyblue':'lightgrey'}}>
            <AddIcon style={{color:props.editMode===0?'#192bc2':null}}/>
            </IconButton>
            </Tooltip>:null}
            {checkDeleteS(props.user.Permissions)===true?<Tooltip title='La modalità eliminazione ti permette di rimuovere gli elementi già posizionati nella mappa.'>
            <IconButton onClick={()=>props.setEditMode(1)}  style={{marginLeft:'5px',width:'fit-content',backgroundColor:props.editMode===1?'coral':'lightgrey'}}>
            <DeleteIcon style={{color:props.editMode===1?'red':null}}/>
            </IconButton>
            </Tooltip>:null}
         
            </div>:null}

            </div>
            
            </div>
            {props.mode===1?<div style={{width:'100%',height:'72.5vh',overflowY:'auto',paddingLeft:'1vw'}}>
                {props.selElement?
            props.categorie.map((cat,i)=>(
                <div style={{width:'100%',marginTop:i===0?0:'1.5vh'}} key={i}>
                    <h4 className='bold-cool' style={{margin:0,color:props.editMode===0?props.selElement.Categoria?props.selElement.Categoria===cat.Nome:null?'#192bc2':'#14213d':'#14213d',fontSize:'1.5vw'}}>{cat.Nome}</h4>
                    {returnSottocategorie(cat.Nome).map((sott,k)=>(
                    <div key={k} style={{cursor:'pointer',width:'25vw',backgroundColor:'white',minHeight:'5vh',borderRadius:10,border:'1px solid #14213d',marginTop:'1vh'}}>
                        <div onClick={()=>openSott(sott.Nome)}  style={{display:'flex',alignItems:'center',height:'5vh'}}>
                        <h4  className='bold-cool' style={{marginLeft:'0.5vw',color:props.editMode===0?props.selElement.Macrocategoria===sott.Nome?'#192bc2':'black':'black'}}>{sott.Nome} ({returnElementi(cat.Nome,sott.Nome).length})</h4>
                        {sott.Open===false?<KeyboardArrowDownIcon  style={{marginLeft:'auto',marginRight:'10px'}}/>:<KeyboardArrowUpIcon  style={{marginLeft:'auto',marginRight:'10px'}}/>}
                        </div>
                        {sott.Open===true?returnElementi(cat.Nome,sott.Nome).map((ele,n)=>(
                            <div onClick={()=>props.setSelElement(ele)} style={{marginLeft:'10px',marginTop:'5px'}} key={n}>
                                <h4 style={{margin:0,fontSize:'0.8vw',color:props.selElement.Nome===ele.Nome?'#192bc2':'black',fontWeight:props.selElement.Nome===ele.Nome?'bold':'normal'}}>{ele.Nome}</h4>
                            </div>
                        )):null}
                        {sott.Open===true?<div style={{height:'1vh'}}></div>:null}
                    </div>))}
                </div>
            )):null}
            </div>:null}
            {props.mode===0?<div style={{width:'100%',height:'72.5vh',overflowY:'auto',paddingLeft:'1vw'}}>
                {
            props.categorie.map((cat,i)=>(
                returnSottocategorie(cat.Nome).length>0?<div style={{width:'100%',marginTop:i===0?0:'1.5vh'}} key={i}>
                    <h4 className='bold-cool' style={{margin:0,color:'#14213d',fontSize:'1.5vw'}}>{cat.Nome}</h4>
                    {returnSottocategorie(cat.Nome).map((sott,k)=>(
                    returnElementiP(cat.Nome,sott.Nome).length>0?<div key={k} style={{display:'flex',flexDirection:'row',alignItems:'flex-start',cursor:'pointer',width:'25vw',backgroundColor:'white',minHeight:'5vh',borderRadius:10,border:'1px solid #14213d',marginTop:'1vh'}}>
                        <button className='btnD' onClick={()=>changeVisualize(sott.Nome)} style={{marginTop:'5px',cursor:'pointer'}}>{sott.Visualize===true?<VisibilityIcon style={{color:'#192bc2'}}/>:<VisibilityOffIcon style={{color:'#ef476f'}}/>}</button>
                       <div>
                        <div onClick={()=>openSott(sott.Nome)}  style={{display:'flex',alignItems:'center',height:'5vh'}}>
                        <h4  className='bold-cool' style={{marginLeft:'0.5vw',color:'black'}}>{sott.Nome} ({returnElementiP(cat.Nome,sott.Nome).length})</h4>
                        {sott.Open===false?<KeyboardArrowDownIcon  style={{marginLeft:'auto',marginRight:'10px'}}/>:<KeyboardArrowUpIcon  style={{marginLeft:'auto',marginRight:'10px'}}/>}
                        </div>
                        {sott.Open===true?returnElementiP(cat.Nome,sott.Nome).map((ele,n)=>(
                            <div onClick={()=>props.setCenter({ lat: ele.Posizione.Lat, lng: ele.Posizione.Lng },props.setZoom(25))
                        } style={{marginLeft:'10px',marginTop:'5px'}} key={n}>
                                <h4 style={{margin:0,fontSize:'0.8vw',color:'black',fontWeight:'normal'}}>{ele.Nome}</h4>
                            </div>
                        )):null}
                        {sott.Open===true?<div style={{height:'1vh'}}></div>:null}
                        </div>
                    </div>:null))}
                </div>:null
            ))}
            </div>:null}
            {props.mode===2?<div style={{width:'100%',height:'72.5vh',overflowY:'auto',paddingLeft:'1vw'}}>
            <div className='box-shadow'  style={{border:'1px solid #192bc2',padding:5,borderRadius:10,marginTop:'1vh',width:'90%'}}>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1, padding: 2,width:'75%' }}
              alignItems="center"
            >
                <h4 className='normal-text' style={{color: '#192bc2',fontWeight:'bold'}}>OPACITÀ</h4>

              <RemoveIcon style={{ marginLeft: "2vw" }} />
              <Slider
                min={0}
                max={100}
                step={1}
                aria-label="Volume"
                defaultValue={1}
                style={{ width: "12vw" }}
                value={props.opacity}
                onChange={handleOpacity}
              />
              <AddIcon />
            </Stack>
     
            </div>
            <h4 className='normal-text' style={{color: '#677483',fontWeight:'bold',marginTop:'2vh'}}>Info planimetria:</h4>
            <div style={{marginTop:'1vh'}}>
            <h4 className='cool-normal' style={{margin:0}}>{props.mappa.Nome}</h4>
            <h4 className='sub-text' style={{margin:0}}>Nome</h4>
            </div>
            <div style={{marginTop:'1vh'}}>
            <h4 className='cool-normal' style={{margin:0}}>{props.mappa.Sede}</h4>
            <h4 className='sub-text' style={{margin:0}}>Sede</h4>
            </div>
            <div style={{marginTop:'1vh'}}>
            <h4 className='cool-normal' style={{margin:0}}>{props.mappa.Creazione}</h4>
            <h4 className='sub-text' style={{margin:0}}>Data di creazione</h4>
            </div>
            <div style={{marginTop:'1vh'}}>
            <h4 className='cool-normal' style={{margin:0}}>{props.mappa.UltimaModifica}</h4>
            <h4 className='sub-text' style={{margin:0}}>Ultima modifica</h4>
            </div>
            </div>:null}
        </div>:null
  )
}

export default Menu