//sezione account
import React, { useState, useEffect } from "react";
import firebase from "firebase";
import { formatData } from "../../EngineClienti/engineClienti";
import AssignmentLateRoundedIcon from '@mui/icons-material/AssignmentLateRounded';
import GenerazionePdf from "../../GenerazionePdf";
import DownloadIcon from '@mui/icons-material/Download';
const Interventi = (props) => {

    const db = firebase.firestore(); //database firebase
    const auth = firebase.auth(); //autentication firebase

    const [interventi,setInterventi] = useState([])

    useEffect(()=>{
      setInterventi(props.interventi)
    },[props.interventi])

    function handleTitoloClick(intz) {
      var elemento = intz;
      elemento.Nome_AziendaCli = elemento.Nome_Azienda
      elemento.Sede_cli = elemento.Sede
      console.log("ya:",elemento)
      db.collection("Users")
        .doc(elemento.Op_Email)
        .get()
        .then((operatoreDoc) => {
          var operatoreData = operatoreDoc.data();
          var operatore = {
            Nome_Azienda: operatoreData.Nome_Azienda,
            Nome: operatoreData.Nome,
            Cognome: operatoreData.Cognome,
            Indirizzo: operatoreData.Indirizzo,
            Sede: operatoreData.Comune,
            Email: operatoreData.Email,
            Telefono: operatoreData.Numero,
          };
  
          GenerazionePdf.generaPdfIntervento(elemento, operatore, [
            { Nome: props.elemento.Nome, Esito: elemento.Esito },
          ]);
        });
    } //funzione che parte quando scarichi un intervento/manutenzione



    return <div style={{paddingLeft:'1rem',height:'80vh',overflowY:'auto',width:'71vw'}}>
      <div>
      {/*informazioni di contatto*/}
        <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
        <AssignmentLateRoundedIcon style={{color:'black'}}/>

      <h4 className="header-normal" style={{marginLeft:'5px'}}>Interventi elemento</h4>
      </div>
      {interventi.length>0?<div style={{marginTop:'1rem'}}>
        {interventi.map((int,n)=>(
          <div key={n} style={{marginTop:'1rem'}}>
            <h4 className="header-normal" style={{color:'#192bc2'}}>{int.Titolo}</h4>
            <div style={{display:'flex',alignItems:'center'}}>
            <h4 className="sub-text">Stato: </h4>
            <h4 className="sub-text" style={{marginLeft:'5px', color:'black'}}>{int.Stato}</h4>
            </div>
            <div style={{display:'flex',alignItems:'center'}}>
            <h4 className="sub-text">Esito: </h4>
            <h4 className="sub-text" style={{marginLeft:'5px', color:'black'}}>{int.Esito?int.Esito:'-'}</h4>
            </div>
            <div style={{display:'flex',alignItems:'center'}}>
            <h4 className="sub-text">Data di intervento: </h4>
            <h4 className="sub-text" style={{marginLeft:'5px', color:'black'}}>{int.Data?formatData(int.Data):'-'}</h4>
            </div>
            <div onClick={()=>handleTitoloClick(int)} style={{display:'flex',alignItems:'center',cursor:'pointer',width:'fit-content',marginTop:'10px'}}>
            <h4  className="medium-text" style={{marginLeft:0}}>Scarica PDF</h4>
            <DownloadIcon style={{color:'black',fontSize:'1rem'}}/>
            </div>
          </div>
        ))}
      </div>:<h4 style={{marginTop:'1rem'}} className="medium-text">Nessun intervento aggiunto...</h4>}

      <div style={{alignItems:'center',marginTop:'5vh',marginLeft:'auto',width:'fit-content'}}>
        </div>
    </div>
    </div>
}

export default Interventi;