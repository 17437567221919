//step inserimento email per verifica off inserito da parte del cli
import React, { useState } from "react";

export function CustomerStep(props){

    const [verificaz, setVerifica] = useState(); //var per verifica di email offritore
    const [started,setStarted] = useState(false)
    const [offritori,setOffritori] = useState([]) //lista off cercati
 //funzione di verifica per email dell' offritore
    function cerca() {
        setOffritori([])
        setStarted(true)
        const nomelavv = props.emaillav;
        console.log(nomelavv);
        var verify = false;
        try {
          if (nomelavv) {
            props.db.collection("Users")
              .get()
              .then((users) => {
                users.forEach((user) => {
                  if(user.data().Nome_Azienda){
                    if (user.data().Nome_Azienda.toLowerCase().includes(nomelavv.toLowerCase())) {
                      if (user.data().Tipo === "Lavoratore") {
                        console.log(user.data().Email);
                        setOffritori((prev)=>{
                          return [
                            ...prev,
                            {
                              Id: user.id,
                              ...user.data()
                            }
                          ]
                        })
                        verify = true;
                      }
                    }
                  }
                
                });
              });
          } else {
            alert('Inserisci la ragione sociale per cercare il manutentore.')
          }
        } catch (e) {}
      } 

      function selectLav(lav){
        props.setLav(lav)
      }


    return(
        <div
          style={{
            marginTop: "5vh",
            width: "50vw",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div
            style={{
              width: "fit-content",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <h4
                        className='titleReg'

            >
              Manutentore (facoltativo)
            </h4>
            <h4
                        style={{marginLeft:'auto',fontWeight:'normal',color:'grey',fontSize:'0.7vw',fontFamily:'Montserrat',marginTop:0,marginBottom:'1vh',marginRight:'auto',width:'fit-content'}}

            >
             {props.lav===null?'Cerca il tuo manutentore e aggiungilo al tuo account Mysic da subito!':'Manutentore selezionato:'}
            </h4>

            {props.lav===null?<div >
            <h4
                       className='headerInput'

            >
              Inserisci Ragione Sociale Manutentore
            </h4>

            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "fit-content",
                marginTop: "1vh",
              }}
            >
              <input
                            className='inputReg'

                type="text"
                onChange={(e) => props.setEmailLav(e.target.value)}
                value={props.emaillav}
                placeholder="Inserisci..."
              />
              <h4 href="#" style={{
                cursor:'pointer',
                fontSize: "1vw",
                color: "#0496ff",
                fontWeight: "400",
                fontFamily: "Montserrat",
                marginBottom: "1vh",
                width: "fit-content",
                marginLeft: "auto",
                marginTop:'1vh',
                borderBottom:'1px solid #0496ff'
              }} onClick={() => cerca()}>
                Cerca
              </h4>
            
             
            </div>
            {started===true?
            offritori.length>0?<div style={{marginTop:'2vh',width:'20vw',height:'15vh',overflowY:'auto',border:'1px solid lightgrey',borderRadius:'10px',padding:'5px',marginLeft:'auto',marginRight:'auto'}}>
                {offritori.map((off,index)=>(
                  <div key={index} onClick={()=>selectLav(off)} style={{borderBottom:'1px solid lightgrey',cursor:'pointer',paddingBottom:'10px'}}>
                    <h4 className='bold-cool' style={{margin:0}}>{off.Nome_Azienda}</h4>
                    <h4 className="sub-text">{off.Email}</h4>
                    </div>
                ))}
              </div>:<h4 className="bold-cool" style={{marginLeft:'auto',marginRight:'auto',width:'fit-content'}}>Nessun manutentore trovato</h4>:null}
              </div>:<div style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "fit-content",
                marginTop: "1vh",
              }}>
                <h4 className="header-normal" style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',marginBottom:'0.5vh'}}>Ragione Sociale:</h4>
                <h4 className="normal-cool" style={{margin:0,width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>{props.lav.Nome_Azienda}</h4>
                <h4 className="header-normal" style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',marginBottom:'0.5vh',marginTop:'1vh'}}>Email:</h4>
                <h4 className="normal-cool" style={{margin:0,width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>{props.lav.CurrentEmail}</h4>
                <div style={{marginTop:'1.5vh',width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>
                <button className="main-button" onClick={()=>{props.setLav(null);props.setEmailLav("");setOffritori([]);setStarted(false)}}>Riseleziona</button>
                </div>
                </div>}
            <div
              style={{
                width: "fit-content",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "3vh",
              }}
            >
              <button
className="nextButton"
                onClick={() => props.setSection(4)}
              >
                Avanti
              </button>
            </div>
            <div
              style={{
                width: "fit-content",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "1vh",
              }}
            >
              <button
className="backButton"
                onClick={() => props.setSection(2)}
              >
                Indietro
              </button>
            </div>
          </div>
        </div>
    )
}