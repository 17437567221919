//sezione account
import React from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BuildIcon from '@mui/icons-material/Build';

const Cliente = (props) => {

    return <div style={{paddingLeft:'1rem',height:'80vh',overflowY:'auto',width:'71vw'}}>
      {props.cliente!==null?<div>
        <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
        <AccountCircleIcon style={{color:'black'}}/>

      <h4 className="header-normal" style={{marginLeft:'5px'}}>Anagrafica Cliente</h4>
      </div>
      {/*rag soc*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{props.cliente.Nome_Azienda}</h4>
    <h4 className="sub-text">Ragione Sociale</h4>
        </div>
      </div>
      {/*nome*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{props.cliente.Nome}</h4>
    <h4 className="sub-text">Nome</h4>
        </div>
      </div>
            {/*cognome*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{props.cliente.Cognome}</h4>
    <h4 className="sub-text">Cognome</h4>
        </div>
      </div>
                  {/*email*/}
                  <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{props.cliente.Email}</h4>
    <h4 className="sub-text">Email</h4>
        </div>
      </div>
      {/*telefono*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{props.cliente.Telefono?props.cliente.Telefono:props.cliente.Numero}</h4>
    <h4 className="sub-text">Telefono</h4>
        </div>
      </div>
      </div>:null}
      <div style={{alignItems:'center',marginTop:'5vh',marginLeft:'auto',width:'fit-content'}}>
        </div>
    </div>
}

export default Cliente;