import firebase from "firebase/app";
const authz = firebase.auth();

export async function customerReg(app,auth,email,password,db,nome,cognome,nomeAz,indirizzo,luogo,telefono,selectedOptionAz,emailjs,toSend,history,emaillav,plan,codeInfo,codeConfirm,lav){
        //se azienda cliente
  
        await app
          .auth()
          .createUserWithEmailAndPassword(email, password); //registra l'utente

        db.collection('UsersId').doc(auth.currentUser.uid).set({
          Email: email,
          CurrentEmail: email
        }).then(()=>{
          db.collection("Users") //aggiunge utente sul db
          .doc(email)
          .set({
            Id: auth.currentUser.uid,
            Nome: nome,
            Cognome: cognome,
            Nome_Azienda: nomeAz,
            Indirizzo: indirizzo,
            Email: email,
            Password: password,
            Numero: telefono,
            Comune: luogo,
            Tipo: selectedOptionAz,
            MemoryUsed: 0,
            Fornitore: emaillav,
            Sedi_Create: 1,
            Plan: plan,
            Max_Sott: plan===0?1:plan===1?5:10,
            Max_Ele: plan===0?70:plan===1?200:1000,
            Max_MB: plan===0?100:plan===1?1024:5125,
            Current_Sott: 0,
            Current_Ele: 0,
            Current_MB:0,
            Current_Int: 0,
            Current_Scad: 0

          })
          .then(() => { 
            db.collection("Users") //crea i tipi di sottoaccount con i permessi : EDITORE
              .doc(email)
              .collection("MacroGruppi")
              .doc("Editore")
              .set({
                Lettura: true,
                Scrittura: true,
                Eliminazione: true,
              });
            db.collection("Users") //crea i tipi di sottoaccount con i permessi : LETTORE
              .doc(email)
              .collection("MacroGruppi")
              .doc("Lettore")
              .set({
                Lettura: true,
                Scrittura: false,
                Eliminazione: false,
              });
            db.collection("Users") //crea i tipi di sottoaccount con i permessi : SCRITTORE
              .doc(email)
              .collection("MacroGruppi")
              .doc("Scrittore")
              .set({
                Lettura: true,
                Scrittura: false,
                Eliminazione: false,
              });

            db.collection("Users") //aggiunge la sede 
              .doc(email)
              .collection("Sedi")
              .doc(luogo)
              .set({
                Indirizzo: indirizzo,
                Comune: luogo,
                Numero: telefono,
                RelazioneTecnica: false,
                Planimetrie: false,
                Schemi: false,
                Conf: false,
                Tag: "",
                MaterialiUtilizzati: false,
                    VisuraCamerale: false,
                    Manuale: false,
                    PrimaVerifica: false,

                    OmologazioneImpianto: false,
                    Index: 999,
                    Doc: 0,
                    Ele:0,
                    Int:0,
                    Man:0,
              })
              .then(() => {
                db.collection("Users") //aggiunge la categoria generale alla sede
                  .doc(email)
                  .collection("Sedi")
                  .doc(luogo).collection('Categorie')
                  .doc('Generale')
                  .set({
                    Nome: 'Generale',
                    Sede: luogo,
                    Doc:0,
                    Ele:0,
                    
                  })
                  .then(() => {
                    if(codeConfirm===true){
                      db.collection('Codes').doc(codeInfo.Id).update({
                        Riscattato:true
                      })
                    } 
                    if(lav!==null){
                                    db.collection("Users") //invia richiesta ad off
                                  .doc(email)
                                  .collection("Offritori")
                                  .doc(lav.Email)
                                  .set({
                                    Email: lav.Email,
                                    Stato: 'pending'
                                  })
                                  .then(() => {
                                    
                                      db.collection("Users")
                                      .doc(lav.Email)
                                      .collection("Richieste")
                                      .doc(email)
                                      .set({
                                        Nome_Azienda: nomeAz,
                                        email: email
                                      })
                                      .then(()=>{
                                          authz.currentUser.sendEmailVerification().then(()=>{
                                            alert(
                                              "Registrazione completata! Vi sarà mandata una mail di conferma."
                                            )
                                            history.push("/login"); //rimanda alla pagina di login
                                          })
                                        
                                      })
                                    
                                  })
                                }else{
                                  authz.currentUser.sendEmailVerification().then(()=>{
                                    alert(
                                      "Registrazione completata! Vi sarà mandata una mail di conferma."
                                    )
                                    history.push("/login"); //rimanda alla pagina di login
                                  })
                                }  
                                  });
                                })
                              })
        }) 

      
            
           
          }