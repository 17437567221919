
export function getImage(db,auth,setImage){
  
    db.collection('UsersId').doc(auth.currentUser.uid).get().then((usx)=>{
        db.collection('Users').doc(usx.data().Email).get().then((us)=>{
            if(us.data().Tipo==='SottoAccount'){
                db.collection('UsersId').doc(us.data().MasterAccount).get().then((sottuss)=>{
                    console.log(sottuss.data())
                    db.collection('Users').doc(sottuss.data().Email).get().then((sottus)=>{
                        if(sottus.data().Photo){
                            setImage(sottus.data().Photo)

                        }else{
                            setImage(null)
                        }
                    })
                })
            }else{
                if(us.data().Photo){
                    setImage(us.data().Photo)

                }else{
                    setImage(null)
                }
            }
       
    })
})
}