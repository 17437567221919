import React, {useState, useRef} from 'react'
import Places from '../../../Places'
import { GoogleMap, useLoadScript, Marker, OverlayView } from "@react-google-maps/api";
import CheckIcon from '@mui/icons-material/Check';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import firebase from "firebase/app";

const Sezione3 = (props) => {

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBy4500tb8vImwBqAyfom60eOXjoW1fQfI&libraries=places",
    
      }); //chiave api per la mappa

      const [center,setCenter] = useState({ lat:0, lng:0})
      const [index, setIndex] = useState(0); //numero click per il posizionamento
      const [firstBound, setFirstBound] = useState(null); //prima lat lng
      const [secondBound, setSecondBound] = useState(null); //seconda lat lng
      const [boundss, setBoundss] = useState(); //lat lng dei due punti click planimetria
      const [rotation, setRotation] = useState(0); //rotazione planimetria
      const [transelateSxPerc, setTranselateSxPerc] = useState(0); //traslazione a sx
      const [transelateTopPerc, setTranselateTopPerc] = useState(0); //traslazione top
      const [mapImageZoom, setMapImageZoom] = useState(100);
      const refContainer = useRef();
      const [opacity, setOpacity] = useState(0.7); //opacità planimetria
      const [markers, setMarkers] = useState([]);

      const [text,setText] = useState(0)
      const mapRef = useRef()

      const storage = firebase.storage();
      var storageRef = storage.ref();

      function Map() {

        return !isLoaded ? (
          <h4>caricamento</h4>
        ) : (
          <div
            style={{
              position: "relative",
              zIndex: 5,
              maxWidth: "100%",
              marginLeft: "-2.5rem",
            }}
          >
            <GoogleMap
              onClick={handleClick}
              zoom={20}
              center={center}
              cli
              ref={mapRef}
              style={{ width: "100%" }}
              mapContainerStyle={{ width: "30vw",height:'30vw',border:'1px solid black' }}
              mapContainerClassName="map-container"
              options={{ minZoom: 10, maxZoom: 25 }}
            >
              {index >=2 && boundss && (
                <OverlayView
                  mapPaneName="overlayLayer"
                  ref={refContainer}
                  id="supercontainer"
                  position={new window.google.maps.LatLngBounds(
                    new window.google.maps.LatLng(firstBound.lat, firstBound.lng),
                    new window.google.maps.LatLng(secondBound.lat, secondBound.lng)
                  ).getCenter()}
                  bounds={boundss}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid red",
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        left: "50%",
                        top: "50%",
                      }}
                    >
                      {index >= 3 ? (
                        <img
                          src={props.planimetria}
                          alt='plan'
                          style={{
                            width: "100%",
                            opacity: opacity,
                            transformOrigin:
                              transelateSxPerc + "% " + transelateTopPerc + "%",
                            transform:
                              "translate(-" +
                              transelateSxPerc +
                              "%, -" +
                              transelateTopPerc +
                              "%) rotate(" +
                              rotation +
                              "deg) scale(" +
                              mapImageZoom +
                              ")",
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                </OverlayView>
              )}
    {markers.map(marker => (
          <Marker key={marker.key} position={new window.google.maps.LatLng(marker.lat,marker.lng)} />
        ))}
              
            </GoogleMap>
          </div>
        );
      }

      function submitPlanimetria(){
        
        const ref = props.db.collection('Users').doc(props.user_id).collection('Sedi').doc(props.sede_id).collection('Piani').doc(props.piano_id).collection('Mappe').doc()

        ref.set({
          ref: ref.id
        })
        //caricamento planimetria
        const uploadTask = storageRef.child(
          props.user_id+"/Mappe/"+props.sede_id+"/"
          +props.piano_id+"/"+ref.id+".jpg"
        ).put(props.file); //percorso del file su storage
        uploadTask.on('state_changed', 
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      
          
        }, 
        (error) => {
          // Handle unsuccessful uploads
        }, 
        // eslint-disable-next-line no-loop-func
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            props.db.collection('Mappe').doc(ref.id).set({
              Creazione:new Date().toLocaleDateString("en-GB"),
              UltimaModifica:new Date().toLocaleDateString("en-GB"),
              ID: ref.id,
              N_Elementi:0,
              Nome: 'Mappa Default',
              Descrizione: '',
              Planimetria: downloadURL,
              Sede: props.sede_id, 
              TranslateSx: transelateSxPerc,
              TranslateTop: transelateTopPerc,
              Rotation:rotation,
              Zoom: mapImageZoom,
              FirstBound: {Lat: firstBound.lat,Lng:firstBound.lng},
              SecondBound: {Lat: secondBound.lat,Lng:secondBound.lng}

            }).then(()=>{
              props.db.collection('Users').doc(props.user_id).collection('Sedi').doc(props.sede_id).collection('Piani').doc(props.piano_id).update({
                N_Mappe: firebase.firestore.FieldValue.increment(1)
              }).then(()=>{
                props.setMapRef(ref.id)
                props.setSection(3)
              })
            })
          })})

      }

      function handleClick(e) {
        console.log(index)
        if (index === 0) {
          setFirstBound({ lat: e.latLng.lat(), lng: e.latLng.lng() });
          setIndex(index + 1);
          setText(1)
          setCenter({ lat: e.latLng.lat(), lng: e.latLng.lng() })
          const newMarker = { lat: e.latLng.lat(), lng: e.latLng.lng(), key: Date.now() };
        setMarkers([...markers, newMarker]);

        } else if (index === 1) {
          setSecondBound({ lat: e.latLng.lat(), lng: e.latLng.lng() });
          setIndex(index + 1);
          setBoundss(
            new window.google.maps.LatLngBounds(
              new window.google.maps.LatLng(firstBound.lat, firstBound.lng),
              new window.google.maps.LatLng(e.latLng.lat(), e.latLng.lng())
            )
          );
        
          setCenter(
            new window.google.maps.LatLngBounds(
              new window.google.maps.LatLng(firstBound.lat, firstBound.lng),
              new window.google.maps.LatLng(e.latLng.lat(), e.latLng.lng())
            ).getCenter()
          );
          const newMarker = { lat: e.latLng.lat(), lng: e.latLng.lng(), key: Date.now() };
          setMarkers([...markers, newMarker]);

          setText(2)
        } else {

    
          setRotation(calculateDiffGradi());
          console.log("Diff Gradi: " + calculateDiffGradi());
    
          setMapImageZoom(calculateZoomFoto());
          console.log("Zoom: " + calculateZoomFoto());
    
          setTranselateSxPerc(calculateTranselateSxPerc());
          console.log("TranselateSx: " + calculateTranselateSxPerc());
    
          setTranselateTopPerc(calculateTranselateTopPerc());
          console.log("TransleateTop: " + calculateTranselateTopPerc());
          setText(3)
          setIndex(index + 1);

        }
        console.log(index)

      } //gestisce i click sulla mappa
    
      function calculateAngoloMappa() {
        //calcolo angolo mappa
    
        var d_ab_gps_y_m = (secondBound.lat - firstBound.lat) * 111121;
        var h_m = 6637000 * (1 - Math.sin((firstBound.lat * Math.PI) / 180));
        var r_m = Math.sqrt(2 * h_m * 6637000 - h_m * h_m);
        var a_l_m = 2 * Math.PI * r_m;
        var d_ab_gps_x_m = ((secondBound.lng - firstBound.lng) / 360) * a_l_m;
        var alpha = Math.atan2(d_ab_gps_y_m, d_ab_gps_x_m);
    
        var ang = alpha * (180 / Math.PI);
        return ang;
      }
    
      function calculateAngoloFoto() {
        var c = Math.atan2(props.height2 - props.height1, props.width2 - props.width1);
        var ang = (c * (-180 / Math.PI)) % 360;
        return ang;
      }
    
      function calculateDiffGradi() {
        var diffGradi = calculateAngoloFoto() - calculateAngoloMappa();
        return diffGradi;
      }
    
      function calculateZoomFoto() {
        var d_ab_gps_y_m = (secondBound.lat - firstBound.lat) * 111121;
        var h_m = 6637000 * (1 - Math.sin((firstBound.lat * Math.PI) / 180));
        var r_m = Math.sqrt(2 * h_m * 6637000 - h_m * h_m);
        var a_l_m = 2 * Math.PI * r_m;
        var d_ab_gps_x_m = ((secondBound.lng - firstBound.lng) / 360) * a_l_m;
    
        var diagReal = Math.sqrt(
          (props.width2 - props.width1) * (props.width2 - props.width1) +
            (props.height2 - props.height1) * (props.height2 - props.height1)
        );
    
        var heightBounds = props.widthImage * (d_ab_gps_y_m / d_ab_gps_x_m);
    
        //console.log("Height Bounds: " + heightBounds);
    
        var diagPointers = Math.sqrt(
          heightBounds * heightBounds +  props.widthImage *  props.widthImage
        );
    
        var zoom = diagPointers / diagReal;
        return zoom;
      }
    
      function calculateTranselateSxPerc() {
        var TransSx = (( props.width2 +  props.width1) * 100) / (2.0 *  props.widthImage);
        return TransSx;
      }
    
      function calculateTranselateTopPerc() {
        var TransTop = (( props.height2 +  props.height1) * 100) / (2.0 *  props.heightImage);
        return TransTop;
      }

      function reposition(){
        setIndex(0)
        setText(0)
        setFirstBound(null)
        setSecondBound(null)
        setMarkers([])
      }

      
  return (
    <div style={{width:'80vw',height:'80vh',marginLeft:'auto',marginRight:'auto',marginTop:'5vh',display:'flex'}}>
        <div style={{width:'35vw',height:'80vh',borderRight:'1px solid lightgrey'}}>
            <div style={{}}>
            <h4 className='normal-text' style={{marginBottom:'1vh'}}>Inserisci l'indirizzo della tua sede:</h4>
        {isLoaded?<Places setCenter={setCenter}/>:null}
            </div>
            {isLoaded&&center.lat!==0&&center.lng!==0?<div style={{marginTop:'2vh',marginBottom:'1.5vh'}}>
              <div style={{display:'flex',height:'4vh',alignItems:'center'}}>
              <div style={{width:'3px',border:'1px solid #14213d',height:'10px',backgroundColor:'#14213d',marginBottom:text===0?'auto':null}}></div>
              {text===0||text===1?<p className='normal-cool' style={{marginLeft:'5px'}}><b>Istruzioni:</b> {text===0?'Trova il tuo edificio e clicca sulla posizione corrispondente al ':'Ora clicca sulla posizione corrispondente al '}
              <b style={{color:text===0?'#192bc2':'red'}}>{text===0?'PUNTO 1 ':'PUNTO 2'}</b> {text===0?'precedentemente piazzato (FOTO A DESTRA)':null}
              </p>:text===2?<div style={{display:'flex'}}><p className='normal-cool' style={{marginLeft:'5px'}}>Clicca in un punto qualunque della mappa per posizionare la tua planimetria.</p>

              </div>:<div style={{display:'flex',alignItems:'center'}}><p className='normal-cool' style={{marginLeft:'5px'}}>Posizionamento della planimetria completato.</p>
              <button className='text-buttons' onClick={submitPlanimetria}  style={{borderRadius:0,height:'fit-content',marginLeft:'2vw',display:'flex',alignItems:'center',}}>Conferma<CheckIcon style={{fontSize:'15px'}}/></button>
              <button className='text-buttons2' onClick={()=>reposition()} style={{borderRadius:0,height:'fit-content',marginLeft:'10px',display:'flex',alignItems:'center',color:'black',borderBottom:'1px solid black'}}>Riposiziona<AutorenewIcon style={{color:'black',fontSize:'15px'}}/></button>

              </div>}
              </div>
            </div>:null}
           {isLoaded&&center.lat!==0&&center.lng!==0?<Map/>:null}
        </div>
        <div>
        <div style={{ width: "35rem", position: "relative",marginLeft:'8vw' }}>
        <div
                style={{
                  position: "absolute",
                  backgroundColor: "blue",
                  top: props.height1 + "px",
                  left: props.width1 + "px",
                  width: "1rem",
                  height: "1rem",
                  borderRadius: 50,
                  padding:2,
                  display:'flex',
                  flexDirection:'column',
                  alignItems:'center',
                  justifyContent:'center'
                }}
              ><h4 style={{margin:0,color:'white',width:'fit-content',height:'fit-content',fontSize:'15px'}}>1</h4></div>
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "red",
                  top: props.height2 + "px",
                  left: props.width2 + "px",
                  width: "1rem",
                  height: "1rem",
                  borderRadius: 50,
                  padding:2,
                  display:'flex',
                  flexDirection:'column',
                  alignItems:'center',
                  justifyContent:'center'
                }}
              ><h4 style={{margin:0,color:'white',width:'fit-content',height:'fit-content',fontSize:'15px'}}>2</h4></div>
        <img src={props.planimetria} alt='planimetria' 
                style={{ width: "100%" }}
></img>
        </div>
        </div>
    </div>
  )
}

export default Sezione3