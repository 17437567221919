import React , { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import firebase from "firebase/app";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getUtente,getSede,formatData } from "../../EngineClienti/engineClienti";
import { getDocumento } from '../../EngineOffritore/engineOffritore';
import Loading from '../../loading';
import NavBar2 from '../../NavBar/NavBar2';

const ModificaScadenzaOffritore = ({history}) => {

    const auth = firebase.auth();
    const db = firebase.firestore();
    const {id} = useParams()

    const [user,setUser] = useState(null)
    const [documento,setDocumento] = useState(null)
    const [sede,setSede] = useState(null)

    const [selectedData,setSelectedData] = useState('')


    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
            getUtente(db,auth,setUser)
          }
        })
      },[auth])

      useEffect(()=>{
        if(user!==null){
         getDocumento(db,id,setDocumento)
      }
    
      },[user])

      useEffect(()=>{
        if(documento!==null){
         getSede(db,documento.Autore,documento.Sede,setSede)   
      }
    
      },[documento])


      function addScadenza(){
        if(selectedData!==''){
    
          const dateSplitted = selectedData.split("-");
          const year = dateSplitted[0];
          const month = dateSplitted[1];
          const day= dateSplitted[2];
          const hours = '12';
          const minutes = '00';
          const arr = JSON.parse(sessionStorage.getItem('documenti'));

          var indexD = arr.findIndex((e)=>e.Id===id)
          arr[indexD].Scadenza = selectedData
          
          sessionStorage.setItem('documenti', JSON.stringify(arr));
                        
          db.collection("Documenti")
          .doc(id)
          .update({
            Scadenza:selectedData,
          }); //aggiorna doc
    
          db.collection("Manutenzioni")
          .doc(documento.Act_ID)
          .update({
            Data:selectedData,
          }); //aggiorna man
    
          db.collection("Users")
          .doc(documento.Autore)
          .collection("Annotazioni")
          .doc(documento.Act_ID)
          .update({
            Day: day,
            Month: month,
            Year: year,
            startHours: hours,
            startMinutes: minutes,
            endHours: hours,
            endMinutes: minutes,
          }); //aggiorna ann cli
          db.collection("Users")
          .doc(user.Email)
          .collection("Annotazioni")
          .doc(documento.Act_ID)
          .update({
            Day: day,
            Month: month,
            Year: year,
            startHours: hours,
            startMinutes: minutes,
            endHours: hours,
            endMinutes: minutes,
          }); //aggiorna ann off
                var datee = new Date();
                var month2 = ('0'+(datee.getMonth()+1)).slice(-2);
                var minutess =  ('0'+(datee.getMinutes())).slice(-2);
    
                  var dataFormattata = datee.getDate()+"-"+month2+"-"+datee.getFullYear()+" "+(datee.getHours())+":"+minutess;
              db.collection("Users").doc(documento.Autore).collection("Notifiche").doc().set({
                Nome_Azienda:user.Nome_Azienda,
                Nome_Cognome:user.Nome+" "+user.Cognome,
                Email:user.Email,
                Messaggio:"ha modificato una scadenza",
                Data:dataFormattata,
                Oggetto: documento.NomeFile
              }) //crea not off
            
           
            alert('Scadenza aggiornata, controlla il calendario per visualizzarla'+documento.Act_ID)
            history.goBack()
      }else{
        alert("Errore, inserire data di scadenza")
      }
      }

  return (
    <div className="background" style={{width:'100vw',height:'100vh'}}>
      <NavBar2/>
        <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
        <div  style={{  width: "30vw",display:'flex',marginTop:'1.2rem' }}>
          <button style={{marginBottom:'auto',marginTop:'1rem'}} onClick={() => history.goBack()}
            className="cancelIconButton"
            
          >
            <ArrowBackIcon
             className="back-button"
            />
          </button>
          <div>
          <h4 className="page-title" style={{margin:0}}>MODIFICA SCADENZA</h4>
          <h4
          className="sub-text-b"
        >
          {documento?documento.NomeFile:null} - {documento?documento.Sede+' ('+documento.Categoria+')':null}
        </h4>
        <h4
          className="sub-text"
          style={{marginTop:'1rem'}}
        >
          Data Selezionata: <b>{documento?formatData(documento.Scadenza):null}</b>
        </h4>
          </div>

        </div>
        <div style={{marginLeft:'3.5rem',marginTop:'2rem',width:'30rem'}}>
{/*SELEZIONE DATA */}
<h4  className="sub-text-b">Seleziona nuova data:</h4>
        <input type='date' style={{marginTop:'0.5rem'}} className="input-style"
         onChange={(e) => {
            setSelectedData(e.target.value);
          }} placeholder="Inserisci..."/>
            <br/>
          <button className='main-button' style={{marginTop:'1rem'}} onClick={()=>addScadenza()}>Aggiungi</button>
        </div>
        </div>
        </div>
  )
}

export default ModificaScadenzaOffritore