//componente con descrizione di ciò che mysic offre
import '../Style/prelogin.css'
import dash from '../../images/screendash.JPG'
import doc from '../../images/documenti.jpg'
import ele from '../../images/elementi.JPG'
import int from '../../images/interventi.JPG'
import man from '../../images/manutenzione.JPG'
import map from '../../images/mappe.JPG'


export default function GridLayout() {

    return (
        <div style={{width:'100vw',overflowX:'hidden',borderTop:'2px solid lightgrey',paddingTop:10,backgroundColor:'#d6eeff38'}}>
            <div className='pre-container' >
            <div></div> 
                <div id='more' className='pre-content'>
                <h4 style={{fontSize:50,color:'#0496ff',fontFamily:'Poppins',marginTop:0, marginBottom:0,width:'fit-content'}}>Scopri di più su Mysic</h4>
            </div>
            <div>
            </div>
            <div className='pre-content'>
               
            </div>

        </div>
 <div id='more1' className='pre-container' >
            <div></div> 
                <div className='pre-content'>
                <h4 className='pre-header'>
                    Dashboard
                </h4>
                <h4 className='pre-text'>
                Gestisci il tuo account tramite la Dashboard di Mysic! <br/>
                Potrai gestire le tue notifiche, visualizzare le scadenze tramite calendario, tenere sotto controllo i tuoi consumi, visualizzare i tuoi clienti o manutentori e molto altro ancora. 
                </h4>
                <div style={{marginTop:15}} className='pre-img'>
                <img src={dash} style={{width:'100%',}} alt='dashboard'>
                </img>
                </div>
            </div>
            <div>
            </div>
            <div className='pre-content'>
                <h4 className='pre-header'>
                    Documenti
                </h4>
                <h4 className='pre-text'>
                Abbi la sicurezza di avere i tuoi documenti sempre a portata di mano! <br/>
                Con Mysic potrai caricare tutti i documenti relativi alla tua sicurezza aziendale, impostare la presenza o meno dei documenti essenziali e pianificarne le scadenze!
                </h4>
                <div style={{marginTop:15}} className='pre-img'>
                <img src={doc} style={{width:'100%',}} alt='documenti'>
                </img>
                </div>
            </div>

        </div>
        <div id='more2' className='pre-container' >
            <div></div>
            
            
                <div className='pre-content'>
                <h4 className='pre-header'>
                    Elementi
                </h4>
                <h4 className='pre-text'>
                Inserisci gli elementi presenti nelle tue sedi! <br/>
                Che sia tramite Excel o manualmente, con Mysic potrai aggiungere tutti i tuoi elementi, visualizzarne i dettagli, gli interventi e le manutenzioni associate. 
                </h4>
                <div style={{marginTop:15}} className='pre-img'>
                <img src={ele} style={{width:'100%',}} alt='elementi'>
                </img>
                </div>
            </div>
            <div>

            </div>
            <div className='pre-content'>
                <h4 className='pre-header'>
                    Interventi
                </h4>
                <h4 className='pre-text'>
               Ti si è rotto un estintore o una lampada d'emergenza? <br/>
                Nessun problema, con Mysic potrai richiedere interventi di riparazione ai tuoi manutentori, visualizzarli e avere sempre sotto controllo il loro stato.
                </h4>
                <div style={{marginTop:15}} className='pre-img'>
                <img src={int} style={{width:'100%',}} alt='interventi'>
                </img>
                </div>
            </div>

        </div>
        <div id='more3' className='pre-container' >
            <div></div>
                <div className='pre-content'>
                <h4 className='pre-header'>
                    Manutenzioni
                </h4>
                <h4 className='pre-text'>
                Visualizza le tue manutenzioni periodiche! <br/>
                Tramite Mysic potrai visualizzare la lista delle tue manutenzioni, vederne lo stato e avere sempre a portata di mano un report completo. 
                </h4>
                <div style={{marginTop:15}} className='pre-img'>
                <img src={man} style={{width:'100%',}} alt='manutenzioni'>
                </img>
                </div>
            </div>
            <div>

            </div>
            <div className='pre-content'>
                <h4 className='pre-header'>
                    Mappe
                </h4>
                <h4 className='pre-text'>
                Visualizza la tua planimetria popolata! <br/>
                Mysic sfrutta Google Maps per consentire di inserire le tue planimetrie e popolarle con le posizioni reali dei tuoi elementi.
                </h4>
                <div style={{marginTop:15}} className='pre-img'>
                <img src={map} style={{width:'100%',}} alt='mappe'>
                </img>
                </div>
            </div>

        </div>
        </div>
       
    )

}